<template>
  <div class="carousel-item " :class="{ 'active': index === 0 }">
    <div class="clearfix"></div>
    <div class="product-scroll">
      <div class="row">
        <div class="col-md-6 col-sm-3 col-4">
          <router-link :to="{ name: 'Detail', params: {id: product.id, slug: slug}}">
            <img :src="product.main_image" :alt="product.short_name" :title="product.name" class="img-fluid">
          </router-link>
        </div>
        <div class="col-md-6 col-sm-9 col-8">
          <router-link :to="{ name: 'Detail', params: {id: product.id, slug: slug}}">
            <h3 class="mb-2">{{product.short_name}}</h3>
          </router-link>
          <h4 v-if="isDiscounted()" class="text-danger text-0 mb-1">{{product.price}} ₽ </h4>
          <h4>{{product.discounted_price}} ₽</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {slugify} from "@/common/slugify.js";

  export default {
    name: "PopularSidebar",
    props: {
      product: {
        type: Object,
        required: true
      },
      index: {
        type: Number,
        required: true
      }
    },
    computed: {
      slug() {
        return slugify(this.product.name)
      },
    },
    methods: {
      isDiscounted() {
        return this.product.discount_size > 0;
      },
    }
  }
</script>

<style scoped>
  .product-scroll .col-md-6 h4 {
    font-size: 16px;
  }

  h3 {
    width: 90%
  }

  @media (max-width: 1499px) {
    .product-scroll .col-md-6 h3 {
      font-size: 14px;
      margin-top: 10px;
    }
  }
</style>