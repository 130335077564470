<template>
  <div>
    <section id="wrapper">
      <section>
        <div class="inner-bg">
          <div class="inner-head wow fadeInDown">
            <h3>Корзина</h3>
          </div>
        </div>
      </section>
      <!--container-->
      <div class="container">
        <div class="shop-in">
          <!--breadcrumbs -->
          <div class="bread2">
            <ul>
              <li>
                <router-link to="/">На главную</router-link>
              </li>
              <li>/</li>
              <li>
                <router-link to="/products">Каталог</router-link>
              </li>
              <li>/</li>
              <li>Корзина</li>
            </ul>
          </div>
          <!--breadcrumbs -->
          <div class="clearfix"></div>
          <!--table-->
          <div class="checkout">
            <div class="table-responsive table-none wow fadeIn">
              <table class="table checkout-table">
                <tr class="table-h">
                  <td>&nbsp;</td>
                  <td>Название</td>
                  <td>Цена</td>
                  <td>Количество</td>
                  <td>Удалить</td>
                  <td>Всего</td>
                </tr>
                <CartProductCard
                  v-for="product in products"
                  :product="product"
                  :key="product.id"
                  v-on:removeFromBasket="removeFromBasket"
                  v-on:recount="recount"
                />
              </table>
            </div>
            <div class="table-responsive table-none2 wow fadeIn">
              <MobileCartProductCard
                v-for="product in products"
                :product="product"
                :key="product.id"
                v-on:removeFromBasket="removeFromBasket"
                v-on:recount="recount"
              />
            </div>
            <div class="col-md-6 col-sm-6 pull-right mb-3">
              <div v-if="discounted_total" class="subtotal boder-b">
                <h2>Всего к оплате: <span class="text-0 text-danger">{{total}} руб. </span></h2>
                <h2>{{discounted_total}} руб.</h2>
              </div>
              <div v-else class="subtotal wow fadeIn">
                <h2>Всего к оплате: {{total}} руб.</h2>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
          <div class="clearfix"></div>
          <br>
          <div class="row wow fadeInDown">
            <div class="col-md-6 col-sm-6">
              <div class="discount-div">
                <div class="row align-items-center promocode-row">
                  <span>ПРОМОКОД</span>
                  <form
                    @submit.prevent="checkPromocode"
                  >
                    <input v-model="promocode" type="text" class="discount">
                    <input type="submit" value="Применить" class="apply">
                  </form>
                </div>
                <div class="clearfix"></div>
                <br>
                <div class="row">
                  <div class="col-md-3 col-sm-3 d-none d-sm-block text-center"><img src="../assets/images/products/icon.png" alt="Введите промокод" class="img-fluid"></div>
                  <div class="col-md-9 shipping col-sm-9 col-xs-12">
                    <h4 v-if="promocodeError" class="text-danger">Вы ввели неверный или неактивный промокод</h4>
                    <h4 v-else-if="promocodeSuccess" class="text-success">Промокод активирован</h4>
                    <h4 v-else>Скидка по промокоду не распространется на товары с другими скидками</h4>
                  </div>
                </div>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12 pull-right ">
              <div class="subtotal">
                <div class="secure">
                  <router-link to="/checkout" v-if="products.length !== 0">Перейти к оплате</router-link>
                </div>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="clearfix"></div>
        </div>
        <!--table-->
        <div class="clearfix"></div>
      </div>
    </section>
    <Footer
    />
  </div>
</template>

<script>
  import {apiService} from "@/common/api.service.js";
  import CartProductCard from "@/components/CartProductCard";
  import MobileCartProductCard from "@/components/MobileCartProductCard";
  import Footer from "@/components/Footer";


  export default {
    name: "Cart",
    components: {
      CartProductCard,
      MobileCartProductCard,
      Footer
    },
    metaInfo: {
      title: 'Корзина',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Корзина товаров интернет-магазина Амбервиль'
        },
      ],
    },
    data() {
      return {
        products: [],
        promocode: '',
        promocodeError: false,
        promocodeSuccess: false,
        discounted_total: null,
        get total() {
          let total = 0;
          let basket = JSON.parse(localStorage.basket);
          this.products.forEach(function (product) {
            total += product.discounted_price * basket[product.uid]
          });
          return total
        },
      }
    },
    computed: {
      promocode_discount() {
        return this.$store.state.promocode_discount
      }
    },
    methods: {
      getBasketProducts() {
        try {
          let basket = JSON.parse(localStorage.basket);
          Object.keys(basket).forEach(function (key) {
            let endpoint = "/api/product/uid/";
            endpoint += '?uid=' + key;
            this.$store.commit('changeLoading');
            apiService(endpoint)
              .then(data => {
                if (data) {
                  if (data.quantity <= 0) {
                    this.$store.commit('changeLoading');
                    delete basket[key];
                    basket = JSON.stringify(basket);
                    localStorage.setItem('basket', basket);
                    this.$store.dispatch('get_basket_quantity')
                  } else {
                    this.products.push(data);
                    this.$store.commit('changeLoading');
                  }
                } else {
                  this.$store.commit('changeLoading');
                  try {
                    delete basket[key];
                  } catch (e) {
                    localStorage.basket = '{}';
                  }
                  basket = JSON.stringify(basket);
                  localStorage.setItem('basket', basket);
                  this.$store.dispatch('get_basket_quantity')
                }
              });
          }, this)
        } catch (e) {
          localStorage.basket = '{}';
          let basket = JSON.parse(localStorage.basket);
        }
      },
      removeFromBasket(uid) {
        this.products = this.products.filter(product => product.uid !== uid);
      },
      recount() {
        this.$forceUpdate()
      },
      checkPromocode() {
        let endpoint = "/api/checkpromocode/";
        endpoint += '?code=' + this.promocode;
        this.$store.commit('changeLoading');
        apiService(endpoint)
          .then(data => {
            if (data.success === 'true') {
              this.promocodeSuccess = true;
              this.promocodeError = false;
              this.$store.commit('setVerifiedPromocode', this.promocode);
              this.$store.commit('setPromocodeDiscount', data.discount_size)
            } else {
              this.promocodeSuccess = false;
              this.promocodeError = true;
            }
            this.$store.commit('changeLoading');
          });
      },
      get_discounted_total() {
        if (this.promocode_discount) {
          let promocode_discount = this.promocode_discount
          let discounted_total = 0;
          let basket = JSON.parse(localStorage.basket);
          this.products.forEach(function (product) {
            if (product.discount_size === 0) {
              discounted_total += product.price * basket[product.uid] * (promocode_discount / 100)
            }
          });
          if (discounted_total > 0) {
            return this.discounted_total = this.total - Math.round(discounted_total)
          } else {
            return this.discounted_total = null
          }
        } else {
          return this.discounted_total = null
        }
      }
    },
    created() {
      this.getBasketProducts()
    },
    updated() {
      this.get_discounted_total()
    }
  }
</script>

<style scoped>

  .subtotal h2 {
    margin-top: 10px;
  }

  .discount {
    width: 170px;
  }

  .checkout-table {
    border-collapse: separate;
    border-spacing: 0 15px;
  }

  .checkout-table td {
    border: none;
    background-color: #dfb859;
  }

  @media (max-width: 991px) {
    .apply {
      font-size: 9px;
      transform: translate(0, -2px);
    }

    .subtotal {
      max-width: 100%;
    }

    .subtotal h2 {
      font-size: 18px;
    }

  }

  @media (max-width: 767px) {
    .subtotal h2 {
      margin-top: 30px;
      font-size: 17px;
    }

    .shipping h4 {
      font-size: 11px;
    }

    .discount {
      width: 130px;
    }

    .apply {
      letter-spacing: 0;
      width: 65px;
    }
  }

  @media (max-width: 641px) {
    .discount {
      width: 100px;
    }
  }

  @media (max-width: 575px) {
    .discount {
      width: 130px;
    }

    .subtotal {
      max-width: inherit;
    }

    .promocode-row {
      justify-content: center;
    }
  }


</style>