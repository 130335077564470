<template>
  <div id="wrapper">
    <!--page heading-->
    <section>
      <div class="inner-bg">
        <div class="inner-head wow fadeInDown">
          <h3>Пароль изменен</h3>
        </div>
      </div>
    </section>
    <!--page heading-->
    <!--container-->
    <div class="container">
      <div class="shop-in pl-0 pr-0">
        <div>
          <div class="bread2">
            <ul>
              <li>
                <router-link to="/">На главную</router-link>
              </li>
              <li>/</li>
              <li>Восстановление пароля</li>
            </ul>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="thanks-bg">
          <div class="thanks-img wow fadeIn"><img src="../assets/images/products/thanks.png" alt="Спасибо" class="img-fluid"></div>
          <div class="thanks-text">
            <h2 class="wow fadeIn">Ваш пароль восстановлен</h2>
            <h3 class="wow fadeIn">Вы можете войти в аккаунт с новым паролем через меню или из личного кабинета</h3>
            <div>
              <router-link to="/" class="wow fadeInLeft">Вернуться на главную</router-link>
              <router-link to="/" class="wow fadeInRight">Личный кабинет</router-link>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="clearfix"></div>
    </div>
    <!--container-->
    <div class="clearfix"></div>
    <!--footer-->
    <Footer
    />
  </div>
</template>

<script>
  import Footer from "@/components/Footer";

  export default {
    name: "PasswordRestored",
    components: {
      Footer,
    },
    metaInfo: {
      title: 'Пароль восстановлен'
    },
  }
</script>

<style scoped>

</style>