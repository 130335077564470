<template>
  <ul class="m-0 p-0">
    <li class="mb-3">
      <span class="clear-choice" @click="unselect" v-if="select_toggle">Очистить всё</span>
      <span class="clear-choice" @click="select" v-else>Выбрать всё</span>
    </li>
    <li v-for="category in categories">
      <label>
        <input type="checkbox" v-model="selected" :value="category.id" name="filter" @change="filterCategory(category.id, $event)">
        {{category.name}} <span>{{category.product_count}}</span> </label>
    </li>
  </ul>
</template>

<script>
  import {apiService} from "@/common/api.service.js";

  export default {
    name: "CategoryList",
    data() {
      return {
        categories: [],
        selected: [],
        select_toggle: true,
      }
    },
    props: {
      group: {
        type: Number,
        required: true,
      },
      filter: {
        type: Array,
      }
    },
    methods: {
      getCategories() {
        let endpoint = "/api/categories/?group=" + this.group;
        apiService(endpoint)
          .then(data => {
            this.categories = data;
            data.forEach(function (category) {
              this.selected.push(category.id);
            }, this);
          })
      },
      filterCategory(cat, event) {
        if (event.target.checked) {
          let pos = this.filter.indexOf(cat);
          this.filter.splice(pos, 1);
        }
        if (!event.target.checked) {
          this.filter.push(cat);
        }
      },
      unselect() {
        this.selected = [];
        this.categories.forEach(function (category) {
          if (!this.filter.includes(category.id)) {
            this.filter.push(category.id);
          }
        }, this);
        this.$emit('selectFilter');
        this.select_toggle = !this.select_toggle;
      },
      select() {
        this.categories.forEach(function (category) {
          if (!this.selected.includes(category.id)) {
            this.selected.push(category.id);
          }
          if (this.filter.includes(category.id)) {
            let pos = this.filter.indexOf(category.id);
            this.filter.splice(pos, 1);
          }
        }, this);
        this.$emit('selectFilter');
        this.select_toggle = !this.select_toggle;
      },
    },
    created() {
      this.getCategories();
    }
  }
</script>

<style scoped>
  .clear-choice {
    color: #9b9b9b;
    cursor: pointer;
    float: left !important;
  }

  .clear-choice:hover {
    color: #c3c3c3;
  }
</style>