<template>
  <div id="wrapper">
    <!--page heading-->
    <section>
      <div class="inner-bg">
        <div class="inner-head wow fadeInDown">
          <h3>Ссылка восстановления пароля отправлена</h3>
        </div>
      </div>
    </section>
    <!--page heading-->
    <!--container-->
    <div class="container">
      <div class="shop-in pl-0 pr-0">
        <div>
          <div class="bread2">
            <ul>
              <li>
                <router-link to="/">На главную</router-link>
              </li>
              <li>/</li>
              <li>Восстановление пароля</li>
            </ul>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="thanks-bg">
          <div class="thanks-text">
            <h2 class="wow fadeIn error-heading">Теперь вы можете восстановить пароль</h2>
            <h3 class="wow fadeIn">На Вашу электронную почту было отправлено письмо с ссылкой восстановления пароля<br> Перейдите по ссылке в письме, чтобы восстановить пароль</h3>
            <div>
              <router-link to="/" class="wow fadeInLeft">Вернуться на главную</router-link>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="clearfix"></div>
    </div>
    <!--container-->
    <div class="clearfix"></div>
    <!--footer-->
    <Footer
    />
  </div>
</template>


<script>
  import Footer from "@/components/Footer";

  export default {
    name: "Restore",
    components: {
      Footer,
    },
    metaInfo: {
      title: 'Восстановление пароля',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Восстановить пароль пользователя интернет-магазина Амбервиль'
        },
      ],
    },
  }
</script>

<style scoped>
  .error-heading {
    margin-top: 10%;
  }
</style>