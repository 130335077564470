<template>
  <nav class="navbar navbar-custom navbar-fixed-top">
    <div class="navbar-header">
      <div>
        <div>
          <router-link to="/" class="navbar-brand">
            <img class="img-fluid" alt="Купить янтарные украшения" title="Купить янтарные украшения" src="../assets/images/logo.png">
          </router-link>
        </div>
        <div>
          <a class="logo-phone" href="tel:+79062385616">+7 (9062) 38-56-16</a>
        </div>
      </div>
      <img class="loading mr-1" v-show="$store.state.loading" alt="Загрузка" src="/static/images/loading.gif">
      <div class="text-white m-0 pt-3 mb-1 fade-alert" v-show="$store.state.username">
        <p class="fade-alert-text">Добро пожаловать, {{ username }}</p>
      </div>
    </div>
    <span class="nav_trigger" ref="menuButton" @click="setMenu()"><i class="fa" :class="menuIcon"></i></span>
    <ul class="navbar-nav2">
      <li class="search-div">
        <div id="sb-search" class="sb-search">
          <form
            id='searchForm'
            @submit.prevent="search()">
            <input class="sb-search-input" v-model="searchInput" placeholder="Поиск ..." type="text" value="" name="search" id="search">
            <input class="sb-search-submit" type="submit" value="">
            <span class="sb-icon-search"></span>
          </form>
        </div>
      </li>
      <li>
        <router-link to="/cabinet" v-if="$store.state.username" class="cabinet mr-3 ml-1"><i class="fa fa-user" aria-hidden="true"></i>
        </router-link>
      </li>
      <li>
        <router-link to="/cart"><i class="fa fa-shopping-cart" aria-hidden="true"></i> <span class="round">{{ basket_quantity }}</span></router-link>
      </li>
    </ul>
    <div class="navbar-links">
      <a class="link12" @click.prevent="goToProducts(0)" href="/products">Каталог</a>
      <a class="link11">|</a>
      <a class="link11" @click.prevent="searchProducts('Серьги')" href="/products/?search=Серьги">Серьги</a>
      <a class="link10" @click.prevent="searchProducts('Кольца')" href="/products/?search=Кольца">Кольца</a>
      <a class="link9" @click.prevent="searchProducts('Колье')" href="/products/?search=Колье">Колье</a>
      <a class="link8" @click.prevent="searchProducts('Браслеты')" href="/products/?search=Браслеты">Браслеты</a>
      <a class="link7" @click.prevent="searchProducts('Подвески')" href="/products/?search=Подвески">Подвески</a>
      <a class="link6" @click.prevent="searchProducts('Бусы')" href="/products/?search=Бусы">Бусы</a>
      <a class="link5" @click.prevent="goToProducts(6)" href="/products/?group=6">Подарки</a>
      <a class="link4" @click.prevent="goToProducts(7)" href="/products/?group=7">Косметика</a>
      <a class="link3">|</a>
      <router-link class="link3" to="/about">О нас</router-link>
      <router-link class="link2" to="/testimonials">Отзывы</router-link>
      <router-link class="link1" to="/cart">Корзина</router-link>
    </div>
  </nav>
</template>

<script>
  export default {
    name: "Navbar",
    data() {
      return {
        searchInput: undefined,
        menuOpen: false,
      }
    },
    computed: {
      basket_quantity() {
        return this.$store.state.basketQuantity
      },
      username() {
        return this.$store.state.username
      },
      menuIcon() {
        return (this.menuOpen) ? "fa-times" : "fa-navicon "
      },
      activeGroup() {
        return this.$store.state.activeGroup
      },
      searchValue() {
        return this.$store.state.search_value
      },
    },
    methods: {
      triggerMenu() {
        this.$refs.menuButton.click()
      },
      search() {
        if (this.$router.currentRoute.name !== 'Products') {
          this.$router.push({name: 'Products', query: { search: this.searchInput }})
        } else {
          this.$store.commit('setSearchValue', this.searchInput);
        }
      },
      setMenu() {
        this.menuOpen = !this.menuOpen;
        document.body.classList.toggle('show_sidebar');
        document.body.classList.toggle('body-noscroll');
      },
      goToProducts(group) {
        if (this.activeGroup === group) {
          this.$store.commit('setSearchValue', '');
          this.reloadProducts();
        }
        this.$store.commit('changeGroup', group);
        if (this.$router.currentRoute.name !== 'Products') {
          if (this.activeGroup !== 0) {
            this.$store.commit('switchProductIgnore')
          }
          this.$router.push({name: 'Products'})
        } else {
          window.scrollTo(0, 0);
        }
      },
      searchProducts(value) {
        if (this.searchValue === value) {
          this.$store.commit('reloadProduct');
        }
        if (this.$router.currentRoute.name !== 'Products') {
          this.$store.commit('changeGroup', 0);
          this.$router.push({name: 'Products', query: { search: value }})
        } else {
          this.$store.commit('setSearchValue', value);
          if (this.activeGroup !== 0) {
            this.$store.commit('changeGroup', 0);
            this.$store.commit('switchActiveIgnore')
          }
          window.scrollTo(0, 0);
        }
      },
      reloadProducts() {
        this.$store.commit('reloadProduct');
      },
    },
    created() {
    }
  }
</script>

<style scoped>
  .navbar-header {
    display: flex;
    align-items: center;
    padding: 0 80px;
  }

  .navbar-header a {
    color: white;
  }

  .navbar-header a:hover {
    text-decoration: underline !important;
  }

  .loading {
    max-width: 100%;
    height: 50px;
  }

  .navbar-brand {
    margin-right: 0;
    padding: 0;
  }

  .navbar-custom img {
    height: 50px;
  }

  .fade-alert {
    animation: fadeInOut 6s linear, forwards;
    animation-delay: 1s;
    opacity: 0;
    position: relative;
    overflow: visible;
  }

  .fade-alert-text {
    position: absolute;
    width: 600px;
    margin-top: 5px;
  }

  .navbar-links {
    margin-top: 12px;
  }

  .navbar-links a:hover {
    color: #d19e66
  }

  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  body.show_sidebar .nav_trigger {
    margin-right: 0;
  }

  .nav_trigger {
    margin: 10px 0 0 0;
    padding: 4px 20px 0 50px;
  }

  .navbar-nav2 {
    margin-top: 16px;
  }

  .navbar-links {
    display: flex;
    align-items: center;
    height: 50px;
  }

  .navbar-links a {
    color: white;
    font-weight: 500;
    margin-right: 10px;
    white-space: nowrap;
    position: relative;
    z-index: 10;
  }

  .navbar-custom {
    padding: 15px 0;
  }

  @media (max-width: 1599px) {
    .link1 {
      display: none;
    }
  }

  @media (max-width: 1519px) {
    .link2 {
      display: none;
    }
  }

  @media (max-width: 1454px) {
    .link3 {
      display: none;
    }
  }

  @media (max-width: 1379px) {
    .link4 {
      display: none;
    }
  }

  @media (max-width: 1289px) {
    .link5 {
      display: none;
    }
  }

  @media (max-width: 1214px) {
    .link6 {
      display: none;
    }
  }

  @media (max-width: 1164px) {
    .link7 {
      display: none;
    }
  }

  @media (max-width: 844px) {
    .fade-alert-text {
      display:none;

    }
  }

  @media (min-width: 768px) and (max-width: 1084px) {
    .link8 {
      display: none;
    }
  }

  @media (min-width: 768px) and (max-width: 924px) {
    .link9 {
      display: none;
    }
  }

  @media (min-width: 768px) and (max-width: 869px) {
    .link10 {
      display: none;
    }
  }

  @media (min-width: 768px) and (max-width: 804px) {
    .link11 {
      display: none;
    }
  }

  @media (max-width: 749px) {
    .link8 {
      display: none;
    }
  }

  @media (max-width: 678px) {
    .link9 {
      display: none;
    }
  }

  @media (max-width: 624px) {
    .link10 {
      display: none;
    }
  }

  @media (max-width: 568px) {
    .link11 {
      display: none;
    }
  }

  @media (max-width: 452px) {

    .link12 {
      margin-left: 10px;
    }

    .navbar-header {
      padding: 0 0 0 10px !important;
    }

    .cabinet {
      display: none;
    }
  }


  @media (max-width: 345px) {
    .link12 {
      display: none;
    }

  }

  @media (max-width: 991px) {
    .nav_trigger {
      padding: 4px 10px 0 30px;
    }

    .loading {
      height: 45px;
      margin-top: 5px;
    }

    .navbar-custom {
      padding: 10px 0 5px 0;
    }

    .navbar-header {
      padding: 0 80px 0 10px;
    }

    .logo-phone {
      margin-left: 10px;
    }

    .navbar-custom img {
      height: 50px;
    }
  }

  @media (max-width: 767px) {
    .loading {
      height: 35px;
      margin-top: 0;
    }

    .fade-alert {
      left: -98%;
    }

    .navbar-brand {
      margin-bottom: 5px;
    }
  }

  @media (max-width: 480px) {
    .nav_trigger {
      padding: 4px 20px 0 10px;
    }

    .loading {
      height: 24px;
    }

    .navbar-brand {
      padding-right: 0;
    }
  }

  @media (max-width: 360px) {
    .navbar-brand {
      width: inherit !important;
      padding: inherit !important;
    }

    .nav_trigger {
      font-size: 26px !important;
    }

  }
</style>