<template>
  <div id="wrapper">
    <!--page heading-->
    <section>
      <div class="inner-bg">
        <div class="inner-head wow fadeInDown">
          <h3>Мы получили ваше сообщение</h3>
        </div>
      </div>
    </section>
    <!--page heading-->
    <!--container-->
    <div class="container">
      <div class="shop-in pl-0 pr-0">
        <div>
          <div class="bread2">
            <ul>
              <li><router-link to="/">На главную</router-link></li>
              <li>/</li>
              <li>Мы получили ваше сообщение</li>
            </ul>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="thanks-bg">
          <div class="thanks-img wow fadeIn"><img src="../assets/images/products/thanks.png" alt="Спасибо" class="img-fluid"></div>
          <div class="thanks-text">
            <h2 class="wow fadeIn">Спасибо за ваше сообщение</h2>
            <h3 class="wow fadeIn">Мы ответим Вам так скоро, как это возможно</h3>
            <div><router-link to="/" class="wow fadeInLeft">Вернуться на главную</router-link> <router-link to="/products" class="wow fadeInRight">Продолжить покупки</router-link></div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="clearfix"></div>
    </div>
    <!--container-->
    <div class="clearfix"></div>
    <!--footer-->
    <Footer
    />
  </div>
</template>

<script>
  import Footer from "@/components/Footer";

  export default {
    name: "ThankYouMessage",
    components: {
      Footer,
    },
    metaInfo: {
      title: 'Спасибо за сообщение',
    },

  }
</script>

<style scoped>

</style>