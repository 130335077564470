function handleResponse(response) {
  if (response.status === 204) {
    return '';
  } else if (response.status === 404) {
    return null;
  } else {
    return response.json();
  }
  
}

function apiService(endpoint, method, data, auth=null) {
  let config = {
    method: method || "GET",
    body: data !== undefined ? JSON.stringify(data) : null,
    headers: {
      'content-type': 'application/json',
    }
  };
  if (auth) {
    config.headers['Authorization'] = 'Token '+ auth
  }
  return fetch(endpoint, config)
           .then(handleResponse)
           .catch(error => console.log(error))
}

export { apiService };