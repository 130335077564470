<template>
  <div>
    <h1 class="text-center my-5">Верхний слайдер</h1>
    <div class="container top-slider">
      <div class='fluidHeight'>
        <div class='sliderContainer'>
          <div class='iosSlider'>
            <swiper :options="swiperOptions" ref="topSlider" class='swiper slider'>
              <swiper-slide v-for="slide in top_slider"
                            :slide="slide"
                            :key="slide.id"
              >
                <div class='title-css'>
                  <h4>{{ slide.text1 }}</h4>
                  <h3>{{ slide.text2 }}</h3>
                  <h5>{{ slide.text3 }}</h5>
                  <p class="shopbt row justify-content-between">
                    <a @click="top_slider_to_edit = slide.id"
                       data-toggle="modal"
                       data-target="#editTopSlider">Изменить</a>
                    <router-link :to="slide.link" class="hvr-icon-wobble-horizontal">{{ slide.text4 }}</router-link>
                  </p>
                </div>
                <img :src='slide.image' :alt="slide.text1 + ' ' + slide.text2 + ' ' + slide.text3 + ' ' + slide.text4" class="w-100"/>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
      <div class="add-slider row justify-content-center my-4">
        <a data-toggle="modal" data-target="#addTopSlider">Добавить слайд</a>
      </div>
    </div>
    <h1 class="text-center my-5">Коллекции</h1>
    <section class="content-section specific-module">
      <div class="div-center">
        <div class="row">
          <div class="col-md-3 col-sm-3 col-xs-6 text-center wow fadeIn" v-for="collection in collection_grid"
               :collection="collection"
               :key="collection.id">
            <div class="box-css">
              <router-link :to="collection.link"><img :src="collection.image" class="img-fluid" alt="" title="">
                <div class="opacitybox white">
                  <div class="boxcontent">
                    <div>
                      <h4 class="white">{{ collection.text }}</h4>
                      <img src="../assets/images/search.svg" width="35" alt="" title="">
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <a class="edit-slide mb-3" @click="collection_grid_to_edit = collection.id" data-toggle="modal"
               data-target="#editCollectionGrid">Редактировать</a>
          </div>
        </div>
      </div>
    </section>
    <h1 class="text-center my-5">Золото и Серебро</h1>
    <div class="img-div3">
      <div class="row">
        <div class="col-md-6 col-sm-6 wow fadeInLeft">
          <a class="right-img">
            <input @change="editGoldImage($event, gold_and_silver[0].id)" ref="editGoldImage" type="file" hidden>
            <div>
              <img :src="gold_and_silver[0].image" class="img-fluid grayscale" alt="Янтарь и золото"
                   v-if="gold_and_silver.length > 0" title="Янтарь и золото" @click="$refs.editGoldImage.click()">
            </div>
          </a>
          <div class="clearfix"></div>
        </div>
        <div class="col-md-6 col-sm-6 wow fadeInRight">
          <a class="right-img">
            <input @change="editSilverImage($event, gold_and_silver[1].id)" ref="editSilverImage" type="file" hidden>
            <div>
              <img :src="gold_and_silver[1].image" class="img-fluid grayscale" alt="Янтарь и серебро"
                   v-if="gold_and_silver.length > 0" title="Янтарь и серебро" @click="$refs.editSilverImage.click()">
            </div>
          </a>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
    <h1 class="text-center my-5">Типы изделий</h1>
    <section class="content-section category">
      <div class="category-in">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <ul class="row justify-content-center">
              <li v-for="type in product_types"
                  :type="type"
                  :key="type.id"
                  class="col-2 px-1 mx-0"
              >
                <div class="grid">
                  <router-link :to="type.link">
                    <figure class="effect-moses">
                      <img :src="type.image" :alt="type.text" :title="type.text" class="img-fluid type-img">
                    </figure>
                  </router-link>
                </div>
                <h2>{{ type.text }}</h2>
                <a class="edit-slide" @click="product_types_to_edit = type.id" data-toggle="modal"
                   data-target="#editProductTypes">Редактировать</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <h1 class="text-center my-5">Категории изделий</h1>
    <div class="section">
      <div class="masonry one_column full-width">
        <div class="content img-div">
          <ul>
            <li class="col-6 col-sm-4 col-md-3 wow fadeIn" v-for="category in category_grid">
              <div class="grid">
                <router-link :to="category.link">
                  <figure class="effect-apollo"><img :alt="category.text" :src="category.image"/>
                    <figcaption>
                      <h2>{{ category.text }}</h2>
                    </figcaption>
                  </figure>
                </router-link>
              </div>
              <a class="edit-slide" @click="category_grid_to_edit = category.id" data-toggle="modal"
                 data-target="#editCategoryGrid">Редактировать</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="modal fade" id="editTopSlider" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeEditTopSlider"
                    @click="top_slider_image_to_edit = undefined; top_slider_image_to_edit_url = undefined"
                    data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Редактировать слайд</h4>
          </div>
          <div class="modal-body">
            <form class="form-horizontal"
                  @submit.prevent="editTopSlider">
              <div class="form-group my-2">
                <label class="mb-1">Верхний текст</label>
                <input v-if="top_slider.length > 0" type="text" class="form-control" required v-model="topSliderEdit.text1">
              </div>
              <div class="form-group my-2">
                <label class="mb-1">Второй текст</label>
                <input v-if="top_slider.length > 0" type="text" class="form-control" required v-model="topSliderEdit.text2">
              </div>
              <div class="form-group my-2">
                <label class="mb-1">Третий текст</label>
                <input v-if="top_slider.length > 0" type="text" class="form-control" required v-model="topSliderEdit.text3">
              </div>
              <div class="form-group my-2">
                <label class="mb-1">Четвертый текст</label>
                <input v-if="top_slider.length > 0" type="text" class="form-control" required v-model="topSliderEdit.text4">
              </div>
              <div class="form-group my-2">
                <label class="mb-1">Ссылка</label>
                <input v-if="top_slider.length > 0" type="text" class="form-control" required v-model="topSliderEdit.link">
              </div>
              <div class="form-group my-2 text-center">
                <input @change="editTopSliderImage" ref="editTopSliderImage" type="file" hidden>
                <label class="mb-1">Изображение</label>
                <div v-if="top_slider_image_to_edit === undefined">
                  <img class="w-50" v-if="top_slider.length > 0" :src="topSliderEdit.image"
                       @click="$refs.editTopSliderImage.click()">
                </div>
                <div v-else>
                  <img class="w-50" :src="top_slider_image_to_edit_url"
                       @click="$refs.editTopSliderImage.click()">
                </div>

              </div>
              <div class="form-group my-3 mb-5">
                <button type="submit" class="btn btn-default button-1 float-right">Сохранить</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="deleteTopSlider" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeDeleteTopSlider" data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Удалить слайд?</h4>
          </div>
          <div class="modal-body text-center">
            <form class="form-horizontal"
                  @submit.prevent="deleteTopSlider()">
              <div class="form-group">
                <div class="col-sm-12">
                  <button type="submit" class="btn btn-default button-1 ">Удалить</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="addTopSlider" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeAddTopSlider" data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Добавить слайд</h4>
          </div>
          <div class="modal-body">
            <form class="form-horizontal"
                  @submit.prevent="addTopSlider">
              <div class="form-group my-2">
                <label class="mb-1">Верхний текст
                  <input v-if="top_slider.length > 0" type="text" class="form-control" required v-model="new_top_slider_text1">
                </label>
              </div>
              <div class="form-group my-2">
                <label class="mb-1">Второй текст
                  <input v-if="top_slider.length > 0" type="text" class="form-control" required v-model="new_top_slider_text2">
                </label>
              </div>
              <div class="form-group my-2">
                <label class="mb-1">Третий текст
                  <input v-if="top_slider.length > 0" type="text" class="form-control" required v-model="new_top_slider_text3">
                </label>
              </div>
              <div class="form-group my-2">
                <label class="mb-1">Четвертый текст
                  <input v-if="top_slider.length > 0" type="text" class="form-control" required v-model="new_top_slider_text4">
                </label>
              </div>
              <div class="form-group my-2">
                <label class="mb-1">Ссылка
                  <input v-if="top_slider.length > 0" type="text" class="form-control" required v-model="new_top_slider_link">
                </label>
              </div>
              <div class="form-group my-2 text-center">
                <input @change="addTopSliderImage" ref="addTopSliderImage" type="file" hidden>
                <label class="mb-1">Изображение</label>
                <div v-if="new_top_slider_image === undefined">
                  <img class="w-50" src='../assets/images/button-cross.png' @click="$refs.addTopSliderImage.click()">
                </div>
                <div v-else>
                  <img class="w-50" :src="new_top_slider_image_url" @click="$refs.addTopSliderImage.click()">
                </div>


              </div>
              <div class="form-group my-3 mb-5">
                <button type="submit" class="btn btn-default button-1 float-right">Сохранить</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="editCollectionGrid" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeEditCollectionGrid"
                    @click="collection_grid_image_to_edit = undefined; collection_grid_image_to_edit_url = undefined"
                    data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Редактировать коллекцию</h4>
          </div>
          <div class="modal-body">
            <form class="form-horizontal"
                  @submit.prevent="editCollectionGrid">
              <div class="form-group my-2">
                <label class="mb-1">Текст</label>
                <input v-if="collection_grid.length > 0" type="text" class="form-control" required v-model="collectionGridEdit.text">
              </div>
              <div class="form-group my-2">
                <label class="mb-1">Ссылка</label>
                <input v-if="collection_grid.length > 0" type="text" class="form-control" required v-model="collectionGridEdit.link">
              </div>
              <div class="form-group my-2 text-center">
                <input @change="editCollectionGridImage" ref="editCollectionGridImage" type="file" hidden>
                <label class="mb-1">Изображение</label>
                <div v-if="collection_grid_image_to_edit === undefined">
                  <img class="w-50" v-if="collection_grid.length > 0" :src="collectionGridEdit.image"
                       @click="$refs.editCollectionGridImage.click()">
                </div>
                <div v-else>
                  <img class="w-50" :src="collection_grid_image_to_edit_url"
                       @click="$refs.editCollectionGridImage.click()">
                </div>
              </div>
              <div class="form-group my-3 mb-5">
                <button type="submit" class="btn btn-default button-1 float-right">Сохранить</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="editProductTypes" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeEditProductTypes"
                    @click="product_types_image_to_edit = undefined; product_types_image_to_edit_url = undefined"
                    data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Редактировать тип изделия</h4>
          </div>
          <div class="modal-body">
            <form class="form-horizontal"
                  @submit.prevent="editProductTypes">
              <div class="form-group my-2">
                <label class="mb-1">Текст</label>
                <input v-if="product_types.length > 0" type="text" class="form-control" required v-model="productTypesEdit.text">
              </div>
              <div class="form-group my-2">
                <label class="mb-1">Ссылка</label>
                <input v-if="product_types.length > 0" type="text" class="form-control" required v-model="productTypesEdit.link">
              </div>
              <div class="form-group my-2 text-center">
                <input @change="editProductTypesImage" ref="editProductTypeImage" type="file" hidden>
                <label class="mb-1">Изображение</label>
                <div v-if="product_types_image_to_edit === undefined">
                  <img class="w-50" v-if="product_types.length > 0" :src="productTypesEdit.image"
                       @click="$refs.editProductTypeImage.click()">
                </div>
                <div v-else>
                  <img class="w-50" :src="product_types_image_to_edit_url"
                       @click="$refs.editProductTypeImage.click()">
                </div>
              </div>
              <div class="form-group my-3 mb-5">
                <button type="submit" class="btn btn-default button-1 float-right">Сохранить</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="editCategoryGrid" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeCategoryGridEdit"
                    @click="category_grid_image_to_edit = undefined; category_grid_image_to_edit_url = undefined"
                    data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Редактировать категорию</h4>
          </div>
          <div class="modal-body">
            <form class="form-horizontal"
                  @submit.prevent="editCategoryGrid">
              <div class="form-group my-2">
                <label class="mb-1">Текст</label>
                <input v-if="category_grid.length > 0" type="text" class="form-control" required v-model="categoryGridEdit.text">
              </div>
              <div class="form-group my-2">
                <label class="mb-1">Ссылка</label>
                <input v-if="category_grid.length > 0" type="text" class="form-control" required v-model="categoryGridEdit.link">
              </div>
              <div class="form-group my-2 text-center">
                <input @change="editCategoryGridImage" ref="editCategoryGridImage" type="file" hidden>
                <label class="mb-1">Изображение</label>
                <div v-if="category_grid_image_to_edit === undefined">
                  <img class="w-50" v-if="category_grid.length > 0" :src="categoryGridEdit.image"
                       @click="$refs.editCategoryGridImage.click()">
                </div>
                <div v-else>
                  <img class="w-50" :src="category_grid_image_to_edit_url"
                       @click="$refs.editCategoryGridImage.click()">
                </div>
              </div>
              <div class="form-group my-3 mb-5">
                <button type="submit" class="btn btn-default button-1 float-right">Сохранить</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {apiService} from "@/common/api.service";
  import {Autoplay, Mousewheel, Pagination, Swiper as SwiperClass} from 'swiper/swiper.esm';
  import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';
  import 'swiper/swiper-bundle.css'

  SwiperClass.use([Pagination, Mousewheel, Autoplay]);
  const {Swiper, SwiperSlide} = getAwesomeSwiper(SwiperClass);

  export default {
    name: "ManagerFrontpage",
    components: {
      Swiper,
      SwiperSlide,
    },
    data() {
      return {
        top_slider: [],
        top_slider_to_edit: undefined,
        top_slider_image_to_edit: undefined,
        top_slider_image_to_edit_url: undefined,
        top_slider_to_delete: undefined,
        new_top_slider_text1: "",
        new_top_slider_text2: "",
        new_top_slider_text3: "",
        new_top_slider_text4: "",
        new_top_slider_link: "",
        new_top_slider_image: undefined,
        collection_grid: [],
        collection_grid_to_edit: undefined,
        collection_grid_image_to_edit: undefined,
        collection_grid_image_to_edit_url: undefined,
        gold_and_silver: [],
        gold_image_to_edit: undefined,
        silver_image_to_edit: undefined,
        product_types: [],
        product_types_to_edit: undefined,
        product_types_image_to_edit: undefined,
        product_types_image_to_edit_url: undefined,
        category_grid: [],
        category_grid_to_edit: undefined,
        category_grid_image_to_edit: undefined,
        category_grid_image_to_edit_url: undefined,
        swiperOptions: {
          slidesPerView: 1,
          centeredSlides: true,
          breakpoints: {
            1000: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 1.5,
            },
          },
        },
      }
    },
    computed: {
      swiper() {
        return this.$refs.topSlider.$swiper
      },
      topSliderEdit() {
        if (typeof this.top_slider_to_edit !== "undefined") {
          return this.top_slider.filter(function (slide) {
            return slide.id === this.top_slider_to_edit
          }, this)[0]
        } else {
          return this.top_slider[0]
        }
      },
      collectionGridEdit() {
        if (typeof this.collection_grid_to_edit !== "undefined") {
          return this.collection_grid.filter(function (collection) {
            return collection.id === this.collection_grid_to_edit
          }, this)[0]
        } else {
          return this.collection_grid[0]
        }
      },
      productTypesEdit() {
        if (typeof this.product_types_to_edit !== "undefined") {
          return this.product_types.filter(function (product_types) {
            return product_types.id === this.product_types_to_edit
          }, this)[0]
        } else {
          return this.product_types[0]
        }
      },
      categoryGridEdit() {
        if (typeof this.category_grid_to_edit !== "undefined") {
          return this.category_grid.filter(function (category) {
            return category.id === this.category_grid_to_edit
          }, this)[0]
        } else {
          return this.category_grid[0]
        }
      }
    },
    methods: {
      getTopSlider() {
        let endpoint = "api/manager/topslider/";
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.top_slider = data;
            this.$store.commit('changeLoading');
          })
      },
      editTopSliderImage(e) {
        this.top_slider_image_to_edit = e.target.files[0];
        this.top_slider_image_to_edit_url = URL.createObjectURL(this.top_slider_image_to_edit);
      },
      editTopSlider() {
        let endpoint = "/api/manager/topslider/" + this.top_slider_to_edit + "/";
        let data = new FormData;
        data.append('text1', this.topSliderEdit.text1);
        data.append('text2', this.topSliderEdit.text2);
        data.append('text3', this.topSliderEdit.text3);
        data.append('text4', this.topSliderEdit.text4);
        data.append('link', this.topSliderEdit.link);
        if (this.top_slider_image_to_edit !== undefined) {
          data.append('image', this.top_slider_image_to_edit);
        }
        this.$store.commit('changeLoading');
        this.$http.patch(endpoint, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Token ' + this.$store.state.a_token,
          },
        }).then(data => {
          this.$refs.closeEditTopSlider.click();
          this.top_slider_image_to_edit = undefined;
          this.top_slider_image_to_edit_url = undefined;
          this.$store.commit('changeLoading');
          this.getTopSlider();
        }, this)
      },
      deleteTopSlider() {
        let endpoint = "/api/manager/topslider/" + this.top_slider_to_delete + "/";
        let method = "DELETE"
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, method, undefined, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.$refs.closeDeleteTopSlider.click();
            this.getTopSlider();
          }, this)
      },
      addTopSliderImage(e) {
        this.new_top_slider_image = e.target.files[0];
        this.new_top_slider_image_url = URL.createObjectURL(this.new_top_slider_image);
      },
      addTopSlider() {
        let endpoint = "/api/manager/topslider/";
        let data = new FormData;
        data.append('text1', this.new_top_slider_text1);
        data.append('text2', this.new_top_slider_text2);
        data.append('text3', this.new_top_slider_text3);
        data.append('text4', this.new_top_slider_text4);
        data.append('link', this.new_top_slider_link);
        data.append('image', this.new_top_slider_image);
        this.$store.commit('changeLoading');
        this.$http.post(endpoint, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Token ' + this.$store.state.a_token,
          },
        }).then(data => {
          this.$store.commit('changeLoading');
          this.$refs.closeAddTopSlider.click();
          this.new_top_slider_image = undefined;
          this.new_top_slider_image_url = undefined;
          this.getTopSlider();
        }, this)
      },
      getCollectionGrid() {
        let endpoint = "api/manager/collectiongrid/";
        let auth = this.$store.state.a_token;
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.collection_grid = data;
          })
      },
      editCollectionGridImage(e) {
        this.collection_grid_image_to_edit = e.target.files[0];
        this.collection_grid_image_to_edit_url = URL.createObjectURL(this.collection_grid_image_to_edit);
      },
      editCollectionGrid() {
        let endpoint = "/api/manager/collectiongrid/" + this.collection_grid_to_edit + "/";
        let data = new FormData;
        data.append('text', this.collectionGridEdit.text);
        data.append('link', this.collectionGridEdit.link);
        if (this.collection_grid_image_to_edit !== undefined) {
          data.append('image', this.collection_grid_image_to_edit);
        }
        this.$store.commit('changeLoading');
        this.$http.patch(endpoint, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Token ' + this.$store.state.a_token,
          },
        }).then(data => {
          this.$store.commit('changeLoading');
          this.$refs.closeEditCollectionGrid.click();
          this.collection_grid_image_to_edit = undefined;
          this.collection_grid_image_to_edit_url = undefined;
          this.getCollectionGrid();
        }, this)
      },
      getGoldAndSilver() {
        let endpoint = "api/manager/goldandsilver/";
        let auth = this.$store.state.a_token;
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.gold_and_silver = data;
          })
      },
      editGoldImage(e, id) {
        this.gold_image_to_edit = e.target.files[0];
        let endpoint = "/api/manager/goldandsilver/" + id + "/";
        let data = new FormData;
        data.append('image', this.gold_image_to_edit);
        this.$store.commit('changeLoading');
        this.$http.patch(endpoint, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Token ' + this.$store.state.a_token,
          },
        }).then(data => {
          this.$store.commit('changeLoading');
          this.getGoldAndSilver()
        })

      },
      editSilverImage(e, id) {
        this.silver_image_to_edit = e.target.files[0];
        let endpoint = "/api/manager/goldandsilver/" + id + "/";
        let data = new FormData;
        data.append('image', this.silver_image_to_edit);
        this.$store.commit('changeLoading');
        this.$http.patch(endpoint, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Token ' + this.$store.state.a_token,
          },
        }).then(data => {
          this.$store.commit('changeLoading');
          this.getGoldAndSilver();
        })
      },
      getProductTypes() {
        let endpoint = "api/manager/producttypeslider/";
        let auth = this.$store.state.a_token;
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.product_types = data;
          })
      },
      editProductTypesImage(e) {
        this.product_types_image_to_edit = e.target.files[0];
        this.product_types_image_to_edit_url = URL.createObjectURL(this.product_types_image_to_edit);
      },
      editProductTypes() {
        let endpoint = "/api/manager/producttypeslider/" + this.product_types_to_edit + "/";
        let data = new FormData;
        data.append('text', this.productTypesEdit.text);
        data.append('link', this.productTypesEdit.link);
        if (this.product_types_image_to_edit !== undefined) {
          data.append('image', this.product_types_image_to_edit);
        }
        this.$store.commit('changeLoading');
        this.$http.patch(endpoint, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Token ' + this.$store.state.a_token,
          },
        }).then(data => {
          this.$store.commit('changeLoading');
          this.$refs.closeEditProductTypes.click();
          this.product_types_image_to_edit = undefined;
          this.product_types_image_to_edit_url = undefined;
          this.getProductTypes();
        }, this)
      },

      getCategoryGrid() {
        let endpoint = "api/manager/categorygrid/";
        let auth = this.$store.state.a_token;
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.category_grid = data;
          })
      },
      editCategoryGridImage(e) {
        this.category_grid_image_to_edit = e.target.files[0];
        this.category_grid_image_to_edit_url = URL.createObjectURL(this.category_grid_image_to_edit);
      },
      editCategoryGrid() {
        let endpoint = "/api/manager/categorygrid/" + this.category_grid_to_edit + "/";
        let data = new FormData;
        data.append('text', this.categoryGridEdit.text);
        data.append('link', this.categoryGridEdit.link);
        if (this.category_grid_image_to_edit !== undefined) {
          data.append('image', this.category_grid_image_to_edit);
        }
        this.$store.commit('changeLoading');
        this.$http.patch(endpoint, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Token ' + this.$store.state.a_token,
          },
        }).then(data => {
          this.$store.commit('changeLoading');
          this.$refs.closeCategoryGridEdit.click();
          this.category_grid_image_to_edit = undefined;
          this.category_grid_image_to_edit_url = undefined;
          this.getCategoryGrid();
        }, this)
      },
    },
    created() {
      this.getTopSlider();
      this.getCollectionGrid();
      this.getGoldAndSilver();
      this.getProductTypes();
      this.getCategoryGrid();
    },
  }
</script>

<style scoped>

  .iosSlider {
    width: 100% !important
  }

  .add-slider a {
    text-align: center;
    background-color: white;
    font-weight: 600;
    color: #000;
    font-family: 'Open Sans', sans-serif;
    width: 180px;
    height: 60px;
    text-transform: uppercase;
    display: block;
    border: solid 2px #dfb859;
    line-height: 60px;
    margin: 0;
  }

  .add-slider a:hover {
    background: #222;
    border: solid 2px #222;
    color: #fff;
  }

  .top-slider {
    overflow: hidden;
  }

  .type-img {
    width: 100%;
    height: auto;
  }

  .edit-slide {
    text-align: center;
    background-color: white;
    font-weight: 600;
    color: #000;
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    height: 35px;
    text-transform: uppercase;
    display: block;
    border: solid 2px #dfb859;
    line-height: 35px;
    margin: 0;
  }

  .edit-slide:hover {
    background: #222;
    border: solid 2px #222;
    color: #fff !important;
  }


</style>