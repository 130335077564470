<template>
  <div class="modal fade" id="myModalHorizontal2" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <button type="button" ref="close" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
          <h4 class="modal-title">Регистрация</h4>
        </div>
        <!-- Modal Body -->
        <div class="modal-body">
          <form class="form-horizontal"
                id="registration-form"
                @submit.prevent="validateRegistration"
          >
            <div class="form-group">
              <div class="col-sm-12">
                <input type="email" id="register-username" autocomplete="email" v-model="register_username" class="form-control" placeholder="Е-мэйл"/>
                <label for="register-username" class="text-danger" v-if="this.register_errors['register_username']">{{this.register_errors['register_username']}}</label>
              </div>
            </div>
            <div class="form-group">
              <div class="col-sm-12">
                <input type="password" id="register-password" autocomplete="new-password" v-model="register_password" class="form-control" placeholder="Пароль"/>
                <label for="register-password" class="text-danger" v-if="this.register_errors['register_password']">{{this.register_errors['register_password']}}</label>
              </div>
            </div>
            <div class="form-group">
              <div class="col-sm-12">
                <input type="password" id="register-password2" class="form-control" autocomplete="new-password" v-model="register_password2" placeholder="Повторите пароль"/>
                <label for="register-password2" class="text-danger" v-if="this.register_errors['register_password2']">{{this.register_errors['register_password2']}}</label>
              </div>
            </div>
            <div class="form-group">
              <div class="col-sm-12">
                <button type="submit" class="btn btn-default button-1">Регистрация</button>
              </div>
            </div>
            <div class="form-group">
              <div class="col-sm-12">
                <p>
                  <a href="#" data-dismiss="modal" data-toggle="modal" data-target="#myModalHorizontal">Войти</a> ||
                  <a href="#" data-dismiss="modal" data-toggle="modal" data-target="#myModalHorizontal4">Восстановить пароль</a>
                </p>
              </div>
            </div>
          </form>
        </div>
        <!-- Modal Footer -->
      </div>
    </div>
  </div>
</template>

<script>
  import {apiService} from "@/common/api.service.js";

  export default {
    name: "RegistrationForm",
    data() {
      return {
        register_username: "",
        register_password: "",
        register_password2: "",
        register_errors: [],
      }
    },
    methods: {
      validateRegistration() {
        this.register_errors = [];
        const validation = /[$%^*={}|<>]/;

        if (!this.register_username) {
          this.register_errors['register_username'] = 'Заполните это поле'
        } else if (this.register_username.match(validation)) {
          this.register_errors['register_username'] = 'Недопустимые символы'
        }

        if (!this.register_password) {
          this.register_errors['register_password'] = 'Заполните это поле'
        } else if (this.register_password.match(validation)) {
          this.register_errors['register_password'] = 'Недопустимые символы'
        }

        if (!this.register_password2) {
          this.register_errors['register_password2'] = 'Заполните это поле'
        } else if (this.register_password2.match(validation)) {
          this.register_errors['register_password2'] = 'Недопустимые символы'
        } else if (this.register_password !== this.register_password2) {
          this.register_errors['register_password2'] = 'Пароли должны совпадать'
        }

        return this.submitRegister()
      },
      submitRegister() {
        if (Object.keys(this.register_errors).length === 0) {
          let endpoint = "/api/register/";
          let method = "POST";
          let data = {'username': this.register_username, 'password': this.register_password, 'password2': this.register_password2}
          this.$store.commit('changeLoading');
          apiService(endpoint, method, data)
            .then(data => {
              if (data.register) {
                this.$refs.close.click()
                this.$router.push('/registered')
              }
              else {
                this.register_errors = [];
                this.register_errors['register_password2'] = data.error
              }
              this.$store.commit('changeLoading');
            }, this)
        }
      },
    }
  }
</script>

<style scoped>

</style>