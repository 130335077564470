<template>
  <div>
    <!--modal popup-->
    <LoginForm

    />
    <RestoreForm

    />
    <RegistrationForm

    />
    <Logout

    />
    <!--modal popup-->
    <div id="push_sidebar">
      <div class="right-logo d-none d-md-block">
        <router-link to="/"><img src="../assets/images/logo-2.png" class="img-fluid"
                                 alt="Интернет-магазин Амбервиль" title="Интернет-магазин Амбервиль"></router-link>
      </div>
      <ul class="list-unstyled">
        <br class="d-md-none">
        <li @click="triggerMenuHome">
          <a>Главная страница</a>
        </li>
        <li>
          <router-link @click.native="triggerMenu" to="/about">О нас</router-link>
        </li>
        <li class="nav-item dropdown">
          <a class="dropdown-toggle" @click.prevent="goToProducts(0)">Каталог</a>
          <ul class="list-unstyled dropdown-menu">
            <li @click.prevent="goToProducts(5)"><a href="/products/?group=5">Янтарные украшения</a></li>
            <li @click.prevent="goToProducts(1)"><a href="/products/?group=1">Изделия в серебре</a></li>
            <li @click.prevent="goToProducts(3)"><a href="/products/?group=3">Изделия в золоте</a></li>
            <li @click.prevent="goToProducts(4)"><a href="/products/?group=4">Изделия в позолоте</a></li>
            <li @click.prevent="goToProducts(2)"><a href="/products/?group=2">Изделия в посеребрении</a></li>
            <li @click.prevent="goToProducts(6)"><a href="/products/?group=6">Подарки и сувениры</a></li>
            <li @click.prevent="goToProducts(7)"><a href="/products/?group=7">Янтарная косметика</a></li>
            <li @click.prevent="goToProducts(0)"><a href="/products/?group=0">Все изделия</a></li>
          </ul>
        </li>
        <li>
          <router-link @click.native="triggerMenu" to="/blog">Блог</router-link>
        </li>
        <li>
          <router-link @click.native="triggerMenu" to="/testimonials">Отзывы</router-link></li>
        <li>
          <router-link @click.native="triggerMenu" to="/contacts">Контакты</router-link>
        </li>
        <li>
          <router-link @click.native="triggerMenu" to="/payment">Оплата</router-link>
        </li>
        <li>
          <router-link @click.native="triggerMenu" to="/delivery">Доставка</router-link>
        </li>
        <li>
          <router-link @click.native="triggerMenu" to="/cart">Корзина</router-link>
        </li>
        <li>
          <router-link @click.native="triggerMenu" to="/cabinet" v-if="this.$store.state.username">Личный кабинет</router-link>
        </li>
        <li class="sign-in" v-if="!this.$store.state.username">
          <input value="ВОЙТИ" type="button" data-toggle="modal" data-target="#myModalHorizontal">
          \
          <input value="РЕГИСТРАЦИЯ" type="button" data-toggle="modal" data-target="#myModalHorizontal2">
        </li>
        <li class="sign-in" v-else>
          <input value="ВЫЙТИ ИЗ АККАУНТА" type="button" data-toggle="modal" data-target="#myModalHorizontal5">
        </li>
        <li>
          <a @click="triggerMenu">Закрыть меню</a>
        </li>
      </ul>
      <br>
    </div>
  </div>
</template>

<script>
  import LoginForm from "@/components/LoginForm"
  import RestoreForm from "@/components/RestoreForm"
  import RegistrationForm from "@/components/RegistrationForm"
  import Logout from "@/components/Logout"

  export default {
    name: "Menu",
    components: {
      LoginForm,
      RestoreForm,
      RegistrationForm,
      Logout
    },
    computed: {
      activeGroup() {
        return this.$store.state.activeGroup
      },
    },
    methods: {
      goToProducts(group) {
        if (this.activeGroup === group) {
          this.$store.commit('setSearchValue', '');
          this.reloadProducts();
        }
        this.$store.commit('changeGroup', group);
        this.triggerMenu()
        if (this.$router.currentRoute.name !== 'Products') {
          if (this.activeGroup !== 0) {
            this.$store.commit('switchProductIgnore')
          }
          this.$router.push({name: 'Products'})
        }
      },
      triggerMenu() {
        this.$emit('triggerMenu')
      },
      triggerMenuHome() {
        this.$emit('triggerMenu');
        this.$router.push('/')
      },
      reloadProducts() {
        this.$store.commit('reloadProduct');
      },
    }
  }
</script>

<style scoped>
  .dropdown-menu {
    padding-left: 15px;
  }
  @media (max-width: 767px) {
    #push_sidebar {
      width: 100% !important;
    }

    body.show_sidebar #push_sidebar {
      left: 0 !important;
    }

    .dropdown-menu {
      display: block;
    }
  }
</style>