import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Products from "@/views/Products.vue"
import Detail from "@/views/Detail.vue"
import Cart from "@/views/Cart.vue"
import Checkout from "@/views/Checkout.vue"
import ThankYou from "@/views/ThankYou.vue"
import ThankYouMessage from "@/views/ThankYouMessage.vue"
import ThankYouReserve from "@/views/ThankYouReserve.vue"
import Error from "@/views/Error.vue"
import Activate from "@/views/Activate.vue"
import Registered from "@/views/Registered.vue"
import Restore from "@/views/Restore.vue"
import SetPassword from "@/views/SetPassword.vue"
import PasswordRestored from "@/views/PasswordRestored.vue"
import Cabinet from "@/views/Cabinet.vue"
import SingleOrder from "@/views/SingleOrder"
import Manager from "@/views/Manager"
import Blog from "@/views/Blog"
import Article from "@/views/Article"
import About from "@/views/About"
import Contacts from "@/views/Contacts"
import Testimonials from "@/views/Testimonials"
import Unsubscribe from "@/views/Unsubscribe"
import Offer from "@/views/Offer"
import Policy from "@/views/Policy"
import Delivery from "@/views/Delivery"
import Payment from "@/views/Payment"
import NotFound from "@/views/NotFound"


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/products",
    name: "Products",
    component: Products
  },
  {
    path: "/detail/:id/:slug",
    props: true,
    name: "Detail",
    component: Detail
  },
  {
    path: "/cart",
    name: "Cart",
    component: Cart
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: Checkout
  },
  {
    path: "/thank-you/:uid",
    props: true,
    name: "ThankYou",
    component: ThankYou
  },
  {
    path: "/thank-you-message",
    name: "ThankYouMessage",
    component: ThankYouMessage
  },
    {
    path: "/thank-you-reserve",
    name: "ThankYouReserve",
    component: ThankYouReserve
  },
  {
    path: "/error",
    name: "Error",
    component: Error
  },
  {
    path: "/activate/:token",
    props: true,
    name: "Activate",
    component: Activate
  },
  {
    path: "/registered/",
    name: "Registered",
    component: Registered
  },
  {
    path: "/restore/",
    name: "Restore",
    component: Restore
  },
  {
    path: "/setpassword/:token",
    name: "SetPassword",
    component: SetPassword
  },
  {
    path: "/passwordrestored",
    name: "PasswordRestored",
    component: PasswordRestored
  },
  {
    path: "/cabinet",
    name: "Cabinet",
    component: Cabinet
  },
  {
    path: "/order/:uid",
    props: true,
    name: "SingleOrder",
    component: SingleOrder
  },
  {
    path: "/manager/",
    name: "Manager",
    component: Manager
  },
  {
    path: "/blog/",
    name: "Blog",
    component: Blog
  },
  {
    path: "/article/:id/:slug",
    props: true,
    name: "Article",
    component: Article
  },
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: Contacts
  },
  {
    path: "/testimonials",
    name: "Testimonials",
    component: Testimonials
  },
  {
    path: "/unsubscribe",
    name: "Unsubscribe",
    component: Unsubscribe
  },
  {
    path: "/oferta",
    name: "Offer",
    component: Offer
  },
  {
    path: "/policy",
    name: "Policy",
    component: Policy
  },
  {
    path: "/delivery",
    name: "Delivery",
    component: Delivery
  },
  {
    path: "/payment",
    name: "Payment",
    component: Payment
  },
  { path: '/404',
    name: "NotFound",
    component: NotFound
  },
  { path: '*',
    redirect: '/404'
  },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,

  routes,
  scrollBehavior(to, from, savedPosition) {
    return {x: 0, y: 0};
  }
});

export default router;
