<template>
  <div id="wrapper">
    <!--page heading-->
    <section>
      <div class="inner-bg">
        <div class="inner-head wow fadeInDown">
          <h3>Управление рассылками</h3>
        </div>
      </div>
    </section>
    <!--page heading-->
    <!--container-->
    <div class="container">
      <div class="shop-in pl-0 pr-0">
        <div>
          <div class="bread2">
            <ul>
              <li>
                <router-link to="/">На главную</router-link>
              </li>
              <li>/</li>
              <li>Отписаться от рассылок</li>
            </ul>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="thanks-bg">
          <div class="thanks-text" v-if="username">
            <h2 class="wow fadeIn error-heading">Вы успешно отписались от всех рассылок</h2>
            <div>
              <router-link to="/" class="wow fadeInLeft">Вернуться на главную</router-link>
            </div>
          </div>
          <div class="thanks-text" v-else>
            <h2 class="wow fadeIn error-heading">Авторизируйтесь, чтобы отписаться от рассылки</h2>
            <div class="row justify-content-center">
              <a data-toggle="modal" data-target="#myModalHorizontal">ВОЙТИ</a>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="clearfix"></div>
    </div>
    <!--container-->
    <div class="clearfix"></div>
    <!--footer-->
    <Footer
    />
  </div>
</template>

<script>
  import {apiService} from "@/common/api.service.js";
  import Footer from "@/components/Footer";

  export default {
    name: "Unsubscribe",
    components: {
      Footer,
    },
    metaInfo: {
      title: 'Отписаться от рассылок',
    },
    computed: {
      username() {
        return this.$store.state.username
      }
    },
    methods: {
      unsubscribe() {
        if (this.$store.state.username) {
          let endpoint = "/api/editclient/";
          let method = "PATCH";
          let data = {
            'mail_discounts': false
          };
          let auth = this.$store.state.a_token;
          this.$store.commit('changeLoading');
          apiService(endpoint, method, data, auth)
            .then(data => {
              this.$store.commit('changeLoading');
            }, this);
        }
      }
    },
    watch: {
      username(oldUsername, newUsername) {
        this.unsubscribe()
      }
    },
    created() {
      this.unsubscribe()
    }
  }
</script>

<style scoped>
  .error-heading {
    margin-top: 10%;
    margin-bottom: 100px;
  }

  @media (max-width: 1499px) {
    .thanks-bg {
      min-height: 480px;
    }
  }

  @media (max-width: 991px) {
    .thanks-bg {
      min-height: 400px;
    }
  }

  @media (max-width: 771px) {
    .thanks-bg {
      background-size: 100% 100%;
    }
  }

  .thanks-text a:hover {
    color: white;
  }
</style>