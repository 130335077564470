<template>
  <li>
    <a name="paginator" v-if="pages > 5 && page === (isActive - 4)" @click="riseLower"><</a>
    <a name="paginator" v-if="page > (isActive - 3) && page < (isActive + 3)" :class="{active:page===isActive}" @click="riseLower">{{page}}</a>
    <a name="paginator" v-if="pages > 5 && page === (isActive + 4)" @click="riseLower">></a>
  </li>
</template>

<script>
  export default {
    name: "ProductPagination",
    props: {
      page: {
        type: Number,
      },
      pages: {
        type: Number,
      },
      isActive: {
        type: Number,
      },
      lower: {
        type: Boolean,
        default: false,
      }
    },
    methods: {
      riseLower() {
        if (this.lower) {
          window.scrollTo(0, 0);
        }
      }
    }
  }
</script>

<style scoped>
  a {
    cursor: pointer;
  }
</style>