import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Vuex from 'vuex';
import store from '@/store'
import axios from 'axios'
import VueMeta from 'vue-meta'
import VueAnalytics from 'vue-analytics';
import VTooltip from 'v-tooltip'


Vue.prototype.$http = axios;

Vue.config.productionTip = false;
Vue.use(Vuex);
const VueCookie = require('vue-cookie');
Vue.use(VueCookie);
Vue.use(VueMeta);
Vue.use(VTooltip,)
VTooltip.options.popover.defaultPopperOptions = {modifiers: {flip:{enabled: false}}};
Vue.use(VueAnalytics, {
  id: 'UA-187526588-1',
  router,
  ecommerce: {
    enabled: true
  }
});


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
