<template>
  <div>
    <h1 class="text-center">Скидки недели</h1>
    <div class="row">
      <div class="bordered col-sm-3" v-for="week in weekly_discounts">
        <h3 class="text-center my-3">{{week.name}}</h3>
        <h2>Товары:</h2>
        <div class="row m-0 justify-content-between" v-for="product in week.products">
          <router-link class="col-11 px-0" :to="{ name: 'Detail', params: {id: product.id, slug: slug(product.name)}}">
            <p class="product-link">{{product.short_name}}</p>
          </router-link>
          <span class="delete" @click="deleteWeekProduct(product.id, week.id)" :title="'Удалить '+ product.short_name">X</span>
        </div>
        <div class="add-product row justify-content-center my-3">
          <a data-toggle="modal" data-target="#addWeekProduct" @click="week_to_add = week.id">Добавить</a>
        </div>
        <h2 class="my-4">Недельная скидка:</h2>
        <div class="row mx-3 justify-content-between mb-3">
          <div class="row align-items-center">
            <input type="number" class="input-discount" v-model="week.week_discount" @change="changeWeekDiscount(week.id, week.week_discount)">
            <h2 class="ml-2">%</h2>
          </div>
        </div>
      </div>
    </div>
    <h1 class="text-center my-4">Промокоды</h1>
    <div class="bordered">
      <div class="table-responsive wow fadeIn">
        <table class="table product-table">
          <tr class="table-h">
            <td>Название</td>
            <td>Код</td>
            <td>Активный</td>
            <td>Скидка</td>
            <td>Дата окончания</td>
            <td>Использований</td>
            <td>Сохранить</td>
            <td>Удалить</td>
          </tr>
          <ManagerDiscountPromocode
            v-for="promocode in promocodes"
            :promocode="promocode"
            :key="promocode.id"
            v-on:getPromocodes="getPromocodes"
          />
        </table>
      </div>
      <div class="add-product row mx-3 justify-content-end my-3">
        <a data-toggle="modal" data-target="#addPromocode">Добавить</a>
      </div>
    </div>
    <ManagerDiscountTag
      v-for="tag in tags"
      :tag="tag"
      :key="tag.id"
      :product_list="product_list"
      v-on:getTags="getProductTags"
    />
    <div class="add-product add-tag row mx-3 justify-content-center my-5">
      <a data-toggle="modal" data-target="#createTag">Создать новый тэг</a>
    </div>
    <div class="modal fade" id="addWeekProduct" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeAddWeekProduct" data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Добавить товар недели</h4>
          </div>
          <div class="modal-body text-center">
            <form class="form-horizontal"
                  @submit.prevent="addWeekProduct">
              <div class="form-group">
                <h3>Выберите изделие</h3>
                <select
                  class="js-countries mt-4"
                  v-model="week_product_to_add">
                  <option value="" selected="selected" disabled>Выберите изделие</option>
                  <option v-for="product_item in product_list" :value="product_item.id">{{product_item.uid}} - {{product_item.short_name}}</option>
                </select>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <button type="submit" class="btn btn-default button-1">Сохранить</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="addPromocode" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeAddPromocode" data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Добавить Промокод</h4>
          </div>
          <div class="modal-body">
            <form class="form-horizontal"
                  @submit.prevent="addPromocode">
              <div class="form-group my-2">
                <label class="mb-1">Название</label>
                <input type="text" class="form-control" placeholder="Название" required v-model="new_promocode_title">
              </div>
              <div class="form-group my-2">
                <label class="mb-1">Код</label>
                <input type="text" class="form-control" placeholder="Код" required v-model="new_promocode_code">
              </div>
              <div class="form-group my-2">
                <label class="mb-1">Размер скидки</label>
                <input type="number" class="form-control" required v-model="new_promocode_discount_size">
              </div>
              <div class="form-group my-2">
                <label class="mb-1">Дата окончания</label>
                <input type="date" class="form-control" v-model="new_promocode_end_date">
              </div>
              <div class="form-group my-2">
                <label class="mb-1">Использований</label>
                <input type="number" class="form-control" v-model="new_promocode_uses">
              </div>
              <div class="form-group my-2">
                <label class="mb-1">Активный</label>
                <input type="checkbox" class="form-control" v-model="new_promocode_active">
              </div>
              <div class="form-group my-3 mb-5">
                <button type="submit" class="btn btn-default button-1 float-right">Сохранить</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="createTag" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeCreateTag" data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Добавить Тэг</h4>
          </div>
          <div class="modal-body">
            <form class="form-horizontal"
                  @submit.prevent="createTag">
              <div class="form-group my-2">
                <label class="mb-1">Название</label>
                <input type="text" class="form-control" placeholder="Название" required v-model="new_tag_name">
              </div>
              <div class="form-group my-2">
                <label class="mb-1">Ссылка</label>
                <input type="text" class="form-control" placeholder="Ссылка" required v-model="new_tag_link">
              </div>
              <div class="form-group my-3 mb-5">
                <button type="submit" class="btn btn-default button-1 float-right">Создать</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import {apiService} from "@/common/api.service.js";
  import {slugify} from "@/common/slugify.js";
  import ManagerDiscountPromocode from "@/components/ManagerDiscountPromocode";
  import ManagerDiscountTag from "@/components/ManagerDiscountTag";

  export default {
    name: "ManagerDiscount",
    components: {
      ManagerDiscountPromocode,
      ManagerDiscountTag,

    },
    data() {
      return {
        weekly_discounts: undefined,
        promocodes: undefined,
        tags: undefined,
        product_list: [],
        week_to_add: undefined,
        week_product_to_add: '',
        new_promocode_title: '',
        new_promocode_code: '',
        new_promocode_active: true,
        new_promocode_discount_size: 0,
        new_promocode_end_date: undefined,
        new_promocode_uses: undefined,
        new_tag_name: undefined,
        new_tag_link: undefined,
      }
    },
    methods: {
      slug(text) {
        return slugify(text)
      },
      getWeeklyDiscounts() {
        let endpoint = "api/manager/weeklydiscount/";
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.weekly_discounts = data;
            this.$store.commit('changeLoading');
          })
      },
      getPromocodes() {
        let endpoint = "api/manager/promocode/";
        let auth = this.$store.state.a_token;
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.promocodes = data;
          })
      },
      getProductTags() {
        let endpoint = "api/manager/producttag/";
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.tags = data
            this.$store.commit('changeLoading');
          })
      },
      getProductList() {
        let endpoint = "api/manager/productsimplelist/";
        let auth = this.$store.state.a_token;
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.product_list = data;
          })
      },
      deleteWeekProduct(product, week) {
        let endpoint = "api/manager/weeklydiscount/" + week + '/' + "remove_product/";
        let auth = this.$store.state.a_token;
        let method = "PATCH";
        let data = {"id": product};
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.getWeeklyDiscounts();
          })
      },
      addWeekProduct() {
        let endpoint = "api/manager/weeklydiscount/" + this.week_to_add + '/' + "add_product/";
        let auth = this.$store.state.a_token;
        let method = "PATCH";
        let data = {"id": this.week_product_to_add};
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.getWeeklyDiscounts();
            this.$refs.closeAddWeekProduct.click()
          })
      },
      changeWeekDiscount(week, discount) {
        let endpoint = "api/manager/weeklydiscount/" + week + '/update_discount/';
        let auth = this.$store.state.a_token;
        let method = "PATCH";
        let data = {"week_discount": discount};
        apiService(endpoint, method, data, auth)
      },
      addPromocode() {
        let endpoint = "api/manager/promocode/";
        let auth = this.$store.state.a_token;
        let method = "POST";
        let data = {
          "title": this.new_promocode_title, "code": this.new_promocode_code, "active": this.new_promocode_active, "discount_size": this.new_promocode_discount_size,
          "end_date": this.new_promocode_end_date || null, "uses": this.new_promocode_uses || null
        };
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.getPromocodes();
            this.$refs.closeAddPromocode.click()
          })
      },
      createTag() {
        let endpoint = "api/manager/producttag/create_tag/";
        let auth = this.$store.state.a_token;
        let method = "POST";
        let data = {
          "name": this.new_tag_name, "link": this.new_tag_link,
        };
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, auth).then(data => {
          this.$store.commit('changeLoading');
          this.getProductTags();
          this.$refs.closeCreateTag.click()
        })
      },
    },
    created() {
      this.getWeeklyDiscounts();
      this.getPromocodes();
      this.getProductTags();
      this.getProductList()
    }
  }
</script>

<style scoped>
  .bordered {
    border: solid 1px black;
  }

  .add-product a {
    text-align: center;
    background-color: white;
    font-weight: 600;
    color: #000;
    font-family: 'Open Sans', sans-serif;
    width: 140px;
    height: 40px;
    text-transform: uppercase;
    display: block;
    border: solid 2px #dfb859;
    line-height: 38px;
    margin: 0;
  }

  .add-product a:hover {
    background: #222;
    border: solid 2px #222;
    color: #fff;
  }

  .add-tag a {
    width: 220px;
    height: 60px;
    line-height: 60px;
  }

  .input-discount {
    width: 100px;
    font-size: 22px;
  }

  .delete {
    cursor: pointer;
    font-weight: 900;
  }

  .product-link {
    cursor: pointer;
    text-decoration: underline;
    color: #575757;
  }
</style>