<template>
  <div id="wrapper">
    <section>
      <div class="inner-bg">
        <div class="inner-head wow fadeInDown">
          <h3>Политика конфеденциальности</h3>
        </div>
      </div>
    </section>
    <!--container-->
    <div class="container">
      <div class="shop-in">
        <div>
          <div class="bread2">
            <ul>
              <li>
                <router-link to="/">На главную</router-link>
              </li>
              <li>/</li>
              <li>Политика конфеденциальности</li>
            </ul>
          </div>
        </div>
        <div class="clearfix"></div>
        <div>
          <div class="text-style2"><p class="wow fadeIn">Политика в отношении обработки и защиты персональных данных пользователей сайта  <router-link to="/">https://www.ambervile.ru</router-link>.</p></div>
          <div class="inner-section terms-text">
            <div class="wow fadeIn">
              <h2 class="no-border">1. ОБЩИЕ ПОЛОЖЕНИЯ</h2>
              <p>1.1. Настоящий документ: «Политика в отношении обработки и защиты персональных данных пользователей сайта <router-link to="/">www.ambervile.ru</router-link>» (далее – Политика) разработан в соответствии со статьей
                18.1 Федерального закона от 27.07.2006 года № 152-ФЗ «О персональных данных» и является основным внутренним документом компании «Амбервиль.ру», регулирующим деятельность в области
                обработки и защиты персональных данных.</p>
              <p>1.2. Политика разработана в целях реализации требований законодательства Российской Федерации в области обработки и защиты персональных данных и направлена на обеспечение защиты прав
                и свобод человека и гражданина при обработке его персональных данных Оператором, в том числе защиты прав на неприкосновенность частной жизни, личной и семейной тайн.</p>
              <p>1.3. Политика определяет состав получаемых и обрабатываемых персональных данных, цели сбора и обработки персональных данных, порядок хранения и передачи персональных данных, а также
                реализуемые Оператором меры, направленные на защиту персональных данных.</p>
              <p>1.4. Действие настоящей Политики распространяется на любую информацию о пользователе, полученную Оператором, как до, так и после утверждения Политики.</p>
              <p>1.5. Действие настоящей Политики распространяется на всю информацию, которую Оператор может получить о пользователе, в том числе во время использования им сайта <router-link to="/">www.ambervile.ru</router-link>.</p>
              <p>1.6. Посещение и использование Сайта означает безоговорочное согласие пользователя на обработку его персональных данных, в соответствии с Приложением № 1 к настоящей Политике;</p>
              <p>1.7. В случае несогласия с условиями обработки персональных данных Оператором, пользователь должен прекратить использование Сайта.</p>
              <p>1.8. Настоящая Политика применяется только к сайту <router-link to="/">www.ambervile.ru</router-link>. Оператор не контролирует и не несет ответственности за сбор и обработку персональных данных третьими лицами, на
                сайты которых пользователь может перейти по ссылкам, доступным на сайте <router-link to="/">www.ambervile.ru</router-link>.</p>
              <p>1.9. Оператор не осуществляет проверку достоверности персональных данных, предоставленных пользователем.</p>
            </div>
            <div class="wow fadeIn">
              <h2>2. ОСНОВНЫЕ ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h2>
              <p>2.1. Сайт — совокупность размещенных в сети Интернет веб-страниц, объединенных единым адресным пространством домена <router-link to="/">www.ambervile.ru</router-link>. Стартовая страница Сайта, с которой может быть
                осуществлен доступ ко всем остальным веб-страницам Сайта, размещена в сети Интернет по адресу <router-link to="/">www.ambervile.ru</router-link>.</p>
              <p>2.2. Персональные данные - любая информация, относящаяся прямо или косвенно к определенному или определяемому физическому лицу (субъекту персональных данных).</p>
              <p>2.3. Обработка персональных данных - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких
                средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение,
                предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
              <p>2.4. Пользователь — лицо, имеющее доступ к Сайту посредством сети Интернет и использующее Сайт.</p>
              <p>2.5. Cookies — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-Пользователь или веб-браузер пересылает веб-серверу в
                HTTP-запросе при попытке открыть страницу Сайта.</p>
            </div>
            <div class="wow fadeIn">
              <h2>3. СОСТАВ ПОЛУЧАЕМЫХ И ОБРАБАТЫВАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
              <p>3.1. Данные о пользователе, получаемые и обрабатываемые в рамках настоящей Политики поступают Оператору следующими способами:</p>
              <ul>
                <li>фамилия, имя, отчество;</li>
                <li>адрес электронной почты;</li>
                <li>Почтовый адрес (адрес доставки).</li>
              </ul>
              <p>3.1.2. Автоматически передаются Оператору в процессе посещения и использования Сайта с помощью установленного на устройстве пользователя программного обеспечения (информация из файлов
                cookie) в том числе: сведения о местоположении; тип устройства пользователя и разрешение его экрана; тип, версия и язык операционной системы, установленной на устройстве пользователя;
                тип, версия и язык браузера (или иной программы, с помощью которой осуществляется доступ к Сайту); IP-адрес; адрес страницы, откуда пользователь перешел на Сайт (рефер), информация о
                том, какие страницы открывает и какие кнопки нажимает пользователь на Сайте.</p>
              <p>На Сайте используется программа «Яндекс.Метрика» для отслеживания и веб-аналитики от компании «ЯНДЕКС» (Российская Федерация, 119021, Москва, ул. Льва Толстого, 16, ООО «ЯНДЕКС»). С
                ее помощью осуществляется сбор анонимных (без привязки к персональным данным пользователя) данных о посещениях сайта с целью лучшего понимания поведения. С дополнительными сведениями
                пользователь может ознакомится по адресу: http://legal.yandex.ru/metrica_termsofuse/.</p>
              <p>На Сайте используется Google Analytics –программа для отслеживания и веб-аналитики, разработанная Google, Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA). Программа
                Google Analytics использует «cookie-файлы». Данные об использовании Сайта (включая IP-адрес), созданные сookie-файлами, как правило, передаются на серверы Google и хранятся на этих
                серверах. Компания Google использует эту информацию в целях оценки использования Сайта, составления отчетов о действиях на Сайте для Оператора и оказания других услуг, связанных с
                действиями на Сайте и в сети Интернет. С дополнительными сведениями пользователь может ознакомится по адресу: http://www.google.com/intl/en/analytics/.</p>
              <p>3.2. Оператор не выполняет обработку специальных категорий персональных данных, касающихся расовой, национальной принадлежности, политических взглядов, религиозных или философских
                убеждений.</p>
            </div>
            <div class="wow fadeIn">
              <h2>4. ЦЕЛИ СБОРА И ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
              <p>4.1. Сбор и обработка персональных данных пользователя, указанных в п. 3.1.1. настоящей Политики осуществляется Оператором в целях:</p>
              <ul>
                <li>заключения и исполнения договоров между Оператором и пользователем;</li>
                <li>связи с пользователем в случае необходимости, в том числе направления уведомлений, запросов и информации, связанных с использованием Сайта, оказанием услуг, а также обработки
                  запросов и заявок от пользователя.
                </li>
                <li>предоставления пользователю специальных предложений, информации о ценах, новостной рассылки и иных сведений от имени Оператора.</li>
              </ul>
              <p>4.2. Сбор и обработка персональных данных пользователя, указанных в п. 3.1.2. осуществляется Оператором в целях создания статистики, которая помогает понять, как пользователи
                используют Сайт, что позволяет оптимизировать его структуру и содержание, повысить удобство использования Сайта.</p>
              <p>Пользователь может в любой момент внести изменения в настройки файлов cookie и блокировать автоматическую передачу указанных файлов. Подробная информация о возможности и способах
                передачи файлов cookie доступна в настройках веб-браузера. Ограничения в использовании файлов cookie могут отразиться на некоторых функциях, доступных на веб-страницах Сайта.</p>
              <p>4.3. Не допускается обработка персональных данных, которые не отвечают целям обработки, указанным в пунктах 4.1. и 4.2. настоящей Политики.</p>
            </div>
            <div class="wow fadeIn">
              <h2>5. ПРЕДОСТАВЛЕНИЕ ДОСТУПА К ПЕРСОНАЛЬНЫМ ДАННЫМ</h2>
              <p>5.1. Срок обработки персональных данных Пользователя не ограничен. Процедура обработки может проводиться любым предусмотренным законодательством РФ способом. В частности, с помощью
                информационных систем персональных данных, которые могут вестись автоматически либо без средств автоматизации.</p>
              <p>5.2. Обрабатываемые персональные данные уничтожаются либо обезличиваются Оператором по достижении целей обработки или в случае утраты необходимости в достижении этих целей, а также
                при отзыве Пользователем согласия на обработку персональных данных.</p>
              <p>5.3. Пользователь вправе в любой момент отозвать согласие на обработку Оператором персональных данных путём направления письменного уведомления на адрес электронной
                почты: manager@ambervile.ru с пометкой «Отзыв согласия на обработку персональных данных». Отзыв пользователем согласия на обработку персональных данных влечёт за собой уничтожение
                записей, содержащих персональные данные, в системах обработки персональных данных Оператора, в том числе удаление аккаунта пользователя на Сайте.</p>
              <p>5.4. Пользователь вправе требовать от Оператора уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими,
                неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать иные предусмотренные законодательством Российской Федерации меры по
                защите своих прав.</p>
              <p>5.5. Право пользователя на изменение, удаление, блокирование персональной информации может быть ограничено требованиями положений законодательства Российской Федерации.</p>
              <p>5.6. В отношении персональных данных пользователя сохраняется их конфиденциальность, кроме случаев, установленных в п. 5.7. настоящей Политики.</p>
              <p>5.7. Оператор вправе осуществлять передачу персональных данных пользователя третьим лицам в следующих случаях:</p>
              <p>5.7.1. Передача персональных данных (Ф.И.О., почтовый адрес) службам доставки для оказания услуги по доставке заказов пользователю.</p>
              <p>5.7.2. Передача предусмотрена российским законодательством в рамках установленной процедуры (по решению суда, запросу правоохранительных органов и т.д.);</p>
              <p>5.7.3. В целях обеспечения возможности защиты прав и законных интересов Оператора.</p>
              <p>5.8. При утрате или разглашении персональных данных Оператор информирует пользователя об утрате или разглашении персональных данных.</p>
            </div>
            <div class="wow fadeIn">
              <h2>6. ЗАЩИТА ПЕРСОНАЛЬНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЕЙ</h2>
              <p>6.1. Уровень защищенности персональных данных пользователей соответствует требованиям, установленным в Постановлении Правительства Российской Федерации от 1 ноября 2012 г. № 1119 «Об
                утверждении требований к защите персональных данных при их обработке в информационных системах персональных данных».</p>
              <p>6.2. Оператор принимает необходимые организационные и технические меры для защиты персональных данных пользователя от неправомерного или случайного доступа, уничтожения, изменения,
                блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц в соответствии с требованиями Приказа ФСТЭК России от 18.02.2013 № 21 «Об утверждении
                Состава и содержания организационных и технических мер по обеспечению безопасности персональных данных при их обработке в информационных системах персональных данных».</p>
            </div>
            <div class="wow fadeIn">
              <h2>7. ОТВЕТСТВЕННОСТЬ</h2>
              <p>7.1. В случае неисполнения своих обязательств, Оператор несёт ответственность за убытки, понесённые пользователем в связи с неправомерным использованием персональных данных, в
                соответствии с законодательством Российской Федерации, за исключением случаев, предусмотренных 7.2. настоящей Политики.</p>
              <p>7.2. В случае утраты или разглашения персональных данных пользователя Оператор не несёт ответственность, если данная информация стала публичным достоянием до её утраты или
                разглашения, либо была разглашена самим пользователем или с согласия пользователя.</p>
            </div>
            <div class="wow fadeIn">
              <h2>8. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h2>
              <p>8.1. Оператор вправе вносить изменения и дополнения в настоящую Политику. Новая редакция Политики действует с момента ее размещения на сайте <router-link to="/">www.ambervile.ru</router-link>, еcли иное не
                предусмотрено в новой редакции Политики. </p>
              <p>8.2. К отношениям в области обработки и защиты персональных данных, не урегулированным в настоящей Политике, применяется действующее законодательство Российской Федерации.</p>
              <p>8.3. Все предложения или вопросы, связанные с обработкой и защитой персональных данных Оператором следует направлять по адресу электронной почты manager@ambervile.ru</p>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
    <Footer/>
  </div>

</template>

<script>
  import Footer from "../components/Footer";

  export default {
    name: "Policy",
    components: {Footer},
    metaInfo: {
      title: 'Пользовательское соглашение',
    },
  }
</script>

<style scoped>
  .terms-text p {
    border-bottom: none;
    padding-bottom: 0;
  }

  .terms-text h2 {
    border-top: solid 1px #e6e4e4;
    padding: 15px 0 15px 0;
  }

  .terms-text ul {
    list-style: disc;
    padding-left: 35px;
    padding-bottom: 15px;
  }

  .terms-text a {
    text-decoration: underline !important;
  }

  .no-border {
    border-top: none !important;
    padding: 0 0 15px 0 !important;
  }

</style>