<template>
  <div id="wrapper">
    <!--page heading-->
    <section>
      <div class="inner-bg">
        <div class="inner-head wow fadeInDown">
          <h3>Личный кабинет</h3>
        </div>
      </div>
    </section>
    <!--page heading-->
    <!--container-->
    <div class="container">
      <div class="shop-in">
        <!--breadcrumbs -->
        <div class="bread2">
          <ul>
            <li>
              <router-link to="/">На главную</router-link>
            </li>
            <li>/</li>
            <li>Личный кабинет</li>
          </ul>
        </div>
        <div v-if="username">
          <h1 class="text-center first-header wow fadeInDown">Здравствуйте, {{username}}</h1>
          <div class="buy-this d-flex justify-content-center change-password-button">
            <button data-toggle="modal" data-target="#changePassword">Изменить пароль</button>
          </div>
          <p class="text-success text-center" v-if="changed_password">Новый пароль сохранен</p>
          <h3 class="text-center wow fadeInDown">Ваш адрес доставки. Вы можете изменить его в форме ниже.</h3>
          <p class="text-success" v-if="this.changed">Новый адрес доставки сохранен</p>
          <form id="chngeprofile" class="wow fadeIn"
                @submit.prevent="validate">
            <div class="row mt-5">
              <div class="col-md-4 col-sm-6 col-xs-6">
                <div class="form-group">
                  <input id="firstname" name="firstname" v-model="firstname" type="text" placeholder="ИМЯ" maxlength="100">
                  <label for="firstname" class="text-danger" v-if="this.errors['firstname']">{{this.errors['firstname']}}</label>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-6">
                <div class="form-group">
                  <input id="secondname" name="secondname" v-model="secondname" type="text" placeholder="ОТЧЕСТВО" maxlength="150">
                  <label for="secondname" class="text-danger" v-if="this.errors['secondname']">{{this.errors['secondname']}}</label>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-6">
                <div class="form-group">
                  <input id="lastname" v-model="lastname" name="lastname" type="text" placeholder="ФАМИЛИЯ">
                  <label for="lastname" class="text-danger" v-if="this.errors['firstname']">{{this.errors['lastname']}}</label>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-6">
                <div class="form-group">
                  <select v-model="country_value" id="country" class="js-countries" @change="changeCountry">
                    <option disabled value="" selected="selected">Выберите страну</option>
                    <option v-for="country in popular_countries" :value="country.id">{{country.name}}</option>
                    <option disabled>------------------</option>
                    <option v-for="country in countries" :value="country.id">{{country.name}}</option>
                  </select>
                  <label for="country" class="text-danger" v-if="this.errors['country']">{{this.errors['country']}}</label>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-6">
                <div class="form-group">
                  <select
                    v-if="country_value === '' || country_value === 1"
                    v-model="region_value"
                    id="region" class="js-countries" @change="changeRegion">
                    <option disabled value="" selected="selected">Выберите регион</option>
                    <option v-for="region in regions" :value="region.id">{{region.name}}</option>
                  </select>

                  <input v-else id="region" v-model="region_value" name="region" type="text" placeholder="РЕГИОН">
                  <label for="region" class="text-danger" v-if="this.errors['region']">{{this.errors['region']}}</label>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-6">
                <div class="form-group">
                  <input v-if="this.another_city" v-model="city_value" id="city" name="city" type="text" placeholder="ГОРОД">
                  <select
                    v-else-if="country_value === '' || country_value === 1"
                    v-model="city_value"
                    id="city" class="js-countries" @change="changeCity">
                    <option disabled value="" selected="selected">Выберите город</option>
                    <option v-for="location in locations" :value="location.id">{{location.name}}</option>
                    <option value="0">Другой</option>
                  </select>
                  <input v-else v-model="city_value" name="city" id="city" type="text" placeholder="ГОРОД">
                  <label for="city" class="text-danger" v-if="this.errors['city']">{{this.errors['city']}}</label>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-6">
                <div class="form-group">
                  <input id="zipcode" v-model="zipcode" name="zipcode" type="text" placeholder="ИНДЕКС">
                  <label for="zipcode" class="text-danger" v-if="this.errors['zipcode']">{{this.errors['zipcode']}}</label>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-6">
                <div class="form-group">
                  <input id="email" v-model="email" name="email" type="text" placeholder="E-MAIL">
                  <label for="email" class="text-danger" v-if="this.errors['email']">{{this.errors['email']}}</label>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-6">
                <div class="form-group">
                  <input id="phone" v-model="phone" name="phone" type="text" placeholder="ТЕЛЕФОН">
                  <label for="phone" class="text-danger" v-if="this.errors['phone']">{{this.errors['phone']}}</label>
                </div>
              </div>
              <div class="col-md-12 col-sm-6 col-xs-6">
                <div class="form-group">
                  <input id="address" v-model="address" name="address" type="text" placeholder="АДРЕС">
                  <label for="address" class="text-danger" v-if="this.errors['address']">{{this.errors['address']}}</label>
                </div>
              </div>
            </div>
            <div class="buy-this d-flex justify-content-center">
              <button type="submit">Сохранить новый адрес</button>
            </div>
          </form>
          <h1 class="text-center mt-5 wow fadeInDown">Ваши заказы</h1>
          <div class="order-set wow fadeIn">
            <CabinetOrderCard
              v-for="order in orders"
              :order="order"
              :key="order.id"
            />
          </div>
          <div class="clearfix"></div>
          <hr>
          <div class="breadcrumbs">
            <ul>
              <ProductPagination
                v-for="(page, index) in pages"
                :page="page"
                :pages="pages"
                :key="index"
                @click.native="isActive = page; getOrders()"
                :isActive="isActive"
              />
            </ul>
          </div>
        </div>
        <div v-else>
          <h1 class="text-center mt-5 wow fadeInDown">Авторизируйтесь, чтобы войти в личный кабинет</h1>
          <div class="row justify-content-center">
            <button class="btn btn-default button-1" data-toggle="modal" data-target="#myModalHorizontal">ВОЙТИ</button>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <div class="clearfix"></div>
    <!--footer-->
    <Footer
    />
    <div class="modal fade" id="changePassword" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeChangePassword" data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Изменить пароль</h4>
          </div>
          <div class="modal-body text-center">
            <form class="form-horizontal"
                  @submit.prevent="validatePassword()">
              <div class="form-group">
                <div class="col-sm-12">
                  <input type="password" id="new-password" autocomplete="new-password" v-model="new_password" class="form-control" placeholder="Введите новый пароль"/>
                  <label for="new-password" class="text-danger" v-if="password_error">{{password_error}}</label>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <input type="password" id="new-password2" class="form-control" autocomplete="new-password" v-model="new_password2" placeholder="Повторите пароль"/>
                  <label for="new-password2" class="text-danger" v-if="password2_error">{{password2_error}}</label>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <button type="submit" class="btn btn-default button-1">Изменить</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {apiService} from "@/common/api.service.js";
  import Footer from "@/components/Footer";
  import CabinetOrderCard from "@/components/CabinetOrderCard";
  import ProductPagination from "@/components/ProductPagination.vue";

  export default {
    name: "Cabinet",
    components: {
      Footer,
      CabinetOrderCard,
      ProductPagination
    },
    metaInfo: {
      title: 'Личный кабинет',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Личный кабинет пользователя интернет-магазина Амбервиль'
        },
      ],
    },
    data() {
      return {
        orders: [],
        firstname: "",
        secondname: "",
        lastname: "",
        address: "",
        zipcode: "",
        email: "",
        phone: "",
        popular_countries: [],
        countries: [],
        regions: [],
        locations: [],
        country_value: "",
        region_value: "",
        city_value: "",
        another_city: false,
        changed: false,
        changed_password: false,
        errors: [],
        pages: Number,
        isActive: 1,
        new_password: '',
        new_password2: '',
        password_error: '',
        password2_error: '',
      }
    },
    computed: {
      username() {
        return this.$store.state.username
      }
    },
    methods: {
      getRouteOptions() {
        if (this.$route.query.ln) {
          this.$store.dispatch('userCookies', this.$route.query.ln);
          this.$cookie.set('token', this.$route.query.ln, {expires: 14});
        }
      },
      validatePassword() {
        this.password_error = '';
        this.password2_error = '';
        const validation = /[$%^*={}|<>]/;

        if (!this.new_password) {
          this.password_error = 'Заполните это поле'
        } else if (this.new_password.match(validation)) {
          this.password_error = 'Недопустимые символы'
        }

        if (!this.new_password2) {
          this.password2_error = 'Заполните это поле'
        } else if (this.new_password2.match(validation)) {
          this.password2_error = 'Недопустимые символы'
        } else if (this.new_password !== this.new_password2) {
          this.password2_error = 'Пароли должны совпадать'
        }

        return this.submitPassword()
      },

      submitPassword() {
        if (!this.password_error && !this.password2_error) {
          let endpoint = "/api/editpassword/";
          let method = "POST";
          let data = {
            'password': this.new_password
          };
          let auth = this.$store.state.a_token;
          this.$store.commit('changeLoading');
          apiService(endpoint, method, data, auth)
            .then(data => {
              this.changed_password = true;
              this.$store.commit('changeLoading');
              this.$refs.closeChangePassword.click()
            }, this);
        }
      },

      getPopularCountries() {
        let endpoint = "/api/countries/popular/";
        apiService(endpoint)
          .then(data => {
            this.popular_countries = data
          })
      },
      getCountries() {
        let endpoint = "/api/countries/";
        apiService(endpoint)
          .then(data => {
            this.countries = data
          })
      },
      getRegions() {
        let endpoint = "/api/regions/";
        apiService(endpoint)
          .then(data => {
            this.regions = data
          })
      },
      getLocations() {
        let endpoint = "/api/locations/";
        endpoint += "?region=" + this.region_value;
        apiService(endpoint)
          .then(data => {
            this.locations = data
          })
      },
      changeCountry() {
        this.city_value = "";
        this.region_value = "";
        this.another_city = false;
        this.getLocations();
      },
      changeRegion() {
        this.country_value = 1;
        this.city_value = "";
        this.another_city = false;
        this.getLocations()
      },
      changeCity() {
        this.country_value = 1;
        if (this.city_value === "0") {
          this.city_value = "";
          this.another_city = true;
        } else {
          this.region_value = this.locations.find((location) => {
            if (this.city_value === location.id) {
              return true
            }
          }, this).region
        }
      },
      getClientInfo() {
        if (this.username) {
          let token = this.$store.state.a_token;
          let endpoint = "api/getclientinfo/";
          apiService(endpoint, undefined, undefined, token)
            .then(data => {
              if (data.first_name !== '') {
                this.firstname = data.first_name;
              }
              if (data.last_name !== '') {
                this.lastname = data.last_name;
              }
              if (data.second_name !== '') {
                this.secondname = data.second_name;
              }
              if (data.country !== '') {
                if (!isNaN(data.country)) {
                  this.country_value = Number(data.country)
                } else {
                  this.country_value = data.country
                }
              }
              if (data.region !== '') {
                if (!isNaN(data.region)) {
                  this.region_value = Number(data.region)
                } else {
                  this.region_value = data.region
                }
              }
              if (data.city !== '') {
                if (!isNaN(data.city)) {
                  this.city_value = Number(data.city)
                } else {
                  this.another_city = true;
                  this.city_value = data.city
                }
              }
              if (data.zipcode !== '') {
                this.zipcode = data.postal_code;
              }
              if (data.address !== '') {
                this.address = data.address;
              }
              if (data.phone_number !== '') {
                this.phone = data.phone_number;
              }
              this.email = data.email;
              this.getLocations();
            }, this)
        }
      },
      validate() {
        this.errors = [];
        const name_validation = /[!@#$%^№&*(),.?"=:;{}|<>+\\/\d]/;
        const location_validation = /[!@#$%^№&*?=:;{}|<>+]/;
        const zipcode_validation = /[!@#$%^№&*(),.?"'`=:;{}|<>+\\/]/;
        const address_validation = /[$%^*={}|<>]/;
        const email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const phone_validation = /[`!@#$%^№&?"'{}|<>a-zA-Z]/

        if (!this.firstname) {
          this.errors['firstname'] = 'Заполните это поле'
        } else if (this.firstname.match(name_validation)) {
          this.errors['firstname'] = 'Недопустимые символы'
        }

        if (this.secondname.match(name_validation)) {
          this.errors['secondname'] = 'Недопустимые символы'
        }

        if (!this.lastname) {
          this.errors['lastname'] = 'Заполните это поле'
        } else if (this.lastname.match(name_validation)) {
          this.errors['lastname'] = 'Недопустимые символы'
        }

        if (!this.city_value && (this.another_city || ((this.country_value !== 1) && (this.country_value !== "")))) {
          this.errors['city'] = 'Заполните это поле'
        } else if (String(this.city_value).match(location_validation)) {
          this.errors['city'] = 'Недопустимые символы'
        } else if (!this.city_value) {
          this.errors['city'] = 'Выберите город'
        }

        if (!this.region_value && (this.country_value !== 1) && (this.country_value !== "")) {
          this.errors['region'] = 'Заполните это поле'
        } else if (String(this.region_value).match(location_validation)) {
          this.errors['region'] = 'Недопустимые символы'
        } else if (!this.region_value) {
          this.errors['region'] = 'Выберите регион'
        }

        if (!this.country_value) {
          this.errors['country'] = 'Выберите страну'
        } else if (isNaN(this.country_value)) {
          this.errors['country'] = 'Недопустимые символы'
        }

        if (!this.zipcode) {
          this.errors['zipcode'] = 'Заполните это поле'
        } else if (this.zipcode.match(zipcode_validation)) {
          this.errors['zipcode'] = 'Недопустимые символы'
        }

        if (!this.address) {
          this.errors['address'] = 'Заполните это поле'
        } else if (this.address.match(address_validation)) {
          this.errors['address'] = 'Недопустимые символы'
        }

        if (!this.email) {
          this.errors['email'] = 'Заполните это поле'
        } else if (!this.email.match(email_regex)) {
          this.errors['email'] = 'Введите корректный e-mail'
        }

        if (!this.phone) {
          this.errors['phone'] = 'Заполните это поле'
        } else if (this.phone.match(phone_validation)) {
          this.errors['phone'] = 'Введите корректный номер телефона'
        }

        return this.submit()
      },

      submit() {
        if (Object.keys(this.errors).length === 0) {
          let endpoint = "/api/editclient/";
          let method = "PATCH";
          let data = {
            'first_name': this.firstname, 'second_name': this.secondname, 'last_name': this.lastname, 'country': this.country_value, 'region': this.region_value,
            'city': this.city_value, 'postal_code': this.zipcode, 'address': this.address, 'email': this.email, 'phone_number': this.phone,
          };
          let auth = this.$store.state.a_token;
          this.$store.commit('changeLoading');
          apiService(endpoint, method, data, auth)
            .then(data => {
              this.getClientInfo();
              this.changed = true;
              this.$store.commit('changeLoading');
            }, this);

        }
      },
      getOrders() {
        let endpoint = "/api/getorders/";
        endpoint += ("?page=" + this.isActive);
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            if (data) {
              this.orders = data.results;
              this.pages = Math.ceil(data.count / 5);
            }
            this.$store.commit('changeLoading');
          }, this);
      }
    },
    watch: {
      username(oldUsername, newUsername) {
        this.getOrders();
        this.getClientInfo()
      }
    },
    created() {
      this.getRouteOptions();
      this.getPopularCountries();
      this.getCountries();
      this.getRegions();
      this.getClientInfo();
      this.getOrders();
    }
  }
</script>

<style scoped>

  .first-header {
    margin-top: 70px;
  }

  .breadcrumbs {
    display: flex;
    justify-content: center;
  }

  .breadcrumbs ul {
    float: none;
  }

  .button-1 {
    font-size: 26px;
  }

  .change-password-button {
    margin-bottom: 40px !important;
  }

  @media (max-width: 767px) {
    .shop-in {
      padding: 33px 0 40px;
    }

    .first-header {
      font-size: 20px;
    }

    .form-group input, .form-group textarea {
      font-size: 13px;
    }
  }

</style>