<template>
  <tr>
    <td class="px-1"><input type="text" class="form-control" required v-model="promocode.title"></td>
    <td class="px-1"><input type="text" class="form-control" required v-model="promocode.code"></td>
    <td><input type="checkbox" class="form-control" v-model="promocode.active"></td>
    <td class="px-1"><input type="number" class="form-control" required v-model="promocode.discount_size"></td>
    <td class="px-1"><input type="date" class="form-control" v-model="promocode.end_date"></td>
    <td class="px-1"><input type="number" class="form-control" v-model="promocode.uses"></td>
    <td>
      <button type="submit" class="filter2 mx-1 mt-1" @click="editPromocode">Сохранить</button>
    </td>
    <td>
      <div class="delete text-center" :title="'Удалить ' + promocode.title" @click="deletePromocode">X</div>
    </td>
  </tr>
</template>

<script>
  import {apiService} from "@/common/api.service.js";

  export default {
    name: "ManagerDiscountPromocode",
    props: {
      promocode: {
        type: Object,
        required: true
      },
    },
    methods: {
      editPromocode() {
        let endpoint = "api/manager/promocode/" + this.promocode.id + '/';
        let auth = this.$store.state.a_token;
        let method = "PUT";
        let data = {
          "title": this.promocode.title, "code": this.promocode.code, "active": this.promocode.active, "discount_size": this.promocode.discount_size,
          "end_date": this.promocode.end_date || null, "uses": this.promocode.uses || null
        };
        apiService(endpoint, method, data, auth)
      },
      deletePromocode() {
        let endpoint = "api/manager/promocode/" + this.promocode.id + '/';
        let auth = this.$store.state.a_token;
        let method = "DELETE";
        this.$store.commit('changeLoading');
        apiService(endpoint, method, undefined, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.$emit('getPromocodes');
          })
      }
    }
  }
</script>

<style scoped>
  .delete {
    cursor: pointer;
    font-weight: 900;
  }
</style>