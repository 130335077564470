<template>
  <div id="wrapper">
    <!--page heading-->
    <section>
      <div class="inner-bg">
        <div class="inner-head wow fadeInDown">
          <h3>Спасибо за заказ</h3>
        </div>
      </div>
    </section>
    <!--page heading-->
    <!--container-->
    <div class="container">
      <div class="shop-in pl-0 pr-0">
        <div>
          <div class="bread2">
            <ul>
              <li>
                <router-link to="/">На главную</router-link>
              </li>
              <li>/</li>
              <li>
                <router-link to="/products">Каталог</router-link>
              </li>
              <li>/</li>
              <li>Спасибо за заказ</li>
            </ul>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="thanks-bg">
          <div class="thanks-img wow fadeIn"><img src="../assets/images/products/thanks.png" alt="Спасибо" class="img-fluid"></div>
          <div class="thanks-text">
            <h2 class="wow fadeIn">За покупку в нашем магазине</h2>
            <h3 class="wow fadeIn">Мы отправили детали заказа на Ваш е-мэйл</h3>
            <div>
              <router-link to="/" class="wow fadeInLeft">Вернуться на главную</router-link>
              <router-link to="/products" class="wow fadeInRight">Продолжить покупки</router-link>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="clearfix"></div>
    </div>
    <!--container-->
    <div class="clearfix"></div>
    <!--footer-->
    <Footer
    />
  </div>
</template>

<script>
  import {apiService} from "@/common/api.service.js";
  import Footer from "@/components/Footer";

  export default {
    name: "ThankYou",
    components: {
      Footer,
    },
    metaInfo: {
      title: 'Спасибо за покупку',
    },
    methods: {
      checkPayment() {
        let endpoint = "/api/order/confirm/" + this.$route.params.uid + '/';
        this.$store.commit('changeLoading');
        apiService(endpoint)
          .then(data => {
            if (data[0] === '/error') {
              this.$router.push(data[0])
            } else {
              this.deleteBasket()
            }
            this.$store.commit('changeLoading');
          })
      },
      deleteBasket() {
        localStorage.basket = '{}';
        this.$store.dispatch('get_basket_quantity')
      }
    },
    created() {
      this.checkPayment()
    }
  }
</script>

<style scoped>

@media (max-width: 1499px){
  .thanks-bg {
    background-size: cover;
  }

}
</style>