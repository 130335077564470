<template>
  <table class="table checkout-table">
    <tr>
      <td colspan="2" class="text-center ">
        <router-link :to="{ name: 'Detail', params: {id: product.id, slug: slug}}">
          <img :src="product.main_image" :alt="product.short_name" :title="product.name" class="img-fluid">
        </router-link>
      </td>
    </tr>
    <tr class="product-name">

      <td>
        <div>
          <router-link :to="{ name: 'Detail', params: {id: product.id, slug: slug}}">
            <h1>{{product.short_name}}</h1>
            <h1 v-if="product.variant_description != null">{{product.variant_description}}</h1>
          </router-link>
        </div>
      </td>

      <td>
        <div class="cost2 nowrap">{{product.discounted_price}} руб.</div>
      </td>
    </tr>
    <tr>
      <td>
        <div class="inc-dre">
          <div class="input-group"><span class="input-group-btn">
                  <button type="button" class="btn btn-default btn-number" @click="decrementQuantity()" :disabled="quantity <= 1">
                    <span class="glyphicon glyphicon-minus"></span> </button>
                  </span>
            <input name="quant[1]" class="input-number" :value="quantity" type="text" readonly>
            <span class="input-group-btn">
                  <button type="button" class="btn btn-default btn-number" @click="incrementQuantity()" :disabled="quantity >= product.quantity">
                    <span
                      class="glyphicon glyphicon-plus"></span> </button>
                  </span></div>
        </div>
      </td>
      <td>
        <p class="text-center">
          <a @click="removeItem()">
            <i class="fa fa-trash-o" aria-hidden="true"></i><br>Удалить
          </a>
        </p>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <div class="cost">{{ price }} руб.</div>
      </td>
    </tr>
  </table>
</template>

<script>
  import {slugify} from "@/common/slugify.js";

  export default {
    name: "MobileCartProductCard",
    props: {
      product: {
        type: Object,
        required: true
      },
    },
    computed: {
      price() {
        return this.product.discounted_price * this.quantity
      },
      slug() {
        return slugify(this.product.name)
      }
    },
    data() {
      return {
        quantity: Number,
      }
    },
    methods: {
      getQuantity() {
        let basket = JSON.parse(localStorage.basket);
        this.quantity = basket[this.product.uid];
        if (this.quantity > this.product.quantity) {
          this.quantity = this.product.quantity;
          basket[this.product.uid] = this.quantity;
          basket = JSON.stringify(basket);
          localStorage.setItem('basket', basket);
        }
      },
      incrementQuantity() {
        let basket = JSON.parse(localStorage.basket);
        if (this.quantity < this.product.quantity) {
          this.quantity += 1;
          basket[this.product.uid] = this.quantity;
          basket = JSON.stringify(basket);
          localStorage.setItem('basket', basket);
          this.$emit('recount')
        }
      },
      decrementQuantity() {
        let basket = JSON.parse(localStorage.basket);
        if (this.quantity > 1) {
          this.quantity -= 1;
          basket[this.product.uid] = this.quantity;
          basket = JSON.stringify(basket);
          localStorage.setItem('basket', basket);
          this.$emit('recount')
        }
      },
      removeItem() {
        let basket = JSON.parse(localStorage.basket);
        delete basket[this.product.uid];
        basket = JSON.stringify(basket);
        localStorage.setItem('basket', basket);
        this.updateBasketQuantity();
        this.$emit('removeFromBasket', this.product.uid)
        this.$emit('recount')
      },
      updateBasketQuantity() {
        this.$store.dispatch('get_basket_quantity')
      }
    },
    mounted() {
      this.getQuantity()
    }
  }
</script>

<style scoped>
  .product-name td a h1 {
    font-size: 14px;
  }

  td h1 {
    margin-bottom: 0;
  }

  td p {
    margin-bottom: 0;
  }

  td {
    border: none;
  }

  .product-name h1 {
    font-size: 14px;
  }

  .cost {
    font-size: 16px;
  }

  .cost2 {
    font-size: 16px;
  }

  .nowrap{
    white-space: nowrap;
  }
</style>