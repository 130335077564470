<template>
  <div id="wrapper">
    <section>
      <div class="inner-bg">
        <div class="inner-head wow fadeInDown">
          <h3>Контакты</h3>
        </div>
      </div>
    </section>
    <div class="container">
      <div class="shop-in">
        <div class="row">
          <div class="col-md-12">
            <!--breadcrumbs -->
            <div class="bread2">
              <ul>
                <li><a href="/">На главную</a>
                </li>
                <li>/</li>
                <li><a>Контакты</a></li>
              </ul>
            </div>
            <!--breadcrumbs -->
          </div>
        </div>
        <h2 class="wow fadeInDown mt-5 title-h">Наши реквизиты</h2>
        <div class="my-5 wow fadeInDown">
          <h2>{{details.title}}</h2>
          <h3 class="text-muted">ИНН: {{details.INN}}</h3>
          <h3 class="text-muted">ОГРН: {{details.OGRN}}</h3>
          <h3 class="text-muted">Юридический адрес: {{details.address}}</h3>
          <h3 class="mt-4"><i class="fa fa-envelope-o" aria-hidden="true"></i><a :href="'mailto:' + details.email"><u>{{details.email}}</u></a></h3>
          <h3><i class="fa fa-phone" aria-hidden="true"></i> <a :href="'tel:' + details.phone">{{details.phone_display}}</a></h3>
        </div>
        <div class="contact-us pt-3">
          <div class="contact-in">
            <h2 class="wow fadeIn mb-4">Напишите нам</h2>
            <h4 class="wow fadeIn">Мы постараемся ответить на все Ваши вопросы в ближайшее время </h4>
            <div class="clearfix"></div>
            <div class="wow fadeIn mx-3">
              <div class="clearfix"></div>
              <div class="form-2 h-100">
                <form @submit.prevent="sendMessage">
                  <div class="form-group">
                    <input placeholder="Как вас зовут?" type="text" name="name" v-model="name" required>
                  </div>
                  <div class="form-group">
                    <input placeholder="Адрес электронной почты" type="email" name="email" v-model="email" required>
                  </div>
                  <div class="form-group">
                    <textarea placeholder="СООБЩЕНИЕ" v-model="message" required></textarea>
                  </div>
                  <div class="sub-bt">
                    <button class="submit-css" type="submit">ОТПРАВИТЬ</button>
                  </div>
                </form>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
  import {apiService} from "@/common/api.service.js";
  import Footer from "@/components/Footer";

  export default {
    name: "Contacts",
    components: {
      Footer,
    },
    metaInfo: {
      title: 'Контакты компании Амбервиль',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Напишите нам, если у вас есть вопросы об изделиях нашего интрнет магазина'
        },
      ],
    },
    data() {
      return {
        details: {},
        name: undefined,
        email: undefined,
        message: undefined,
      }
    },
    methods: {
      getDetails() {
        let endpoint = "api/companydetails/";
        this.$store.commit('changeLoading');
        apiService(endpoint)
          .then(data => {
            this.details = data[0]
            this.$store.commit('changeLoading');
          })
      },
      sendMessage() {
        let endpoint = "api/sendmessage/contacts/";
        let method = "POST";
        let data = {name: this.name, email: this.email, message: this.message};
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, undefined)
          .then(data => {
            this.$router.push(data[0])
            this.$store.commit('changeLoading');
          }, this)
      }
    },
    created() {
      this.getDetails();
    }
  }
</script>

<style scoped>
  .logo-contact {
    border: double 3px #000;
    padding: 10px 10px;
    text-align: center;
    border-right: none;
    border-left: none;
  }

  textarea {
    height: 200px;
    color: #222;
    letter-spacing: 2px;
    padding: 10px 10px 10px 5px;
    max-width: none;
  }

  .submit-css:hover {
    background: #222;
    color: #fff;
    border: solid 2px #222;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    padding-right: 40px;
  }

  h3 {
    margin: 0;
  }

  .title-h {
    margin: 30px 0 !important;
  }

  .fa {
    font-size: 18px;
    margin: 0;
    color: #000000;
    width: 40px;
    height: 25px;
    line-height: 28px;
    text-align: center;
  }

  .contact-in h4 {
    border-bottom: 0;
  }

  @media (max-width: 767px) {
    .contact-us {
      padding: 0 15px 40px 15px;
    }
  }
</style>