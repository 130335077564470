<template>
  <div>
    <div class="row justify-content-between order-menu mx-0 my-5">
      <a @click="filter = 'Все'; isActive=1; getOrders()">Все</a>
      <a @click="filter = 'Оплаченные'; isActive=1; getOrders()">Оплаченные</a>
      <a @click="filter = 'Отправленные'; isActive=1; getOrders()">Отправленные</a>
      <a @click="filter = 'Доставленные'; isActive=1; getOrders()">Доставленные</a>
    </div>
    <div class="breadcrumbs">
      <form
      @submit.prevent="isActive=1;  getOrders()"
      >
        <input type="text" class="search-input" v-model="filter"/>
        <button type="submit" class="filter2 ml-3">Поиск</button>

      </form>
      <ul>
        <ProductPagination
          v-for="(page, index) in pages"
          :page="page"
          :pages="pages"
          :key="index"
          @click.native="isActive = page; getOrders()"
          :isActive="isActive"
        />
      </ul>
    </div>
    <ManagerOrderCard
      v-for="order in orders"
      :order="order"
      :popular_countries="popular_countries"
      :countries="countries"
      :regions="regions"
      :key="order.uid"
    />
    <div class="breadcrumbs">
      <ul>
        <ProductPagination
          v-for="(page, index) in pages"
          :page="page"
          :pages="pages"
          :key="index"
          @click.native="isActive = page; getOrders()"
          :isActive="isActive"
        />
      </ul>
    </div>
  </div>
</template>

<script>
  import {apiService} from "@/common/api.service.js";
  import ManagerOrderCard from "@/components/ManagerOrderCard";
  import ProductPagination from "@/components/ProductPagination.vue";


  export default {
    name: "ManagerOrder",
    components: {
      ManagerOrderCard,
      ProductPagination
    },
    data() {
      return {
        orders: [],
        popular_countries: [],
        countries: [],
        regions: [],
        filter: 'Все',
        pages: Number,
        isActive: 1,
      }
    },
    methods: {
      getOrders() {
        let endpoint = "/api/manager/order/?filter=" + this.filter;
        endpoint += ("&page=" + this.isActive);
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.orders = data.results;
            this.pages = Math.ceil(data.count / 10);
            this.$store.commit('changeLoading');
          })
      },
      getPopularCountries() {
        let endpoint = "/api/countries/popular/";
        apiService(endpoint)
          .then(data => {
            this.popular_countries = data
          })
      },
      getCountries() {
        let endpoint = "/api/countries/";
        apiService(endpoint)
          .then(data => {
            this.countries = data
          })
      },
      getRegions() {
        let endpoint = "/api/regions/";
        apiService(endpoint)
          .then(data => {
            this.regions = data
          })
      },
    },
    created() {
      this.getOrders();
      this.getPopularCountries();
      this.getCountries();
      this.getRegions();
    }
  }
</script>

<style scoped>
  .order-menu a {
    text-align: center;
    background-color: white;
    font-weight: 600;
    color: #000;
    font-family: 'Open Sans', sans-serif;
    width: 233px;
    height: 60px;
    text-transform: uppercase;
    float: right;
    display: block;
    border: solid 2px #dfb859;
    line-height: 58px;
    margin: 0;
  }

  .order-menu a:hover {
    background: #222;
    border: solid 2px #222;
    color: #fff;
  }

  .search-input {
    padding: 2px;
    font-size: 16px;
  }
</style>