<template>
  <div id="wrapper">
    <!--page heading-->
    <section>
      <div class="inner-bg">
        <div class="inner-head wow fadeInDown">
          <h3>Аккаунт активирован</h3>
        </div>
      </div>
    </section>
    <!--page heading-->
    <!--container-->
    <div class="container">
      <div class="shop-in pl-0 pr-0">
        <div>
          <div class="bread2">
            <ul>
              <li>
                <router-link to="/">На главную</router-link>
              </li>
              <li>/</li>
              <li>Активация</li>
            </ul>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="thanks-bg">
          <div class="thanks-img wow fadeIn"><img src="../assets/images/products/thanks.png" alt="Спасибо" class="img-fluid"></div>
          <div class="thanks-text">
            <h2 class="wow fadeIn">Ваш аккаунт активирован</h2>
            <h3 class="wow fadeIn">Теперь вся информация о Ваших заказах доступна Вам из личного кабинета</h3>
            <div>
              <router-link to="/" class="wow fadeInLeft">Вернуться на главную</router-link>
              <router-link to="/" class="wow fadeInRight">Личный кабинет</router-link>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="clearfix"></div>
    </div>
    <!--container-->
    <div class="clearfix"></div>
    <!--footer-->
    <Footer
    />
  </div>
</template>

<script>
  import {apiService} from "@/common/api.service.js";
  import Footer from "@/components/Footer";

  export default {
    name: "Activate",
    components: {
      Footer,
    },
    metaInfo: {
      title: 'Активация аккаунта',
    },
    methods: {
      activate() {
        let token = this.$route.params.token;
        let endpoint = "/api/activate/" + token + '/'
        apiService(endpoint)
          .then(data => {
            if (data.username) {
              this.$store.dispatch('setUsername', data.username);
              this.$store.dispatch('setAToken', data.a_token);
              this.$cookie.set('token', data.a_token, {expires: 14, domain: '127.0.0.1'})
            } else {
              this.$router.push('/error')
            }
          })
      }
    },
    created() {
      this.activate()
    }
  }
</script>

<style scoped>

</style>