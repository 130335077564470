<template>
  <div class="row m-0 align-items-stretch">
    <div class="col-10">
      <div class="text-center" v-if="block.type === 'Изображение'"><img :src="block.image" :alt="title" title="" class="img-fluid"></div>
      <div class="blog-text" v-else-if="block.type === 'Текст'">
        <p>{{block.text}}</p>
      </div>
      <div class="blog-text" v-else-if="block.type === 'Изображение и текст'">
        <p><img class="paragraph-image" :src="block.image" :alt="title" title=""> {{block.text}}</p>
      </div>
      <p class="text-muted pointer float-right" data-toggle="modal" :data-target="dataTargetDeleteBlock">Удалить</p>
    </div>
    <div class="col-2 d-flex flex-column justify-content-between align-items-center">
      <div class="mt-3"><img class="pointer" @click="decreasePosition()" src="/static/images/up.png"></div>
      <a class="edit-button" data-toggle="modal" :data-target="dataTargetEditBlock">Редактировать</a>
      <div class="mb-4 rotate-90"><img class="pointer" @click="increasePosition()" src="/static/images/up.png"></div>
    </div>
    <div class="modal fade" :id="idEditBlock" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeEditBlock"
                    data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Редактировать блок</h4>
          </div>
          <div class="modal-body">
            <form class="form-horizontal"
                  @submit.prevent="editBlock">
              <div class="form-group">
                <div class="col-sm-12">
                  <select v-model="block.type">
                    <option v-for="choice in type_choices" :value="choice">{{choice}}</option>
                  </select>
                </div>
              </div>
              <div class="form-group text-center my-3" v-if="block.type !== 'Текст'">
                <div class="col-sm-12">
                  <input @change="addBlockImage" ref="addBlockImage" type="file" hidden>
                  <label class="mн-1">Изображение</label>
                  <div v-if="block.image === null">
                    <img class="w-50" src='../assets/images/button-cross.png' @click="$refs.addBlockImage.click()">
                  </div>
                  <div v-else>
                    <img class="w-50" :src="block.image" @click="$refs.addBlockImage.click()">
                  </div>
                </div>
              </div>
              <div class="form-group my-3" v-if="block.type !== 'Изображение'">
                <div class="col-sm-12">
                  <label class="mb-1">Текст</label>
                  <textarea rows='10' class="form-control h-100" required v-model="block.text"></textarea>
                </div>
              </div>
              <div class="form-group my-3 mb-5">
                <button type="submit" class="btn btn-default button-1 float-right">Сохранить</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" :id="idDeleteBlock" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeDeleteBlock" data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Удалить блок?</h4>
          </div>
          <div class="modal-body text-center">
            <form class="form-horizontal"
                  @submit.prevent="deleteBlock()">
              <div class="form-group">
                <div class="col-sm-12">
                  <button type="submit" class="btn btn-default button-1 ">Удалить</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {apiService} from "@/common/api.service.js";

  export default {
    name: "ManagerBlogBlock",
    props: {
      block: {
        type: Object,
        required: true
      },
      title: '',
    },
    computed: {
      dataTargetEditBlock() {
        return '#editBlock' + this.block.id
      },
      idEditBlock() {
        return 'editBlock' + this.block.id
      },
      dataTargetDeleteBlock() {
        return '#deleteBlock' + this.block.id
      },
      idDeleteBlock() {
        return 'deleteBlock' + this.block.id
      }
    },
    data() {
      return {
        type_choices: ['Изображение', 'Текст', 'Изображение и текст'],
        edit_block_image: undefined,
        edit_block_image_url: undefined,
      }
    },
    methods: {
      decreasePosition() {
        let endpoint = "api/manager/articleblock/" + this.block.id + "/decrease_position/";
        let auth = this.$store.state.a_token;
        let method = "PATCH";
        apiService(endpoint, method, undefined, auth)
          .then(data => {
            this.$emit('changeArticle');
          })
      },

      increasePosition() {
        let endpoint = "api/manager/articleblock/" + this.block.id + "/increase_position/";
        let auth = this.$store.state.a_token;
        let method = "PATCH";
        apiService(endpoint, method, undefined, auth)
          .then(data => {
            this.$emit('changeArticle');
          })
      },

      addBlockImage(e) {
        this.edit_block_image = e.target.files[0];
        this.edit_block_image_url = URL.createObjectURL(this.edit_block_image);
        this.block.image = this.edit_block_image_url
      },

      editBlock() {
        let endpoint = "api/manager/articleblock/" + this.block.id + "/";
        let data = new FormData;
        data.append('type', this.block.type);
        if (this.block.type === 'Изображение') {
          if (this.edit_block_image !== undefined) {
            data.append('image', this.edit_block_image);
          }
          data.append('text', '');
        } else if (this.block.type === 'Текст') {
          data.append('image', '');
          data.append('text', this.block.text);
        } else if (this.block.type === 'Изображение и текст') {
          if (this.edit_block_image !== undefined) {
            data.append('image', this.edit_block_image);
          }
          data.append('text', this.block.text);
        }
        this.$store.commit('changeLoading');
        this.$http.patch(endpoint, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Token ' + this.$store.state.a_token,
          },
        }).then(data => {
          this.$store.commit('changeLoading');
          this.$refs.closeEditBlock.click();
          this.$emit('changeArticle');
        }, this)
      },
      deleteBlock() {
        let endpoint = "api/manager/articleblock/" + this.block.id + "/";
        let method = "DELETE"
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, method, undefined, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.$refs.closeDeleteBlock.click();
            this.$emit('changeArticle')
          }, this)
      },
    }
  }
</script>

<style scoped>
  .edit-button {
    text-align: center;
    background-color: white;
    font-weight: 600;
    color: #000;
    font-family: 'Open Sans', sans-serif;
    width: 140px;
    height: 30px;
    text-transform: uppercase;
    float: right;
    display: block;
    border: solid 2px #dfb859;
    line-height: 30px;
    margin: 0;
  }

  .edit-button:hover {
    background: #222;
    border: solid 2px #222;
    color: #fff !important;
  }

  .paragraph-image {
    width: 25%;
    float: left;
    margin: 0 15px 15px 0
  }

  .rotate-90 {
    transform: rotate(180deg);
  }</style>