<template>
  <tr>
    <td class="text-center bg-white image-td d-none d-md-table-cell" v-if="!link">
      <img data-v-2d0fb042="" :src="imageSource" :alt="product.name" :title="product.name" class="img-fluid">
    </td>
    <td class="text-center bg-white image-td d-none d-md-table-cell" v-else>
      <router-link :to="{ name: 'Detail', params: {id: link, slug: slug(product.name)}}">
        <img data-v-2d0fb042="" :src="imageSource" :alt="product.name" :title="product.name" class="img-fluid">
      </router-link>
    </td>
    <td class="product-name" v-if="!link">
      <h1 class="mb-0">{{product.name}}<br></h1>
      <h1 v-if="product.variant_description">{{product.variant_description}}</h1>
    </td>
    <td class="product-name" v-else>
      <router-link :to="{ name: 'Detail', params: {id: link, slug: slug(product.name)}}">
        <h1 class="mb-0">{{product.name}}<br></h1>
        <h1 v-if="product.variant_description">{{product.variant_description}}</h1>
      </router-link>
    </td>
    <td>
      <div class="cost2">{{product.price}} руб.</div>
    </td>
    <td>
      <div class="cost2">{{product.quantity}}</div>
    </td>
    <td>
      <div class="cost2">{{product.price * product.quantity}} руб.</div>
    </td>
  </tr>
</template>

<script>
  import {apiService} from "@/common/api.service.js";
  import {slugify} from "@/common/slugify.js";

  export default {
    name: "CabinetOrderTableProduct",
    props: {
      product: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        imageSource: "/static/images/no-image.jpg",
        link: undefined
      }
    },
    methods: {
      slug(text) {
        return slugify(text)
      },

      getProductTemplate() {
        let endpoint = "/api/getproducttemplate/" + this.product.uid;
        apiService(endpoint)
          .then(data => {
            if (data) {
              this.imageSource = data.main_image
              this.link = data.id
            }
          }, this);
      }
    },
    created() {
      this.getProductTemplate()
    }
  }
</script>

<style scoped>

  .image-td {
    width: 10%;
  }

  .product-name {
    font-family: 'Open Sans', sans-serif;
  }

  .product-name h1 {
    font-size: 19px;
    line-height: 19px;
  }

  .cost2 {
    font-size: 17px;
    white-space: nowrap;
  }

  @media (max-width: 991px) {
    .cost2 {
      font-size: 17px;
    }
  }

  @media (max-width: 767px) {
    .cost2 {
      font-size: 15px;
    }

    .product-name h1 {
      font-size: 13px;
      line-height: 13px;
    }
  }

</style>