<template>
  <div id="wrapper">
    <!--page heading-->
    <section>
      <div class="inner-bg">
        <div class="inner-head wow fadeInDown">
          <h3>Спасибо за заказ</h3>
        </div>
      </div>
    </section>
    <!--page heading-->
    <!--container-->
    <div class="container">
      <div class="shop-in pl-0 pr-0">
        <div>
          <div class="bread2">
            <ul>
              <li><router-link to="/">На главную</router-link></li>
              <li>/</li>
              <li>Спасибо за заказ</li>
            </ul>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="thanks-bg">
          <div class="thanks-img wow fadeIn"><img src="../assets/images/products/thanks.png" alt="Спасибо" class="img-fluid"></div>
          <div class="thanks-text">
            <h2 class="wow fadeIn">Спасибо за заказ</h2>
            <h3 class="wow fadeIn">Вы выбрали оплату наложенным платежом. Наш менеджер перезвонит Вам в ближайшее рабочее время, чтобы подтвердить заказ</h3>
            <div><router-link to="/" class="wow fadeInLeft">Вернуться на главную</router-link> <router-link to="/products" class="wow fadeInRight">Продолжить покупки</router-link></div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="clearfix"></div>
    </div>
    <!--container-->
    <div class="clearfix"></div>
    <!--footer-->
    <Footer
    />
  </div>
</template>

<script>
  import Footer from "@/components/Footer";

  export default {
    name: "ThankYouReserve",
    components: {
      Footer,
    },
    metaInfo: {
      title: 'Спасибо за заказ',
    },

    methods: {
      deleteBasket() {
        localStorage.basket = '{}';
        this.$store.dispatch('get_basket_quantity')
      }
    },

    created() {
      this.deleteBasket()
    }

  }
</script>

<style scoped>

</style>