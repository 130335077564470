<template>
  <div id="wrapper">
    <!--page heading-->
    <section>
      <div class="inner-bg">
        <div class="inner-head wow fadeInDown">
          <h3>Восстановить пароль</h3>
        </div>
      </div>
    </section>
    <!--page heading-->
    <!--container-->
    <div class="container">
      <div class="shop-in pl-0 pr-0">
        <div>
          <div class="bread2">
            <ul>
              <li>
                <router-link to="/">На главную</router-link>
              </li>
              <li>/</li>
              <li>Восстановление пароля</li>
            </ul>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="thanks-bg">
          <div class="thanks-text">
            <h2 class="wow fadeIn error-heading">Восстановить пароль</h2>
            <h3 class="wow fadeIn">Введите новый пароль</h3>
            <form
              id="newpassword-form"
              @submit.prevent="validateNewPassword">
              <div class="form-group">
                <div class="form-row">
                  <div class="col">
                    <input type="password" id="restore-newpassword" class="form-control ml-auto" v-model="restore_newpassword" placeholder="Новый пароль"/>
                    <label for="restore-newpassword" v-if="this.newpassword_errors['restore_newpassword']">{{this.newpassword_errors['restore_newpassword']}}</label>
                  </div>
                  <div class="col">
                    <input type="password" id="restore-newpassword2" class="form-control" v-model="restore_newpassword2" placeholder="Повторите пароль"/>
                    <label for="restore-newpassword2" class="text-danger" v-if="this.newpassword_errors['restore_newpassword2']">{{this.newpassword_errors['restore_newpassword2']}}</label>
                  </div>
                </div>
              </div>
              <button type="submit" class="wow fadeInLeft">Восстановить пароль</button>
            </form>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="clearfix"></div>
    </div>
    <!--container-->
    <div class="clearfix"></div>
    <!--footer-->
    <Footer
    />
  </div>
</template>

<script>
  import {apiService} from "@/common/api.service.js";
  import Footer from "@/components/Footer";

  export default {
    name: "SetPassword",
    components: {
      Footer,
    },
    metaInfo: {
      title: 'Введите новый пароль',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Введите новый пароль пользователя интернет-магазина Амбервиль'
        },
      ],
    },
    data() {
      return {
        restore_newpassword: "",
        restore_newpassword2: "",
        newpassword_errors: [],
      }
    },
    methods: {
      validateNewPassword() {
        this.newpassword_errors = [];
        const validation = /[$%^*={}|<>]/;

        if (!this.restore_newpassword) {
          this.newpassword_errors['restore_newpassword'] = 'Заполните это поле'
        } else if (this.restore_newpassword.match(validation)) {
          this.newpassword_errors['restore_newpassword'] = 'Недопустимые символы'
        }

        if (!this.restore_newpassword2) {
          this.newpassword_errors['restore_newpassword2'] = 'Заполните это поле'
        } else if (this.restore_newpassword2.match(validation)) {
          this.newpassword_errors['restore_newpassword2'] = 'Недопустимые символы'
        } else if (this.restore_newpassword !== this.restore_newpassword2) {
          this.newpassword_errors['restore_newpassword2'] = 'Пароли должны совпадать'
        }

        return this.submitNewPassword()
      },

      submitNewPassword() {
        if (Object.keys(this.newpassword_errors).length === 0) {
          let endpoint = "/api/setpassword/";
          let method = "POST";
          let data = {'token': this.$route.params.token, 'password': this.restore_newpassword, 'password2': this.restore_newpassword2};
          this.$store.commit('changeLoading');
          apiService(endpoint, method, data)
            .then(data => {
              if (data.success) {
                this.$router.push('/passwordrestored')
              }
              else {
                this.$router.push('/error')
              }
              this.$store.commit('changeLoading');
            }, this)
        }
      }
    }
  }
</script>

<style scoped>
  .error-heading {
    margin-top: 10%;
  }

  .form-row {
    padding: 0 10%;
  }

  .form-control {
    max-width: 400px;
  }

  @media (max-width: 1499px) {
    .thanks-bg {
      min-height: 480px;
    }
  }

  @media (max-width: 991px) {
    .thanks-bg {
      min-height: 400px;
    }
  }

  @media (max-width: 771px) {
    .thanks-bg {
      background-size: 100% 100%;
    }
  }

  .thanks-text button {
    background: #fff;
    width: 248px;

    display: inline-block;
    line-height: 58px;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    border: solid 2px #dfb859;
    font-size: 15px;
  }

  .thanks-text button:hover {
    background: #222;
    color: #fff;
    border: solid 2px #222;
  }
</style>