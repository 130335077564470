<template>
  <div id="wrapper">
    <section>
      <div class="inner-bg">
        <div class="inner-head wow fadeInDown">
          <h3>Условия</h3>
        </div>
      </div>
    </section>
    <!--container-->
    <div class="container">
      <div class="shop-in">
        <div>
          <div class="bread2">
            <ul>
              <li>
                <router-link to="/">На главную</router-link>
              </li>
              <li>/</li>
              <li>Условия</li>
            </ul>
          </div>
        </div>
        <div class="clearfix"></div>
        <div>
          <div class="text-style2"><p class="wow fadeIn">Публичная оферта (договор) о заключении договора розничной купли-продажи ювелирных изделий, сувениров и изделий без драгметалла, дистанционным
            способом.</p></div>
          <div class="inner-section terms-text">
            <div class="wow fadeIn">
              <h2 class="no-border">1. Общие положения.</h2>
              <p>1.1. В соответствии с п. 2 ст. 437 Гражданского Кодекса Российской Федерации (ГК РФ) Продавец предлагает любому физическому лицу, далее «Покупатель», разместить заказ и осуществить
                покупку ювелирных изделий (далее — «Товар», «Изделие»), размещенных в электронных каталогах на сайте Продавца <router-link to="/">https://www.ambervile.ru/</router-link>.</p>
              <p>1.2. Настоящий договор является публичной офертой, т. е. предложением Продавца о заключении с любым физическим лицом договора розничной купли-продажи товаров дистанционным способом на
                основании ознакомления с описанием товара, представленного на Сайте.</p>
              <p>1.3. Акцепт — действия Покупателя по формированию Заказа и по оплате Товара посредством наличных или безналичных денежных средств или электронных средств платежа. Акцепт считается
                состоявшимся после выполнения Покупателем пункта 1.6.</p>
              <p>1.4. Заказывая Товары через Интернет-магазин, Покупатель соглашается с условиями настоящего договора, изложенными ниже.</p>
              <p>К отношениям между Покупателем и Продавцом применяются положения Гражданского Кодекса РФ, Закона РФ «О защите прав потребителей» от 07.02.1992 г.№ 2300–1 (с изменениями и
                дополнениями).</p>
              <p>Постановления Правительства РФ от 27.09.2007 г. № 612 «Об утверждении Правил продажи товаров дистанционным способом» (с изменениями и дополнениями), Правила продажи ювелирных изделий
                из драгоценных металлов и (или) драгоценных камней, утвержденные Постановлением Правительства Российской Федерации от 19 января 1998 г. N 55, Постановление Правительства РФ от
                06.05.2016 N 394 "Об опробовании, анализе и клеймении ювелирных и других изделий из драгоценных металлов", Постановление Правительства РФ от 05.04.1999 N 372 "О сертификации
                драгоценных металлов, драгоценных камней и продукции из них".</p>
              <p>1.5. Продавец имеет право вносить изменения и дополнения в настоящий Договор. Договор с учетом изменений и дополнений вступает в силу с даты размещения на сайте Продавца. Покупатель
                принимает на себя обязательства отслеживать изменения и дополнения, внесенные в настоящий договор.</p>
              <p>1.6. Покупатель соглашается с условиями настоящего Договора путем нажатия кнопки «Отправить заказ» при оформлении заказа.</p>
              <p>1.7. Срок действия настоящего предложения (оферты) – бессрочно в период работы сайта <router-link to="/">https://www.ambervile.ru/</router-link>.</p>
            </div>
            <div class="wow fadeIn">
              <h2>2. Правила оформления заказа.</h2>
              <p>2.1. Покупатель самостоятельно оформляет заказ в Интернет-магазине.</p>
              <p>2.2. Оплата товара производится одним из удобных для Покупателя способов, представленных на сайте.</p>
              <p>2.3. Все информационные материалы, представленные на Сайте, носят достоверную информацию о свойствах и характеристиках Товара. Покупатель ознакомлен с тем, что вес заказанного изделия
                и характеристики вставок могут незначительно отклоняться от информации, указанной на сайте (Окончательный вес изделия и вставок может отличаться от заявленного общего веса в пределах
                15% в большую или меньшую сторону, в связи с технологическими особенностями производственного процесса), при этом цена соответствующего артикула Товара не изменяется. В случае
                возникновения у Покупателя вопросов, касающихся свойств и характеристик Товара, перед оформлением Заказа, Покупатель может обратиться к Продавцу по телефону +7 (9062) 38-56-16.</p>
            </div>
            <div class="wow fadeIn">
              <h2>3. Порядок получения товара.</h2>
              <p>3.1. Доставка ювелирных изделий осуществляется на территории любых государств.</p>
              <p>3.2. Продавец приложит все усилия для соблюдения сроков доставки, указанных на Сайте, тем не менее, задержки в доставке возможны ввиду непредвиденных обстоятельств, произошедших не по
                вине Продавца.</p>
              <p>3.3. Условия доставки описаны в Разделе Доставка.</p>
              <p>3.4. При доставке на дом курьер передает заказ в руки Покупателю только после получения от него наличных средств за изделие и доставку (в случае наложенного платежа).</p>
              <p>3.5. Товар считается доставленным в момент его передачи Покупателю. Подписываясь в товаросопроводительных документах, Покупатель подтверждает исполнение Заказа. В случае
                предоставления Покупателем недостоверной информации о его контактных данных Продавец за ненадлежащее исполнение договора ответственности не несет.</p>
            </div>
            <div class="wow fadeIn">
              <h2>4. Оплата товара.</h2>
              <p>4.1. Покупатель вносит оплату за заказанный Товар при получении в размере 100% от цены Товара, указанной на сайте.</p>
              <p>4.2. Цены на любые позиции Товара, указанные на сайте <router-link to="/">https://www.ambervile.ru/</router-link>, могут быть изменены Продавцом в одностороннем порядке без уведомления Покупателя. В случае изменения
                цены на заказанные позиции Товара, Продавец обязуется в кратчайшие сроки проинформировать Покупателя о таком изменении. Покупатель вправе подтвердить либо аннулировать Заказ. В случае
                несогласия Покупателя с новой ценой Товара Продавец возвращает Покупателю предварительно оплаченные денежные средства. В случае отсутствия связи с Покупателем Продавец вправе по своему
                выбору аннулировать Заказ либо доставить Товар Покупателю по новой цене. Оплата Покупателем Товара по новой цене и принятие Товара означает полное согласие Покупателя со стоимостью
                Товара.</p>
              <p>4.3. Обязанность Продавца по передаче Покупателю Товаров возникает только после полной оплаты Покупателем Товара.</p>
              <p>4.4. Порядок оплаты описан в Разделе Оплата.</p>
              <p>4.5. Исполнение Покупателем обязанности по оплате Товара, а также Доставки, в случае заказа Покупателем соответствующей услуги, возможно следующими способами:</p>
              <p>4.5.1. путем совершения операции по оплате Товара с использованием Банковской карты в порядке, установленном действующим законодательством Российской Федерации и договором между
                кредитной организацией, эмитировавшей Карту, и Покупателем;</p>
              <p>4.5.2. Путем совершения операций с электронными денежными средствами в соответствии с Федеральным законом № 161-ФЗ от 27.06.2011 г. «О национальной платежной системе».</p>
              <p>4.6. В случае отказа Покупателя от Товара при возврате Покупателю суммы предварительной оплаты, внесенной Покупателем в безналичном порядке, в том числе при использовании для оплаты
                Товара электронных денежных средств, банковские и иные комиссии, уплаченные Покупателем в этой связи, Продавцом не компенсируются.</p>
            </div>
            <div class="wow fadeIn">
              <h2>5. Возврат товара.</h2>
              <p>5.1. Возврат товара может быть осуществлён согласно ФЗ «О защите прав потребителей», возврат осуществляется только при выявлении заводского брака. По закону, согласно статье 26.2,
                реализация ювелирных украшений из серебра, золота производится на основании установленных Правительством РФ условий. Возврат товара осуществляется за счет покупателя. </p>
              <p>5.2. Товар может быть возвращен, если отмечаются:</p>
              <ul>
                <li>отсутствие фирменной пробы (у неоригинальных товаров);</li>
                <li>несоответствие пробе, отраженной на бирке (золотое кольцо по факту оказывается позолоченным);</li>
                <li>выпадение драгоценных камней, вставок из изделия;</li>
                <li>поломка часов или украшений до истечения гарантийного срока;</li>
                <li>обнаружение дефектов на браслетах, цепочках: выпадающие звенья, сломанный замок, застежка.</li>
              </ul>
              <p>В соответствии с Постановлением Правительства РФ №55 от 19.01.1998 года вернуть ювелирные изделия надлежащего качества, если не подошел размер, украшение не понравилось, не получится.
                На основании статьи 476 ГК РФ, продавец не должен нести ответственность за возникшие после продажи поломки. Возврат осуществляется только при выявлении заводского брака.</p>
              <p>5.3. Возврат Товара ненадлежащего качества возможен в течение гарантийного срока в случае, если сохранены его товарный вид, потребительские свойства, а также документ, подтверждающий
                факт и условия покупки указанного товара: кассовый чек (при наличном расчете), товарный чек (товарная накладная). Условия Возврата Товара ненадлежащего качества описаны в Разделе
                Возврат товара и предоплаты.</p>
              <p>5.4. Покупатель, которому продан Товар ненадлежащего качества, если это не было оговорено Продавцом, вправе по своему выбору потребовать:</p>
              <ul>
                <li>безвозмездного устранения недостатков товара или возмещения расходов на их исправление покупателем или третьим лицом.</li>
                <li>соразмерного уменьшения покупной цены.</li>
                <li>замены на Товар аналогичной марки (модели, артикула) или на такой же Товар другой марки (модели, артикула) с соответствующим перерасчетом покупной цены.</li>
                <li>вправе отказаться от исполнения договора и потребовать возврата уплаченной за товар суммы.</li>
              </ul>
              <p>5.5. При возврате Товара от Покупателя Продавец, в случае необходимости, производит проверку качества Товара. В случае спора о причинах возникновения недостатков Товара Продавец
                производит его экспертизу. Если в результате экспертизы товара установлено, что его недостатки возникли вследствие обстоятельств, за которые не отвечает Продавец, Покупатель обязан
                возместить Продавцу расходы на проведение экспертизы, а также связанные с ее проведением расходы на хранение и транспортировку.</p>
              <p>5.6. Срок устранения недостатков товара не может превышать сорок пять дней.</p>
              <p>5.7. Гарантийный срок на Товар составляет 14 дней, на ювелирные изделия с бриллиантами и изделия с драгоценными вставками составляет 1 месяц с момента передачи Товара Покупателю.
                Гарантия не распространяется на дефекты, образовавшиеся в результате: механических повреждений (царапин, разрывов, потертостей и т. д.); воздействия экстремальных температур,
                растворителей, кислот, воды; неправильного использования (эксплуатации); естественного износа.</p>
            </div>
            <div class="wow fadeIn">
              <h2>6. Конфиденциальность и защита персональной информации.</h2>
              <p>6.1. При регистрации в Интернет-магазине Покупатель предоставляет о себе следующую информацию: Фамилия, Имя, Отчество, адрес электронной почты, номер мобильного телефона, адрес
                доставки. Покупатель несёт ответственность за достоверность предоставленной информации при оформлении Заказа. Продавец не несет ответственности за содержание и достоверность
                информации, предоставленной Покупателем при оформлении Заказа.</p>
              <p>6.2. Продавец использует полученную от Покупателя информацию:</p>
              <ul>
                <li>для регистрации Покупателя в Интернет-магазине;</li>
                <li>для выполнения своих обязательств перед Покупателем;</li>
                <li>для оценки и анализа работы Интернет-магазина;</li>
                <li>для определения победителя в акциях, проводимых Продавцом.</li>
              </ul>
              <p>Покупатель дает свое согласие на обработку Продавцом персональных данных Покупателя, указанных в п. 6.1. Договора, в целях исполнения Договора, проведения исследований, направленных
                на улучшение качества предоставляемых услуг и Товаров, в том числе для проведения маркетинговых программ и исследований, статистических исследований.</p>
              <p>Покупатель дает свое согласие Продавцу на предоставление права привлекать на договорной основе третьих лиц для целей обработки персональных данных Покупателя на условиях соблюдения
                данными лицами требований законодательства Российской Федерации об обеспечении конфиденциальности и безопасности персональных данных.</p>
              <p>6.3. Соглашаясь с условиями настоящего Договора, Покупатель соглашается с тем, что Продавец вправе направлять Покупателю сообщения рекламно-информационного характера. Если Покупатель
                не желает получать рассылки от Продавца, он должен отказаться от получения такой рассылки в Интернет-магазине.</p>
              <p>6.4. Продавец обязуется не разглашать полученную от Покупателя информацию. Не считается нарушением предоставление Продавцом информации агентам и третьим лицам, действующим на
                основании договора с Продавцом, для исполнения обязательств перед Покупателем. Не считается нарушением обязательств разглашение информации в соответствии с обоснованными и применимыми
                требованиями закона.</p>
              <p>6.5. Продавец не несет ответственности за сведения, предоставленные Покупателем на Сайте в общедоступной форме.</p>
              <p>6.6. Продавец имеет право на осуществление записи телефонных переговоров с Покупателем. В соответствии с п.4 ст.16 Федерального закона «Об информации, информационных технологиях и о
                защите информации» Продавец обязуется предотвращать попытки несанкционированного доступа к информации о Покупателе и/или передачу ее лицам, не имеющим непосредственного отношения к
                исполнению Договора, а также своевременно обнаруживать и пресекать такие попытки.</p>
              <p>6.7. Продавец вправе использовать технологию «cookies» (технология, позволяющая веб-серверу посылать служебную информацию на компьютер пользователя и сохранять ее в браузере).</p>
              <p>6.8. Продавец получает информацию об идентификаторе устройства посетителя Сайта, подключённого к локальной сети и/или сети Интернет (ip-адресе). Данная информация используется
                исключительно в статистических целях и не используется для установления личности посетителя.</p>
            </div>
            <div class="wow fadeIn">
              <h2>7. Интеллектуальная собственность.</h2>
              <p>7.1. Вся текстовая информация и графические изображения, находящиеся на Сайте, являются собственностью Продавца и/или его контрагентов.</p>
            </div>
            <div class="wow fadeIn">
              <h2>8. Ответственность сторон.</h2>
              <p>8.1. В случае нарушения сторонами условий настоящего Договора стороны несут ответственность в соответствии с действующим законодательством РФ.</p>
              <p>8.2. В случае форс-мажорных обстоятельств, стороны освобождаются от ответственности за неисполнение настоящего Договора.</p>
              <p>8.3. При наступлении обстоятельств непреодолимой силы, которые сторона по настоящему Договору не могла ни предвидеть, ни предотвратить разумными мерами, срок исполнения обязательств
                по настоящему Договору отодвигается соразмерно времени, в течение которого продолжают действовать такие обстоятельства, без возмещения каких-либо убытков. К таким событиям
                чрезвычайного характера, в частности, относятся: наводнения, пожар, землетрясение, взрыв, шторм, оседание почвы, иные явления природы, эпидемия, а также война или военные действия,
                террористические акты; перепады напряжения в электросети и иные обстоятельства, приведшие к выходу из строя технических средств какой-либо из сторон договора. Сторона, для которой
                создалась ситуация, при которой стало невозможно исполнять свои обязательства из-за наступления обстоятельств непреодолимой силы, обязана о наступлении, предположительном сроке
                действия и прекращения этих обстоятельств незамедлительно (но не позднее 5 (пяти) рабочих дней) уведомить в письменной форме другую сторону. В случае спора о времени наступления,
                сроках действия и окончания обстоятельств непреодолимой силы заключение компетентного органа в месте нахождения соответствующей Стороны будет являться надлежащим и достаточным
                подтверждением начала, срока действия и окончания указанных обстоятельств.
                Не уведомление или несвоевременное уведомление стороны о начале действия обстоятельств непреодолимой силы лишает ее в дальнейшем права ссылаться на них как на основание, освобождающее
                от ответственности за неисполнение обязательств по настоящему договору. Если обстоятельства непреодолимой силы и/или их последствия продолжают действовать более 30 (тридцати)
                календарных дней подряд, то Договор может быть расторгнут по инициативе любой из сторон путем направления в адрес другой стороны письменного уведомления.</p>
            </div>
            <div class="wow fadeIn">
              <h2>9. Дополнительные условия.</h2>
              <p>9.1. Акции и скидки интернет-магазина не распространяются на изделия-исключения, если другое не обозначено в условиях акции. Акции и купоны не суммируются, если иное не предусмотрено условиями акции.</p>
            </div>
            <div class="wow fadeIn">
              <h2>10. Информация о Продавце.</h2>
              <p>ИП Ещенко Елена Евгеньевна</p>
              <p>ИНН: 390500497442</p>
              <p>ОГРН: 306390509600198</p>
              <p>Юридический адрес: г. Калининград, ул. С. Колоскова 5-2</p>
              <p>Е-mail: manager@ambervile.ru</p>
              <p>Тел. +7 (9062) 38-56-16</p>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
    <Footer/>
  </div>

</template>

<script>

  import Footer from "../components/Footer";

  export default {
    name: "Offer",
    components: {Footer},
    metaInfo: {
      title: 'Публичная оферта',
    },
  }
</script>

<style scoped>
  .terms-text p {
    border-bottom: none;
    padding-bottom: 0;
  }

  .terms-text h2 {
    border-top: solid 1px #e6e4e4;
    padding: 15px 0 15px 0;
  }

  .terms-text ul {
    list-style: disc;
    padding-left: 35px;
    padding-bottom: 15px;
  }

  .terms-text a {
    text-decoration: underline!important;
  }

  .no-border {
    border-top: none !important;
    padding: 0 0 15px 0 !important;
  }

</style>