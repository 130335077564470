<template>
  <div>
    <div class="check-img wow fadeIn">
      <div class="img-1"><img :src="product.main_image" :alt="product.short_name" :title="product.name" class="img-fluid"></div>
      <div class="clearfix"></div>
    </div>
    <div class="title2 wow fadeIn">
      <div class="row m-0">
        <div class="col-md-9 col-sm-7 col-xs-12">
          <div class="product-name">{{product.short_name}}</div>
        </div>
        <div class="col-md-3 col-sm-5 col-xs-12">
          <h2 class="rate-css2">{{ price }} руб.</h2>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "CheckoutProductCard",
    props: {
      product: {
        type: Object,
        required: true
      },
    },
    computed: {
      price() {
        return this.product.discounted_price * this.quantity
      }
    },
    data() {
      return {
        quantity: Number,
      }
    },
    methods: {
      getQuantity() {
        let basket = JSON.parse(localStorage.basket);
        this.quantity = basket[this.product.uid];
        if (this.quantity > this.product.quantity) {
          this.quantity = this.product.quantity;
          basket[this.product.uid] = this.quantity;
          basket = JSON.stringify(basket);
          localStorage.setItem('basket', basket);
        }
      },
    },
    created() {
      this.getQuantity()
    }
  }
</script>

<style scoped>
  .img-fluid {
    width: 30%;
    padding: 10px 0;
  }

  .title2 {
    color: black;
    background: white;
  }

  .img-1 {
    background: white;
  }

  .product-name {
    padding: 0 10px;
    max-height: 50px;
    overflow: hidden;
  }

</style>