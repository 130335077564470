<template>
  <div>
    <div v-if="!new_product_show">
      <div class="row justify-content-between product-menu mx-0 mt-5">
        <a @click="filter = 'Все';  isActive=1; getProducts()">Все</a>
        <a @click="filter = 'Не в наличии'; isActive=1; getProducts()">Не в наличии</a>
        <a @click="filter = 'Со скидкой'; isActive=1; getProducts()">Со скидкой</a>
        <a @click="filter = 'Коллекции'; isActive=1; getProducts()">Коллекции</a>
      </div>
      <div class="row justify-content-between product-menu mx-0 mt-3">
        <a @click="filter = 'Товар недели'; isActive=1; getProducts()">Товар недели</a>
        <a @click="filter = 'Тэги'; isActive=1; getProducts()">Тэги</a>
        <a @click="filter = 'Неактивные'; isActive=1; getProducts()">Неактивные</a>
      </div>
    </div>
    <div class="row mx-0 my-5 product-menu" v-if="!new_product_show">
      <a @click="new_product_show = true">Добавить новый</a>
    </div>
    <div class="row mx-0 my-5 product-menu" v-if="new_product_show">
      <a @click="new_product_show = false">Все товары</a>
    </div>
    <div v-if="!new_product_show">
      <div class="breadcrumbs">
        <div class="row m-0 justify-content-between">
          <div class="row m-0">
            <div class="select-p mr-4">
              <select @change="selected_category=$event.target.value; isActive=1; getProducts()"
                      class="selectpicker select-1" data-style="btn-primary">
                <option value="0">Все категории</option>
                <option v-for="category in categories" :value="category.id">{{category.group.name}} - {{category.name}}</option>
              </select>
            </div>
            <form
              @submit.prevent="isActive=1; getProducts()"
            >
              <input type="text" class="search-input" v-model="filter"/>
              <button type="submit" class="filter2 ml-2">Поиск</button>
            </form>
          </div>
          <ul>
            <ProductPagination
              v-for="(page, index) in pages"
              :page="page"
              :key="index"
              :pages="pages"
              @click.native="isActive = page; getProducts()"
              :isActive="isActive"
            />
          </ul>
        </div>
      </div>
      <ManagerProductCard
        v-for="product in products"
        :product='product'
        :categories="categories"
        :shops="shops"
        :week_1_list="week_1_list"
        :week_2_list="week_2_list"
        :week_3_list="week_3_list"
        :week_4_list="week_4_list"
        :tags="tags"
        :attr_images="attr_images"
        :collections="collections"
        :product_list="product_list"
        :key="product.id"
        v-on:reloadProducts="getProducts()"/>
      <div class="breadcrumbs">
        <ul>
          <ProductPagination
            v-for="(page, index) in pages"
            :page="page"
            :key="index"
            :pages="pages"
            @click.native="isActive = page; getProducts()"
            :isActive="isActive"
          />
        </ul>
      </div>
    </div>
    <div v-if="new_product_show" class="new-product">
      <div v-if="new_stage === 1">
        <h1 class="mb-5"> 1. Основная информация</h1>
        <p v-if="!validated" class="text-danger">Заполните обязательные поля *</p>
        <div class="form-group">
          <input type="text" class="form-control" v-model="new_uid" @change="checkUID" required placeholder="Артикул *"/>
          <p v-if="!uid_checked" class="text-danger">Товар с таким артикулом уже существует</p>
          <input type="text" class="form-control" v-model="new_short_name" required placeholder="Короткое название *"/>
          <input type="text" class="form-control" v-model="new_name" required placeholder="Полное название *"/>
          <select v-model="new_category" class="js-countries">
            <option value="" disabled selected>Выберите категорию *</option>
            <option v-for="category in categories" :value="category.id">{{category.group.name}} - {{category.name}}</option>
          </select>
          <select v-model="new_shop" class="js-countries">
            <option value="" selected>Выберите магазин</option>
            <option v-for="shop in shops" :value="shop.id">{{shop.name}}</option>
          </select>
          <textarea class="form-control description-area mt-2 ml-1" v-model="new_description" required placeholder="Описание *"></textarea>
          <input type="text" class="form-control" v-model="new_comment" placeholder="Комментарий"/>
          <div class="row ml-1 mt-2">
            <span class="active-span">Активный</span> <input type="checkbox" class="form-control checkbox-active" v-model="new_active"/>
          </div>
          <div class="product-menu my-4 row mx-0 justify-content-end">
            <a @click="nextStage()">Далее</a>
          </div>
        </div>
      </div>
      <div v-if="new_stage === 2">
        <h1 class="mb-5"> 2. Характеристики</h1>
        <p v-if="!validated" class="text-danger">Заполните обязательные поля *</p>
        <div class="form-group">
          <div class="row mx-0">
            <div class="col-sm-4 row m-0 align-items-center">
              <input type="number" class="input-price mx-1 col-10" v-model="new_price" required placeholder="Цена *" @input="editDiscountedPrice">
              <h2 class="col-1">руб.</h2>
            </div>
            <div class="col-sm-4 row m-0 align-items-center">
              <input type="number" class="input-price mx-1 col-10" v-model="new_discount_size" required placeholder="Скидка *" @input="editDiscountedPrice">
              <h2>%</h2>
            </div>
            <div class="col-sm-4 row m-0 align-items-center">
              <input type="number" class="input-price mx-1 col-10" v-model="new_discounted_price" placeholder="Цена со скидкой" readonly>
              <h2>руб.</h2>
            </div>
          </div>
          <div class="row mx-0">
            <div class="col-sm-4 row m-0 align-items-center">
              <input type="number" class="input-price mx-1" v-model="new_quantity" required placeholder="Количество *">
            </div>
            <div class="col-sm-4">
            </div>
            <div class="col-sm-4 row m-0 align-items-center">
              <input type="number" class="input-price mx-1 col-10" v-model="new_delivery_weight" placeholder="Вес для рассчета доставки">
              <h2>г</h2>
            </div>
          </div>
          <h1 class="text-center"> Аттрибуты</h1>
          <div v-for="(attr, key) in new_attributes" class="row ml-4 align-items-center" :key="key">
            <select
              v-model="attr.image.id"
              class="js-countries col-3 mb-0">
              <option value="" disabled selected> Выберите изображение *</option>
              <option v-for="image in attr_images" :value="image.id">{{image.name}}</option>
            </select>
            <input type="text" class="form-control col-4 mx-2" v-model="attr.name" required placeholder="Название *">
            <input type="text" class="form-control col-3 mx-2 mb-0" v-model="attr.value" required placeholder="Значение *">
            <h2 class="col-1 my-0 delete-x" @click="removeAttribute(key)">X</h2>
          </div>
          <div>
            <button class="filter2 mt-3" @click="addAttribute">Добавить</button>
          </div>
          <div class="product-menu my-4 row mx-0 justify-content-between">
            <a @click="previousStage()">Назад</a>
            <a @click="nextStage()">Далее</a>
          </div>
        </div>
      </div>
      <div v-if="new_stage === 3">
        <h1 class="mb-5"> 3. Фотографии</h1>
        <p v-if="!validated" class="text-danger">Добавьте хотя бы одну фотографию и выберите основную</p>
        <input @change="showImages" ref="fileUpload" type="file" multiple hidden>
        <div v-if="this.new_images.length === 0" class="row justify-content-center">
          <img class="button-cross" src='../assets/images/button-cross.png' @click="$refs.fileUpload.click()"/>
        </div>
        <div v-else class="row justify-content-around">
          <div v-for="image in this.new_images">
            <img class="button-cross" :src='image.url' @click="$refs.fileUpload.click()"/>
            <input type="radio" class="w-100 checkbox-active mt-2" :value="image.url" v-model="new_image_main">
            <p class="text-center">Основная</p>
          </div>
        </div>
        <div class="product-menu my-4 row mx-0 justify-content-between">
          <a @click="previousStage()">Назад</a>
          <a @click="nextStage()">Далее</a>
        </div>
      </div>
      <div v-if="new_stage === 4">
        <h1 class="mb-5"> 4. Коллекции и варианты</h1>
        <p v-if="!validated" class="text-danger">Закончите создание коллекции или заполните все поля, если изделие - вариант</p>
        <div class="row mx-0">
          <div class="col-sm-6">
            <select
              class="js-countries select-1 mb-4"
              v-model="new_collection">
              <option value="" selected="selected">Не в коллекции</option>
              <option v-for="collection in collections" :value="collection.id">{{collection.name}}</option>
              <option value="0">Создать новую</option>
            </select>
            <div v-if="new_collection === '0'" class="row mx-0">
              <input type="text" class="form-control col-6 mr-2" v-model="new_collection_create_name" placeholder="Название *">
              <button class="filter2 mt-3" @click="addCollection">Добавить</button>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row mx-0 mb-4">
              <span class="active-span">Вариант</span> <input type="checkbox" class="form-control checkbox-active" v-model="new_variant"/>
            </div>
            <select
              v-if="new_variant"
              class="js-countries mb-4"
              v-model="new_variant_item">
              <option value="" selected="selected" disabled>Выберите основное изделие</option>
              <option v-for="product_item in product_list" :value="product_item.id">{{product_item.uid}} - {{product_item.short_name}}</option>
            </select>
            <input type="text" class="form-control" v-model="new_variant_description" placeholder="Описание варианта">
          </div>
        </div>
        <div class="product-menu my-4 row mx-0 justify-content-between">
          <a @click="previousStage()">Назад</a>
          <a @click="nextStage()">Далее</a>
        </div>
      </div>
      <div v-if="new_stage === 5">
        <h1 class="mb-5"> 5. Скидки и промо</h1>
        <h2 class="text-center my-3">Недельные скидки</h2>
        <div class="row justify-content-between m-0 mb-5">
          <div class="form-group">
            <input type="checkbox" class="mt-3 mb-2 checkbox-active mx-auto" v-model="new_week_1"> <span class="mt-3">Текущая</span>
          </div>
          <div class="form-group">
            <input type="checkbox" class="mt-3 mb-2 checkbox-active mx-auto" v-model="new_week_2"> <span class="mt-3">Следующая</span>
          </div>
          <div class="form-group">
            <input type="checkbox" class="mt-3 mb-2 checkbox-active mx-auto" v-model="new_week_3"> <span class="mt-3">Через неделю</span>
          </div>
          <div class="form-group">
            <input type="checkbox" class="mt-3 mb-2 checkbox-active mx-auto" v-model="new_week_4"> <span class="mt-3">Через 2 недели</span>
          </div>
        </div>
        <h2 class="text-center my-3">Тэги</h2>
        <div v-for="tag in tags" class="form-group text-center">
          <input type="checkbox" class="mt-3 mb-2 checkbox-active mx-auto" :value="tag.id" v-model="new_tags"> <span class="mt-3">{{tag.name}}</span>
        </div>
        <div class="product-menu my-4 row mx-0 justify-content-between">
          <a @click="previousStage()">Назад</a>
          <a @click="createProduct ">Создать товар</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {apiService} from "@/common/api.service.js";
  import ManagerProductCard from "@/components/ManagerProductCard";
  import ProductPagination from "@/components/ProductPagination.vue";

  export default {
    name: "ManagerProduct",
    components: {
      ManagerProductCard,
      ProductPagination
    },
    data() {
      return {
        products: [],
        filter: 'Все',
        pages: Number,
        isActive: 1,
        categories: [],
        selected_category: 0,
        shops: [],
        week_1_list: [],
        week_2_list: [],
        week_3_list: [],
        week_4_list: [],
        tags: [],
        attr_images: [],
        collections: [],
        product_list: [],
        new_product_show: false,
        new_uid: undefined,
        uid_checked: true,
        new_short_name: undefined,
        new_name: undefined,
        new_active: true,
        new_category: "",
        new_description: undefined,
        new_quantity: undefined,
        new_price: undefined,
        new_delivery_weight: undefined,
        new_discount_size: 0,
        new_discounted_price: undefined,
        new_week_1: false,
        new_week_2: false,
        new_week_3: false,
        new_week_4: false,
        new_shop: "",
        new_tags: [],
        new_collection: "",
        new_collection_create_name: undefined,
        new_variant: false,
        new_variant_item: "",
        new_variant_description: undefined,
        new_comment: undefined,
        new_attributes: [],
        new_images: [],
        new_image_main: undefined,
        new_stage: 1,
        validated: true,
      }
    },
    methods: {
      getProducts() {
        let endpoint = "/api/manager/product/?filter=" + this.filter;
        endpoint += ("&category=" + this.selected_category);
        endpoint += ("&page=" + this.isActive);
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.products = data.results;
            this.pages = Math.ceil(data.count / 30);
            this.$store.commit('changeLoading');
          });
      },
      getCategories() {
        let endpoint = "api/manager/category/";
        let auth = this.$store.state.a_token;
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.categories = data;
          })
      },
      getShops() {
        let endpoint = "api/manager/shop/";
        let auth = this.$store.state.a_token;
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.shops = data;
          })
      },
      getWeekLists() {
        let endpoint = "api/manager/weeklydiscount/";
        let auth = this.$store.state.a_token;
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.week_1_list = data[0].product_list;
            this.week_2_list = data[1].product_list;
            this.week_3_list = data[2].product_list;
            this.week_4_list = data[3].product_list;
          })
      },
      getProductTags() {
        let endpoint = "api/manager/producttag/";
        let auth = this.$store.state.a_token;
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.tags = data
          })
      },
      getAttributeImages() {
        let endpoint = "api/manager/attributeimage/";
        let auth = this.$store.state.a_token;
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.attr_images = data
          })
      },
      getCollections() {
        let endpoint = "api/manager/collectionlist/";
        let auth = this.$store.state.a_token;
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.collections = data;
          })
      },
      getProductList() {
        let endpoint = "api/manager/productsimplelist/";
        let auth = this.$store.state.a_token;
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.product_list = data;
          })
      },

      editDiscountedPrice() {
        return this.new_discounted_price = Math.ceil(this.new_price - (this.new_price * (this.new_discount_size / 100)))
      },

      addAttribute() {
        this.new_attributes.push({
          name: '',
          value: '',
          image: {'id': ''},
        })
      },

      removeAttribute(key) {
        this.new_attributes.splice(key, 1)
      },

      showImages(e) {
        var image;
        this.new_images = [];
        for (image of e.target.files) {
          this.new_images.push({'image': image, 'url': URL.createObjectURL(image)});
        }
      },

      addCollection() {
        if (!this.new_collection_create_name) {
          return this.validated = false
        } else {
          let endpoint = "api/manager/collection/";
          let method = 'POST';
          let data = {'name': this.new_collection_create_name};
          let auth = this.$store.state.a_token;
          this.$store.commit('changeLoading');
          apiService(endpoint, method, data, auth)
            .then(data => {
              this.$store.commit('changeLoading');
              this.getCollections();
              this.new_collection = data.id;
              this.validated = true;
            })
        }
      },

      checkUID() {
        let endpoint = "/api/manager/product/check_uid/?uid=" + this.new_uid;
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            if (data.success) {
              this.uid_checked = true
            } else {
              this.uid_checked = false
            }
          }, this);
      },

      previousStage() {
        this.validated = true;
        this.new_stage -= 1;
      },

      nextStage() {
        if (this.new_stage === 1) {
          if (!this.new_uid || !this.new_short_name || !this.new_name || !this.new_category || !this.new_description) {
            return this.validated = false
          }
          if (!this.uid_checked) {
            return
          }
          let endpoint = "api/manager/categoryattribute/";
          endpoint += '?category=' + this.new_category;
          let auth = this.$store.state.a_token;
          if (this.new_attributes.length === 0) {
            this.$store.commit('changeLoading');
            apiService(endpoint, undefined, undefined, auth)
              .then(data => {
                this.$store.commit('changeLoading');
                var attr;
                for (attr of data) {
                  this.new_attributes.push({'image': attr.image, 'name': attr.name, 'value': ''})
                }
              })
          }
        }
        if (this.new_stage === 2) {
          if (!this.new_price || this.new_discount_size === '' || !this.new_quantity) {
            return this.validated = false
          }
          var attr;
          for (attr of this.new_attributes) {
            if (attr.name === '' || attr.value === '' || attr.image.id === '') {
              return this.validated = false
            }
          }
        }
        if (this.new_stage === 3) {
          if (this.new_images.length === 0 || !this.new_image_main) {
            return this.validated = false
          }
        }
        if (this.new_stage === 4) {
          if (this.new_collection === '0') {
            return this.validated = false
          }
          if (this.new_variant) {
            if (!this.new_variant_item || !this.new_variant_description) {
              return this.validated = false
            }
          }
        }
        this.validated = true;
        this.new_stage += 1;
      },

      createProduct() {
        let endpoint = 'api/manager/product/';
        let method = 'POST';
        let data = {
          'uid': this.new_uid, 'short_name': this.new_short_name, 'name': this.new_name, 'active': this.new_active, 'category': this.new_category,
          'description': this.new_description, 'quantity': this.new_quantity, 'price': this.new_price, 'delivery_weight': this.new_delivery_weight,
          'discount_size': this.new_discount_size, 'shop': this.new_shop, 'tags': this.new_tags, 'collection': this.new_collection, 'variant': this.new_variant,
          'variant_item': this.new_variant_item, 'variant_description': this.new_variant_description, 'comment': this.new_comment,
          'week_discount': this.new_week_1, 'week_1': this.new_week_1, 'week_2': this.new_week_2, 'week_3': this.new_week_3, 'week_4': this.new_week_4,
          'attributes': this.new_attributes
        };
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, auth)
          .then(data => {
            let endpoint = 'api/manager/productimage/create_multiple/';
            let formData = new FormData();
            for (var i = 0; i < this.new_images.length; i++) {
              formData.append('image[' + i + ']', this.new_images[i].image);
              if (this.new_images[i].url === this.new_image_main) {
                formData.append('main', 'image[' + i + ']');
              }
            }
            formData.append('product', data.id);
            this.$http.post(endpoint, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Token ' + this.$store.state.a_token,
              }
            }).then(data => {
              this.new_product_show = false;
              this.new_uid = undefined;
              this.uid_checked = true;
              this.new_short_name = undefined;
              this.new_name = undefined;
              this.new_active = true;
              this.new_category = "";
              this.new_description = undefined;
              this.new_quantity = undefined;
              this.new_price = undefined;
              this.new_delivery_weight = undefined;
              this.new_discount_size = 0;
              this.new_discounted_price = undefined;
              this.new_week_1 = false;
              this.new_week_2 = false;
              this.new_week_3 = false;
              this.new_week_4 = false;
              this.new_shop = "";
              this.new_tags = [];
              this.new_collection = "";
              this.new_collection_create_name = undefined;
              this.new_variant = false;
              this.new_variant_item = "";
              this.new_variant_description = undefined;
              this.new_comment = undefined;
              this.new_attributes = [];
              this.new_images = [];
              this.new_image_main = undefined;
              this.new_stage = 1;
              this.$store.commit('changeLoading');
              this.getProducts();
            })
          })
      }
    },

    created() {
      this.getProducts();
      this.getCategories();
      this.getShops();
      this.getWeekLists();
      this.getProductTags();
      this.getAttributeImages();
      this.getCollections();
      this.getProductList();
    }
  }
</script>

<style scoped>
  .product-menu a {
    text-align: center;
    background-color: white;
    font-weight: 600;
    color: #000;
    font-family: 'Open Sans', sans-serif;
    width: 233px;
    height: 60px;
    text-transform: uppercase;
    float: right;
    display: block;
    border: solid 2px #dfb859;
    line-height: 58px;
    margin: 0;
  }

  .product-menu a:hover {
    background: #222;
    border: solid 2px #222;
    color: #fff;
  }

  .search-input {
    padding: 2px;
    font-size: 16px;
  }

  .select-1 {
    width: 350px;
  }

  .new-product {
    border: solid 1px black;
    padding: 20px;
  }

  .form-group input {
    font-size: 16px;
  }

  .description-area {
    font-size: 16px;
    height: 100px;
    width: 100%;
    max-width: 100%;
  }

  select + select {
    margin-left: 0;
  }

  .active-span {
    font-size: 16px;
    color: rgb(73, 80, 87);
    margin-right: 10px;
  }

  .checkbox-active {
    box-sizing: border-box;
    height: 25px;
    width: 25px;
  }

  .input-price {
    width: 100%;
  }

  .delete-x {
    cursor: pointer;
  }

  .button-cross {
    width: 100px;
    cursor: pointer;
  }

</style>