<template>
  <div class="carousel-item text-center" :class="{ 'active': index === 0 }">
    <div class="row">
      <div class="col-md-12 col-sm-5">
        <router-link :to="{ name: 'Detail', params: {id: product.id, slug: slug}}">
          <img :src="product.main_image" :alt="product.short_name" :title="product.name" class="img-fluid">
        </router-link>
      </div>
      <div class="col-md-12 col-sm-7">
        <div class="no-div">
          <ul>
            <li>
              <h5>{{days}}</h5>
              <span>Дн </span></li>
            <li>
              <h5>{{hours}}</h5>
              <span>Час</span></li>
            <li>
              <h5>{{mins}}</h5>
              <span>Мин</span></li>
            <li>
              <h5>{{sec}}</h5>
              <span>Сек</span></li>
          </ul>
          <div class="clearfix"></div>
        </div>
        <div class="product-name">
          <router-link :to="{ name: 'Detail', params: {id: product.id, slug: slug}}">
            <h3>{{product.short_name}}</h3>
          </router-link>
        </div>
        <div class="rate-css">
          <span>{{product.discounted_price}} руб.</span>&nbsp;&nbsp;
          <samp class="text-de">{{product.price}} руб.</samp></div>
      </div>
    </div>
  </div>
</template>

<script>
  import {slugify} from "@/common/slugify.js";

  export default {
    name: "WeeklyDiscountSidebar",
    props: {
      product: {
        type: Object,
        required: true
      },
      index: {
        type: Number,
        required: true
      }
    },
    computed: {
      slug() {
        return slugify(this.product.name)
      },
      days() {
        return this.$store.state.days_left
      },
      hours() {
        return this.$store.state.hours_left
      },
      mins() {
        return this.$store.state.mins_left
      },
      sec() {
        return this.$store.state.sec_left
      }
    },
  }
</script>

<style scoped>
  @media (max-width: 1399px) {
    .no-div li h5 {
      font-weight: 800;

    }
  }

</style>