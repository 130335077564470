<template>
  <div id="wrapper">
    <section>
      <div class="inner-bg">
        <div class="inner-head wow fadeInDown">
          <h3>Отзывы</h3>
        </div>
      </div>
    </section>
    <div class="container">
      <div class="shop-in">
        <div class="row">
          <div class="col-md-12">
            <!--breadcrumbs -->
            <div class="bread2">
              <ul>
                <li><a href="/">На главную</a>
                </li>
                <li>/</li>
                <li><a>Отзывы</a></li>
              </ul>
            </div>
            <!--breadcrumbs -->
          </div>
        </div>
        <div class="tetpbg">
          <div class="row">
            <div class='col-md-12 text-center'>
              <div class="title-box  wow fadeIn">
                <h2>Отзывы наших <span>клиентов</span></h2>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
          <div class='row'>
            <div class='col-md-12 col-center'>
              <div class="carousel slide wow fadeIn" data-ride="carousel" data-interval="15000" id="quote-carousel">
                <!-- Carousel Slides / Quotes -->
                <div class="carousel-inner">
                  <div class="carousel-item" :class="{active: testimonial === testimonials[0]}"
                  v-for="testimonial in testimonials"
                    :testimonial="testimonial"
                    :key="testimonial.id">
                    <div class="row">
                      <div class="col-sm-9 col-center mt-5">
                        <div class="clients text-center">
                          <img src="../assets/images/coma.jpg" class="coma1"> {{testimonial.text}}
                          <img src="../assets/images/coma2.jpg" class="coma12"></div>
                        <p class="clients-name">- {{testimonial.name}} - </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Carousel Buttons Next/Prev -->
                <a data-slide="prev" href="#quote-carousel" class="left left-arrow"><i class="fa fa-chevron-left"></i></a> <a data-slide="next" href="#quote-carousel" class="right right-arrow"><i
                class="fa fa-chevron-right"></i></a></div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
  import {apiService} from "@/common/api.service.js";
  import Footer from "@/components/Footer";

  export default {
    name: "Testimonials",
    components: {
      Footer,
    },
    metaInfo: {
      title: 'Отзывы',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Отзывы покупателей интернет-магазина Амбервиль'
        },
      ],
    },
    data() {
      return {
        testimonials: []
      }
    },
    methods: {
      getTestimonials() {
        let endpoint = "/api/testimonials/";
        this.$store.commit('changeLoading');
        apiService(endpoint)
          .then(data => {
            this.$store.commit('changeLoading');
            this.testimonials = data;
          })
      },

    },
    created() {
      this.getTestimonials()
    }
  }
</script>

<style scoped>

</style>