<template>
  <tr>
    <td class="text-center bg-white image-td" v-if="!link">
      <img data-v-2d0fb042="" :src="imageSource" :alt="product.name" :title="product.name" class="img-fluid">
    </td>
    <td class="text-center bg-white image-td" v-else>
      <router-link :to="{ name: 'Detail', params: {id: link, slug: slug(product.name)}}">
        <img data-v-2d0fb042="" :src="imageSource" :alt="product.name" :title="product.name" class="img-fluid">
      </router-link>
    </td>
    <td>
      <div class="cost2">{{product.uid}}</div>
    </td>
    <td>
      <p class="text-center">{{shop}}</p>
    </td>
    <td class="product-name" v-if="!link">
      <h1 class="mb-1">{{product.name}}<br></h1>
      <h1 v-if="product.variant_description">{{product.variant_description}}</h1>
    </td>
    <td class="product-name" v-else>
      <router-link :to="{ name: 'Detail', params: {id: link, slug: slug(product.name)}}">
        <h1 class="mb-1">{{product.name}}<br></h1>
        <h1 v-if="product.variant_description">{{product.variant_description}}</h1>
      </router-link>
    </td>
    <td>
      <div class="cost2">{{product.price}} руб.</div>
    </td>
    <td>
      <div class="cost2">{{product.quantity}}</div>
    </td>
    <td>
      <div class="cost">{{product.price * product.quantity}} руб.</div>
    </td>
  </tr>
</template>

<script>
  import {apiService} from "@/common/api.service.js";
  import {slugify} from "@/common/slugify.js";

  export default {
    name: "ManagerOrderTableProduct",
    props: {
      product: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        imageSource: "/static/images/no-image.jpg",
        link: undefined,
        shop: undefined,
      }
    },
    methods: {
      slug(text) {
        return slugify(text)
      },
      getProductTemplate() {
        let endpoint = "/api/getproducttemplate/" + this.product.uid;
        apiService(endpoint)
          .then(data => {
            if (data) {
              this.imageSource = data.main_image;
              this.link = data.id
            }
          }, this);
      },
      getShop() {
        let endpoint = "/api/manager/product/get_shop/?uid=" + this.product.uid;
        let auth = this.$store.state.a_token;
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            if (data) {
              this.shop = data.shop
            }
          }, this);
      }
    },
    created() {
      this.getProductTemplate();
      this.getShop();
    }
  }
</script>

<style scoped>

  .image-td {
    width: 10%;
  }

</style>