<template>
    <div>
        <section id="wrapper">
            <!--page heading-->
            <section>
                <div class="inner-bg">
                    <div class="inner-head wow fadeInDown text-center mx-5">
                        <h3>{{product.short_name}}</h3>
                    </div>
                </div>
            </section>
            <!--page heading-->
            <!--container-->
            <div class="container">
                <div class="shop-in">
                    <div class="row">
                        <div class="col-md-12">
                            <!--breadcrumbs -->
                            <div class="bread2">
                                <ul>
                                    <li><a href="/">На главную</a>
                                    </li>
                                    <li>/</li>
                                    <li><a href="/products">Каталог</a></li>
                                    <li>/</li>
                                    <li>{{product.short_name}}</li>
                                </ul>
                            </div>
                            <!--breadcrumbs -->
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="row">
                        <!--Left side -->
                        <div class="col-lg-3 col-md-12 col-sm-12 div-none2 wow fadeInLeft l-col">
                            <div class="cat-div">
                                <div class="row align-items-center">
                                    <div class="col-md-4 col-sm-4 col-xs-4"><img
                                            src="../assets/images/delivery-truck.png" width="46"
                                            alt="Бесплатная доставка"></div>
                                    <div class="col-md-8 col-sm-8 col-xs-4 icon-div">
                                        <p>Бесплатная доставка</p>
                                        <p>от 3000 руб.</p>
                                    </div>
                                    <div class="clearfix"></div>
                                    <hr>
                                    <div class="col-md-4 col-sm-4 col-xs-4"><img src="../assets/images/supermarket.png"
                                                                                 width="46" alt="Натуральный янтарь">
                                    </div>
                                    <div class="col-md-8 col-sm-8 col-xs-8 icon-div">
                                        <p>100 %</p>
                                        <p>натуральный янтарь</p>
                                    </div>
                                    <div class="clearfix"></div>
                                    <hr>
                                    <div class="col-md-4 col-sm-4"><img src="../assets/images/reuse.png" width="46"
                                                                        alt="Быстрая доставка"></div>
                                    <div class="col-md-8 col-sm-8 icon-div">
                                        <p>Доставка</p>
                                        <p>от 2-х дней</p>
                                    </div>
                                    <div class="clearfix"></div>
                                    <hr>
                                    <div class="clearfix"></div>
                                    <div class="col-md-4 col-sm-4 col-xs-4"><img src="../assets/images/checked.png"
                                                                                 width="46" alt="Украшения со скидками">
                                    </div>
                                    <div class="col-md-8 col-sm-8 col-xs-8 icon-div">
                                        <p>Скидки</p>
                                        <p>каждую неделю</p>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <div class="cat-div">
                                <h2>Популярное</h2>
                                <div id="carousel-example-generic2" class="carousel slide" data-ride="carousel">
                                    <!-- Wrapper for slides -->
                                    <div class="carousel-inner">
                                        <PopularSidebar
                                                v-for="(product, index) in popularProducts"
                                                :key="product.id"
                                                :index="index"
                                                :product="product"
                                        />
                                    </div>
                                    <!-- Controls -->
                                    <a class="left arrow-left" href="#carousel-example-generic2" role="button"
                                       data-slide="prev"> <span
                                            class="glyphicon glyphicon-chevron-left"></span> </a> <a
                                        class="right arrow-right"
                                        href="#carousel-example-generic2"
                                        role="button" data-slide="next"> <span
                                        class="glyphicon glyphicon-chevron-right"></span> </a></div>
                            </div>
                        </div>
                        <!--right side -->
                        <div class="col-lg-9 col-md-12 col-sm-12 div-left wow fadeInRight r-col">
                            <div class="row">
                                <div class="col-lg-8 col-md-6 col-sm-12 text-center">
                                    <div class="large-5 column">
                                        <Magnifier :src="zoom_image" :src-large="zoom_image" v-if="desktopWidth"/>
                                        <img :src="zoom_image" :alt="product.short_name" v-else
                                             class="img-fluid w-75 text-center mb-3">
                                        <div class="xzoom-container">
                                            <div class="xzoom-thumbs">
                                                <img v-for="image in productImages"
                                                     @click="zoom_image = image.image"
                                                     class="xzoom-gallery mx-1"
                                                     width="80"
                                                     :src="image.image"
                                                     :xpreview="image.image"
                                                     :alt="product.name">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12">
                                    <div class="list-div border-0">
                                        <h2 class="mt-5 mb-4 my-md-2">Характеристики</h2>
                                        <div class="clearfix"></div>
                                        <ul>
                                            <ProductAttribute
                                                    v-for="attribute in productAttributes"
                                                    :attribute="attribute"
                                                    :key="attribute.id"
                                            />
                                        </ul>
                                    </div>
                                    <div class="price-row price-row-mobile">
                                        <div class="price-2 mt-4 mt-md-0">
                                            <ul>
                                                <li class="tab1">ЦЕНА:
                                                    <span v-if="isDiscounted()" class="text-0 text-muted">{{product.price}} руб.</span>
                                                    <span> {{product.discounted_price}} руб.</span></li>
                                                <li v-if="product.quantity <= 0" class="tab1"><a>НЕТ В НАЛИЧИИ</a></li>
                                                <li v-else-if="inBasket" class="tab1 normal-space">
                                                    <div class="less-height">
                                                        <a><span class="green">✅</span> ТОВАР В КОРЗИНЕ</a>
                                                        <router-link class="gray incart" to="/cart"> Посмотреть корзину
                                                            <span><i class="fa fa-shopping-cart"></i></span>
                                                        </router-link>
                                                    </div>
                                                </li>
                                                <li v-else="inBasket" class="tab1"><a
                                                        @click="addToBasket(product.uid); updateBasketQuantity()">ДОБАВИТЬ
                                                    В КОРЗИНУ</a></li>
                                            </ul>
                                        </div>
                                        <div class="read-full d-none d-md-block">
                                            <ul>
                                                <li><a data-toggle="collapse" data-target="#demo"> <span>Описание</span>
                                                </a></li>
                                                <li><a data-toggle="collapse" data-target="#demo"><img
                                                        src="../assets/images/products/arrow.jpg"></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div v-if="productCollection.length > 0" class="list-div border-0">
                                        <div class="clearfix"></div>
                                        <br>
                                        <h2 class="my-4 my-md-2">Комплект "{{product.collection}}"</h2>
                                        <div class="clearfix"></div>
                                        <ul>
                                            <ProductCollectionCard
                                                    v-for="product in productCollection"
                                                    :product="product"
                                                    :key="product.id"
                                            />
                                        </ul>
                                    </div>
                                    <div v-if="productVariants.length > 0" class="list-div border-0">
                                        <div class="clearfix"></div>
                                        <br>
                                        <h2 class="my-4 my-md-2">Варианты</h2>
                                        <div class="clearfix"></div>
                                        <ul>
                                            <ProductVariantCard
                                                    v-for="product in productVariants"
                                                    :product="product"
                                                    :key="product.id"
                                            />
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="clearfix">&nbsp;</div>
                            <div class="price-row price-row-desktop">
                                <div class="price-2 mt-4 mt-md-0">
                                    <ul>
                                        <li class="tab1">ЦЕНА:
                                            <span v-if="isDiscounted()"
                                                  class="text-0 text-muted">{{product.price}} руб.</span>
                                            <span> {{product.discounted_price}} руб.</span></li>
                                        <li v-if="product.quantity <= 0" class="tab1"><a>НЕТ В НАЛИЧИИ</a></li>
                                        <li v-else-if="inBasket" class="tab1 normal-space">
                                            <div class="less-height">
                                                <a><span class="green">✅</span> ТОВАР В КОРЗИНЕ</a>
                                                <router-link class="gray incart" to="/cart"> Посмотреть корзину <span><i
                                                        class="fa fa-shopping-cart"></i></span></router-link>
                                            </div>
                                        </li>
                                        <li v-else="inBasket" class="tab1"><a
                                                @click="addToBasket(product.uid); updateBasketQuantity()">ДОБАВИТЬ В
                                            КОРЗИНУ</a></li>
                                    </ul>
                                </div>
                                <div class="read-full d-none d-md-block">
                                    <ul>
                                        <li><a data-toggle="collapse" data-target="#demo"> <span>Описание</span> </a>
                                        </li>
                                        <li><a data-toggle="collapse" data-target="#demo"><img
                                                src="../assets/images/products/arrow.jpg"></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                            <div id="demo" class="collapse show mt-4">
                                <div class="inner-div">
                                    <div class="row m-0">
                                        <div class="col-md-12 product-info">
                                            <h2>{{product.name}}</h2>

                                            <p><img src="../assets/images/products/line.jpg"></p>
                                            <p>{{product.description}}</p>
                                            <p><img src="../assets/images/products/line.jpg"></p>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="clearfix"></div>
            </div>
            <!--container-->
            <div class="clearfix"></div>
            <!--footer-->
            <Footer
            />
        </section>
    </div>
</template>

<script>
    import {apiService} from "@/common/api.service.js";
    import ProductAttribute from "@/components/ProductAttribute";
    import ProductCollectionCard from "@/components/ProductCollectionCard";
    import ProductVariantCard from "@/components/ProductVariantCard";
    import PopularSidebar from "@/components/PopularSidebar";
    import Magnifier from "@/components/Magnifier";
    import Footer from "@/components/Footer";


    export default {
        name: "Detail",
        components: {
            PopularSidebar,
            ProductAttribute,
            ProductCollectionCard,
            ProductVariantCard,
            Magnifier,
            Footer,
        },
        data() {
            return {
                product: {},
                productImages: {'normal_size': []},
                productAttributes: [],
                popularProducts: [],
                productCollection: [],
                productVariants: [],
                inBasket: false,
                zoom_image: '',
            }
        },
        metaInfo() {
            return {
                title: this.title,
                meta: [
                    {vmid: 'description', name: 'description', content: this.description},
                ],
            };
        },

        computed: {
            desktopWidth() {
                return document.documentElement.clientWidth > 767
            },
            title() {
                if (this.product) {
                    return this.product.name
                } else {
                    return ''
                }
            },
            description() {
                if (this.product) {
                    return this.product.description
                } else {
                    return ''
                }
            },
        },

        methods: {

            getProduct() {
                let id = this.$route.params.id;
                let endpoint = "/api/product/" + id + '/';
                this.$store.commit('changeLoading');
                apiService(endpoint)
                    .then(data => {
                        this.product = data;
                        this.zoom_image = this.product.main_image;
                        this.$store.commit('changeLoading');
                        this.checkBasket()
                    })
            },

            getProductImages() {
                let endpoint = "/api/product/images/";
                endpoint += '?id=' + this.$route.params.id;
                apiService(endpoint)
                    .then(data => {
                        this.productImages = data;
                        this.hasImages = true;
                    })
            },
            getProductAttributes() {
                let endpoint = "/api/product/attributes/";
                endpoint += '?product=' + this.$route.params.id;
                apiService(endpoint)
                    .then(data => {
                        this.productAttributes = data;
                    })
            },

            getPopularProducts() {
                let endpoint = "/api/popular/";
                apiService(endpoint)
                    .then(data => {
                        this.popularProducts = data;
                    })

            },
            getCollection() {
                let endpoint = "/api/product/collection/";
                endpoint += '?product=' + this.$route.params.id;
                apiService(endpoint)
                    .then(data => {
                        this.productCollection = data;
                    })
            },

            getProductVariants() {
                let endpoint = "/api/product/variants/";
                endpoint += '?product=' + this.$route.params.id;
                apiService(endpoint)
                    .then(data => {
                        this.productVariants = data;
                    })
            },

            isDiscounted() {
                return this.product.discount_size > 0;
            },

            addToBasket(uid) {
                var basket = JSON.parse(localStorage.basket);
                basket[uid] = 1;
                basket = JSON.stringify(basket);
                localStorage.setItem('basket', basket);
                this.inBasket = true
            },
            checkBasket() {
                let uid = this.product.uid;
                let basket = JSON.parse(localStorage.basket);
                if (uid in basket) {
                    return this.inBasket = true
                }
                else {
                    return this.inBasket = false
                }
            },
            updateBasketQuantity() {
                this.$store.dispatch('get_basket_quantity')
            }
        },
        created() {
            this.getProduct();
            this.getProductImages();
            this.getProductAttributes();
            this.getPopularProducts();
            this.getCollection();
            this.getProductVariants();
        },
        beforeRouteUpdate(to, from, next) {
            next();
            this.getProduct();
            this.getProductImages();
            this.getProductAttributes();
            this.getPopularProducts();
            this.getCollection();
            this.getProductVariants();
        },
    }
</script>

<style scoped>
    .xzoom-thumbs img {
        cursor: pointer;
    }

    .green {
        color: #00cc00;
    }

    .price-row {
        margin-top: 10px;
    }

    .magnifier-box.vertical img {
        width: 100%;
    }

    .less-height, .less-height a {
        height: auto;
        line-height: 40px;
    }

    .gray {
        color: dimgray;
    }

    .icon-div {
        margin: 10px 0;
    }

    .icon-div p {
        margin-bottom: 0;
    }

    .icon-div p {
        font-size: 16px;
    }

    .price-row-mobile {
        display: block !important;
    }

    .price-row-desktop {
        display: none !important;
    }

    .price-2 li {
        white-space: nowrap;
    }

    .normal-space {
        white-space: normal!important;
    }

    @media (max-width: 1499px) {
        .read-full {
            height: 78px;
            width: 180px;
        }

        .icon-div h4 {
            font-size: 14px;
        }

        .l-col {
            flex: 0 0 30%;
            max-width: 30%;
        }

        .r-col {
            flex: 0 0 70%;
            max-width: 70%;
        }

        .incart {
            font-size: 13px;
        }
    }

    @media (max-width: 991px) {

        .r-col {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    @media (max-width: 767px) {

        .price-2 {
            width: 100%;
        }

        .price-2 li:last-child {
            width: 50%;
        }

        .tab1 {
            width: 50%;
        }

        .tab1 a {
            width: 100%
        }

    }

    @media (max-width: 498px) {

        .price-2 {
            height: 160px;
        }

        .price-2 li:last-child {
            width: 100%;
            border: solid 1px #ccc !important;
            border-top: none !important;
        }

        .tab1 {
            width: 100%;
        }

        .product-info {
            padding: 15px;
        }

    }

    @media (min-width: 768px) {

        .price-row-mobile {
            display: none !important;
        }

        .price-row-desktop {
            display: flex !important;
        }

        .price-row {
            display: flex;
            justify-content: space-between;
        }

        .price-2 {
            width: 72%;
            float: left;
            margin-right: 5px;
        }

        .price-2 ul li {
            display: inline-block;
            width: 50%;
        }

        .read-full {
            height: 80px;
        }

        .read-full img {
            height: 70px;
        }

        .read-full ul li {
            display: inline-block;
            width: 50%;
        }

    }


</style>