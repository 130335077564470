<template>
  <div id="wrapper">
    <!--page heading-->
    <section>
      <div class="inner-bg">
        <div class="inner-head wow fadeInDown">
          <h3>Менеджер</h3>
        </div>
      </div>
    </section>
    <!--page heading-->
    <!--container-->
    <div v-if="!isManager">
      <h1 class="text-center my-5">Здесь ничего нет</h1>
    </div>
    <div class="container" v-else>
      <div class="shop-in pl-0 pr-0">
        <div>
          <div class="bread2">
            <ul>
              <li>
                <router-link to="/">На главную</router-link>
              </li>
              <li>/</li>
              <li>Менеджер</li>
            </ul>
          </div>
        </div>
        <div class="row justify-content-between manager-menu">
          <a @click="tabActive = 1">Заказы</a>
          <a @click="tabActive = 2">Товары</a>
          <a @click="tabActive = 3">Скидки и Промо</a>
          <a @click="tabActive = 4">Коллекции</a>
        </div>
        <div class="row justify-content-between manager-menu">
          <a @click="tabActive = 5">Главная страница</a>
          <a @click="tabActive = 6">Блог</a>
          <a @click="tabActive = 7">Информация</a>
        </div>

        <div class="mt-5">
          <ManagerOrder
            v-if="tabActive === 1"
          />
          <ManagerProduct
            v-if="tabActive === 2"
          />
          <ManagerDiscount
            v-if="tabActive === 3"
          />
          <ManagerCollection
            v-if="tabActive === 4"
          />
          <ManagerFrontpage
            v-if="tabActive === 5"
          />
          <ManagerBlog
            v-if="tabActive === 6"
          />
          <ManagerInfo
            v-if="tabActive === 7"
          />
        </div>
        <div class="clearfix"></div>
        <div class="clearfix"></div>
      </div>
      <div class="clearfix"></div>
    </div>
    <!--container-->
    <div class="clearfix"></div>
    <!--footer-->
    <Footer
    />
  </div>
</template>

<script>
  import {apiService} from "@/common/api.service.js";
  import ManagerOrder from "@/components/ManagerOrder";
  import ManagerProduct from "@/components/ManagerProduct";
  import ManagerDiscount from "@/components/ManagerDiscount";
  import ManagerCollection from "@/components/ManagerCollection";
  import ManagerFrontpage from "@/components/ManagerFrontpage";
  import ManagerBlog from "@/components/ManagerBlog";
  import ManagerInfo from "@/components/ManagerInfo";
  import Footer from "@/components/Footer";

  export default {
    name: "Manager",
    components: {
      ManagerOrder,
      ManagerProduct,
      ManagerDiscount,
      ManagerCollection,
      ManagerFrontpage,
      ManagerBlog,
      ManagerInfo,
      Footer,
    },
    data() {
      return {
        tabActive: 1,
        isManager: false
      }
    },
    metaInfo: {
      title: 'Менеджер'
    },
    methods: {
      get_manager() {
        let auth = this.$store.state.a_token;
        let endpoint = "/api/getmanager/";
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.isManager = (data.ismanager === 'true')
          })
      }
    },
    created() {
      this.get_manager();
    }

  }
</script>

<style scoped>

  .manager-menu {
    margin-bottom: 60px;
  }

  .manager-menu a {
    text-align: center;
    background-color: white;
    font-weight: 600;
    color: #000;
    font-family: 'Open Sans', sans-serif;
    width: 233px;
    height: 60px;
    text-transform: uppercase;
    float: right;
    display: block;
    border: solid 2px #dfb859;
    line-height: 58px;
    margin: 0;
  }

  .manager-menu a:hover {
    background: #222;
    border: solid 2px #222;
    color: #fff;
  }

    @media (min-width: 1366px) {
    .container {
      width: 1300px;
    }
  }
</style>