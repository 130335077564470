<template>
  <div class="col-md-4 col-6 thum-mrg fadeIn d-flex flex-column" :class="{wow: desktopWidth}">

    <div class="main-image-div" @mouseover="getSecondImage()" @mouseleave="show_second=false">
      <v-popover trigger='hover' placement="bottom" delay="300" :openGroup="product.uid" :open="second_image">
        <router-link :to="{ name: 'Detail', params: {id: product.id, slug: slug}}">
          <img :src="showedImage" :alt="product.short_name" :key="showedImage" :class="{fadeIn: show_second}" class="img-fluid img-boder-css wow">
        </router-link>
        <template slot="popover">
          <div class="name-pro mb-3">{{product.name}}</div>
          <div class="name-pro mt-auto ">
            <span class="mr-2"> {{product.discounted_price}} руб.</span>
            <span v-if="isDiscounted()" class="text-0 text-muted">{{product.price}} руб. </span>
          </div>
          <div v-if="product.discounted_week" class="name-pro text-danger">Товар недели</div>
          <div class="text-center mt-3">
            <div v-if="product.quantity <= 0">НЕТ В НАЛИЧИИ</div>
            <div v-else-if="!inBasket" class="filter2 text-white" @click.prevent="addToBasket(product.uid)">Добавить в корзину</div>
            <div v-else>
              <p class="green-text text-left">Товар добавлен в корзину</p>
              <router-link to="/cart">
                <div class="filter2 hollow">Посмотреть корзину</div>
              </router-link>
            </div>
          </div>
          <div class="text-center my-3">
            <router-link :to="{ name: 'Detail', params: {id: product.id, slug: slug}}">
              <div class="filter2 description-button text-white">Описание</div>
            </router-link>
          </div>
          <div class="text-muted">
            <p>Характеристики:</p>
            <p v-for="attr in product.attributes" class="m-0">{{attr.name}}: {{attr.value}}</p>
          </div>

        </template>
      </v-popover>
    </div>
    <div class="clearfix"></div>
    <div class="clearfix"></div>
    <div class="d-flex flex-column">
      <router-link :to="{ name: 'Detail', params: {id: product.id, slug: slug}}">
        <div class="name-pro mb-3">{{product.short_name}}</div>
      </router-link>
    </div>
    <div class="clearfix"></div>
    <div class="name-pro mt-auto ">
      <span class="mr-2"> {{product.discounted_price}} руб.</span>
      <span v-if="isDiscounted()" class="text-0 text-muted">{{product.price}} руб. </span>
    </div>
    <div v-if="product.discounted_week" class="name-pro text-danger">Товар недели</div>
    <div v-else><br></div>

  </div>
</template>

<script>
  import {apiService} from "@/common/api.service.js";
  import {slugify} from "@/common/slugify.js";


  export default {
    name: "ProductCard",
    props: {
      product: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        inBasket: false,
        second_image: null,
        show_second: false,
        tooltip: {
          tooltip: true
        }
      }
    },
    computed: {
      desktopWidth() {
        return document.documentElement.clientWidth > 767
      },
      slug() {
        return slugify(this.product.name)
      },
      showedImage() {
        if ((this.show_second === true) && (this.second_image !== 'none')) {
          return this.second_image
        } else {
          return this.product.main_image
        }
      }
    },
    methods: {
      isDiscounted() {
        return this.product.discount_size > 0;
      },
      addToBasket(uid) {
        var basket = JSON.parse(localStorage.basket);
        if (!basket[uid]) {
          basket[uid] = 1;
          basket = JSON.stringify(basket);
          localStorage.setItem('basket', basket);
          this.updateBasketQuantity();
          this.inBasket = true;
        }
      },
      updateBasketQuantity() {
        this.$store.dispatch('get_basket_quantity')
      },
      getInBasket() {
        var basket = JSON.parse(localStorage.basket);
        if (basket[this.product.uid]) {
          this.inBasket = true
        } else {
          this.inBasket = false
        }
      },
      getSecondImage() {
        if (this.second_image == null) {
          let endpoint = "/api/getsecondimage/";
          endpoint += '?id=' + this.product.id;
          apiService(endpoint)
            .then(data => {
              if (data !== 'none') {
                this.second_image = data.image;
                this.show_second = true;
              } else {
                this.second_image = data;
              }
            })
        } else if (this.second_image !== 'none') {
          this.show_second = true;
        }
      },
    },
    created() {
      this.getInBasket()
    }
  }
</script>

<style scoped>


  .main-image-div {
    height: 373.7px;
  }

  .green-text {
    color: #1e9023;
  }

  .description-button {
    background: #1e9023;
  }

  .filter2 {
    cursor: pointer;
  }

  .hollow {
    color: black;
    background: white;
    border: 3px solid #d19e66;
  }

  @media (max-width: 1499px) {
    .name-pro {
      font-size: 14px;
    }

    .main-image-div {
      height: 232.5px;
    }

    .filter2 {
      font-size: 13px;
    }

  }

  @media (max-width: 991px) {

    .main-image-div {
      height: 273px;
    }
  }

  @media (max-width: 767px) {

    .main-image-div {
      height: auto;
    }

  }


</style>