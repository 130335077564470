<template>
  <li>
    <div class="row 0 align-items-center">
      <div class="col-md-2 col-sm-2 col-2 pt-0 mr-2">
        <img :src="attribute.image" :alt="attribute.name" :title="attribute.name">
      </div>
      <div class="col-md-8 col-sm-8 col-8 ml-2">
        <h3 class="m-0 bold">{{attribute.name}}</h3>
        <h3 class="attr-value">{{attribute.value}}</h3>
      </div>
    </div>
  </li>
</template>

<script>
  export default {
    name: "ProductAttribute",
    props: {
      attribute: {
        type: Object,
        required: true,
      }
    }

  }
</script>

<style scoped>
  .bold {
    font-weight: 600;
  }

  .attr-value {
    margin: 10px 0 0 0;
  }

  @media (max-width: 1499px) {
    img {
      width: 40px;
    }

    .list-div li {
      padding: 10px 0;
    }

    h3 {
      font-size: 16px;
    }

    .attr-value {
      margin: 5px 0 0 0;
    }
  }
</style>