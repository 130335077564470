<template>
  <div class="order-card" v-if="!deleted">
    <div class="row justify-content-between m-0">
      <h1> Заказ № {{order.uid}}</h1>
      <h4>{{order.created_at}}</h4>
    </div>
    <div class="row justify-content-between m-0">
      <h2 :class="statusClass">{{order.status}}</h2>
      <div>
        <h3 v-if="delivered">Трек номер: {{order.tracking_number}}</h3>
        <p class="text-danger" v-if="delivered && !order.shipment_notified">Трек номер не выслан клиенту</p>
      </div>
    </div>
    <div class="row justify-content-between m-0">
      <h2>{{order.client}}</h2>
      <h2>{{order.overall}} руб.</h2>
    </div>
    <div class="row justify-content-end order-menu mx-0 my-3">
      <a v-if="delivered" data-toggle="modal" :data-target="dataTargetNotify">Выслать трек</a>
    </div>
    <div class="row justify-content-between order-menu mx-0 my-5">
      <a data-toggle="modal" :data-target="dataTargetStatus">Изменить статус/трек</a>
      <a data-toggle="modal" :data-target="dataTargetAddress">Изменить адрес</a>
      <a data-toggle="modal" :data-target="dataTargetDelete">Удалить</a>
    </div>
    <div class="table-responsive wow fadeIn">
      <table class="table product-table">
        <tr class="table-h">
          <td>&nbsp;</td>
          <td>Артикул</td>
          <td>Магазин</td>
          <td>Название</td>
          <td>Цена</td>
          <td>Количество</td>
          <td>Всего</td>
        </tr>
        <ManagerOrderTableProduct
          v-for="(product, key) in order.products"
          :product="product"
          :key="key"
        />
      </table>
    </div>
    <div class="order-details">
      <div class="row m-0">
        <div class="col-md-6 col-xs-12 wow fadeIn">
          <hr>
          <div class="pull-left text-color">Всего</div>
          <div class="pull-right"><strong>{{order.total}} руб.</strong></div>
          <div class="clearfix"></div>
          <hr>
          <div class="clearfix"></div>
          <div class="pull-left text-color"> Скидка по промокоду <b>{{order.promocode_title}}</b></div>
          <div class="pull-right"><strong>{{order.discount}} руб.</strong></div>
          <div class="clearfix"></div>
          <hr>
          <div class="clearfix"></div>
          <div class="pull-left text-color"> Доставка</div>
          <div class="pull-right"><strong>{{order.delivery_price}} руб.</strong></div>
          <div class="clearfix"></div>
          <hr>
          <div class="clearfix"></div>
          <div class="pull-left text-color"><strong>Всего со скидкой</strong></div>
          <div class="pull-right"><strong>{{order.overall}} руб.</strong></div>
          <div class="clearfix"></div>
          <hr>
          <br>
        </div>
        <div class="col-md-6 col-xs-12 wow fadeIn thanks-text pl-3">
          <div class="mt-3"><strong>ФИО: </strong> {{order.last_name}} {{order.first_name}} {{order.second_name}}</div>
          <div class="mt-3"><strong>Адрес: </strong> {{order.postal_code}}, {{order.country_name}}, {{order.region_name}}, {{order.city_name}}, {{order.address}}</div>
          <div class="mt-3"><strong>Телефон: </strong> {{order.phone_number}}</div>
          <div class="mt-3"><strong>Email: </strong> {{order.email}}</div>
          <div class="mt-3"><strong>Оплата: </strong> {{payment}}</div>
          <div class="mt-3"><strong>Доставка: </strong> {{delivery}}</div>
        </div>
      </div>
      <div class="m-3" v-if="order.comment"><strong>Комментарий: </strong> {{order.comment}}</div>
    </div>
    <div class="modal fade" :id="idStatus" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <button type="button" class="close" ref="closeStatus" data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Редактировать статус/трек заказа {{ order.uid }}</h4>
          </div>
          <!-- Modal Body -->
          <div class="modal-body text-center">
            <form class="form-horizontal"
                  @submit.prevent="changeStatus">
              <div class="form-group">
                <div class="col-sm-12">
                  <select v-model="order.status">
                    <option v-for="choice in status_choices" :value="choice">{{choice}}</option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <input type="text" class="form-control" v-model="order.tracking_number" placeholder="Трек-номер"/>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <textarea class="form-control" v-model="order.comment" placeholder="Комментарий"> </textarea>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <button type="submit" class="btn btn-default button-1">Сохранить</button>
                </div>
              </div>
            </form>
          </div>
          <!-- Modal Footer -->
        </div>
      </div>
    </div>
    <div class="modal fade" :id="idAddress" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <button type="button" class="close" ref="closeAddress" data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Редактировать адрес заказа {{ order.uid }}</h4>
          </div>
          <!-- Modal Body -->
          <div class="modal-body text-center">
            <form class="form-horizontal"
                  @submit.prevent="changeAddress">
              <div class="form-group">
                <div class="col-sm-12">
                  <input type="text" class="form-control" v-model="order.last_name" required placeholder="Фамилия"/>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <input type="text" class="form-control" v-model="order.first_name" required placeholder="Имя"/>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <input type="text" class="form-control" v-model="order.second_name" placeholder="Отчество"/>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <select v-model="order.country" class="js-countries" @change="changeCountry">
                    <option disabled value="" selected="selected">Выберите страну</option>
                    <option v-for="country in popular_countries" :value="country.id">{{country.name}}</option>
                    <option disabled>------------------</option>
                    <option v-for="country in countries" :value="country.id">{{country.name}}</option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <select
                    v-if="order.country === '' || order.country === 1 || order.country === '1'"
                    v-model="order.region"
                    class="js-countries" @change="changeRegion">
                    <option disabled value="" selected="selected">Выберите регион</option>
                    <option v-for="region in regions" :value="region.id">{{region.name}}</option>
                  </select>
                  <input v-else v-model="order.region" name="region" type="text" placeholder="РЕГИОН">
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <input v-if="this.another_city" v-model="order.city" name="city" type="text" placeholder="ГОРОД">
                  <select
                    v-else-if="order.country === '' || order.country === 1 || order.country === '1'"
                    v-model="order.city"
                    class="js-countries" @change="changeCity">
                    <option disabled value="" selected="selected">Выберите город</option>
                    <option v-for="location in locations" :value="location.id">{{location.name}}</option>
                    <option value="0">Другой</option>
                  </select>
                  <input v-else v-model="order.city" name="city" type="text" placeholder="ГОРОД">
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <input type="text" class="form-control" v-model="order.postal_code" required placeholder="Индекс"/>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <input type="text" class="form-control" v-model="order.address" required placeholder="Адрес"/>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <input type="text" class="form-control" v-model="order.email" required placeholder="Email"/>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <input type="text" class="form-control" v-model="order.phone_number" required placeholder="Телефон"/>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <button type="submit" class="btn btn-default button-1">Сохранить</button>
                </div>
              </div>
            </form>
          </div>
          <!-- Modal Footer -->
        </div>
      </div>
    </div>
    <div class="modal fade" :id="idDelete" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <button type="button" class="close" ref="closeDelete" data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Удалить заказ</h4>
          </div>
          <!-- Modal Body -->
          <div v-if="!deleted" class="modal-body text-center">
            <h3 class="mt-3">Вы уверены, что хотите удалить заказ {{ order.uid }}?</h3>
            <button class="btn btn-default button-1 mt-5 mb-3" @click="deleteOrder">Удалить</button>
          </div>
          <div v-else class="modal-body text-center">
            <h3 class="mt-3">Заказ удален</h3>
            <button data-dismiss="modal" class="btn btn-default button-1 mt-5 mb-3">Закрыть
            </button>
          </div>
          <!-- Modal Footer -->
        </div>
      </div>
    </div>
    <div class="modal fade" :id="idNotify" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <button type="button" class="close" ref="closeNotify" data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Отправить трек клиенту ({{ order.email }})?</h4>
          </div>
          <!-- Modal Body -->
          <div v-if="!deleted" class="modal-body text-center">
            <h3 class="mt-3 text-danger" v-if="order.shipment_notified">Вы уже отправляли трек номер заказа {{order.uid}}</h3>
            <button v-if="!order.shipment_notified" class="btn btn-default button-1 mt-5 mb-3" @click="notifyClient">Отправить</button>
            <button v-else class="btn btn-default button-1 mt-5 mb-3" @click="notifyClient">Отправить повторно</button>
          </div>
          <div v-else class="modal-body text-center">
            <h3 class="mt-3">Заказ удален</h3>
            <button data-dismiss="modal" class="btn btn-default button-1 mt-5 mb-3">Закрыть
            </button>
          </div>
          <!-- Modal Footer -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {apiService} from "@/common/api.service.js";
  import ManagerOrderTableProduct from "@/components/ManagerOrderTableProduct"

  export default {
    name: "ManagerOrderCard",
    components: {
      ManagerOrderTableProduct
    },
    props: {
      order: {
        type: Object,
        required: true
      },
      popular_countries: {
        type: Array,
        required: true
      },
      countries: {
        type: Array,
        required: true
      },
      regions: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        status_choices: ['Не оплачен', 'Оплачен', 'Отправлен', 'Доставлен', 'Отменен'],
        locations: [],
        another_city: false,
        deleted: false,
      }
    },
    computed: {
      delivered() {
        if ((this.order.status === 'Отправлен') || (this.order.status === 'Доставлен')) {
          return true
        } else {
          return false
        }
      },
      statusClass() {
        if (this.order.status === 'Оплачен') {
          return 'text-paid'
        } else if (this.order.status === 'Отправлен') {
          return 'text-sent'
        } else if (this.order.status === 'Доставлен') {
          return 'text-delivered'
        } else if (this.order.status === 'Отменен') {
          return 'text-cancelled'
        }
      },
      payment() {
        if (this.order.payment === "cards") {
          return "Банковская карта"
        } else if (this.order.payment === "nalozh_d") {
          return "Наложенный платеж"
        } else {
          return this.order.payment
        }
      },
      delivery() {
        if (this.order.delivery === "pochta") {
          return "Почта"
        } else if (this.order.delivery === "ems") {
          return "EMS"
        } else if (this.order.delivery === "nalozh") {
          return "Наложенный платеж"
        } else {
          return this.order.delivery
        }
      },
      dataTargetStatus() {
        return '#status' + this.order.uid
      },
      idStatus() {
        return 'status' + this.order.uid
      },
      dataTargetAddress() {
        return '#address' + this.order.uid
      },
      idAddress() {
        return 'address' + this.order.uid
      },
      dataTargetDelete() {
        return '#delete' + this.order.uid
      },
      idDelete() {
        return 'delete' + this.order.uid
      },
      dataTargetNotify() {
        return '#notify' + this.order.uid
      },
      idNotify() {
        return 'notify' + this.order.uid
      }
    },
    methods: {
      fixLocations() {
        if (!isNaN(this.order.country)) {
          this.order.country = Number(this.order.country);
          this.order.region = Number(this.order.region);
          this.order.city = Number(this.order.city)
        }
      },
      changeStatus() {
        let endpoint = "/api/manager/order/" + this.order.id + "/";
        let method = "PATCH";
        let data = {"status": this.order.status, "tracking_number": this.order.tracking_number, "comment": this.order.comment};
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.$refs.closeStatus.click()
          })
      },
      changeAddress() {
        let endpoint = "/api/manager/order/" + this.order.id + "/";
        let method = "PATCH";
        let data = {
          "first_name": this.order.first_name, "second_name": this.order.second_name, "last_name": this.order.last_name,
          "country": this.order.country, "region": this.order.region, "city": this.order.city, "postal_code": this.order.postal_code,
          "address": this.order.address, "email": this.order.email, "phone_number": this.order.phone_number
        };
        this.order.country_name = this.popular_countries.concat(this.countries).filter(country => {
          return country.id === Number(this.order.country)
        })[0].name;
        if (isNaN(this.order.region)) {
          this.order.region_name = this.order.region;
        } else {
          this.order.region_name = this.regions.filter(region => {
            return region.id === this.order.region
          })[0].name;
        }
        if (isNaN(this.order.city)) {
          this.order.city_name = this.order.city;
        } else {
          this.order.city_name = this.locations.filter(city => {
            return city.id === this.order.city
          })[0].name;
        }
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.$refs.closeAddress.click()
          })
      },
      deleteOrder() {
        let endpoint = "/api/manager/order/" + this.order.id + "/";
        let auth = this.$store.state.a_token;
        let method = "DELETE";
        this.$store.commit('changeLoading');
        apiService(endpoint, method, undefined, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.$refs.closeDelete.click();
            this.deleted = true
          }, this)
      },
      notifyClient() {
        let endpoint = "/api/order/sent/" + this.order.id + "/";
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.order.shipment_notified = true;
            this.$store.commit('changeLoading');
            this.$refs.closeNotify.click();
          }, this)
      },
      getLocations() {
        let endpoint = "/api/locations/";
        endpoint += "?region=" + this.order.region;
        apiService(endpoint)
          .then(data => {
            this.locations = data
          })
      },
      changeCountry() {
        this.order.city = "";
        this.order.region = ""
        this.another_city = false;
        this.getLocations();
      },
      changeRegion() {
        this.order.country = 1;
        this.order.city = "";
        this.another_city = false;
        this.getLocations()
      },
      changeCity() {
        this.order.country = 1;
        if (this.order.city === "0") {
          this.order.city = "";
          this.another_city = true;
        } else {
          this.order.region = this.locations.find((location) => {
            if (this.order.city === location.id) {
              return true
            }
          }, this).region
        }
      },
    },
    created() {
      this.getLocations();
      this.fixLocations();

    }
  }
</script>

<style scoped>
  .order-card {
    border: solid 1px black;
    margin: 10px 0;
    padding: 10px;
  }

  .text-paid {
    color: #b3bb4f;
  }

  .text-sent {
    color: #00926a;
  }

  .text-delivered {
    color: #006ac3;
  }

  .text-cancelled {
    color: #8b241c;
  }

  .order-menu a {
    text-align: center;
    background-color: white;
    font-weight: 600;
    color: #000;
    font-family: 'Open Sans', sans-serif;
    width: 233px;
    height: 60px;
    text-transform: uppercase;
    float: right;
    display: block;
    border: solid 2px #dfb859;
    line-height: 58px;
    margin: 0;
  }

  .order-menu a:hover {
    background: #222;
    border: solid 2px #222;
    color: #fff;
  }
</style>