<template>
  <div>
    <div class="row justify-content-between mx-0 mt-5">
      <div class="form-group col-sm-8">
        <select class="js-countries" v-model="article.id"
                @change="changeArticle">
          <option v-for="article in article_list" :value="article.id">{{article.title}}</option>
        </select>
      </div>
      <a class="add-new" data-toggle="modal" data-target="#addArticle">Добавить статью</a>
    </div>
    <div class="blog-in">
      <div class="wow fadeIn">
        <h1>{{article.title}}</h1>
        <p class="text-center pointer text-muted" data-toggle="modal" data-target="#changeTitle">Изменить заголовок</p>
        <ul class="comm-date">
          <li><i class="fa fa-calendar" aria-hidden="true"></i>&nbsp; &nbsp;{{article.date_created}}</li>
        </ul>
        <ManagerBlogBlock class="row m-0 align-items-stretch" v-for="block in article.blocks"
                          :block="block"
                          :key="block.id"
                          :title="article.title"
                          v-on:changeArticle="changeArticle"
        />
        <div class="pull-right">
        </div>
      </div>
    </div>
    <div class="row justify-content-center mx-0 mt-5">
      <a class="add-new" data-toggle="modal" data-target="#addBlock">Добавить блок</a>
    </div>
    <div class="row justify-content-end mx-0 mt-5">
      <a class="add-new" data-toggle="modal" data-target="#deleteArticle">Удалить статью</a>
    </div>
    <div class="modal fade" id="addBlock" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeAddBlock"
                    data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Добавить блок</h4>
          </div>
          <div class="modal-body">
            <form class="form-horizontal"
                  @submit.prevent="addBlock">
              <div class="form-group">
                <div class="col-sm-12">
                  <select v-model="new_block_type">
                    <option v-for="choice in type_choices" :value="choice">{{choice}}</option>
                  </select>
                </div>
                <div class="form-group text-center my-3" v-if="new_block_type !== 'Текст'">
                  <div class="col-sm-12">
                    <input @change="addBlockImage" ref="addBlockImage" type="file" hidden>
                    <label class="mн-1">Изображение</label>
                    <div v-if="new_block_image === undefined">
                      <img class="w-50" src='../assets/images/button-cross.png' @click="$refs.addBlockImage.click()">
                    </div>
                    <div v-else>
                      <img class="w-50" :src="new_block_image_url" @click="$refs.addBlockImage.click()">
                    </div>
                  </div>
                </div>
                <div class="form-group my-3" v-if="new_block_type !== 'Изображение'">
                  <div class="col-sm-12">
                    <label class="mb-1">Текст</label>
                    <textarea rows='10' class="form-control h-100" v-model="new_block_text"></textarea>
                  </div>
                </div>
              </div>
              <div class="form-group my-3 mb-5">
                <button type="submit" class="btn btn-default button-1 float-right">Сохранить</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="changeTitle" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeChangeTitle"
                    data-dismiss="modal"><span aria-hidden="true">&times;</span>
              <span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Изменить заголовок</h4>
          </div>
          <div class="modal-body">
            <form class="form-horizontal"
                  @submit.prevent="changeTitle">
              <div class="form-group">
                <div class="col-sm-12">
                  <label class="mb-1">Заголовок</label>
                  <input class="form-control" v-model="article.title">
                </div>
              </div>
              <div class="form-group my-3 mb-5">
                <button type="submit" class="btn btn-default button-1 float-right">Сохранить</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="addArticle" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeAddArticle"
                    data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Добавить статью</h4>
          </div>
          <div class="modal-body">
            <form class="form-horizontal"
                  @submit.prevent="addArticle">
              <div class="form-group">
                <div class="col-sm-12">
                  <label class="mb-1">Заголовок</label>
                  <input class="form-control" required v-model="new_article_title">
                </div>
              </div>
              <div class="form-group my-3 mb-5">
                <button type="submit" class="btn btn-default button-1 float-right">Сохранить</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="deleteArticle" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeDeleteArticle" data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Удалить статью?</h4>
          </div>
          <div class="modal-body text-center">
            <form class="form-horizontal"
                  @submit.prevent="deleteArticle()">
              <div class="form-group">
                <div class="col-sm-12">
                  <button type="submit" class="btn btn-default button-1 ">Удалить</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {apiService} from "@/common/api.service.js";
  import ManagerBlogBlock from "@/components/ManagerBlogBlock"

  export default {
    name: "ManagerBlog",
    components: {
      ManagerBlogBlock
    },
    data() {
      return {
        type_choices: ['Изображение', 'Текст', 'Изображение и текст'],
        article_list: [],
        article: {},
        new_block_type: 'Изображение',
        new_block_image: undefined,
        new_block_image_url: undefined,
        new_block_text: '',
        new_article_title: '',
      }
    },
    methods: {
      getArticle() {
        let endpoint = "api/manager/article/" + this.article_list[0].id + "/";
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.article = data;
            this.$store.commit('changeLoading');
          })
      },

      changeArticle() {
        let endpoint = "api/manager/article/" + this.article.id + "/";
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.article = data;
            this.$store.commit('changeLoading');
          })
      },
      changeTitle() {
        let endpoint = "api/manager/article/" + this.article.id + "/";
        let method = "PATCH";
        let data = {'title': this.article.title};
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.$refs.closeChangeTitle.click();
          })
      },

      getArticleList() {
        let endpoint = "api/manager/articlelist/";
        let auth = this.$store.state.a_token;
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.article_list = data;
            this.getArticle();
          })
      },
      addBlock() {
        let endpoint = "api/manager/articleblock/";
        let data = new FormData;
        data.append('type', this.new_block_type);
        data.append('article', this.article.id);
        if (this.new_block_type === 'Изображение') {
          data.append('image', this.new_block_image);
        } else if (this.new_block_type === 'Текст') {
          data.append('text', this.new_block_text);
        } else if (this.new_block_type === 'Изображение и текст') {
          data.append('image', this.new_block_image);
          data.append('text', this.new_block_text);
        }
        this.$store.commit('changeLoading');
        this.$http.post(endpoint, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Token ' + this.$store.state.a_token,
          },
        }).then(data => {
          this.$refs.closeAddBlock.click();
          this.new_block_text = '';
          this.new_block_image = undefined;
          this.new_block_image_url = undefined;
          this.$store.commit('changeLoading');
          this.changeArticle();
        }, this)
      },
      addBlockImage(e) {
        this.new_block_image = e.target.files[0];
        this.new_block_image_url = URL.createObjectURL(this.new_block_image);
      },
      addArticle() {
        let endpoint = "api/manager/article/";
        let method = "POST";
        let data = {'title': this.new_article_title};
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, auth)
          .then(data => {
            this.$refs.closeAddArticle.click();
            this.new_article_title = '';
            this.$store.commit('changeLoading');
            this.getArticleList();
          })
      },
      deleteArticle() {
        let endpoint = "api/manager/article/" + this.article.id + "/";
        let method = "DELETE";
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, method, undefined, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.$refs.closeDeleteArticle.click();
            this.getArticleList();
          })
      }
    },
    created() {
      this.getArticleList()
    }
  }
</script>

<style scoped>
  .add-new {
    text-align: center;
    background-color: white;
    font-weight: 600;
    color: #000;
    font-family: 'Open Sans', sans-serif;
    width: 233px;
    height: 60px;
    text-transform: uppercase;
    float: right;
    display: block;
    border: solid 2px #dfb859;
    line-height: 60px;
    margin: 0;
  }

  .add-new:hover {
    background: #222;
    border: solid 2px #222;
    color: #fff !important;
  }

  .js-countries {
    width: 100%;
    height: 100%;
  }

</style>