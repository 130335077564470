<template>
  <div class="modal fade" id="myModalHorizontal5" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <button type="button" class="close" ref="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
          <h4 class="modal-title">Выйти</h4>
        </div>
        <!-- Modal Body -->
        <div v-if="username" class="modal-body text-center">
          <h3 class="mt-3">Вы уверены, что хотите выйти из аккаунта {{username}}?</h3>
          <button class="btn btn-default button-1 mt-5 mb-3" @click="logout">Выйти</button>
        </div>
        <div v-else class="modal-body text-center">
          <h3 class="mt-3">Вы вышли из аккаунта</h3>
          <button data-dismiss="modal" class="btn btn-default button-1 mt-5 mb-3">Закрыть</button>
        </div>
        <!-- Modal Footer -->
      </div>
    </div>
  </div>
</template>

<script>
  import {apiService} from "@/common/api.service.js";

  export default {
    name: "Logout",
    computed: {
      username() {
        return this.$store.state.username
      }
    },
    methods: {
      logout() {
        let endpoint = "/api/logout";
        let method = "POST";
        let token = this.$store.state.a_token;
        apiService(endpoint, method, undefined, token)
          .then(data => {
            this.$store.dispatch('setUsername', undefined);
            this.$store.dispatch('setAToken', undefined);
            if (this.$cookie.get('token')) {
              this.$cookie.delete('token')
            }
          })
      }
    }
  }
</script>

<style scoped>

</style>