<template>
  <form class="row" @submit.prevent="editSchedule">
    <div class="col-4 form-group">
      <input required v-model="schedule.days"/>
    </div>
    <div class="col-4 form-group">
      <input required v-model="schedule.hours"/>
    </div>
    <div class="col-4 text-right">
      <button type="submit" class="filter2">Изменить</button>
      <span class="delete ml-3" @click="deleteSchedule">X</span>
    </div>
  </form>
</template>

<script>
  import {apiService} from "@/common/api.service";

  export default {
    name: "ManagerInfoShopSchedule",
    props: {
      schedule: {
        type: Object,
        required: true
      },
    },
    methods: {
      editSchedule() {
        let endpoint = "api/manager/shopschedule/" + this.schedule.id + "/";
        let method = "PATCH";
        let data = {'days': this.schedule.days, 'hours': this.schedule.hours};
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, auth)
          .then(data => {
            this.$store.commit('changeLoading');
          })
      },
      deleteSchedule() {
        let endpoint = "/api/manager/shopschedule/" + this.schedule.id + "/";
        let method = "DELETE";
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, method, undefined, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.$emit('getShops');
          }, this);
      }
    },
  }
</script>

<style scoped>
  .delete {
    cursor: pointer;
    font-weight: 900;
    display: inline;
  }
</style>