<template>
  <tr class="table-bottom">
    <td class="text-center bg-white image-td">
      <router-link :to="{ name: 'Detail', params: {id: product.id, slug: slug}}">
        <img :src="product.main_image" :alt="product.short_name" :title="product.name" class="img-fluid">
      </router-link>
    </td>
    <td class="product-name">
      <router-link :to="{ name: 'Detail', params: {id: product.id, slug: slug}}">
        <h1>{{product.short_name}}<br></h1>
        <h1 v-if="product.variant_description != null">{{product.variant_description}}</h1>
      </router-link>
    </td>
    <td>
      <div class="cost2">{{product.discounted_price}} руб.</div>
    </td>
    <td>
      <div class="inc-dre">
        <div class="input-group"><span class="input-group-btn">
                  <button type="button" class="btn btn-default btn-number" @click="decrementQuantity()" :disabled="quantity <= 1">
                    <span class="glyphicon glyphicon-minus"></span> </button>
                  </span>
          <input name="quant[1]" class="input-number" :value="quantity" type="text" readonly>
          <span class="input-group-btn">
                  <button type="button" class="btn btn-default btn-number" @click="incrementQuantity()" :disabled="quantity >= product.quantity">
                    <span class="glyphicon glyphicon-plus"></span> </button>
                  </span></div>
      </div>
    </td>
    <td class="remove-css text-center"><p><a @click="removeItem()"><i class="fa fa-trash-o" aria-hidden="true"></i>
      <br>
      УДАЛИТЬ </a></p>
    </td>
    <td>
      <div class="cost">{{ price }} руб.</div>
    </td>
  </tr>
</template>

<script>
  import {slugify} from "@/common/slugify.js";

  export default {
    name: "CartProductCard",
    props: {
      product: {
        type: Object,
        required: true
      },
    },
    computed: {
      slug() {
        return slugify(this.product.name)
      },
      price() {
        return this.product.discounted_price * this.quantity
      }
    },
    data() {
      return {
        quantity: Number,
      }
    },
    methods: {
      getQuantity() {
        let basket = JSON.parse(localStorage.basket);
        this.quantity = basket[this.product.uid];
        if (this.quantity > this.product.quantity) {
          this.quantity = this.product.quantity;
          basket[this.product.uid] = this.quantity;
          basket = JSON.stringify(basket);
          localStorage.setItem('basket', basket);
        }
      },
      incrementQuantity() {
        let basket = JSON.parse(localStorage.basket);
        if (this.quantity < this.product.quantity) {
          this.quantity += 1;
          basket[this.product.uid] = this.quantity;
          basket = JSON.stringify(basket);
          localStorage.setItem('basket', basket);
          this.$emit('recount')
        }
      },
      decrementQuantity() {
        let basket = JSON.parse(localStorage.basket);
        if (this.quantity > 1) {
          this.quantity -= 1;
          basket[this.product.uid] = this.quantity;
          basket = JSON.stringify(basket);
          localStorage.setItem('basket', basket);
          this.$emit('recount')
        }
      },
      removeItem() {
        let basket = JSON.parse(localStorage.basket);
        delete basket[this.product.uid];
        basket = JSON.stringify(basket);
        localStorage.setItem('basket', basket);
        this.updateBasketQuantity();
        this.$emit('removeFromBasket', this.product.uid)
        this.$emit('recount')
      },
      updateBasketQuantity() {
        this.$store.dispatch('get_basket_quantity')
      }
    },
    mounted() {
      this.getQuantity()
    }
  }
</script>

<style scoped>
  .image-td {
    width: 10%;
    padding-bottom: 15px;
  }

  .product-name h1 {
    font-size: 16px;
    margin-bottom: 0;
  }

  td {
    border:none;
    border-bottom: solid 1px black;
  }

  @media (max-width: 991px) {

    .product-name h1 {
      font-size: 14px;
    }

    .cost {
      font-size: 13px;
      margin: 0 10px;
    }
  }

</style>