<template>
  <li>
    <router-link :to="{ name: 'Detail', params: {id: product.id, slug: slug}}">
      <div class="row 0 align-items-center">
        <div class="col-md-2 col-sm-2 col-2 pt-0">
          <img class="img-50" :src="product.main_image" :alt="product.name" :title="product.name">
        </div>
        <div class="col-md-8 col-sm-8 col-8 ml-2">
          <h3>{{product.short_name}}</h3>
        </div>
      </div>
    </router-link>
  </li>
</template>

<script>
  import {slugify} from "@/common/slugify.js";

  export default {
    name: "ProductCollectionCard",
    props: {
      product: {
        type: Object,
        required: true,
      }
    },
    computed: {
      slug() {
        return slugify(this.product.name)
      },
    }
  }
</script>

<style scoped>

  .list-div li {
    padding: 0;
  }

  .img-50 {
    width: 70px;
  }

  h3 {
    margin-left: 25px;
  }

  @media (max-width: 1499px) {
    h3 {
      font-weight: 400;
      font-size: 15px;
      line-height: 16px;
      margin-left: 40px;
    }
  }

  @media (max-width: 991px) {
    h3 {
      margin-left: 25px;
      font-size: 16px;
      line-height: 18px;
    }
  }

</style>