<template>
  <div class="modal fade" id="myModalHorizontal4" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <button type="button" ref="close" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
          <h4 class="modal-title">Восстановить пароль</h4>
        </div>
        <!-- Modal Body -->
        <div class="modal-body">
          <form class="form-horizontal"
                id="restore-form"
                @submit.prevent="validateRestore">
            <div class="form-group">
              <div class="col-sm-12">
                <input type="text" id="restore-username" class="form-control" v-model="restore_username" placeholder="Е-мэйл"/>
                <label for="restore-username" class="text-danger" v-if="this.restore_errors['restore_username']">{{this.restore_errors['restore_username']}}</label>
              </div>
            </div>
            <div class="form-group">
              <div class="col-sm-12">
                <button type="submit" class="btn btn-default button-1">Восстановить пароль</button>
              </div>
            </div>
          </form>
        </div>
        <!-- Modal Footer -->
      </div>
    </div>
  </div>
</template>

<script>
  import {apiService} from "@/common/api.service.js";

  export default {
    name: "RestoreForm",
    data() {
      return {
        restore_username: "",
        restore_errors: [],
      }
    },
    methods: {
      validateRestore() {
        this.restore_errors = [];
        const validation = /[$%^*={}|<>]/;

        if (!this.restore_username) {
          this.restore_errors['restore_username'] = 'Заполните это поле'
        } else if (this.restore_username.match(validation)) {
          this.restore_errors['restore_username'] = 'Недопустимые символы'
        }

        return this.submitRestore()
      },
      submitRestore() {
        if (Object.keys(this.restore_errors).length === 0) {
          let endpoint = "/api/restore/";
          let method = "POST";
          this.$store.commit('changeLoading');
          apiService(endpoint, method, {'username': this.restore_username})
            .then(data => {
              if (data.success) {
                this.$refs.close.click();
                this.$router.push('/restore')
              } else if (data.nouser) {
                this.restore_errors = [];
                this.restore_errors['restore_username'] = 'Пользователя с таким именем не существует'
              } else {
                this.$refs.close.click();
                this.$router.push('/error')
              }
              this.$store.commit('changeLoading');
            }, this)
        }
      },
    }
  }
</script>

<style scoped>

</style>