<template>
  <tr>
    <td class="text-center bg-white image-td">
      <router-link :to="{ name: 'Detail', params: {id: product.id, slug:slug}}">
        <img data-v-2d0fb042="" :src="product.main_image" :alt="product.name" :title="product.name" class="img-fluid">
      </router-link>
    </td>
    <td>
      <div class="cost2">{{product.uid}}</div>
    </td>
    <td class="product-name">
      <router-link :to="{ name: 'Detail', params: {id: product.id, slug:slug}}">
        <h1 class="mb-1">{{product.name}}<br></h1>
        <h1 v-if="product.variant_description">{{product.variant_description}}</h1>
      </router-link>
    </td>
    <td>
      <div class="cost2" v-if="product.variant">Вариант</div>
    </td>
    <td>
      <div class="cost2 delete" @click="deleteCollectionProduct" :title="'Удалить '+ product.short_name">X</div>
    </td>
  </tr>
</template>

<script>
  import {apiService} from "@/common/api.service.js";
  import {slugify} from "@/common/slugify.js";

  export default {
    name: "ManagerCollectionTableProduct",
    props: {
      product: {
        type: Object,
        required: true
      },
      collection: {
        type: Number,
        required: true
      }
    },
    computed: {
      slug() {
        return slugify(this.product.name)
      }
    },
    methods: {
      deleteCollectionProduct() {
        let endpoint = "api/manager/collection/" + this.collection + '/' + "remove_product/";
        let auth = this.$store.state.a_token;
        let method = "PATCH";
        let data = {"id": this.product.id};
        apiService(endpoint, method, data, auth)
          .then(data => {
            this.$emit('getCollections');
          })
      },
    }
  }
</script>

<style scoped>
  .image-td {
    width: 10%;
  }

  .delete {
    cursor: pointer;
  }
</style>