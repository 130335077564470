<template>
  <div id="wrapper">
    <!--slider-->
    <section>
      <div class='fluidHeight'>
        <div class='sliderContainer'>
          <div class='iosSlider wow fadeIn'>
            <swiper :options="swiperOptions" ref="topSlider" class='swiper slider'>
              <swiper-slide v-for="slide in top_slider"
                            :slide="slide"
                            :key="slide.id"
              >
                <div class='title-css'>
                  <router-link class="text-white" :to="slide.link">
                    <h4>{{ slide.text1 }}</h4>
                    <h3>{{ slide.text2 }}</h3>
                    <h5>{{ slide.text3 }}</h5>
                    <p class="shopbt">
                      <router-link :to="slide.link" class="hvr-icon-wobble-horizontal">{{ slide.text4 }}</router-link>
                    </p>
                  </router-link>
                </div>
                <img :src='slide.image' :alt="slide.text1 + ' ' + slide.text2 + ' ' + slide.text3 + ' ' + slide.text4" class="w-100"/>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </section>
    <!-- Popular Collections-->
    <section class="content-section specific-module">
      <div class="div-center">
        <div class="specific-content">
          <h2 class="title-h wow fadeInDown"><span>Изысканные</span> комплекты украшений</h2>
        </div>
        <div class="row">
          <div class="col-md-3 col-sm-3 col-6 text-center wow fadeIn" v-for="collection in collection_grid"
               :collection="collection"
               :key="collection.id">
            <div class="box-css">
              <router-link :to="collection.link"><img :src="collection.image" class="img-fluid" :alt="collection.text">
                <div class="opacitybox white">
                  <div class="boxcontent">
                    <h4 class="white">{{ collection.text }}</h4>
                    <img src="../assets/images/search.svg" width="35" :alt="collection.link"></div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="View-all wow fadeInDown">
          <router-link to="/products">ВЕСЬ КАТАЛОГ <i class="fa fa-long-arrow-right"
                                                      aria-hidden="true"></i></router-link>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="clearfix"></div>
    </section>
    <section class="content-section text-center product-bg">
      <div class="col-md-10 col-center">
        <div class="title-heading  wow fadeInUp">
          <h3 class="wow fadeInUp">Янтарные изделия в золоте и серебре</h3>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="img-div3">
        <div class="row">
          <div class="col-md-6 col-sm-6 wow fadeInLeft">
            <a class="right-img"
               @click="$store.commit('changeGroup', 3); $router.push({name: 'Products'})">
              <div><img :src="gold_and_silver[0].image" class="img-fluid grayscale" alt="Янтарь и золото"
                        v-if="gold_and_silver.length > 0" title="Янтарь и золото"></div>
            </a>
            <div class="clearfix"></div>
          </div>
          <div class="col-md-6 col-sm-6 wow fadeInRight">
            <a class="right-img"
               @click="$store.commit('changeGroup', 1); $router.push({name: 'Products'})">
              <div><img :src="gold_and_silver[1].image" class="img-fluid grayscale" alt="Янтарь и серебро"
                        v-if="gold_and_silver.length > 0" title="Янтарь и серебро"></div>
            </a>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="clearfix"></div>
    </section>
    <!--best of our store-->
    <section class="content-section">
      <div class="best-div">
        <div class="best-of-our-store">
          <h2 class="wow fadeInUp"><span>Новые скидки</span> каждую неделю</h2>
          <carousel class="owl-carousel owl-theme wow fadeIn"
                    :items="5"
                    :autoplay="true"
                    :nav="false"
                    :dots="false"
                    :loop="true"
                    :autoplayHoverPause="true"
                    :responsive="{0:{items:2},576:{items:3},768:{items:4},1000:{items:5}}"
                    v-if="showWeekCarousel">
            <div class="item img-title" v-for="product in week_products"
                 :product="product"
                 :key="product.id">
              <router-link :to="{ name: 'Detail', params: {id: product.id, slug: slug(product.name)}}">
                <div class="owl-item-boder">
                  <div class="hover-div">
                    <div class="our-store">
                      <img src="../assets/images/search.svg" class="search-image top-50" :alt="product.name" :title="product.short_name">
                    </div>
                    <img :src="product.main_image" :alt="product.name" :title="product.short_name" class="img-fluid">
                  </div>
                </div>
                <h4>{{ product.short_name }}</h4>
                <p class="price"><span>{{ product.discounted_price }} руб. </span>&nbsp;<samp>{{ product.price }} руб. </samp>
                </p>
              </router-link>
            </div>
          </carousel>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="clearfix"></div>
    </section>
    <section class="content-section category">
      <div class="category-in p-0">
        <h2 class="text-center wow fadeInUp">Огромный выбор <span>янтарных украшений</span></h2>
        <div class="carousel-inner wow fadeIn">
          <div class="carousel-item active">
            <ul class="row justify-content-center mx-4">
              <li v-for="type in product_types"
                  :type="type"
                  :key="type.id"
                  class=" col-6 col-sm-4 col-md-2 mx-0"
              >
                <router-link :to="type.link">
                  <div>
                    <figure class="effect-moses mx-0">
                      <img :src="type.image" :alt="type.text" :title="type.text" class="img-fluid w-100">
                    </figure>
                  </div>
                  <h2>{{ type.text }}</h2>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </section>
    <!--latest jewellery collection-->
    <section class="bg-2 content-section ">
      <h2 class="text-center  wow fadeInDown">Ювелирные изделия и подарки <span>из янтаря</span></h2>
      <div class="clearfix"></div>
      <div class="section">
        <div class="masonry one_column full-width">
          <div class="content img-div">
            <ul>
              <li class="col-6 col-sm-4 col-md-3 wow fadeIn" v-for="category in category_grid">
                <div class="grid">
                  <router-link :to="category.link">
                    <figure class="effect-apollo"><img :alt="category.text" :src="category.image"/>
                      <figcaption>
                        <h2>{{ category.text }}</h2>
                      </figcaption>
                    </figure>
                  </router-link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!--Popular Brands-->
    <section>
      <div class="best-div mb-5">
        <div class="best-of-our-store">
          <h2 class="wow fadeInDown"><span>Выбор</span> покупателей</h2>
          <carousel class="owl-carousel owl-theme wow fadeIn"
                    :items="5"
                    :autoplay="true"
                    :nav="false"
                    :dots="false"
                    :loop="true"
                    :autoplayHoverPause="true"
                    :responsive="{0:{items:2},576:{items:3},768:{items:4},1000:{items:5}}"
                    v-if="showProductCarousel">
            <div class="item img-title text-left" v-for="product in popular_products"
                 :product="product"
                 :key="product.id">
              <router-link :to="{ name: 'Detail', params: {id: product.id, slug: slug(product.name)}}">
                <img :src="product.main_image" :alt="product.name"/>
                <h4>{{ product.short_name }}</h4>
                <p class="price" v-if="product.price !== product.discounted_price"><span>{{ product.discounted_price }} руб.
            </span>&nbsp;&nbsp;<samp>{{ product.price }}
                  руб. </samp></p>
                <p class="price" v-else><span>{{ product.price }} руб. </span></p>
              </router-link>
            </div>
          </carousel>
        </div>
      </div>
    </section>
    <div class="clearfix"></div>
    <Footer/>
  </div>
</template>

<script>
  import {apiService} from "@/common/api.service.js";
  import {slugify} from "@/common/slugify.js";
  import Footer from "@/components/Footer";
  import {Autoplay, Mousewheel, Pagination, Swiper as SwiperClass} from 'swiper/swiper.esm';
  import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';
  import 'swiper/swiper-bundle.css'
  import carousel from 'vue-owl-carousel'

  SwiperClass.use([Pagination, Mousewheel, Autoplay]);
  const {Swiper, SwiperSlide} = getAwesomeSwiper(SwiperClass);


  export default {
    name: "Home",
    components: {
      Footer,
      Swiper,
      SwiperSlide,
      carousel,
    },
    metaInfo: {
      title: 'Ambervile || Интернет-магазин янтарных украшений',
      titleTemplate: null,
    },
    data() {
      return {
        top_slider: [],
        category_grid: [],
        collection_grid: [],
        gold_and_silver: [],
        product_types: [],
        week_products: [],
        popular_products: [],
        swiperOptions: {
          slidesPerView: 1,
          loop: true,
          centeredSlides: true,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          breakpoints: {
            1000: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 1.5,
            },
          },
        },
        showWeekCarousel: false,
        showProductCarousel: false,
      }
    },
    computed: {
      swiper() {
        return this.$refs.topSlider.$swiper
      },
    },
    methods: {
      slug(text) {
        return slugify(text)
      },
      getTopSlider() {
        let endpoint = "/api/frontpage/topslider/"
        this.$store.commit('changeLoading');
        apiService(endpoint)
          .then(data => {
            this.top_slider = data;
            this.$store.commit('changeLoading');
            this.swiper.slideTo(1);
          })
      },
      getCollectionGrid() {
        let endpoint = "/api/frontpage/collectiongrid/"
        apiService(endpoint)
          .then(data => {
            this.collection_grid = data;
          })
      },
      getGoldAndSilver() {
        let endpoint = "/api/frontpage/goldandsilver/"
        apiService(endpoint)
          .then(data => {
            this.gold_and_silver = data;
          })
      },
      getCategoryGrid() {
        let endpoint = "/api/frontpage/categorygrid/"
        apiService(endpoint)
          .then(data => {
            this.category_grid = data;
          })
      },
      getProductTypes() {
        let endpoint = "/api/frontpage/producttypes/"
        apiService(endpoint)
          .then(data => {
            this.product_types = data;
          })
      },
      getWeekProducts() {
        let endpoint = "/api/frontpage/weekproducts/"
        apiService(endpoint)
          .then(data => {
            this.week_products = data;
            this.showWeekCarousel = true;
          })
      },
      getPopularProducts() {
        let endpoint = "/api/frontpage/popular/"
        apiService(endpoint)
          .then(data => {
            this.popular_products = data;
            this.showProductCarousel = true;
          })
      },
    },
    created() {
      this.getTopSlider();
      this.getCollectionGrid();
      this.getGoldAndSilver();
      this.getWeekProducts();
      this.getProductTypes();
      this.getCategoryGrid();
      this.getPopularProducts();
    },
  };

</script>

<style scoped>
  .title-heading {
    max-width: none;
    width: auto;
  }

  .title-heading h3 {
    max-width: none;
    width: auto;
  }

  .owl-carousel {
    display: block;
  }

  .search-image {
    position: relative;
    width: 35px !important;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .top-50 {
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .View-all {
    margin-top: 30px;
  }

  .price {
    margin-left: 5px;
  }


  @media (max-width: 1499px) {
    .title-heading h3 {
      font-size: 30px;
    }
  }

  @media (max-width: 1024px) {
    .footer-logos .owl-nav {
      top: 0;
    }
  }

  @media (max-width: 991px) {
    .img-title h4, .price {
      font-size: 15px;
    }
  }

  @media (max-width: 767px) {
    .title-css {
      min-height: auto;
    }

    .title-heading h3 {
      font-size: 25px;
      line-height: 1;
    }

    .category-in h2 {
      font-size: 25px;
    }

    .fadeInDown, .fadeInUp, .fadeInRight, .fadeInLeft {
      animation: none;
    }

    figure.effect-apollo img {
      opacity: 0.6;
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }

    figure.effect-apollo figcaption::before {
      -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
      transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
    }

    figure.effect-apollo p, figure.effect-apollo h2 {
      opacity: 1;
      -webkit-transition-delay: 0s;
      transition-delay: 0s;
    }
  }

  @media (max-width: 480px) {
    .bg-2 h2, .best-of-our-store h2, .title-h {
      font-size: 30px;
    }

    .View-all {
      margin-bottom: 30px;
    }

    .content-section h2, .title-h {
      margin: 30px 0 35px 0 !important;
    }
  }

  @media (max-width: 400px) {
    .fluidHeight {
      height: 360px !important;
    }

  }


</style>
