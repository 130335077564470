<template>
  <div id="wrapper">
    <!--page heading-->
    <section>
      <div class="inner-bg">
        <div class="inner-head wow fadeInDown">
          <h3>Заказ № {{order.uid}}</h3>
        </div>
      </div>
    </section>
    <!--page heading-->
    <!--container-->
    <div class="container">
      <div class="shop-in pl-0 pr-0">
        <div>
          <div class="bread2">
            <ul>
              <li>
                <router-link to="/">На главную</router-link>
              </li>
              <li>/</li>
              <li>
                <router-link to="/cabinet">Личный кабинет</router-link>
              </li>
              <li>/</li>
              <li>Заказ № {{order.uid}}</li>
            </ul>
          </div>
        </div>
        <div class="clearfix"></div>
        <div>
          <div class="row justify-content-between m-0">
            <h1>Заказ № {{order.uid}}</h1>
            <h3>{{order.created_at}}</h3>
          </div>
          <div class="row justify-content-between m-0 align-items-end">
            <div class="">
              <h2 :class="statusClass" class="mb-0">{{order.status}}</h2>
              <h3 v-if="delivered" class="mb-0">Трек номер: {{order.tracking_number}}</h3>
            </div>
            <h1 class="mb-0">{{order.overall}} руб.</h1>
          </div>
          <div class="order-details">
            <div class="table-responsive wow fadeIn">
              <table class="table product-table">
                <tr class="table-h">
                  <td class="d-none d-md-table-cell">&nbsp;</td>
                  <td>Название</td>
                  <td>Цена</td>
                  <td>Шт</td>
                  <td>Всего</td>
                </tr>
                <CabinetOrderTableProduct
                  v-for="product in orderProducts"
                  :product="product"
                  :key="product.uid"
                />
              </table>
            </div>
            <div class="row m-0">
              <div class="col-md-6 col-xs-12 wow fadeIn">
                <hr>
                <div class="pull-left text-color">Всего</div>
                <div class="pull-right"><strong>{{order.total}} руб.</strong></div>
                <div class="clearfix"></div>
                <hr>
                <div class="clearfix"></div>
                <div class="pull-left text-color"> Скидка по промокоду</div>
                <div class="pull-right"><strong>{{order.discount}} руб.</strong></div>
                <div class="clearfix"></div>
                <hr>
                <div class="clearfix"></div>
                <div class="pull-left text-color"> Доставка</div>
                <div class="pull-right"><strong>{{order.delivery_price}} руб.</strong></div>
                <div class="clearfix"></div>
                <hr>
                <div class="clearfix"></div>
                <div class="pull-left text-color"><strong>Всего со скидкой</strong></div>
                <div class="pull-right"><strong>{{order.overall}} руб.</strong></div>
                <div class="clearfix"></div>
                <hr>
                <br>
              </div>
              <div class="col-md-6 col-xs-12 wow fadeIn thanks-text pl-3">
                <h2 class="text-center">Адрес доставки</h2>
                <div class="mt-3"><strong>ФИО: </strong> {{order.last_name}} {{order.first_name}} {{order.second_name}}</div>
                <div class="mt-3"><strong>Адрес: </strong> {{order.postal_code}}, {{order.country_name}}, {{order.region_name}}, {{order.city_name}}, {{order.address}}</div>
                <div class="mt-3"><strong>Телефон: </strong> {{order.phone_number}}</div>
                <div class="mt-3"><strong>Email: </strong> {{order.email}}</div>
                <br>
                <div class="mt-3" v-if="delivered"><strong>Трек номер: </strong> {{order.tracking_number}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="thanks-text text-center">
          <div>
            <router-link to="/" class="wow fadeInLeft mr-sm-1">Вернуться на главную</router-link>
            <router-link to="/cabinet" class="wow fadeInRight ml-sm-1">Личный кабинет</router-link>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="clearfix"></div>
    </div>
    <!--container-->
    <div class="clearfix"></div>
    <!--footer-->
    <Footer
    />
  </div>
</template>


<script>
  import {apiService} from "@/common/api.service.js";
  import CabinetOrderTableProduct from "@/components/CabinetOrderTableProduct"
  import Footer from "@/components/Footer";

  export default {
    name: "SingleOrder",
    components: {
      Footer,
      CabinetOrderTableProduct,
    },
    metaInfo: {
      title: 'Детали заказа',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Отследить Ваш заказ'
        },
      ],
    },
    data() {
      return {
        order: {},
        orderProducts: [],
      }
    },
    computed: {
      delivered() {
        return this.order.status !== 'Оплачен'
      },
      statusClass() {
        if (this.order.status === 'Оплачен') {
          return 'text-paid'
        } else if (this.order.status === 'Отправлен') {
          return 'text-sent'
        } else if (this.order.status === 'Доставлен') {
          return 'text-delivered'
        }
      }
    },
    methods: {
      getRouteOptions() {
        if (this.$route.query.ln) {
          this.$store.dispatch('userCookies', this.$route.query.ln);
          this.$cookie.set('token', this.$route.query.ln, {expires: 14});
        }
      },
      getOrder() {
        let uid = this.$route.params.uid;
        let endpoint = "/api/getorder/" + uid + '/';
        apiService(endpoint)
          .then(data => {
            if (!data.error) {
              this.order = data;
              this.getOrderProducts()
            }
          })
      },
      getOrderProducts() {
        let endpoint = "/api/getorderproducts/" + this.order.id;
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            if (data) {
              this.orderProducts = data
            }
            this.$store.commit('changeLoading');
          }, this);
      },
    },
    created() {
      this.getRouteOptions()
      this.getOrder();
    }
  }
</script>

<style scoped>
  .text-paid {
    color: #6fbb6f;
  }

  .text-sent {
    color: #00926a;
  }

  .text-delivered {
    color: #006ac3;
  }

  .order-details {
    border-top: none;
    margin-top: -25px;
    padding-bottom: 15px;
  }

  .product-table {
    width: 90%;
    margin: 50px 5% 25px 5%;
    border-style: hidden;
    border-collapse: separate;
    border-spacing: 0 15px;
  }

  .thanks-text {
    text-align: left;
  }

  .thanks-text a {
    text-align: center;
  }

  @media (max-width: 767px)
  {
    .table-h td {
      font-size: 10px;
    }
  }
</style>