<template>
  <div id="wrapper">
    <section>
      <div class="inner-bg">
        <div class="inner-head wow fadeInDown">
          <h3>Оплата</h3>
        </div>
      </div>
    </section>
    <div class="container">
      <div class="shop-in">
        <div class="row">
          <div class="col-md-12">
            <!--breadcrumbs -->
            <div class="bread2">
              <ul>
                <li><a href="/">На главную</a>
                </li>
                <li>/</li>
                <li><a>Оплата</a></li>
              </ul>
            </div>
            <!--breadcrumbs -->
          </div>
        </div>
        <div class="row wow fadeIn">
          <div class="text-center col-12 mt-4">
            <img src="/static/images/payment-card.png" class="img-fluid" alt="Оплата банковской картой">
          </div>
        </div>
        <h3 class="mt-4 text-justify wow fadeInDown">На нашем сайте Вы можете провести оплату любыми банковскими картами. Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на
          платёжный шлюз ПАО СБЕРБАНК. Соединение с платёжным шлюзом и передача информации осуществляется в защищённом режиме с использованием протокола шифрования SSL. В случае если Ваш банк
          поддерживает технологию безопасного проведения интернет-платежей Verified By Visa, MasterCard SecureCode, MIR Accept, J-Secure, для проведения платежа также может потребоваться ввод
          специального пароля. Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной информации обеспечивается ПАО СБЕРБАНК. Введённая информация не будет
          предоставлена третьим лицам за исключением случаев, предусмотренных законодательством РФ. Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями
          платёжных систем МИР, Visa Int., MasterCard Europe Sprl, JCB.
        </h3>
        <div class="row wow fadeIn">
          <div class="text-center col-12 mt-4">
            <img src="/static/images/payment-nalozh.jpg" class="img-fluid" alt="Оплата наложенным платежом">
          </div>
        </div>
        <h3 class="mt-4 text-justify wow fadeInDown">Вы можете провести оплату на почте при получении товара. Оплата наложенным платежом возможна только для заказов с доставкой по территории РФ
          стоимостью до 10000 рублей на одного покупателя. При выборе доставки наложенным платежом с Вами свяжется менеджер интернет-магазина для подтверждения заказа. Интернет-магазин имеет право
          отказаться от доставки наложенным платежом без обьяснения причин.
        </h3>
        <h3 class="mt-4 text-justify wow fadeInDown">Срок возврата товара надлежащего качества составляет 30 дней с момента получения товара. Возврат переведённых средств, производится на ваш
          банковский счёт в течение 5-30 рабочих дней (срок зависит от банка, который выдал вашу банковскую карту).
        </h3>
        <h3 class="mt-4 text-justify wow fadeInDown">Если Вам необходимы другие способы оплаты, пожалуйста, напишите нам
          <router-link to="/contacts"><u>на этой странице</u>.</router-link>
        </h3>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
  import Footer from "@/components/Footer";

  export default {
    name: "Payment",
    components: {
      Footer,
    },
    metaInfo: {
      title: 'Оплата',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Способы оплаты заказов в интернет-магазине Амбервиль'
        },
      ],
    },
  }
</script>

<style scoped>

</style>