<template>
  <div id="wrapper">
    <section>
      <div class="inner-bg">
        <div class="inner-head wow fadeInDown">
          <h3>Доставка</h3>
        </div>
      </div>
    </section>
    <div class="container">
      <div class="shop-in">
        <div class="row">
          <div class="col-md-12">
            <!--breadcrumbs -->
            <div class="bread2">
              <ul>
                <li><a href="/">На главную</a>
                </li>
                <li>/</li>
                <li><a>Доставка</a></li>
              </ul>
            </div>
            <!--breadcrumbs -->
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-sm-6 pl-sm-0 wow fadeInLeft">
            <img src="/static/images/delivery-post.jpg" class="img-fluid" alt="Бесплатная доставка почтой России">
          </div>
          <div class="col-sm-6 pl-sm-0 wow fadeInRight">
            <img src="/static/images/delivery-ems.jpg" class="img-fluid" alt="Бесплатная доставка EMS России">
          </div>
        </div>
        <div class="inner-section">
          <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
            <div class="panel panel-default wow fadeInDown">
              <div class="panel-heading panel-bg" role="tab" id="headingOne">
                <h4 class="panel-title">
                  <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Стоимость доставки</a>
                </h4>
              </div>
              <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                <div class="panel-body">
                  <h3>Доставка почтой России</h3>
                  <h4 class="text-justify">На любые заказы от 3000 рублей доставка почтой России по территории РФ абсолютно <span class="red">БЕСПЛАТНО</span></h4>
                  <table class="table">
                    <thead class="ghead">
                    <tr>
                      <th scope="col">Вес посылки</th>
                      <th class="pr-1" scope="col">до 200 г</th>
                      <th class="pr-1" scope="col">200 - 500 г</th>
                      <th class="d-none d-xs-table-cell pr-1" scope="col">500 - 900 г</th>
                      <th class="d-none d-sm-table-cell pr-1" scope="col">900 - 2000 г</th>
                      <th class="d-none d-md-table-cell pr-1" scope="col">2 - 3 кг</th>
                      <th class="d-none d-md-table-cell" scope="col">+ 1 кг</th>
                    </tr>
                    </thead>
                    <tr>
                      <th>Доставка по России</th>
                      <td class="pr-1">290 руб</td>
                      <td class="pr-1">290 руб</td>
                      <td class="d-none d-xs-table-cell pr-1">340 руб</td>
                      <td class="d-none d-sm-table-cell pr-1">440 руб</td>
                      <td class="d-none d-md-table-cell pr-1">540 руб</td>
                      <td class="d-none d-md-table-cell">+ 100 руб</td>
                    </tr>
                    <tr>
                      <th>Международная доставка</th>
                      <td class="pr-1">540 руб</td>
                      <td class="pr-1">830 руб</td>
                      <td class="d-none d-xs-table-cell pr-1">1330 руб</td>
                      <td class="d-none d-sm-table-cell pr-1">2330 руб</td>
                      <td class="d-none d-md-table-cell pr-1">3330 руб</td>
                      <td class="d-none d-md-table-cell">+ 1000 руб</td>
                    </tr>
                  </table>
                  <h3>Курьерская доставка EMS</h3>
                  <h4 class="text-justify">На любые заказы от 10000 рублей доставка EMS по территории РФ абсолютно <span class="red">БЕСПЛАТНО</span></h4>
                  <table class="table">
                    <thead class="ghead">
                    <tr>
                      <th scope="col">Вес посылки</th>
                      <th class="pr-1" scope="col">до 400 г</th>
                      <th class="pr-1" scope="col">400 - 1000 г</th>
                      <th class="d-none d-xs-table-cell pr-1" scope="col">1 - 1.5 кг</th>
                      <th class="d-none d-sm-table-cell pr-1" scope="col">1.5 - 2 кг</th>
                      <th class="d-none d-md-table-cell pr-1" scope="col">2 - 3 кг</th>
                      <th class="d-none d-md-table-cell" scope="col">+ 1 кг</th>
                    </tr>
                    </thead>
                    <tr>
                      <th>Доставка по России</th>
                      <td class="pr-1">900 руб</td>
                      <td class="pr-1">980 руб</td>
                      <td class="d-none d-xs-table-cell pr-1">1200 руб</td>
                      <td class="d-none d-sm-table-cell pr-1">1370 руб</td>
                      <td class="d-none d-md-table-cell pr-1">1400 руб</td>
                      <td class="d-none d-md-table-cell">+ 100 руб</td>
                    </tr>
                    <tr>
                      <th>Международная доставка</th>
                      <td class="pr-1">2000 руб</td>
                      <td class="pr-1">2300 руб</td>
                      <td class="d-none d-xs-table-cell pr-1">2650 руб</td>
                      <td class="d-none d-sm-table-cell pr-1">2950 руб</td>
                      <td class="d-none d-md-table-cell pr-1">3350 руб</td>
                      <td class="d-none d-md-table-cell">+ 400 руб</td>
                    </tr>
                  </table>
                  <h3>Почта России наложенный платеж</h3>
                  <h4 class="text-justify">Только по территории РФ и для заказов не свыше 10000 рублей</h4>
                  <table class="table">
                    <thead class="ghead">
                    <tr>
                      <th scope="col">Вес посылки</th>
                      <th class="pr-1" scope="col">до 200 г</th>
                      <th class="pr-1" scope="col">200 - 500 г</th>
                      <th class="d-none d-xs-table-cell pr-1" scope="col">500 - 900 г</th>
                      <th class="d-none d-sm-table-cell pr-1" scope="col">900 - 2000 г</th>
                      <th class="d-none d-md-table-cell pr-1" scope="col">2 - 3 кг</th>
                      <th class="d-none d-md-table-cell" scope="col">+ 1 кг</th>
                    </tr>
                    </thead>
                    <tr>
                      <th>Доставка по России</th>
                      <td class="pr-1">690 руб</td>
                      <td class="pr-1">690 руб</td>
                      <td class="d-none d-xs-table-cell pr-1">740 руб</td>
                      <td class="d-none d-sm-table-cell pr-1">840 руб</td>
                      <td class="d-none d-md-table-cell pr-1">940 руб</td>
                      <td class="d-none d-md-table-cell">+ 100 руб</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="panel panel-default wow fadeInDown">
              <div class="panel-heading panel-bg" role="tab" id="headingTwo">
                <h4 class="panel-title">
                  <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Сроки доставки</a>
                </h4>
              </div>
              <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                <div class="panel-body">
                  <table class="table">
                    <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Доставка почтой</th>
                      <th scope="col">Доставка курьером</th>
                    </tr>
                    </thead>
                    <tr>
                      <th>Доставка по России</th>
                      <td>3 - 10 дней</td>
                      <td>2 - 6 дней</td>
                    </tr>
                    <tr>
                      <th>Международная доставка</th>
                      <td>7 - 28 дней</td>
                      <td>4 - 14 дней</td>
                    </tr>
                  </table>
                  <p>
                    Заказ формируется к отправке в течение 1-2 дней. Доставка в отдаленные регионы России может быть дольше заявленной в таблице.
                    Учитывая сложившуюся эпидемиологическую ситуацию возможны задержки в международных отправлениях. Более детальную информацию о
                    сроках доставки вы можете найти на сайте <a href="https://www.pochta.ru/support/post-rules/delivery-terms">Почты России.</a>
                  </p>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="panel panel-default wow fadeInDown">
              <div class="panel-heading panel-bg" role="tab" id="headingThree">
                <h4 class="panel-title">
                  <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Отслеживание посылки</a>
                </h4>
              </div>
              <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                <div class="panel-body">
                  <p>
                    Вы можете найти трек-номер вашего отправления в
                    <router-link to="/cabinet">личном кабинете</router-link>
                    или по ссылке в письме, отправленном на вашу электронную почту.
                  </p>
                  <p><a href="https://www.pochta.ru/tracking">Отслеживание посылок Почты России</a></p>
                  <p><a href="https://www.pochta.ru/emspost/">Отслеживание отправлений EMS</a></p>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="panel panel-default wow fadeInDown">
              <div class="panel-heading panel-bg" role="tab" id="heading4">
                <h4 class="panel-title">
                  <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapse4" aria-expanded="false" aria-controls="collapseThree">Правила доставки</a>
                </h4>
              </div>
              <div id="collapse4" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading4">
                <div class="panel-body">
                  <p>При получении заказа вам будут переданы все необходимые документы на покупку: товарный, кассовый чеки.</p>
                  <p>При доставке проверяйте товар из янтаря на наличие механических повреждений. При отсутствии заявления во время получения товара претензий по поводу механических повреждений,
                    в дальнейшем подобные претензии не рассматриваются. </p>
                  <p>Посылки, оплачиваемые наложенным платежом, отправляются только по территории РФ и стоимостью не более 10000 рублей на одного покупателя. При выборе доставки наложенным платежом
                     с Вами свяжется менеджер интернет-магазина для подтверждения заказа. Интернет-магазин имеет право отказаться от доставки наложенным платежом без обьяснения причин. </p>
                  <p>Внимательно проверяйте свою электронную почту, номер телефона и почтовый адрес при оформлении заказа в нашем интернет-магазине янтаря. Искажение регистрационных данных может
                    замедлить доставку вашей посылки. Конфиденциальность гарантируем.</p>
                  <p>В случае вопросов, пожеланий и претензий обращайтесь к нам по следующим координатам:</p>
                  <p>Телефон: <a :href="'tel:' + details.phone">{{details.phone_display}}</a></p>
                  <p>Email: <a :href="'mailto:' + details.email">{{details.email}}</a></p>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
  import {apiService} from "@/common/api.service.js";
  import Footer from "@/components/Footer";

  export default {
    name: "Delivery",
    components: {
      Footer,
    },
    metaInfo: {
      title: 'Доставка',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Стоимость, сроки и условия доставки по России и по всему миру из интернет-магазина Амбервиль'
        },
      ],
    },
    data() {
      return {
        details: {},
      }
    },
    methods: {
      getDetails() {
        let endpoint = "api/companydetails/";
        this.$store.commit('changeLoading');
        apiService(endpoint)
          .then(data => {
            this.details = data[0]
            this.$store.commit('changeLoading');
          })
      },
    },
    created() {
      this.getDetails()
    }
  }
</script>

<style scoped>
  .inner-section {
    border: none;
  }

  .panel-body h3 {
    font-size: 26px;
    font-weight: 600;
  }

  .panel-body h4 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
  }

  .panel-body a {
    text-decoration: underline !important;
  }

  .panel-body p {
    text-align: justify;
  }

  th {
    font-weight: 600;
  }

  td, .ghead th {
    white-space: nowrap;
  }

  .red {
    color: #f61900
  }

  @media (max-width: 767px) {
    .panel-body h3 {
      font-size: 22px;
    }

    .panel-body h4 {
      font-size: 15px;
    }
  }

  @media (max-width: 390px) {
    table {
      display: none;
    }
  }

  @media (min-width: 475px) {
    .d-xs-table-cell {
      display: table-cell !important;
    }
  }
</style>