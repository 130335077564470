<template>
  <div id="app">
    <Navbar
      ref="navbar"
    />
    <Menu
      v-on:triggerMenu="triggerMenu"/>
    <router-view></router-view>
  </div>
</template>

<script>
  import Navbar from "@/components/Navbar.vue";
  import Menu from "@/components/Menu.vue";

  export default {
    name: "App",
    components: {
      Navbar,
      Menu,
    },
    metaInfo: {
      title: 'Ambervile',
      titleTemplate: '%s || Ambervile - интернет-магазин янтарных украшений',
      meta: [
        {vmid: 'description', name: 'description', content: 'Серьги и кольца из янтаря и серебра. Янтарные бусы и браслеты. Только качественные украшения из янтаря с доставкой по всему миру.'},
        {vmid: 'image', property: 'og:image', content: 'https://www.ambervile.ru/static/images/og-image.jpg'}
      ],
    },
    methods: {
      triggerMenu() {
        this.$refs.navbar.triggerMenu()
      }
    },
    beforeCreate() {
      if (!localStorage.basket) {
        localStorage.basket = '{}'
      }
    },
    created() {
      this.$store.dispatch('get_basket_quantity');
      this.$store.dispatch('get_time_countdown');
      if (this.$cookie.get('token')) {
        this.$store.dispatch('userCookies', this.$cookie.get('token'));
      }
    }
  };
</script>

<style>
  .tooltip {
    display: block !important;
    z-index: 10000;
  }

  .tooltip .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  .tooltip[x-placement^="top"] {
    margin-bottom: -5px;
  }

  .tooltip[x-placement^="top"] .tooltip-arrow {
    border-width: 5px 5px 0 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    bottom: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
  }

  .tooltip[x-placement^="bottom"] {
    margin-top: -5px;
  }

  .tooltip[x-placement^="bottom"] .tooltip-arrow {
    border-width: 0 5px 5px 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
    top: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
  }

  .tooltip[x-placement^="right"] {
    margin-left: 5px;
  }

  .tooltip[x-placement^="right"] .tooltip-arrow {
    border-width: 5px 5px 5px 0;
    border-left-color: transparent !important;
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
    left: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
  }

  .tooltip[x-placement^="left"] {
    margin-right: 5px;
  }

  .tooltip[x-placement^="left"] .tooltip-arrow {
    border-width: 5px 0 5px 5px;
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    right: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
  }

  .tooltip.popover .popover-inner {
    background: white;
    color: black;
    padding: 24px;
    border-radius: 5px;
    box-shadow: 0 5px 30px #d19e66;
    border: solid 1px #d19e66;
    transition: opacity .95s, visibility .95s;
  }

  .tooltip.popover {
    width: 300px

  }

  .tooltip.popover .wrapper {
    min-width: auto;
    overflow: hidden;
  }

  .tooltip.popover .popover-arrow {
    border-color: #f9f9f9;
  }

  .tooltip[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  .tooltip[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }

  @media (max-width: 1499px) {
    .tooltip.popover {
      width: 220px

    }

    .tooltip.popover .popover-inner {
      padding: 10px;
    }

  }
</style>