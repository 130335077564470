<template>
  <div id="wrapper">
    <!--page heading-->
    <section>
      <div class="inner-bg">
        <div class="inner-head wow fadeInDown">
          <h3>Ошибка</h3>
        </div>
      </div>
    </section>
    <!--page heading-->
    <!--container-->
    <div class="container">
      <div class="shop-in pl-0 pr-0">
        <div>
          <div class="bread2">
            <ul>
              <li>
                <router-link to="/">На главную</router-link>
              </li>
              <li>/</li>
              <li>Ошибка</li>
            </ul>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="thanks-bg">
          <div class="thanks-text">
            <h2 class="wow fadeIn error-heading">Что-то пошло не так</h2>
            <h3 class="wow fadeIn">Возможно, что проблема уже устранена <br> Если ошибка повторяется, пожалуйста, напишите нам в
              <router-link class="normal-anchor" to="/contacts">форме обратной связи</router-link>
            </h3>
            <div>
              <router-link to="/" class="wow fadeInLeft">Вернуться на главную</router-link>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="clearfix"></div>
    </div>
    <!--container-->
    <div class="clearfix"></div>
    <!--footer-->
    <Footer
    />
  </div>
</template>

<script>
  import Footer from "@/components/Footer";

  export default {
    name: "Error",
    components: {
      Footer,
    },
    metaInfo: {
      title: 'Ошибка',
    },
  }
</script>

<style scoped>
  .error-heading {
    margin-top: 10%;
  }

  .normal-anchor {
    background: none;
    width: auto;
    display: inline-block;
    line-height: normal;
    text-transform: none;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    border: none;
    font-size: inherit;
  }

  .normal-anchor:hover {
    background: none;
    width: auto;
    display: inline-block;
    line-height: normal;
    text-transform: none;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    border: none;
    font-size: inherit;
  }

  @media (max-width: 1499px) {
    .thanks-bg {
      min-height: 480px;
    }
  }

  @media (max-width: 991px) {
    .thanks-bg {
      min-height: 400px;
    }
  }

  @media (max-width: 771px) {
    .thanks-bg {
      background-size: 100% 100%;
    }
  }
</style>