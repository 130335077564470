<template>
  <div class="my-5">
    <div class="order-box" @click="show = !show">
      <div class="row pt-3">
        <h1 class="mr-auto ml-4 ml-md-5">Заказ № {{order.uid}}</h1>
        <div>
          <h4 class="ml-auto mr-4 mr-md-5">{{order.created_at}}</h4>
        </div>
      </div>
      <div class="row">
        <div class="mr-auto ml-4 ml-md-5">
          <div class="">
            <h2 class="status" :class="statusClass">{{order.status}}</h2>
            <h3 class="mb-0" v-if="delivered">Трек номер: {{order.tracking_number}}</h3>
          </div>
        </div>
        <h1 class="ml-auto mr-4 mr-md-5 mb-0">{{order.overall}} ₽</h1>
      </div>
      <div class="row-fluid">
        <h3 class="text-center m-0 d-none d-md-block">Нажмите, чтобы посмотреть детали заказа</h3>
        <div class="d-flex justify-content-center">
          <img class="rotate-90 mb-2 drop-arrow" src="/static/images/up.png">
        </div>

      </div>
    </div>
    <div class="order-details" v-if="show">
      <div class="table-responsive wow fadeIn">
        <table class="table product-table">
          <tr class="table-h">
            <td class="d-none d-md-table-cell">&nbsp;</td>
            <td>Название</td>
            <td>Цена</td>
            <td>Шт</td>
            <td>Всего</td>
          </tr>
          <CabinetOrderTableProduct
            v-for="product in orderProducts"
            :product="product"
            :key="product.uid"
          />
        </table>
      </div>
      <div class="row m-0">
        <div class="col-md-6 col-xs-12 wow fadeIn overall-text">
          <div class="pull-left text-color">Всего</div>
          <div class="pull-right"><strong>{{order.total}} ₽</strong></div>
          <div class="clearfix"></div>
          <hr>
          <div class="clearfix"></div>
          <div class="pull-left text-color"> Скидка по промокоду</div>
          <div class="pull-right"><strong>{{order.discount}} ₽</strong></div>
          <div class="clearfix"></div>
          <hr>
          <div class="clearfix"></div>
          <div class="pull-left text-color"> Доставка</div>
          <div class="pull-right"><strong>{{order.delivery_price}} ₽</strong></div>
          <div class="clearfix"></div>
          <hr>
          <div class="clearfix"></div>
          <div class="pull-left text-color"><strong>Всего со скидкой</strong></div>
          <div class="pull-right"><strong>{{order.overall}} ₽</strong></div>
          <div class="clearfix"></div>
          <hr>
        </div>
        <div class="col-md-6 col-xs-12 wow fadeIn thanks-text pl-3">
          <h1 class="mt-3">Адрес доставки</h1>
          <div class="mt-3"><strong>ФИО: </strong> {{order.last_name}} {{order.first_name}} {{order.second_name}}</div>
          <div class="mt-2 mt-md-3"><strong>Адрес: </strong> {{order.postal_code}}, {{order.country_name}}, {{order.region_name}}, {{order.city_name}}, {{order.address}}</div>
          <div class="mt-2 mt-md-3"><strong>Телефон: </strong> {{order.phone_number}}</div>
          <div class="mt-2 mt-md-3"><strong>Email: </strong> {{order.email}}</div>
          <div class="mt-2 mt-md-3" v-if="delivered"><strong>Трек номер: </strong> {{order.tracking_number}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {apiService} from "@/common/api.service.js";
  import CabinetOrderTableProduct from "@/components/CabinetOrderTableProduct"

  export default {
    name: "CabinetOrderCard",
    components: {
      CabinetOrderTableProduct
    },
    props: {
      order: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        show: false,
        orderProducts: [],
      }
    },
    computed: {
      delivered() {
        if ((this.order.status === 'Отправлен') || (this.order.status === 'Доставлен')) {
          return true
        } else {
          return false
        }
      },
      statusClass() {
        if (this.order.status === 'Оплачен') {
          return 'text-paid'
        } else if (this.order.status === 'Отправлен') {
          return 'text-sent'
        } else if (this.order.status === 'Доставлен') {
          return 'text-delivered'
        } else if (this.order.status === 'Отменен') {
          return 'text-cancelled'
        }
      }
    },
    methods: {
      getOrderProducts() {
        let endpoint = "/api/getorderproducts/" + this.order.id;
        let auth = this.$store.state.a_token;
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            if (data) {
              this.orderProducts = data
            }
          }, this);
      },
    },
    created() {
      this.getOrderProducts()
    }
  }
</script>

<style scoped>
  .text-paid {
    color: #4e84d8;
  }

  .text-sent {
    color: #21b400;
  }

  .text-delivered {
    color: #dfb859;
  }

  .text-cancelled {
    color: #f61900;
  }

  .order-box {
    background: rgb(240, 240, 240);
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    z-index: 2;
  }

  .order-box:hover {
    box-shadow: inset 0px 0px 10px 4px rgba(155, 155, 155, 0.2);
    transition: box-shadow 0.2s ease-in-out;
  }

  .order-details {
    border: solid #c7c7c7 1px;
    border-top: none;
    margin-top: -25px;
    padding-bottom: 15px;
  }

  .product-table {
    width: 90%;
    margin: 50px 5% 25px 5%;
    border-style: hidden;
    border-collapse: separate;
    border-spacing: 0 15px;
  }

  .thanks-text {
    text-align: left;
  }

  .thanks-text h1 {
    font-size: 20px;
  }

  .thanks-text a {
    text-align: center;
  }

  .rotate-90 {
    transform: rotate(180deg);
  }

  .drop-arrow {
    width: 20px;
  }

  .overall-text {
    margin-top: 57px;
  }

  @media (max-width: 1060px) {
    .row-fluid img {
      width: 20px !important;
    }
  }

  @media (max-width: 767px) {
    .order-box h1, .status {
      font-size: 19px;
    }

    .order-box h3 {
      font-size: 15px;
    }

    .table-h td {
      font-size: 10px;
    }

    hr {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .overall-text {
      margin-top: 20px;
    }

  }


</style>