<template>
  <div class="row shop-row mx-0 mt-5">
    <input @change="editShopImage($event)" :ref="'editShopImage'+shop.id" type="file" hidden>
    <div class="col-md-5 wow fadeInLeft">
      <img class="shop-image" :src="shop.image" @click="$refs['editShopImage'+shop.id].click()">
    </div>
    <div class="col-md-7  wow fadeInRight">
      <div class="about-text">
        <form @submit.prevent="editShopInfo()">
          <div class="form-group">
            <div class="row mx-0 align-items-center">
              <h3 class="text-muted mb-0">Город</h3>
              <input required v-model="shop.city">
            </div>
          </div>
          <div class="form-group">
            <div class="row mx-0 align-items-center">
              <h3 class="text-muted mb-0">Адрес</h3>
              <input required v-model="shop.address">
            </div>
          </div>
          <div class="form-group">
            <div class="row mx-0 align-items-center">
              <h3 class="text-muted mb-0">Карта</h3>
              <input required v-model="shop.map">
            </div>
          </div>
          <div class="d-flex">
            <button type="submit" class="filter2 mt-3 ml-auto">Изменить</button>
          </div>
        </form>
        <h3 class="text-muted">Режим работы</h3>
        <ManagerInfoShopSchedule
        v-for="schedule in shop.schedules"
        :schedule="schedule"
        :key="schedule.id"
        v-on:getShops="$emit('getShops')"
        />
        <div class="d-flex">
          <button class="filter2 mt-3 mx-auto" data-toggle="modal" :data-target="addScheduletarget">Добавить расписание</button>
        </div>
      </div>
    </div>
    <div class="modal fade" :id="addScheduleId" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeAddSchedule" data-dismiss="modal">X<span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Добавить расписание</h4>
          </div>
          <div class="modal-body">
            <form class="form-horizontal"
                  @submit.prevent="addSchedule">
              <div class="form-group my-2">
                <label class="mb-1">Дни
                  <input required v-model="new_schedule_days">
                </label>
              </div>
              <div class="form-group my-2">
                <label class="mb-1">Часы
                  <input required v-model="new_schedule_hours">
                </label>
              </div>
              <div class="form-group my-3 mb-5">
                <button type="submit" class="btn btn-default button-1 float-right">Сохранить</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import {apiService} from "@/common/api.service";
  import ManagerInfoShopSchedule from "@/components/ManagerInfoShopSchedule";


  export default {
    name: "ManagerInfoShop",
    props: {
      shop: {
        type: Object,
        required: true
      },
    },
    computed: {
      addScheduleId() {
        return 'addSchedule' + this.shop.id
      },
      addScheduletarget() {
        return '#addSchedule' + this.shop.id
      }
    },
    data() {
      return {
        new_schedule_days: '',
        new_schedule_hours: ''
      }
    },
    components: {
      ManagerInfoShopSchedule
    },
    methods: {
      editShopImage(e) {
        let endpoint = "api/manager/shopinfo/" + this.shop.id + "/";
        let data = new FormData;
        data.append('image', e.target.files[0]);
        this.$store.commit('changeLoading');
        this.$http.patch(endpoint, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Token ' + this.$store.state.a_token,
          },
        }).then(data => {
          this.$store.commit('changeLoading');
          this.$emit('getShops');
        }, this)
      },
      editShopInfo() {
        let endpoint = "api/manager/shopinfo/" + this.shop.id + "/";
        let method = "PATCH";
        let data = {'city': this.shop.city, 'address': this.shop.address, 'map': this.shop.map};
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.$emit('getShops');
          })
      },
      addSchedule() {
        let endpoint = "api/manager/shopschedule/";
        let method = "POST";
        let data = {'shop': this.shop.id, 'days': this.new_schedule_days, 'hours': this.new_schedule_hours};
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.$emit('getShops');
            this.new_schedule_days = '';
            this.new_schedule_hours = '';
            this.$refs.closeAddSchedule.click();
          })
      }
    }
  }
</script>

<style scoped>

  .shop-image {
    width: 100%;
  }


</style>