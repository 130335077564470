<template>
  <div id="wrapper">
    <section>
      <div class="inner-bg">
        <div class="inner-head wow fadeInDown">
          <h3>О нас</h3>
        </div>
      </div>
    </section>
    <div class="container">
      <div class="shop-in">
        <div class="row">
          <div class="col-md-12">
            <!--breadcrumbs -->
            <div class="bread2">
              <ul>
                <li><a href="/">На главную</a>
                </li>
                <li>/</li>
                <li><a>О нас</a></li>
              </ul>
            </div>
            <!--breadcrumbs -->
          </div>
        </div>
        <div class="specific-content">
          <h2 class="wow fadeInDown mt-5 title-h">Об интернет-магазине "Амбервиль"</h2>
        </div>
        <div class="about-text wow fadeInDown" v-if="abouttexts.length > 0">
          <p>{{abouttexts[0].text}}</p>
          <div class="about-image-row wow justify-content-between fadeInDown mx-4">
            <div v-for="image in aboutimages">
              <img :src="image.image" alt="Янтарь и Серебро - Амбервиль">
            </div>
          </div>
          <div class="about-image-big wow fadeInDown d-md-none">
            <div><img v-if="aboutimages.length > 0" :src="aboutimages[0].image" alt="Янтарь и Серебро - Амбервиль"></div>
          </div>
          <p>{{abouttexts[1].text}}</p>

        </div>

        <div class="specific-content">
          <h2 class="wow fadeInDown mt-5 title-h">Наши магазины</h2>
          <div class="wow fadeIn text-style">
            <p>Все изделия из нашего каталога можно посмотреть в магазинах нашей розничной сети</p>
          </div>
        </div>
        <div class="row shop-row mx-0 mb-5" v-for="shop in shops">
          <div class="col-md-5 wow fadeInLeft">
            <img class="shop-image" :src="shop.image" :alt="'Магазин Янтарь и Серебро - Амбервиль - '+ shop.city + ' - ' + shop.address">
          </div>
          <div class="col-md-7  wow fadeInRight">
            <div class="about-text">
              <h1>{{shop.city}}</h1>
              <h2>{{shop.address}}</h2>
              <h3 class="text-muted">Режим работы</h3>
              <h3 v-for="schedule in shop.schedules">{{schedule.days}}: {{schedule.hours}}</h3>
              <div class="map-responsive">
                <iframe
                  :src="shop.map" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" class="mt-3" tabindex="0"></iframe>
              </div>
            </div>
          </div>
        </div>
        <h2 class="wow fadeInDown mt-5 title-h">Документы и свидетельства</h2>
        <div class="row mx-0 mb-5 docs wow fadeInDown">
          <div class="col-12 col-md-4 my-1" v-for="document in documents">
            <img :src="document.image" alt="Магазин Амбервиль - Юридическая информация">
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>

</template>

<script>
  import {apiService} from "@/common/api.service.js";
  import Footer from "@/components/Footer";

  export default {
    name: "About",
    components: {
      Footer
    },
    metaInfo: {
      title: 'О компании Амбервиль',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Все о компании Амбервиль. Адреса магазинов Янтарь и Серебро в Калининграде. График работы магазинов Янтарь и Серебро.'
        },
      ],
    },
    data() {
      return {
        abouttexts: [],
        aboutimages: [],
        shops: [],
        documents: [],
      }
    },
    methods: {
      getAboutTexts() {
        let endpoint = "/api/abouttext/";
        apiService(endpoint)
          .then(data => {
            this.abouttexts = data
          })
      },
      getAboutImages() {
        let endpoint = "/api/aboutimage/";
        apiService(endpoint)
          .then(data => {
            this.aboutimages = data
          })
      },
      getShops() {
        let endpoint = "/api/shops/";
        this.$store.commit('changeLoading');
        apiService(endpoint)
          .then(data => {
            this.shops = data
            this.$store.commit('changeLoading');
          })
      },
      getDocuments() {
        let endpoint = "/api/document/";
        this.$store.commit('changeLoading');
        apiService(endpoint)
          .then(data => {
            this.documents = data
            this.$store.commit('changeLoading');
          })
      },
    },
    created() {
      this.getAboutTexts();
      this.getAboutImages();
      this.getShops();
      this.getDocuments();
    }
  }
</script>

<style scoped>

  .about-text {
    text-align: justify;
  }

  .about-text p {
    margin-bottom: 20px;
    white-space: pre-wrap;
    text-align: justify;
  }

  .about-text h1 {
    margin-bottom: 0;
  }

  .shop-image {
    width: 100%;
  }

  .map-responsive {
    overflow: hidden;
    padding-bottom: 65%;
    position: relative;
    height: 0;
    margin-top: 0;
  }

  .map-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  .about-image-row {
    display: flex;
    margin-bottom: 30px;

  }

  .about-image-row img {
    width: 100%;
    border: 1px solid #ccc;
    box-shadow: 5px 5px 5px #999;

  }

  .docs img {
    width: 100%;
  }

  .shop-row:nth-child(even) {
    direction: rtl;
  }

  @media (max-width: 1499px) {
    .map-responsive {
      padding-bottom: 56.25%;
    }
  }

  @media (max-width: 991px) {
    .about-text {
      padding: 0;
    }

    .about-text h1 {
      font-size: 24px;
    }

    .about-text h3 {
      font-size: 15px;
      margin-bottom: 0;
    }

    .map-responsive {
      padding-bottom: 50%;
    }
  }

  @media (max-width: 767px) {
    .shop-image {
      display: none;
    }

    .about-text {
      padding: 0;
    }

    .about-image-row {
      display: none;
    }

    .about-image-big img {
      width: 100%;
      padding-bottom: 30px;
    }
  }

</style>