<template>
  <div class="modal fade" id="myModalHorizontal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <button type="button" class="close" ref="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
          <h4 class="modal-title">Войти</h4>
        </div>
        <!-- Modal Body -->
        <div class="modal-body">
          <form class="form-horizontal"
                id="login-form"
                @submit.prevent="validateLogin">
            <div class="form-group">
              <div class="col-sm-12">
                <input type="text" id="login_username" class="form-control" v-model="login_username" autocomplete="email" placeholder="Е-мэйл"/>
                <label for="login_username" class="text-danger" v-if="this.login_errors['login_username']">{{this.login_errors['login_username']}}</label>
              </div>
            </div>
            <div class="form-group">
              <div class="col-sm-12">
                <input type="password" id="login_password" class="form-control" v-model="login_password" autocomplete="current-password" placeholder="Пароль"/>
                <label for="login_password" class="text-danger" v-if="this.login_errors['login_password']">{{this.login_errors['login_password']}}</label>
                <label for="login_password" class="text-success" v-if="this.$store.state.username">Вы вошли как {{this.$store.state.username}}</label>
              </div>
            </div>
            <div class="form-group">
              <div class="col-sm-12">
                <button type="submit" class="btn btn-default button-1">Войти</button>
              </div>
            </div>
            <div class="form-group">
              <div class="col-sm-12">
                <p>
                  <a href="#" data-dismiss="modal" data-toggle="modal" data-target="#myModalHorizontal4">Восстановить пароль</a> ||
                  <a href="#" data-dismiss="modal" data-toggle="modal" data-target="#myModalHorizontal2">Регистрация</a>
                </p>
              </div>
            </div>
          </form>
        </div>
        <!-- Modal Footer -->
      </div>
    </div>
  </div>
</template>

<script>
  import {apiService} from "@/common/api.service.js";

  export default {
    name: "LoginForm",
    data() {
      return {
        login_username: "",
        login_password: "",
        login_errors: [],
      }
    },
    methods: {
      validateLogin() {
        this.login_errors = [];
        const validation = /[$%^*={}|<>]/;

        if (!this.login_password) {
          this.login_errors['login_password'] = 'Заполните это поле'
        } else if (this.login_password.match(validation)) {
          this.login_errors['login_password'] = 'Недопустимые символы'
        }

        if (!this.login_username) {
          this.login_errors['login_username'] = 'Заполните это поле'
        } else if (this.login_username.match(validation)) {
          this.login_errors['login_username'] = 'Недопустимые символы'
        }

        return this.submitLogin()
      },
      submitLogin() {
        if (Object.keys(this.login_errors).length === 0) {
          let endpoint = "/api/login/";
          let method = "POST";
          this.$store.commit('changeLoading');
          apiService(endpoint, method, {'username': this.login_username, 'password': this.login_password})
            .then(data => {
              if (data.a_token) {
                this.$store.dispatch('setUsername', data.username);
                this.$store.dispatch('setAToken', data.a_token);
                this.$cookie.set('token', data.a_token, {expires: 14});

                this.$refs.close.click()

              } else {
                this.login_errors = [];
                this.login_errors['login_password'] = 'Неверный e-mail или пароль'
              }
              this.$store.commit('changeLoading');
            }, this)
        }
      },
    }
  }
</script>

<style scoped>

</style>