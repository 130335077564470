<template>
    <form class="form-group mt-5 col-6" @submit.prevent="editTestimonial()">
      <h5 class="mt-4">
        <input class="testimonial-name" required v-model="testimonial.name"/>
      </h5>
      <textarea class="testimonial-area" required v-model="testimonial.text"></textarea>
      <div class="row justify-content-between mx-0 mt-3">
        <button type="submit" class="filter2">Изменить</button>
        <button class="filter2" @click="deleteTestimonial()">Удалить</button>
      </div>
    </form>
</template>

<script>
  import {apiService} from "@/common/api.service";

  export default {
    name: "ManagerInfoTestimonial",
    props: {
      testimonial: {
        type: Object,
        required: true
      },
    },
    methods: {
      editTestimonial() {
        let endpoint = "/api/manager/testimonial/" + this.testimonial.id + "/";
        let method = "PATCH";
        let data = {"name": this.testimonial.name, "text": this.testimonial.text};
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, auth)
          .then(
            this.$store.commit('changeLoading')
          );
      },
      deleteTestimonial() {
        let endpoint = "/api/manager/testimonial/"  + this.testimonial.id + "/";
        let method = "DELETE";
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, method, undefined, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.$emit('getTestimonials');
            }, this);
      }
    }

  }
</script>

<style scoped>

  .testimonial-name {
    font-size: 16px;
  }

  .testimonial-area {
    font-size: 14px;
    height: 150px;
    width: 100%;
    max-width: 100%;
  }
</style>