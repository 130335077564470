<template>
  <div id="wrapper">
    <section>
      <div class="inner-bg">
        <div class="inner-head wow fadeInDown">
          <h3>404</h3>
        </div>
      </div>
    </section>
    <div class="container">
      <div class="shop-in pl-0 pr-0">
        <div>
          <div class="bread2">
            <ul>
              <li>
                <router-link to="/">НА ГЛАВНУЮ</router-link>
              <li>/</li>
              <li>Страница не найдена</li>
            </ul>
          </div>
        </div>
        <div class="clearfix"></div>
        <div>
          <div class="contact-us wow fadeInDown bg-not-found">
            <div class="four"><img src="../assets/images/warning.svg" alt="Страница не найдена">
              <h3>404</h3>
              <router-link to="/" class="link-txt">Вернуться на главную <i class="fa fa-long-arrow-right" aria-hidden="true"></i></router-link>

            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="clearfix"></div>
    </div>
    <Footer
    />
  </div>
</template>

<script>
  import Footer from "@/components/Footer";

  export default {
    name: "NotFound",
    components: {
      Footer,
    },
    metaInfo: {
      title: 'Ошибка 404',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Страница не обнаружена'
        },
      ],
    },
  }
</script>


<style scoped>
  .bg-not-found {
    background: #e1e1e1;
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
</style>