<template>
  <div>
    <div id="wrapper">
      <!--page heading-->
      <section>
        <div class="inner-bg d-576">
          <div class="inner-head wow fadeInDown">
            <h3>Каталог</h3>
          </div>
        </div>
        <div class="inner-bg tag-header">
          <div>
            <h3>Каталог</h3>
          </div>
          <div class="container">
            <div class="tag-grid">
              <div class="tag-element">
                <a :href="'?search=Серьги'" @click.prevent="$store.commit('setSearchValue', 'Серьги');">
                  Серьги
                </a>
              </div>
              <div class="tag-element">
                <a :href="'?search=Кольца'" @click.prevent="$store.commit('setSearchValue', 'Кольца');">
                  Кольца
                </a>
              </div>
              <div class="tag-element">
                <a :href="'?search=Бусы'" @click.prevent="$store.commit('setSearchValue', 'Бусы');">
                  Бусы
                </a>
              </div>
              <div class="tag-element">
                <a :href="'?search=Подвески'" @click.prevent="$store.commit('setSearchValue', 'Подвески');">
                  Подвески
                </a>
              </div>
              <div class="tag-element">
                <a :href="'?search=Браслеты'" @click.prevent="$store.commit('setSearchValue', 'Браслеты');">
                  Браслеты
                </a>
              </div>
              <div class="tag-element">
                <a :href="'?search=Колье'" @click.prevent="$store.commit('setSearchValue', 'Колье');">
                  Колье
                </a>
              </div>
              <div class="tag-element">
                <a :href="'?search=Броши'" @click.prevent="$store.commit('setSearchValue', 'Броши');">
                  Броши
                </a>
              </div>
              <div class="tag-element">
                <a :href="'?search=Лечебные'" @click.prevent="$store.commit('setSearchValue', 'Лечебные');">
                  Лечебные
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--container-->
      <div class="container">
        <div class="shop-in">
          <!--breadcrumbs -->
          <div class="tag-breadcrumbs">
            <div class="tag-element" v-if="tags.length > 0" v-for="product_tag in tags">
              <a :href="'?tag='+product_tag.link" @click.prevent="setNewTag(product_tag)">
                {{product_tag.name}}
              </a>
            </div>
          </div>
          <div class="col-md-12">
            <div class="bread2">
              <ul>
                <li>
                  <router-link to="/">НА ГЛАВНУЮ</router-link>
                <li>/</li>
                <li>КАТАЛОГ</li>
              </ul>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="row">
            <!--left-side-->
            <div class="col-12 col-lg-4 left-side">
              <button data-toggle="collapse" id="mobile-menu" data-target="#div-open" class="menu-icon" ref="productsMenu"></button>
              <div class="clearfix"></div>
              <div id="div-open" class="collapse  wow fadeIn">
                <div class="row">
                  <div class="col-lg-12 col-md-6 col-sm-12">
                    <div class="cat-div  wow fadeIn">
                      <h2>ТОВАРЫ</h2>
                      <div class="Category">
                        <div id="accordion">
                          <div class="card-header">
                            <a :class="{highlited: activeGroup === 5}"
                               class="collapsed card-link"
                               data-toggle="collapse" @click="clickMenu(5)"
                               ref="group5"
                               href="#list5">
                              ЯНТАРНЫЕ УКРАШЕНИЯ
                            </a>
                          </div>
                          <div id="list5" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                              <CategoryList
                                :group="5"
                                :filter="filter"
                                @change.native="getProducts(); isActive = 1;"
                                @selectFilter="getProducts()"
                              />
                            </div>
                          </div>
                          <div class="card-header">
                            <a :class="{highlited: activeGroup === 1}"
                               class="collapsed card-link"
                               data-toggle="collapse" @click="clickMenu(1)"
                               ref="group1"
                               href="#list1">
                              ИЗДЕЛИЯ В СЕРЕБРЕ
                            </a>
                          </div>
                          <div id="list1" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                              <CategoryList
                                :group="1"
                                :filter="filter"
                                @change.native="getProducts(); isActive = 1;"
                                @selectFilter="getProducts()"
                              />
                            </div>
                          </div>
                          <div class="card-header">
                            <a :class="{highlited: activeGroup === 3}"
                               class="collapsed card-link"
                               data-toggle="collapse" @click="clickMenu(3)"
                               ref="group3"
                               href="#list3">
                              ИЗДЕЛИЯ В ЗОЛОТЕ
                            </a>
                          </div>
                          <div id="list3" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                              <CategoryList
                                :group="3"
                                :filter="filter"
                                @change.native="getProducts(); isActive = 1;"
                                @selectFilter="getProducts()"
                              />
                            </div>
                          </div>
                          <div class="card-header">
                            <a :class="{highlited: activeGroup === 4}"
                               class="collapsed card-link"
                               data-toggle="collapse" @click="clickMenu(4)"
                               ref="group4"
                               href="#list4">
                              ИЗДЕЛИЯ В ПОЗОЛОТЕ
                            </a>
                          </div>
                          <div id="list4" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                              <CategoryList
                                :group="4"
                                :filter="filter"
                                @change.native="getProducts(); isActive = 1;"
                                @selectFilter="getProducts()"
                              />
                            </div>
                          </div>
                          <div class="card-header">
                            <a :class="{highlited: activeGroup === 2}"
                               class="collapsed card-link"
                               data-toggle="collapse" @click="clickMenu(2)"
                               ref="group2"
                               href="#list2">
                              ИЗДЕЛИЯ В ПОСЕРЕБРЕНИИ
                            </a>
                          </div>
                          <div id="list2" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                              <CategoryList
                                :group="2"
                                :filter="filter"
                                @change.native="getProducts(); isActive = 1;"
                                @selectFilter="getProducts()"
                              />
                            </div>
                          </div>
                          <div class="card-header">
                            <a :class="{highlited: activeGroup === 6}"
                               class="collapsed card-link"
                               data-toggle="collapse" @click="clickMenu(6)"
                               ref="group6"
                               href="#list6">
                              ПОДАРКИ И СУВЕНИРЫ
                            </a>
                          </div>
                          <div id="list6" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                              <CategoryList
                                :group="6"
                                :filter="filter"
                                @change.native="getProducts(); isActive = 1;"
                                @selectFilter="getProducts()"
                              />
                            </div>
                          </div>
                          <div class="card-header">
                            <a :class="{highlited: activeGroup === 7}"
                               class="collapsed card-link"
                               data-toggle="collapse" @click="clickMenu(7)"
                               ref="group7"
                               href="#list7">
                              ЯНТАРНАЯ КОСМЕТИКА
                            </a>
                          </div>
                          <div id="list7" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                              <CategoryList
                                :group="7"
                                :filter="filter"
                                @change.native="getProducts(); isActive = 1;"
                                @selectFilter="getProducts()"
                              />
                            </div>
                          </div>
                          <div class="card-header">
                            <a :class="{highlited: activeGroup === 0}"
                               class="collapsed card-link"
                               data-toggle="collapse" @click="clickMenu(0)"
                               ref="group0"
                               href="#collapse7">
                              ВСЕ ТОВАРЫ
                            </a>
                          </div>
                          <div id="collapse7" class="collapse" data-parent="#accordion"></div>
                          <div class="pull-right d-sm-none mt-3" @click="toggleMenuMobile()">
                            <input type="button" class="filter2" value="Сохранить выбор">
                          </div>
                        </div>
                      </div>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-6 col-sm-12">
                    <div class="cat-div  wow fadeIn">
                      <h2>В пределах цены:</h2>
                      <label class="mt-3 mb-3">Диапазон:</label>
                      <vue-slider v-model="minMax"
                                  :min="minPrice"
                                  :max="maxPrice"
                                  :tooltip="'always'"
                                  :tooltip-placement="'bottom'"
                                  :tooltip-formatter="rub"
                                  :dotStyle="{'boxShadow': '0.5px 0.5px 2px 1px #f18a1a'}"
                                  :processStyle="{'background-color':'#f18a1a'}"
                                  :tooltipStyle="{'background':'transparent', 'color': '#6c757d', 'border-color':'lightgrey', 'border':'1px'}"
                                  :silent="true"
                                  class="mb-5 mx-3 mx-sm-2"
                      ></vue-slider>
                      <div class="pull-right" @click="toggleMenuMobile(); isActive = 1; getProducts(false); ">
                        <input type="button" class="filter2" value="Сортировать">
                      </div>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-6 col-sm-12 d-576">
                    <div class="cat-div  wow fadeIn">
                      <h2>ТОВАРЫ НЕДЕЛИ</h2>
                      <div id="carousel-example-generic" class="carousel slide" data-ride="carousel">
                        <!-- Wrapper for slides -->
                        <div class="carousel-inner">
                          <WeeklyDiscountSidebar
                            v-for="(product, index) in weeklyProducts"
                            :key="product.id"
                            :index="index"
                            :product="product"
                          />
                        </div>
                        <!-- Controls -->
                        <a class="left arrow-left" href="#carousel-example-generic" data-slide="prev"> <span
                          class="glyphicon glyphicon-chevron-left"></span> </a> <a class="right arrow-right"
                                                                                   href="#carousel-example-generic"
                                                                                   data-slide="next"> <span
                        class="glyphicon glyphicon-chevron-right"></span> </a></div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-6 col-sm-12 d-576">
                    <div class="cat-div  wow fadeIn">
                      <h2>Популярное</h2>
                      <div id="carousel-example-generic2" class="carousel slide" data-ride="carousel">
                        <!-- Wrapper for slides -->
                        <div class="carousel-inner">
                          <PopularSidebar
                            v-for="(product, index) in popularProducts"
                            :key="product.id"
                            :index="index"
                            :product="product"
                          />
                        </div>
                        <!-- Controls -->
                        <a class="left arrow-left" href="#carousel-example-generic2" data-slide="prev"> <span
                          class="glyphicon glyphicon-chevron-left"></span> </a> <a class="right arrow-right"
                                                                                   href="#carousel-example-generic2"
                                                                                   data-slide="next"> <span
                        class="glyphicon glyphicon-chevron-right"></span> </a></div>
                    </div>
                  </div>
                </div>
                <div class="clearfix"></div>
              </div>
              <div class="clearfix"></div>
            </div>
            <!--right-side-->
            <div class="col-12 col-lg-8 right-side">
              <div class="row">
                <div class="col-md-6 col-12 select-p">
                  <select @change="order=$event.target.value; getProducts(nullifyPrices=false)"
                          class="selectpicker select-1" data-style="btn-primary">
                    <option value="default">По умолчанию</option>
                    <option value="new">Самые новые</option>
                    <option value="min">Дешевле</option>
                    <option value="max">Дороже</option>
                    <option value="discount">Со скидкой</option>
                  </select>
                </div>
                <div class="d-md-none col-6">
                  <input type="button" class="filter2" @click="toggleMenuMobile" :value="activeGroupName">
                </div>
                <div class="col-12 col-sm-6 bread mt-3 mt-sm-0">
                  <div class="breadcrumbs">
                    <ul>
                      <ProductPagination
                        v-for="(page, index) in pages"
                        :key="index"
                        :page="page"
                        :pages="pages"
                        :activeGroup="activeGroup"
                        @click.native="isActive = page; bypassSearchReset = true; getProducts(nullifyPrices=false)"
                        :isActive="isActive"
                        :lower="false"
                      />
                    </ul>
                  </div>
                </div>
              </div>
              <div class="clearfix"></div>
              <div class="row justify-content-center">
                <ProductCard
                  v-for="product in products"
                  :product="product"
                  :key="product.id"
                />
                <div class="clearfix"></div>
              </div>
              <div class="clearfix"></div>
              <hr>
              <div class="breadcrumbs">
                <ul>
                  <ProductPagination
                    v-for="(page, index) in pages"
                    :key="index"
                    :page="page"
                    :pages="pages"
                    @click.native="isActive = page; bypassSearchReset = true; getProducts(nullifyPrices=false)"
                    :isActive="isActive"
                    :lower="true"
                  />
                </ul>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
      <div class="clearfix"></div>
      <Footer
      />
    </div>
  </div>
</template>

<script>
  import {apiService} from "@/common/api.service.js";
  import ProductCard from "@/components/ProductCard.vue";
  import ProductPagination from "@/components/ProductPagination.vue";
  import CategoryList from "@/components/CategoryList";
  import WeeklyDiscountSidebar from "@/components/WeeklyDiscountSidebar";
  import PopularSidebar from "@/components/PopularSidebar";
  import Footer from "@/components/Footer";
  import VueSlider from 'vue-slider-component'
  import 'vue-slider-component/theme/default.css'

  export default {
    name: "Products",
    components: {
      Footer,
      PopularSidebar,
      WeeklyDiscountSidebar,
      CategoryList,
      ProductPagination,
      ProductCard,
      VueSlider,
    },
    metaInfo: {
      title: 'Каталог',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Каталог ювелирных изделий, сувениров и украшений из янтаря. Бесплатная доставка по РФ от 3000 рублей. Каждую неделю - специальные предложения.'
        },
      ],
    },
    data() {
      return {
        category_groups: [],
        products: [],
        pages: Number,
        error: null,
        isActive: 1,
        filter: [],
        order: 'default',
        minPrice: 0,
        minValue: 0,
        maxPrice: 0,
        maxValue: 0,
        nullifyPrices: true,
        weeklyProducts: [],
        popularProducts: [],
        externalLink: false,
        bypassSearchReset: false,
        rub: '{value} руб.',
        tags: '',
        tag: '',
        tag_name: '',

      }
    },
    computed: {
      activeGroup() {
        return this.$store.state.activeGroup
      },

      searchValue() {
        return this.$store.state.search_value
      },

      productReload() {
        return this.$store.state.product_reload
      },

      ignoreProduct() {
        return this.$store.state.ignore_get_products
      },

      ignoreActive() {
        return this.$store.state.ignore_active_change
      },

      minMax: {
        get: function () {
          return [this.minValue, this.maxValue]
        },
        set: function (value) {
          this.minValue = value[0];
          this.maxValue = value[1];
        }
      },

      activeGroupName() {
        if (this.searchValue !== '') {
          return this.searchValue
        }
        else if (this.tag !== '') {
          return this.tag_name
        } else if (this.activeGroup !== 0) {
          if (this.category_groups.length > 0)
            return this.category_groups.find(e => e.id === this.activeGroup).name
        } else {
          return 'Все изделия'
        }
      },
    },

    watch: {

      productReload() {
        this.getProducts()
      },

      activeGroup: function (newVal, oldVal) {
        if (oldVal !== 0) {
          let list_old = 'list' + String(oldVal);
          document.querySelector('#' + list_old).classList.remove('show');
        }
        if (!this.ignoreActive) {
          this.$store.commit('setSearchValue', '');
          this.tag = '';
          this.isActive = 1;
          this.getProducts()
        } else {
          this.$store.commit('switchActiveIgnore')
        }
      },

      searchValue: function (newVal, oldVal) {
        if ((newVal !== '') && (this.products.length > 0)) {
          this.bypassSearchReset = true;
          this.isActive = 1;
          this.getProducts()
        }
      },
    },

    methods: {
      getRouteOptions() {
        if (this.$route.query.tag) {
          this.tag = this.$route.query.tag
          this.bypassSearchReset = true
          this.getTagName()
        }
        if (this.$route.query.group) {
          this.$store.commit('changeGroup', Number(this.$route.query.group))
        }
        if (this.$route.query.search) {
          if (this.$store.state.search_value !== this.$route.query.search) {
            this.bypassSearchReset = true
          }
          this.$store.commit('setSearchValue', this.$route.query.search)
        }
      },

      getCategoryGroups() {
        let endpoint = "/api/categorygroups/";
        apiService(endpoint)
          .then(data => {
            this.category_groups = data
          })
      },

      getProducts(nullifyPrices = true) {
        if (!this.ignoreProduct) {
          if (nullifyPrices === true) {
            this.nullifyPrices = true
          } else {
            this.nullifyPrices = false
          }
          let endpoint = "/api/products/";
          endpoint += ("?group=" + this.activeGroup + "&page=" + this.isActive + "&filter=" + this.filter
            + "&order=" + this.order + '&search=' + this.searchValue + '&tag=' + this.tag);
          if ((typeof this.minValue === "number") && (this.nullifyPrices === false)) {
            endpoint += "&minprice=" + this.minValue + "&maxprice=" + this.maxValue
          }
          if (this.bypassSearchReset) {
            this.bypassSearchReset = false
          } else {
            this.$store.commit('setSearchValue', '');
            this.tag = ''
          }
          this.$store.commit('changeLoading');
          apiService(endpoint)
            .then(data => {
              this.products = data.results;
              this.pages = Math.ceil(data.count / 15);
              this.minPrice = data.min_price.price;
              this.maxPrice = data.max_price.price;
              if (this.nullifyPrices === true) {
                this.minValue = this.minPrice;
                this.maxValue = this.maxPrice;
              }
              this.$store.commit('changeLoading');
            })
        } else {
          this.$store.commit('switchProductIgnore')
        }
      },

      getWeeklyProducts() {
        let endpoint = "/api/weeklyproducts/";
        apiService(endpoint)
          .then(data => {
            this.weeklyProducts = data;
          })
      },

      getPopularProducts() {
        let endpoint = "/api/popular/";
        apiService(endpoint)
          .then(data => {
            this.popularProducts = data;
          })
      },

      clickMenu(group) {
        if (this.activeGroup === group && this.tag === '') {
          this.$store.commit('setSearchValue', '');
          this.getProducts();
        } else {
          this.$store.commit('changeGroup', group);
          this.isActive = 1;
        }

      },

      toggleMenuMobile() {
        if (window.innerWidth < 768) {
          this.$refs.productsMenu.click();
          window.scrollTo(0, 0);
        }
      },

      expandCategoryList() {
        if (this.activeGroup !== 0) {
          let ref_name = 'group' + String(this.activeGroup);
          this.$refs[ref_name].click()
        }
      },

      fixSearchInitial() {
        if (this.$route.query.search) {
          this.$store.commit('changeGroup', 0);
        }
      },

      getTags() {
        let endpoint = '/api/gettags/';
        apiService(endpoint)
          .then(data => {
            this.tags = data
          })
      },

      getTagName() {
        let endpoint = '/api/gettagname/';
        endpoint += "?tag=" + this.tag;
        apiService(endpoint)
          .then(data => {
            this.tag_name = data.name
          })
      },

      setNewTag(tag) {
        this.$store.commit('setSearchValue', '');
        this.tag = tag.link;
        this.tag_name = tag.name;
        this.bypassSearchReset = true;
        this.getProducts();
      },

    },

    beforeMount() {
      this.fixSearchInitial()
    },

    created() {
      this.getRouteOptions();
      this.getCategoryGroups();
      this.getProducts();
      this.getWeeklyProducts();
      this.getPopularProducts();
      this.getTags();
    },
    mounted() {
      this.expandCategoryList()
    },

    beforeRouteLeave: function (to, from, next) {
      this.$store.commit('setSearchValue', '');
      next();
    },

  }
</script>

<style scoped>
  .highlited {
    color: white;
    background-color: black;
  }

  .select-p select {
    background: white;
  }

  .d-576 {
    display: none;
  }

  .tag-header {
    background: none;
    padding: 120px 0 20px 0;
  }

  .tag-header h3 {
    font-size: 25px;
    font-family: "Playfair Display", serif;
    padding: 0;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
  }

  .tag-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;

  }

  .tag-element {
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: solid 2px #dfb859;

  }

  .tag-element a {
    margin: 0;
    font-size: 15px;
  }

  .tag-breadcrumbs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 50px;
  }

  @media (max-width: 1499px) {
    .card-link {
      font-size: 12px;
    }
  }

  @media (max-width: 991px) {
    .card-link {
      font-size: 12px;
    }
  }

  @media (min-width: 1500px) {
    .left-side {
      flex: 0 0 25%;
      max-width: 25%;
    }

    .right-side {
      flex: 0 0 75%;
      max-width: 75%;
    }
  }

  @media (min-width: 576px) {
    .d-576 {
      display: block;
    }

    .tag-header {
      display: none;
    }

    .tag-element {
      width: 200px;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border: solid 2px #dfb859;

    }

    .tag-element a {
      margin: 0;
      font-size: 15px;
    }
  }

  @media (max-width: 991px) {
    .tag-element {
      width: 150px;
    }

  @media (max-width: 575px) {
    .tag-element {
      width: auto;
    }

    .tag-breadcrumbs {
      display: none;
    }
  }


  }

</style>