<template>
  <!--footer-->
  <div class="footer-css">
    <div class="contact-stripe">

    </div>
    <div class="clearfix"></div>
    <div class="footer-in">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-5 col-xl-4 wow fadeIn" data-wow-delay=".1s">
            <div class="my-1"><img class="footer-img" src="../assets/images/logo.png" alt="Купить янтарные серьги, подвески, браслеты, кольца"></div>
            <div class="about-b">
              <div class="footer-text">
                <p>В нашем интернет-магазине мы предоставляем Вам возможность купить ювелирные украшения и сувениры из янтаря.
                  В нашем каталоге вы найдете много изысканных изделий, которые с любовью изготовили лучшие мастера янтарного дела.
                  Богатый ассортимент украшений из золота, серебра и янтаря удовлетворит самого искушенного покупателя.
                  Будьте с нами и мы порадуем Вас и Ваших близких подарками из солнечного прибалтийского камня – янтаря.</p>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3 link-footer  wow fadeIn" data-wow-delay=".2s">
            <h2 @click.prevent="goToProducts(0)">
              <a href="/products">Каталог</a>
            </h2>
            <div class="row">
              <ul class="pull-left mr-sm-2 mb-2">
                <li @click.prevent="goToProducts(0)">
                  <a href="/products/?group=0"><i class="fa fa-stop" aria-hidden="true"></i> Все изделия</a>
                </li>
                <li @click.prevent="goToProducts(5)">
                  <a href="/products/?group=5"><i class="fa fa-stop" aria-hidden="true"></i> Янтарные украшения</a>
                </li>
                <li @click.prevent="goToProducts(1)">
                  <a href="/products/?group=1"><i class="fa fa-stop" aria-hidden="true"></i> Изделия в серебре</a>
                </li>
                <li @click.prevent="goToProducts(3)">
                  <a href="/products/?group=3"><i class="fa fa-stop" aria-hidden="true"></i> Изделия в золоте</a>
                </li>
                <li @click.prevent="goToProducts(4)">
                  <a href="/products/?group=4"><i class="fa fa-stop" aria-hidden="true"></i> Изделия в позолоте</a>
                </li>
                <li @click.prevent="goToProducts(2)">
                  <a href="/products/?group=2"><i class="fa fa-stop" aria-hidden="true"></i> Посеребрение</a>
                </li>
                <li @click.prevent="goToProducts(6)">
                  <a href="/products/?group=6"><i class="fa fa-stop" aria-hidden="true"></i> Подарки и сувениры</a>
                </li>
                <li @click.prevent="goToProducts(7)">
                  <a href="/products/?group=7"><i class="fa fa-stop" aria-hidden="true"></i> Янтарная косметика</a>
                </li>
              </ul>
              <ul class="pull-left second-list">
                <li @click.prevent="searchProducts('Серьги')">
                  <a href="/products/?search=Серьги"><i class="fa fa-stop" aria-hidden="true"></i> Серьги</a>
                </li>
                <li @click.prevent="searchProducts('Кольца')">
                  <a href="/products/?search=Кольца"><i class="fa fa-stop" aria-hidden="true"></i> Кольца</a>
                </li>
                <li @click.prevent="searchProducts('Колье')">
                  <a href="/products/?search=Колье"><i class="fa fa-stop" aria-hidden="true"></i> Колье</a>
                </li>
                <li @click.prevent="searchProducts('Браслеты')">
                  <a href="/products/?search=Браслеты"><i class="fa fa-stop" aria-hidden="true"></i> Браслеты</a>
                </li>
                <li @click.prevent="searchProducts('Бусы')">
                  <a href="/products/?search=Бусы"><i class="fa fa-stop" aria-hidden="true"></i> Бусы</a>
                </li>
                <li @click.prevent="searchProducts('Броши')">
                  <a href="/products/?search=Броши"><i class="fa fa-stop" aria-hidden="true"></i> Броши</a>
                </li>
                <li @click.prevent="searchProducts('Подвески')">
                  <a href="/products/?search=Подвески"><i class="fa fa-stop" aria-hidden="true"></i> Подвески</a>
                </li>
                <li @click.prevent="searchProducts('Лечебные')">
                  <a href="/products/?search=Лечебные"><i class="fa fa-stop" aria-hidden="true"></i> Лечебные</a>
                </li>
              </ul>
            </div>

            <div class="clearfix"></div>
          </div>
          <div class="col-sm-6 col-md-3 col-xl-2 link-footer  wow fadeIn" data-wow-delay=".2s">
            <h2>
              <router-link to="/about">О магазине</router-link>
            </h2>
            <ul class="pull-left">
              <li>
                <router-link to="/cart"><i class="fa fa-stop" aria-hidden="true"></i> Корзина</router-link>
              </li>
              <li>
                <router-link to="/about"><i class="fa fa-stop" aria-hidden="true"></i> О нас</router-link>
              </li>
              <li>
                <router-link to="/blog"><i class="fa fa-stop" aria-hidden="true"></i> Блог</router-link>
              </li>
              <li>
                <router-link to="/testimonials"><i class="fa fa-stop" aria-hidden="true"></i> Отзывы</router-link>
              </li>
              <li>
                <router-link to="/contacts"><i class="fa fa-stop" aria-hidden="true"></i> Контакты</router-link>
              </li>
              <li>
                <router-link to="/oferta"><i class="fa fa-stop" aria-hidden="true"></i> Оферта</router-link>
              </li>
              <li>
                <router-link to="/payment"><i class="fa fa-stop" aria-hidden="true"></i> Оплата</router-link>
              </li>
              <li>
                <router-link to="/delivery"><i class="fa fa-stop" aria-hidden="true"></i> Доставка</router-link>
              </li>
            </ul>
            <div class="clearfix"></div>
          </div>
          <div class="col-sm-12 col-xl-3 footer-address  wow fadeIn mt-3 mt-xl-0" data-wow-delay=".4s">
            <h2>Контакты</h2>
            <ul>
              <li><i class="fa fa-tag" aria-hidden="true"></i>ИП Ещенко Елена Евгеньевна</li>
              <li><i class="fa fa-map-marker" aria-hidden="true"></i>Калининград, проспект Мира 19-21</li>
              <li><i class="fa fa-phone" aria-hidden="true"></i> <a href="tel: +79062385616">+7 (9062) 38-56-16</a></li>
              <li><i class="fa fa-envelope-o" aria-hidden="true"></i> <a href="mailto:manager@ambervile.ru">manager@ambervile.ru</a></li>
            </ul>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="copyright">
      <div class="footer-in">
        <div class="pull-left"> © 2020.
          <router-link to="/">ambervile</router-link>
        </div>
        <div class="pull-right">
          <a href="https://www.djangoproject.com"><img src="../assets/images/django_logo.png"></a>
          <a href="https://vuejs.org"><img src="../assets/images/vue_logo.png"></a>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Footer",
    computed: {
      activeGroup() {
        return this.$store.state.activeGroup
      },
      searchValue() {
        return this.$store.state.search_value
      },
    },
    methods: {
      goToProducts(group) {
        if (this.activeGroup === group) {
          this.$store.commit('setSearchValue', '');
          this.reloadProducts();
        }
        this.$store.commit('changeGroup', group);
        if (this.$router.currentRoute.name !== 'Products') {
          if (this.activeGroup !== 0) {
            this.$store.commit('switchProductIgnore')
          }
          this.$router.push({name: 'Products'})
        } else {
          window.scrollTo(0, 0);
        }
      },
      searchProducts(value) {
        if (this.searchValue === value) {
          this.$store.commit('reloadProduct');
        }
        if (this.$router.currentRoute.name !== 'Products') {
          this.$store.commit('changeGroup', 0);
          this.$router.push({name: 'Products', query: { search: value }})
        } else {
          this.$store.commit('setSearchValue', value);
          if (this.activeGroup !== 0) {
            this.$store.commit('changeGroup', 0);
            this.$store.commit('switchActiveIgnore')
          }
          window.scrollTo(0, 0);
        }
      },
      reloadProducts() {
        this.$store.commit('reloadProduct');
      },
    }
  }
</script>

<style scoped>
  .footer-css {
    padding-top: 25px;
  }

  .footer-img {
    height: 50px;
  }

  .pull-right img {
    width: 25px;
  }

  h2 a {
    color: white;
  }

  .link-footer h2 {
    margin-left: 0;
  }

  .link-footer ul {
    margin-left: 0 !important;
  }

  .row .pull-left {
    width: auto;
  }
  

  @media (max-width: 1499px) {
    .second-list {
      margin-top: 0;
    }
  }

  @media (max-width: 767px) {
    .link-footer li {
      margin: 0 0 6px 0
    }
  }
</style>