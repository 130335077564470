<template>
  <div>
    <div class="row mx-0 my-5 collection-new">
      <a data-toggle="modal" data-target="#addCollection">Создать коллекцию</a>
    </div>
    <h1 class="text-center">Коллекции</h1>
    <div class="breadcrumbs">
      <ul>
        <ProductPagination
          v-for="(page, index) in pages"
          :page="page"
          :key="index"
          :pages="pages"
          @click.native="isActive = page; getCollections()"
          :isActive="isActive"
        />
      </ul>
    </div>
    <div v-for="collection in collections" class="my-5">
      <h2 class="text-center mb-3">{{collection.name}}</h2>
      <div class="table-responsive wow fadeIn bordered p-2">
        <table class="table product-table">
          <tr class="table-h">
            <td>&nbsp;</td>
            <td>Артикул</td>
            <td>Название</td>
            <td>Вариант</td>
            <td>Удалить</td>
          </tr>
          <ManagerCollectionTableProduct
            v-for="product in collection.products"
            :product="product"
            :key="product.id"
            :collection="collection.id"
            v-on:getCollections="getCollections"
          />
        </table>
        <div class="row justify-content-between collection-menu mx-0 mt-5 mb-2">
          <a data-toggle="modal" data-target="#addToCollection" @click="collection_to_add = collection.id">Добавить в коллекцию</a>
          <a data-toggle="modal" data-target="#deleteCollection" @click="collection_to_delete = collection.id">Удалить коллекцию</a>
        </div>
      </div>
    </div>
    <div class="modal fade" id="addCollection" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeAddCollection" data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Создать новую коллекцию</h4>
          </div>
          <div class="modal-body text-center">
            <form class="form-horizontal"
                  @submit.prevent="createNewCollection">
              <div class="form-group">
                <input type="text" class="form-control" placeholder="Введите название коллекции" required v-model="new_collection">
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <button type="submit" class="btn btn-default button-1 ">Создать</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="addToCollection" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeAddToCollection" data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Добавить товар в коллекцию</h4>
          </div>
          <div class="modal-body text-center">
            <form class="form-horizontal"
                  @submit.prevent="addToCollection">
              <div class="form-group">
                <h3>Выберите изделие</h3>
                <select
                  class="js-countries mt-4"
                  v-model="collection_product_to_add">
                  <option value="" selected="selected" disabled>Выберите изделие</option>
                  <option v-for="product_item in product_list" :value="product_item.id">{{product_item.uid}} - {{product_item.short_name}}</option>
                </select>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <button type="submit" class="btn btn-default button-1">Сохранить</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="deleteCollection" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeDeleteCollection" data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Удалить коллекцию?</h4>
          </div>
          <div class="modal-body text-center">
            <form class="form-horizontal"
                  @submit.prevent="deleteCollection">
              <div class="form-group">
                <div class="col-sm-12">
                  <button type="submit" class="btn btn-default button-1 ">Удалить</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="breadcrumbs">
      <ul>
        <ProductPagination
          v-for="(page, index) in pages"
          :page="page"
          :key="index"
          :pages="pages"
          @click.native="isActive = page; getCollections()"
          :isActive="isActive"
        />
      </ul>
    </div>
  </div>
</template>

<script>
  import {apiService} from "@/common/api.service.js";
  import ManagerCollectionTableProduct from "@/components/ManagerCollectionTableProduct"
  import ProductPagination from "@/components/ProductPagination.vue";

  export default {
    name: "ManagerCollection",
    components: {
      ManagerCollectionTableProduct,
      ProductPagination
    },
    data() {
      return {
        pages: Number,
        isActive: 1,
        collections: [],
        product_list: [],
        collection_to_add: undefined,
        collection_product_to_add: '',
        collection_to_delete: undefined,
        new_collection: undefined,
      }
    },
    methods: {
      getCollections() {
        let endpoint = "api/manager/collection/";
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        endpoint += ("?page=" + this.isActive);
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.collections = data.results;
            this.pages = Math.ceil(data.count / 10);
            this.$store.commit('changeLoading');
          })
      },
      getProductList() {
        let endpoint = "api/manager/productsimplelist/";
        let auth = this.$store.state.a_token;
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.product_list = data;
          })
      },
      createNewCollection() {
        let endpoint = "api/manager/collection/";
        let auth = this.$store.state.a_token;
        let method = "POST";
        let data = {
          "name": this.new_collection
        };
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.getCollections();
            this.$refs.closeAddCollection.click()
          })
      },
      addToCollection() {
        let endpoint = "api/manager/collection/" + this.collection_to_add + '/' + "add_product/";
        let auth = this.$store.state.a_token;
        let method = "PATCH";
        let data = {"id": this.collection_product_to_add};
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.getCollections();
          })
      },
      deleteCollection() {
        let endpoint = "api/manager/collection/" + this.collection_to_delete + '/';
        let auth = this.$store.state.a_token;
        let method = "DELETE";
        this.$store.commit('changeLoading');
        apiService(endpoint, method, undefined, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.getCollections();
            this.$refs.closeDeleteCollection.click()
          })
      }
    },
    created() {
      this.getCollections();
      this.getProductList()
    }
  }
</script>

<style scoped>
  .bordered {
    border: solid 1px black;
  }

  .collection-new a {
    text-align: center;
    background-color: white;
    font-weight: 600;
    color: #000;
    font-family: 'Open Sans', sans-serif;
    width: 233px;
    height: 60px;
    text-transform: uppercase;
    float: right;
    display: block;
    border: solid 2px #dfb859;
    line-height: 58px;
    margin: 0;
  }

  .collection-new a:hover {
    background: #222;
    border: solid 2px #222;
    color: #fff;
  }

  .collection-menu a {
    text-align: center;
    background-color: white;
    font-weight: 600;
    color: #000;
    font-family: 'Open Sans', sans-serif;
    width: 220px;
    height: 45px;
    text-transform: uppercase;
    display: block;
    border: solid 2px #dfb859;
    line-height: 45px;
    margin: 0;
  }

  .collection-menu a:hover {
    background: #222;
    border: solid 2px #222;
    color: #fff;
  }

</style>