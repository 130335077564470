<template>
  <div>
    <h1 class="text-center my-5">Отзывы</h1>
    <div class="row">
      <ManagerInfoTestimonial
        v-for="testimonial in testimonials"
        :testimonial="testimonial"
        :key="testimonial.id"
        v-on:getTestimonials="getTestimonials()"
      />
    </div>
    <div class="add-new row justify-content-center my-4">
      <a data-toggle="modal" data-target="#addTestimonial">Добавить отзыв</a>
    </div>
    <h1 class="text-center my-5">О компании</h1>
    <div class="about-text wow fadeInDown">
      <form v-if="abouttexts.length > 0" @submit.prevent="editAboutText(1)">
        <div class="form-group">
          <textarea class="about-textarea" required v-model="abouttexts[0].text"></textarea>
          <div class="d-flex">
            <button class="filter2 mt-3 ml-auto">Изменить</button>
          </div>
        </div>
      </form>
      <div class="about-image-row wow justify-content-between fadeInDown mx-4">
        <input @change="editAboutImage($event)" ref="editAboutImage" type="file" hidden>
        <div v-for="image in aboutimages">
          <img :src="image.image" @click="setImageToEdit(image.id)">
        </div>
      </div>
      <form v-if="abouttexts.length > 0" @submit.prevent="editAboutText(2)">
        <div class="form-group">
          <textarea class="about-textarea" required v-model="abouttexts[1].text"></textarea>
          <div class="d-flex">
            <button class="filter2 mt-3 ml-auto">Изменить</button>
          </div>
        </div>
      </form>
      <h1 class="text-center my-5">Реквизиты</h1>
      <div class="my-5" v-if="details">
        <form @submit.prevent="editDetails()">
          <div class="form-group details">
            <input class="w-100" required v-model="details.title" placeholder="Наименование">
            <div class="row mx-0 align-items-center">
              <h3 class="text-muted mb-0">ИНН:</h3>
              <input required v-model="details.INN">
            </div>
            <div class="row mx-0 align-items-center">
              <h3 class="text-muted mb-0">ОГРН:</h3>
              <input required v-model="details.OGRN">
            </div>
            <div class="row mx-0 align-items-center">
              <h3 class="text-muted mb-0">Юридический адрес:</h3>
              <input required v-model="details.address">
            </div>
            <div class="row mx-0 align-items-center">
              <h3 class="text-muted mb-0">E-mail:</h3>
              <input required v-model="details.email">
            </div>
            <div class="row mx-0 align-items-center">
              <h3 class="text-muted mb-0">Телефон:</h3>
              <input required v-model="details.phone">
            </div>
            <div class="row mx-0 align-items-center">
              <h3 class="text-muted mb-0">Отображение телефона:</h3>
              <input required v-model="details.phone_display">
            </div>
            <div class="d-flex">
              <button type="submit" class="filter2 mt-3 ml-auto">Изменить</button>
            </div>
          </div>
        </form>
      </div>
      <h1 class="text-center my-5">Документы</h1>
      <div class="row mx-0 mb-5 docs">
        <input @change="editDocument($event)" ref="editDocument" type="file" hidden>
        <div class="col-12 col-md-4 my-1" v-for="document in documents">
          <img :src="document.image" @click="setDocumentToEdit(document.id)">
        </div>
      </div>
      <h1 class="text-center my-5">Магазины</h1>
      <ManagerInfoShop
        v-for="shop in shops"
        :shop="shop"
        :key="shop.id"
        v-on:getShops="getShops"
      />
    </div>
    <div class="modal fade" id="addTestimonial" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeAddTestimonial" data-dismiss="modal">X<span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Добавить отзыв</h4>
          </div>
          <div class="modal-body">
            <form class="form-horizontal"
                  @submit.prevent="addTestimonial">
              <div class="form-group my-2">
                <label class="mb-1">Имя
                  <input required v-model="new_testimonial_name">
                </label>
              </div>
              <div class="form-group my-2">
                <label class="mb-1">Отзыв
                  <textarea required v-model="new_testimonial_text"></textarea>
                </label>
              </div>
              <div class="form-group my-3 mb-5">
                <button type="submit" class="btn btn-default button-1 float-right">Сохранить</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {apiService} from "@/common/api.service";
  import ManagerInfoTestimonial from "@/components/ManagerInfoTestimonial";
  import ManagerInfoShop from "@/components/ManagerInfoShop";

  export default {
    name: "ManagerInfo",
    components: {
      ManagerInfoTestimonial,
      ManagerInfoShop
    },
    data() {
      return {
        testimonials: [],
        new_testimonial_name: '',
        new_testimonial_text: '',
        abouttexts: [],
        aboutimages: [],
        about_image_to_edit: undefined,
        details: undefined,
        documents: [],
        document_to_edit: undefined,
        shops: [],

      }
    },
    methods: {
      getTestimonials() {
        let endpoint = "api/manager/testimonial/";
        let auth = this.$store.state.a_token;
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.testimonials = data;
          })
      },
      addTestimonial() {
        let endpoint = "api/manager/testimonial/";
        let method = "POST";
        let data = {'name': this.new_testimonial_name, 'text': this.new_testimonial_text}
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.getTestimonials();
            this.new_testimonial_name = '';
            this.new_testimonial_text = '';
            this.$refs.closeAddTestimonial.click();
          })
      },
      getAboutTexts() {
        let endpoint = "api/manager/abouttext/";
        let auth = this.$store.state.a_token;
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.abouttexts = data;
          })
      },
      editAboutText(id) {
        let endpoint = "api/manager/abouttext/" + id + "/";
        let method = "PATCH";
        let data = {'name': this.abouttexts[id - 1].name, 'text': this.abouttexts[id - 1].text};
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, auth)
          .then(data => {
            this.$store.commit('changeLoading');
          })
      },
      getAboutImages() {
        let endpoint = "api/manager/aboutimage/";
        let auth = this.$store.state.a_token;
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.aboutimages = data;
          })
      },
      setImageToEdit(id) {
        this.about_image_to_edit = id;
        this.$refs.editAboutImage.click()
      },
      editAboutImage(e) {
        let endpoint = "api/manager/aboutimage/" + this.about_image_to_edit + "/";
        let data = new FormData;
        data.append('image', e.target.files[0]);
        this.$store.commit('changeLoading');
        this.$http.patch(endpoint, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Token ' + this.$store.state.a_token,
          },
        }).then(data => {
          this.$store.commit('changeLoading');
          this.getAboutImages();
        }, this)
      },
      getDetails() {
        let endpoint = "api/manager/companydetails/";
        let auth = this.$store.state.a_token;
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.details = data[0];
          })
      },
      editDetails() {
        let endpoint = "api/manager/companydetails/1/";
        let method = "PATCH";
        let data = {
          'title': this.details.title, 'INN': this.details.INN, 'OGRN': this.details.OGRN, 'address': this.details.address, 'email': this.details.email,
          'phone': this.details.phone, 'phone_display': this.details.phone_display
        };
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, auth)
          .then(data => {
            this.$store.commit('changeLoading');
          })
      },
      getDocuments() {
        let endpoint = "api/manager/document/";
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.documents = data;
          })
      },
      setDocumentToEdit(id) {
        this.document_to_edit = id;
        this.$refs.editDocument.click()
      },
      editDocument(e) {
        let endpoint = "api/manager/document/" + this.document_to_edit + "/";
        let data = new FormData;
        data.append('image', e.target.files[0]);
        this.$store.commit('changeLoading');
        this.$http.patch(endpoint, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Token ' + this.$store.state.a_token,
          },
        }).then(data => {
          this.$store.commit('changeLoading');
          this.getDocuments();
        }, this)
      },
      getShops() {
        let endpoint = "api/manager/shopinfo/";
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, undefined, undefined, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.shops = data;
          })
      },
    },
    created() {
      this.getTestimonials();
      this.getAboutTexts();
      this.getAboutImages();
      this.getDetails();
      this.getDocuments();
      this.getShops();
    }
  }
</script>

<style scoped>
  .add-new a {
    text-align: center;
    background-color: white;
    font-weight: 600;
    color: #000;
    font-family: 'Open Sans', sans-serif;
    width: 180px;
    height: 60px;
    text-transform: uppercase;
    display: block;
    border: solid 2px #dfb859;
    line-height: 60px;
    margin: 0;
  }

  .add-new a:hover {
    background: #222;
    border: solid 2px #222;
    color: #fff;
  }

  textarea {
    font-size: 14px;
    height: 150px;
    width: 100%;
    max-width: 100%;
    padding: 5px;
  }

  .about-textarea {
    height: 300px;
  }

  .about-image-row {
    display: flex;
    margin-bottom: 30px;

  }

  .about-image-row img {
    width: 100%;
    border: 1px solid #ccc;
    box-shadow: 5px 5px 5px #999;

  }

  .details input {
    font-size: 16px;
    display: flex;
    flex: 1;
  }

  .docs img {
    width: 100%;
  }

</style>