<template>
  <div id="wrapper">
    <!--page heading-->
    <section>
      <div class="inner-bg">
        <div class="inner-head wow fadeInDown">
          <h3>Регистрация</h3>
        </div>
      </div>
    </section>
    <!--page heading-->
    <!--container-->
    <div class="container">
      <div class="shop-in pl-0 pr-0">
        <div>
          <div class="bread2">
            <ul>
              <li>
                <router-link to="/">На главную</router-link>
              </li>
              <li>/</li>
              <li>Регистрация</li>
            </ul>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="thanks-bg">
          <div class="thanks-text">
            <h2 class="wow fadeIn error-heading">Благодарим за регистрацию</h2>
            <h3 class="wow fadeIn">На Вашу электронную почту было отправлено письмо с ссылкой активации<br> Перейдите по ссылке в письме, чтобы завершить регистрацию</h3>
            <div>
              <router-link to="/" class="wow fadeInLeft">Вернуться на главную</router-link>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="clearfix"></div>
    </div>
    <!--container-->
    <div class="clearfix"></div>
    <!--footer-->
    <Footer
    />
  </div>
</template>

<script>

  import Footer from "@/components/Footer";

  export default {
    name: "Registered",
    components: {
      Footer,
    },
    metaInfo: {
      title: 'Благодарим за регистрацию',
    },
  }
</script>

<style scoped>
  .error-heading {
    margin-top: 10%;
  }

  @media (max-width: 1499px) {
    .thanks-bg {
      min-height: 480px;
    }
  }

  @media (max-width: 991px) {
    .thanks-bg {
      min-height: 400px;
    }
  }

    @media (max-width: 771px) {
    .thanks-bg {
      background-size: 100% 100%;
    }
  }

</style>