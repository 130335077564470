<template>
  <div>
    <h1 class="text-center my-4">{{tag.name}}</h1>
    <div class="bordered">
      <div class="mx-3 my-4">
        <router-link class="text-muted" :to="{ path: '/products', query: { tag: tag.link }}">https://www.ambervile.ru/products?tag={{tag.link}}</router-link>
      </div>
      <div class="row m-3 justify-content-between" v-for="product in tag.products">
        <router-link :to="{ name: 'Detail', params: {id: product.id, slug: slug(product.short_name)}}">
          <h3 class="product-link">{{product.uid}} - {{product.short_name}}</h3>
        </router-link>
        <span class="delete" @click="deleteTagProduct(tag.id, product.id)" :title="'Удалить '+ product.short_name">X</span>
      </div>
      <div class="add-product row mx-3 justify-content-end my-3">
        <a data-toggle="modal" :data-target="dataTargetTag">Добавить</a>
      </div>
      <div class="add-product row mx-3 justify-content-between my-3" v-if="tag.name!=='Популярное'">
        <a data-toggle="modal" :data-target="dataTargetEditTag">Редактировать</a>
        <a data-toggle="modal" :data-target="dataTargetDeleteTag">Удалить</a>
      </div>
    </div>
    <div class="modal fade" :id="idTag" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeAddTagProduct" data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Добавить товар к {{tag.name}}</h4>
          </div>
          <div class="modal-body text-center">
            <form class="form-horizontal"
                  @submit.prevent="addTagProduct">
              <div class="form-group">
                <h3>Выберите изделие</h3>
                <select
                  class="js-countries mt-4"
                  v-model="product_to_add">
                  <option value="" selected="selected" disabled>Выберите изделие</option>
                  <option v-for="product_item in product_list" :value="product_item.id">{{product_item.uid}} - {{product_item.short_name}}</option>
                </select>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <button type="submit" class="btn btn-default button-1">Сохранить</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" :id="idEditTag" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeEditTag" data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Редактировать тэг {{tag.name}}</h4>
          </div>
          <div class="modal-body">
            <form class="form-horizontal"
                  @submit.prevent="editTag">
              <div class="form-group my-2">
                <label class="mb-1">Название</label>
                <input type="text" class="form-control" placeholder="Название" v-model="tag.name">
              </div>
              <div class="form-group my-2">
                <label class="mb-1">Код</label>
                <input type="text" class="form-control" placeholder="Ссылка" v-model="tag.link">
              </div>
              <div class="form-group my-3 mb-5">
                <button type="submit" class="btn btn-default button-1 float-right">Сохранить</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" :id="idDeleteTag" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeDeleteTag" data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Удалить тэг {{tag.name}}?</h4>
          </div>
          <div class="modal-body text-center">
            <form class="form-horizontal"
                  @submit.prevent="deleteTag()">
              <div class="form-group">
                <div class="col-sm-12">
                  <button type="submit" class="btn btn-default button-1 ">Удалить</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {apiService} from "@/common/api.service.js";
  import {slugify} from "@/common/slugify.js";

  export default {
    name: "ManagerDiscountTag",
    props: {
      tag: {
        type: Object,
        required: true
      },
      product_list: {
        type: Array,
      },
    },
    computed: {
      dataTargetTag() {
        return '#tag' + this.tag.id
      },
      idTag() {
        return 'tag' + this.tag.id
      },
      dataTargetEditTag() {
        return '#edittag' + this.tag.id
      },
      idEditTag() {
        return 'edittag' + this.tag.id
      },
      dataTargetDeleteTag() {
        return '#deletetag' + this.tag.id
      },
      idDeleteTag() {
        return 'deletetag' + this.tag.id
      },
    },
    data() {
      return {
        product_to_add: "",
      }
    },
    methods: {
      slug(text) {
        return slugify(text)
      },
      deleteTagProduct(tag, product) {
        let endpoint = "api/manager/producttag/" + tag + '/' + "remove_product/";
        let auth = this.$store.state.a_token;
        let method = "PATCH";
        let data = {"id": product};
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, auth)
          .then(data => {
            this.tag.products = this.tag.products.filter(function (el) {
              return el.id !== product;
            });
            this.$store.commit('changeLoading');
          })
      },
      addTagProduct() {
        let endpoint = "api/manager/producttag/" + this.tag.id + '/' + "add_product/";
        let auth = this.$store.state.a_token;
        let method = "PATCH";
        let data = {"id": this.product_to_add};
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.$emit('getTags');
            this.$refs.closeAddTagProduct.click()
          })
      },
      editTag() {
        let endpoint = "api/manager/producttag/" + this.tag.id + '/';
        let auth = this.$store.state.a_token;
        let method = "PATCH";
        let data = {"name": this.tag.name, "link": this.tag.link};
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.$emit('getTags');
            this.$refs.closeEditTag.click()
          })
      },
      deleteTag() {
        let endpoint = "api/manager/producttag/" + this.tag.id + '/';
        let auth = this.$store.state.a_token;
        let method = "DELETE";
        this.$store.commit('changeLoading');
        apiService(endpoint, method, undefined, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.$emit('getTags');
            this.$refs.closeDeleteTag.click()
          })
      }
    }
  }
</script>

<style scoped>
  .bordered {
    border: solid 1px black;
  }

  .add-product a {
    text-align: center;
    background-color: white;
    font-weight: 600;
    color: #000;
    font-family: 'Open Sans', sans-serif;
    width: 140px;
    height: 40px;
    text-transform: uppercase;
    display: block;
    border: solid 2px #dfb859;
    line-height: 38px;
    margin: 0;
  }

  .add-product a:hover {
    background: #222;
    border: solid 2px #222;
    color: #fff;
  }

  .delete {
    cursor: pointer;
    font-weight: 900;
  }
</style>