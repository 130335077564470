<template>
  <div id="wrapper">
    <!--page heading-->
    <section>
      <div class="inner-bg">
        <div class="inner-head wow fadeInDown">
          <h3>Оформление заказа</h3>
        </div>
      </div>
    </section>
    <!--page heading-->
    <!--container-->
    <div class="container">
      <div class="shop-in">
        <!--breadcrumbs -->
        <div class="bread2">
          <ul>
            <li>
              <router-link to="/">На главную</router-link>
            </li>
            <li>/</li>
            <li>
              <router-link to="/products">Каталог</router-link>
            </li>
            <li>/</li>
            <li>
              <router-link to="/cart">Корзина</router-link>
            </li>
            <li>/</li>
            <li>Оформление заказа</li>
          </ul>
        </div>
        <!--breadcrumbs -->
        <div class="clearfix"></div>
        <div>
          <div class="row">
            <!--left-side-->
            <div class="col-md-6 col-sm-12 wow fadeIn">
              <div class="left-bg">
                <CheckoutProductCard
                  v-for="product in products"
                  :product="product"
                  :key="product.id"
                />
                <div class="clearfix"></div>
                <div class="col-md-12 mb-5 mb-md-0 pb-1 wow fadeIn">
                  <hr>
                  <div class="pull-left text-color">Всего</div>
                  <div class="pull-right"><strong>{{total}} руб.</strong></div>
                  <div class="clearfix"></div>
                  <hr>
                  <div class="clearfix"></div>
                  <div class="pull-left text-color"> Скидка по промокоду</div>
                  <div class="pull-right"><strong> {{discount}} руб.</strong></div>
                  <div class="clearfix"></div>
                  <hr>
                  <div class="clearfix"></div>
                  <div class="pull-left text-color"> Доставка</div>
                  <div class="pull-right"><strong> {{delivery_price}} руб.</strong></div>
                  <div class="clearfix"></div>
                  <hr>
                  <div class="clearfix"></div>
                  <div class="pull-left text-color"><strong>Всего со скидкой</strong></div>
                  <div class="pull-right"><strong>{{overall}} руб.</strong></div>
                  <div class="clearfix"></div>
                  <hr>
                </div>
              </div>
            </div>
            <!--right-side-->
            <div class="col-md-6 col-sm-12  wow fadeIn">
              <div class="clearfix"></div>
              <form class="right-form"
                    id="checkout"
                    @submit.prevent="validate"
              >
                <div class="col-lg-12">
                  <div class="title-form">
                    <h2>1. Информация о получателе</h2>
                  </div>
                </div>
                <div class="clearfix"></div>
                <div class="row mx-0 mb-5 desktop-form">
                  <div class="col-md-6 col-sm-6 col-xs-6">
                    <div class="form-group">
                      <input id="firstname" name="firstname" v-model="firstname" type="text" placeholder="*ИМЯ" maxlength="100">
                      <label for="firstname" class="text-danger" v-if="this.errors['firstname']">{{this.errors['firstname']}}</label>
                    </div>
                    <div class="form-group">
                      <input id="secondname" name="secondname" v-model="secondname" type="text" placeholder="ОТЧЕСТВО" maxlength="150">
                      <label for="secondname" class="text-danger" v-if="this.errors['secondname']">{{this.errors['secondname']}}</label>
                    </div>
                    <div class="form-group">
                      <select
                        v-if="country_value === '' || country_value === 1"
                        v-model="region_value"
                        id="region" class="js-countries" @change="changeRegion">
                        <option disabled value="" selected="selected">Выберите регион</option>
                        <option v-for="region in regions" :value="region.id">{{region.name}}</option>
                      </select>
                      <input v-else id="region" v-model="region_value" name="region" type="text" placeholder="*РЕГИОН">
                      <label for="region" class="text-danger" v-if="this.errors['region']">{{this.errors['region']}}</label>
                    </div>
                    <div class="form-group">
                      <input id="zipcode" v-model="zipcode" name="zipcode" type="text" placeholder="*ИНДЕКС">
                      <label for="zipcode" class="text-danger" v-if="this.errors['zipcode']">{{this.errors['zipcode']}}</label>
                    </div>
                    <div class="form-group">
                      <input id="email" v-model="email" name="email" type="text" placeholder="*E-MAIL">
                      <label for="email" class="text-danger" v-if="this.errors['email']">{{this.errors['email']}}</label>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-6">
                    <div class="form-group">
                      <input id="lastname" v-model="lastname" name="lastname" type="text" placeholder="*ФАМИЛИЯ">
                      <label for="lastname" class="text-danger" v-if="this.errors['firstname']">{{this.errors['lastname']}}</label>
                    </div>
                    <div class="form-group">
                      <select v-model="country_value" id="country" class="js-countries" @change="changeCountry">
                        <option disabled value="" selected="selected">Выберите страну</option>
                        <option v-for="country in popular_countries" :value="country.id">{{country.name}}</option>
                        <option disabled>------------------</option>
                        <option v-for="country in countries" :value="country.id">{{country.name}}</option>
                      </select>
                      <label for="country" class="text-danger" v-if="this.errors['country']">{{this.errors['country']}}</label>
                    </div>
                    <div class="form-group">
                      <input v-if="this.another_city" v-model="city_value" id="city" name="city" type="text" placeholder="ГОРОД">
                      <select
                        v-else-if="country_value === '' || country_value === 1"
                        v-model="city_value"
                        id="city" class="js-countries" @change="changeCity">
                        <option disabled value="" selected="selected">Выберите город</option>
                        <option v-for="location in locations" :value="location.id">{{location.name}}</option>
                        <option value="0">Другой</option>
                      </select>
                      <input v-else v-model="city_value" name="city" id="city" type="text" placeholder="*ГОРОД">
                      <label for="city" class="text-danger" v-if="this.errors['city']">{{this.errors['city']}}</label>
                    </div>
                    <div class="form-group">
                      <input id="address" v-model="address" name="address" type="text" placeholder="*АДРЕС">
                      <label for="address" class="text-danger" v-if="this.errors['address']">{{this.errors['address']}}</label>
                    </div>
                    <div class="form-group">
                      <input id="phone" v-model="phone" name="phone" type="text" placeholder="*ТЕЛЕФОН">
                      <label for="phone" class="text-danger" v-if="this.errors['phone']">{{this.errors['phone']}}</label>
                    </div>
                  </div>
                </div>
                <div class="row mx-0 mb-5 mobile-form">
                  <div class="col-md-6 col-sm-6 col-xs-6">
                    <div class="form-group">
                      <input id="firstname" name="firstname" v-model="firstname" type="text" placeholder="*ИМЯ" maxlength="100">
                      <label for="firstname" class="text-danger" v-if="this.errors['firstname']">{{this.errors['firstname']}}</label>
                    </div>
                    <div class="form-group">
                      <input id="lastname" v-model="lastname" name="lastname" type="text" placeholder="*ФАМИЛИЯ">
                      <label for="lastname" class="text-danger" v-if="this.errors['firstname']">{{this.errors['lastname']}}</label>
                    </div>
                    <div class="form-group">
                      <input id="secondname" name="secondname" v-model="secondname" type="text" placeholder="ОТЧЕСТВО" maxlength="150">
                      <label for="secondname" class="text-danger" v-if="this.errors['secondname']">{{this.errors['secondname']}}</label>
                    </div>
                    <div class="form-group">
                      <select v-model="country_value" id="country" class="js-countries" @change="changeCountry">
                        <option disabled value="" selected="selected">Выберите страну</option>
                        <option v-for="country in popular_countries" :value="country.id">{{country.name}}</option>
                        <option disabled>------------------</option>
                        <option v-for="country in countries" :value="country.id">{{country.name}}</option>
                      </select>
                      <label for="country" class="text-danger" v-if="this.errors['country']">{{this.errors['country']}}</label>
                    </div>
                    <div class="form-group">
                      <select
                        v-if="country_value === '' || country_value === 1"
                        v-model="region_value"
                        id="region" class="js-countries" @change="changeRegion">
                        <option disabled value="" selected="selected">Выберите регион</option>
                        <option v-for="region in regions" :value="region.id">{{region.name}}</option>
                      </select>
                      <input v-else id="region" v-model="region_value" name="region" type="text" placeholder="*РЕГИОН">
                      <label for="region" class="text-danger" v-if="this.errors['region']">{{this.errors['region']}}</label>
                    </div>
                    <div class="form-group">
                      <input v-if="this.another_city" v-model="city_value" id="city" name="city" type="text" placeholder="*ГОРОД">
                      <select
                        v-else-if="country_value === '' || country_value === 1"
                        v-model="city_value"
                        id="city" class="js-countries" @change="changeCity">
                        <option disabled value="" selected="selected">Выберите город</option>
                        <option v-for="location in locations" :value="location.id">{{location.name}}</option>
                        <option value="0">Другой</option>
                      </select>
                      <input v-else v-model="city_value" name="city" id="city" type="text" placeholder="*ГОРОД">
                      <label for="city" class="text-danger" v-if="this.errors['city']">{{this.errors['city']}}</label>
                    </div>
                    <div class="form-group">
                      <input id="zipcode" v-model="zipcode" name="zipcode" type="text" placeholder="*ИНДЕКС">
                      <label for="zipcode" class="text-danger" v-if="this.errors['zipcode']">{{this.errors['zipcode']}}</label>
                    </div>
                    <div class="form-group">
                      <input id="address" v-model="address" name="address" type="text" placeholder="*АДРЕС">
                      <label for="address" class="text-danger" v-if="this.errors['address']">{{this.errors['address']}}</label>
                    </div>
                    <div class="form-group">
                      <input id="email" v-model="email" name="email" type="text" placeholder="*E-MAIL">
                      <label for="email" class="text-danger" v-if="this.errors['email']">{{this.errors['email']}}</label>
                    </div>
                    <div class="form-group">
                      <input id="phone" v-model="phone" name="phone" type="text" placeholder="*ТЕЛЕФОН">
                      <label for="phone" class="text-danger" v-if="this.errors['phone']">{{this.errors['phone']}}</label>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="title-form mb-4">
                    <h2>2. Выберите доставку </h2>
                    <span class="text-danger ml-3" v-if="this.errors['delivery']"> {{this.errors['delivery']}}</span>
                  </div>
                </div>
                <div class="clearfix"></div>
                <div class="row mx-3 mb-5">
                  <div class="col-4 text-center">
                    <label for="pochta" class="delivery-img" :class="{'selected-delivery': selected_delivery === 'pochta'}">
                      <img class="mb-3" src="../assets/images/pochta1class.jpg" alt="Доставка почтой"/>
                    </label>
                    <input type="radio" name="delivery" id="pochta" value="pochta" v-model="selected_delivery">
                    <p>Доставка почтой</p>
                    <p v-if="country_value === ''" class="text-muted">Выберите страну</p>
                    <div v-else-if="country_value !== 1">
                      <p>{{pochta_international_price}} руб.</p>
                      <p>7-28 дней</p>
                    </div>
                    <p v-else-if="country_value === 1 && total >= 3000" class="text-muted">Бесплатно</p>
                    <p v-else-if="country_value === 1 && region_value === ''" class="text-muted">Выберите регион</p>
                    <div v-else>
                      <p>{{pochta_price}} руб.</p>
                      <p>3-10 дней</p>
                    </div>
                  </div>
                  <div class="col-4 text-center">
                    <label for="ems" class="delivery-img" :class="{'selected-delivery': selected_delivery === 'ems'}">
                      <img class="mb-3" src="../assets/images/ems.jpg" alt="Доставка EMS"/>
                    </label>
                    <input type="radio" name="delivery" id="ems" value="ems" v-model="selected_delivery">
                    <p>Доставка курьером</p>
                    <p v-if="country_value === ''" class="text-muted">Выберите страну</p>
                    <div v-else-if="country_value !== 1">
                      <p>{{ems_international_price}} руб.</p>
                      <p>4-14 дней</p>
                    </div>
                    <p v-else-if="country_value === 1 && total >= 10000" class="text-muted">Бесплатно</p>
                    <p v-else-if="country_value === 1 && region_value === ''" class="text-muted">Выберите регион</p>
                    <div v-else>
                      <p>{{ems_price}} руб.</p>
                      <p>2-6 дней</p>
                    </div>
                  </div>
                  <div class="col-4 text-center">
                    <label v-if="(country_value === 1 || country_value === '') && total <= 10000" for="nalozh" class="delivery-img" :class="{'selected-delivery': selected_delivery === 'nalozh'}">
                      <img class="mb-3" src="../assets/images/pochta_nalozhennyy.jpg" alt="Доставка наложенным платежом"/>
                    </label>
                    <label v-else for="nalozh">
                      <img class="mb-3 disabled-choice" src="../assets/images/pochta_nalozhennyy.jpg" alt="Доставка наложенным платежом"/>
                    </label>
                    <input v-if="(country_value === 1 || country_value === '') && total <= 10000" type="radio" name="delivery" id="nalozh" value="nalozh" v-model="selected_delivery">
                    <p>Наложенный платеж</p>
                    <p v-if="total > 10000" class="text-muted">Доступно для заказов до 10000 руб.</p>
                    <p v-else-if="country_value === ''" class="text-muted">Выберите страну</p>
                    <p v-else-if="country_value !== 1" class="text-muted">Доступно только в РФ</p>
                    <p v-else-if="country_value === 1 && region_value === ''" class="text-muted">Выберите регион</p>
                    <div v-else>
                      <p>{{nalozh_price}} руб.</p>
                      <p>4-14 дней</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="title-form mb-4">
                    <h2>3. Выберите оплату</h2>
                    <span class="text-danger ml-3" v-if="this.errors['payment']"> {{this.errors['payment']}}</span>
                  </div>
                </div>
                <div class="clearfix"></div>
                <div class="row mx-3">
                  <div class="col-4 text-center">
                    <label for="cards" class="delivery-img" :class="{'selected-delivery': selected_payment === 'cards'}">
                      <img class="mb-3" src="../assets/images/cards.jpg" alt="Оплата банковской картой"/>
                    </label>
                    <input type="radio" name="payment" id="cards" value="cards" v-model="selected_payment">
                    <p>Банковская карта</p>
                  </div>
                  <div class="col-4 text-center">
                    <label v-if="country_value === 1 || country_value === ''" for="nalozh_d" class="delivery-img" :class="{'selected-delivery': selected_payment === 'nalozh_d'}">
                      <img class="mb-3" src="../assets/images/pochta_nalozhennyy.jpg" alt="Наложенный платеж"/>
                    </label>
                    <label v-else for="nalozh_d">
                      <img class="mb-3 disabled-choice" src="../assets/images/pochta_nalozhennyy.jpg" alt="Наложенный платеж"/>
                    </label>
                    <input v-if="country_value === 1 || country_value === ''" type="radio" name="payment" id="nalozh_d" value="nalozh_d" v-model="selected_payment">
                    <p v-if="total > 10000" class="text-muted">Доступно для заказов до 10000 руб.</p>
                    <p v-else-if="country_value === 1 || country_value === ''">Наложенный платеж</p>
                    <p v-else class="text-muted">Доступно только в РФ</p>
                  </div>
                </div>
                <div class="clearfix"></div>
                <div class="double-b"></div>
                <div class="clearfix"></div>
                <div class="col-lg-12">
                  <div class="ship2">
                    <input type="checkbox" v-model="mail_discounts">
                    Я хочу получать информацию о еженедельных скидках
                  </div>
                  <div class="clearfix"></div>
                  <div class="ship2 my-2">
                    <p class="text-muted">Нажимая оплатить вы принимаете
                      <router-link class="text-muted" to="oferta"><u>условия</u></router-link>
                      и
                      <router-link class="text-muted" to="policy"><u>пользовательское соглашение</u></router-link>
                    </p>
                  </div>
                  <div class="clearfix"></div>
                  <div class="buy-this mt-3">
                    <p class="text-danger" v-if="Object.keys(this.errors).length !== 0">Заполните все обязательные поля</p>
                    <button v-if="products.length !== 0 && selected_delivery!=='nalozh'" type="submit">ОПЛАТИТЬ</button>
                    <button v-if="products.length !== 0 && selected_delivery==='nalozh'" type="submit">ОСТАВИТЬ ЗАКАЗ</button>
                    <p v-if="payment_link" class="text-muted mt-3">Для оплаты вы будете перенаправлены на платёжный шлюз ПАО Сбербанк. Если перенаправление не произошло, нажмите по <a
                      class="payment-link text-primary" :href="payment_link"> этой ссылке</a></p>
                    <div class="clearfix"></div>
                  </div>
                </div>
                <div class="clearfix"></div>
              </form>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="clearfix"></div>
    </div>
    <div class="clearfix"></div>
    <!--footer-->
    <Footer
    />
  </div>
</template>

<script>
  import {apiService} from "@/common/api.service.js";
  import Footer from "@/components/Footer";
  import CheckoutProductCard from "@/components/CheckoutProductCard"

  export default {
    name: "Checkout",
    components: {
      CheckoutProductCard,
      Footer,
    },
    metaInfo: {
      title: 'Оформить заказ',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Оформить заказ в интернет-магазине Амбервиль'
        },
      ],
    },
    data() {
      return {
        products: [],
        discount: 0,
        get total() {
          let total = 0;
          let basket = JSON.parse(localStorage.basket);
          this.products.forEach(function (product) {
            total += product.discounted_price * basket[product.uid]
          });
          return total
        },
        login_username: "",
        login_password: "",
        firstname: "",
        secondname: "",
        lastname: "",
        address: "",
        zipcode: "",
        email: "",
        phone: "",
        popular_countries: [],
        countries: [],
        regions: [],
        locations: [],
        country_value: "",
        region_value: "",
        city_value: "",
        another_city: false,
        selected_delivery: "pochta",
        selected_payment: "cards",
        payment_link: null,

        get pochta_price() {
          let price = 290;
          let delivery_weight = 0;
          let basket = JSON.parse(localStorage.basket);
          this.products.forEach(function (product) {
            delivery_weight += product.delivery_weight * basket[product.uid]
          });
          if (delivery_weight > 400 && delivery_weight < 501) {
            price += 50;
          }
          if (delivery_weight > 500) {
            let additional = Math.ceil(delivery_weight / 500);
            price += (additional - 1) * 50;
          }
          return price
        },
        get pochta_international_price() {
          let price = 540;
          let delivery_weight = 0;
          let basket = JSON.parse(localStorage.basket);
          this.products.forEach(function (product) {
            delivery_weight += product.delivery_weight * basket[product.uid]
          });
          if (delivery_weight > 200 && delivery_weight < 451) {
            price = 830
          }
          else if (delivery_weight > 450 && delivery_weight < 901) {
            price = 1330
          }
          else if (delivery_weight > 900 && delivery_weight < 1001) {
            price = 2330;
          }
          else if (delivery_weight > 1000) {
            let additional = Math.ceil(delivery_weight / 1000);
            price = 2330 + (additional - 1) * 1000;
          }
          return price
        },
        get ems_price() {
          let price = 900;
          let delivery_weight = 0;
          let basket = JSON.parse(localStorage.basket);
          this.products.forEach(function (product) {
            delivery_weight += product.delivery_weight * basket[product.uid]
          });
          if (delivery_weight > 400 && delivery_weight < 1001) {
            price = 980;
          }
          else if (delivery_weight > 1000 && delivery_weight < 1501) {
            price = 1200;
          }
          else if (delivery_weight > 1500 && delivery_weight < 2001) {
            price = 1370;
          }
          else if (delivery_weight > 2000 && delivery_weight < 3001) {
            price = 1400;
          }
          else if (delivery_weight > 3000) {
            let additional = Math.ceil(delivery_weight / 1000);
            price = 1400 + (additional - 3) * 100;
          }
          return price
        },
        get ems_international_price() {
          let price = 2000;
          let delivery_weight = 0;
          let basket = JSON.parse(localStorage.basket);
          this.products.forEach(function (product) {
            delivery_weight += product.delivery_weight * basket[product.uid]
          });
          if (delivery_weight > 450 && delivery_weight < 951) {
            price = 2300
          }
          else if (delivery_weight > 950 && delivery_weight < 1451) {
            price = 2650;
          }
          else if (delivery_weight > 950 && delivery_weight < 1451) {
            price = 2650;
          }
          else if (delivery_weight > 1450 && delivery_weight < 2001) {
            price = 2950;
          }
          else if (delivery_weight > 2000 && delivery_weight < 3001) {
            price = 3350;
          }
          else if (delivery_weight > 3000) {
            let additional = Math.ceil(delivery_weight / 1000);
            price = 3350 + (additional - 3) * 400;
          }
          return price
        },
        get nalozh_price() {
          let price = 690;
          let delivery_weight = 0;
          let basket = JSON.parse(localStorage.basket);
          this.products.forEach(function (product) {
            delivery_weight += product.delivery_weight * basket[product.uid]
          });
          if (delivery_weight > 400 && delivery_weight < 501) {
            price += 50;
          }
          if (delivery_weight > 500) {
            let additional = Math.ceil(delivery_weight / 500);
            price += (additional - 1) * 50;
          }
          return price
        },
        get delivery_price() {
          let price = 0;
          if ((this.selected_delivery === 'pochta') && (this.total < 3000) && (this.country_value === 1) && (this.region_value !== "")) {
            price = this.pochta_price
          }
          else if ((this.selected_delivery === 'pochta') && (this.country_value !== 1) && (this.country_value !== "")) {
            price = this.pochta_international_price
          }
          else if ((this.selected_delivery === 'ems') && (this.total < 10000) && (this.country_value === 1) && (this.region_value !== "")) {
            price = this.ems_price
          }
          else if ((this.selected_delivery === 'ems') && (this.country_value !== 1) && (this.country_value !== "")) {
            price = this.ems_international_price
          }
          else if ((this.selected_delivery === 'nalozh') && (this.total <= 10000) && (this.country_value === 1) && (this.region_value !== "")) {
            price = this.nalozh_price
          }
          return price
        },
        get overall() {
          return this.total - this.discount + this.delivery_price
        },
        mail_discounts: true,
        login_errors: [],
        errors: [],
        submitted: false,
      }
    },
    computed: {
      promocode_discount() {
        return this.$store.state.promocode_discount
      },
      username() {
        return this.$store.state.username
      }
    },
    methods: {
      getBasketProducts() {
        try {
          let basket = JSON.parse(localStorage.basket);
          Object.keys(basket).forEach(function (key) {
            let endpoint = "/api/product/uid/";
            endpoint += '?uid=' + key;
            apiService(endpoint)
              .then(data => {
                if (data) {
                  if (data.quantity <= 0) {
                    delete basket[key];
                    basket = JSON.stringify(basket);
                    localStorage.setItem('basket', basket);
                    this.$store.dispatch('get_basket_quantity')
                  } else {
                    this.products.push(data);
                  }
                } else {
                  try {
                    delete basket[key];
                  } catch (e) {
                    localStorage.basket = '{}';
                  }
                  basket = JSON.stringify(basket);
                  localStorage.setItem('basket', basket);
                  this.$store.dispatch('get_basket_quantity')
                }
              });
          }, this)
        } catch (e) {
          localStorage.basket = '{}';
          let basket = JSON.parse(localStorage.basket);
        }
        ;

      },
      getPopularCountries() {
        let endpoint = "/api/countries/popular/";
        apiService(endpoint)
          .then(data => {
            this.popular_countries = data
          })
      },
      getCountries() {
        let endpoint = "/api/countries/";
        apiService(endpoint)
          .then(data => {
            this.countries = data
          })
      },
      getRegions() {
        let endpoint = "/api/regions/";
        apiService(endpoint)
          .then(data => {
            this.regions = data
          })
      },
      getLocations() {
        let endpoint = "/api/locations/";
        endpoint += "?region=" + this.region_value;
        this.$store.commit('changeLoading');
        apiService(endpoint)
          .then(data => {
            this.locations = data;
            this.$store.commit('changeLoading');
          })
      },
      get_discount() {
        if (this.promocode_discount) {
          let promocode_discount = this.promocode_discount;
          let discount = 0;
          let basket = JSON.parse(localStorage.basket);
          this.products.forEach(function (product) {
            if (product.discount_size === 0) {
              discount += product.price * basket[product.uid] * (promocode_discount / 100)
            }
          });
          if (discount > 0) {
            return this.discount = Math.round(discount)
          }
        }
      },
      changeCountry() {
        this.city_value = "";
        this.region_value = ""
        this.another_city = false;
        this.getLocations();
      },
      changeRegion() {
        this.country_value = 1;
        this.city_value = "";
        this.another_city = false;
        this.getLocations()
      },
      changeCity() {
        this.country_value = 1;
        if (this.city_value === "0") {
          this.city_value = "";
          this.another_city = true;
        } else {
          this.region_value = this.locations.find((location) => {
            if (this.city_value === location.id) {
              return true
            }
          }, this).region
        }
      },

      validate() {
        this.errors = [];
        const name_validation = /[!@#$%^№&*(),.?"=:;{}|<>+\\/\d]/;
        const location_validation = /[!@#$%^№&*?=:;{}|<>+]/;
        const zipcode_validation = /[!@#$%^№&*(),.?"'`=:;{}|<>+\\/]/;
        const address_validation = /[$%^*={}|<>]/;
        const email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const phone_validation = /[`!@#$%^№&?"'{}|<>a-zA-Z]/

        if (!this.firstname) {
          this.errors['firstname'] = 'Заполните это поле'
        } else if (this.firstname.match(name_validation)) {
          this.errors['firstname'] = 'Недопустимые символы'
        }

        if (this.secondname.match(name_validation)) {
          this.errors['secondname'] = 'Недопустимые символы'
        }

        if (!this.lastname) {
          this.errors['lastname'] = 'Заполните это поле'
        } else if (this.lastname.match(name_validation)) {
          this.errors['lastname'] = 'Недопустимые символы'
        }

        if (!this.city_value && (this.another_city || ((this.country_value !== 1) && (this.country_value !== "")))) {
          this.errors['city'] = 'Заполните это поле'
        } else if (String(this.city_value).match(location_validation)) {
          this.errors['city'] = 'Недопустимые символы'
        } else if (!this.city_value) {
          this.errors['city'] = 'Выберите город'
        }

        if (!this.region_value && (this.country_value !== 1) && (this.country_value !== "")) {
          this.errors['region'] = 'Заполните это поле'
        } else if (String(this.region_value).match(location_validation)) {
          this.errors['region'] = 'Недопустимые символы'
        } else if (!this.region_value) {
          this.errors['region'] = 'Выберите регион'
        }

        if (!this.country_value) {
          this.errors['country'] = 'Выберите страну'
        } else if (isNaN(this.country_value)) {
          this.errors['country'] = 'Недопустимые символы'
        }

        if (!this.zipcode) {
          this.errors['zipcode'] = 'Заполните это поле'
        } else if (this.zipcode.match(zipcode_validation)) {
          this.errors['zipcode'] = 'Недопустимые символы'
        }

        if (!this.address) {
          this.errors['address'] = 'Заполните это поле'
        } else if (this.address.match(address_validation)) {
          this.errors['address'] = 'Недопустимые символы'
        }

        if (!this.email) {
          this.errors['email'] = 'Заполните это поле'
        } else if (!this.email.match(email_regex)) {
          this.errors['email'] = 'Введите корректный e-mail'
        }

        if (!this.phone) {
          this.errors['phone'] = 'Заполните это поле'
        } else if (this.phone.match(phone_validation)) {
          this.errors['phone'] = 'Введите корректный номер телефона'
        }

        if (!this.selected_delivery) {
          this.errors['delivery'] = 'Выберите доставку'
        } else if ((this.selected_delivery !== 'pochta') && (this.selected_delivery !== 'ems') && (this.selected_delivery !== 'nalozh')) {
          this.errors['delivery'] = 'Недопустимые символы'
        }

        if (!this.selected_payment) {
          this.errors['payment'] = 'Выберите оплату'
        } else if ((this.selected_payment !== 'cards') && (this.selected_payment !== 'nalozh_d')) {
          this.errors['payment'] = 'Недопустимые символы'
        }

        return this.submit()
      },

      submit() {
        if ((Object.keys(this.errors).length === 0) && (this.products.length > 0)) {
          if (this.selected_delivery === 'nalozh') {
            var endpoint = "/api/order/reserve/";
          } else {
            var endpoint = "/api/order/create/";
          }
          console.log(endpoint)
          let method = "POST";
          let data = {
            'basket': JSON.parse(localStorage.basket), 'promocode': this.$store.state.verified_promocode, 'firstname': this.firstname,
            'secondname': this.secondname, 'lastname': this.lastname, 'country': this.country_value, 'region': this.region_value,
            'city': this.city_value, 'zipcode': this.zipcode, 'address': this.address, 'email': this.email, 'phone': this.phone,
            'delivery': this.selected_delivery, 'payment': this.selected_payment, 'overall': this.overall, 'mail_discounts': this.mail_discounts
          };
          let auth = null
          if (this.$store.state.a_token) {
            auth = this.$store.state.a_token
          }
          this.$store.commit('changeLoading');
          apiService(endpoint, method, data, auth)
            .then(data => {
              if (data[0] === '/error') {
                this.$router.push(data[0])
              } else {
                this.payment_link = data[0]
                window.open(data[0])
              }
              this.$store.commit('changeLoading');
            }, this)
        }
      },

      getClientInfo() {
        if (this.username) {
          let token = this.$store.state.a_token;
          let endpoint = "api/getclientinfo/";
          apiService(endpoint, undefined, undefined, token)
            .then(data => {
              if (data.first_name !== '') {
                this.firstname = data.first_name;
              }
              if (data.last_name !== '') {
                this.lastname = data.last_name;
              }
              if (data.second_name !== '') {
                this.secondname = data.second_name;
              }
              if (data.country !== '') {
                if (!isNaN(data.country)) {
                  this.country_value = Number(data.country)
                } else {
                  this.country_value = data.country
                }
              }
              if (data.region !== '') {
                if (!isNaN(data.region)) {
                  this.region_value = Number(data.region)
                } else {
                  this.region_value = data.region
                }
              }
              if (data.city !== '') {
                if (!isNaN(data.city)) {
                  this.city_value = Number(data.city)
                } else {
                  this.another_city = true;
                  this.city_value = data.city
                }
              }
              if (data.zipcode !== '') {
                this.zipcode = data.postal_code;
              }
              if (data.address !== '') {
                this.address = data.address;
              }
              if (data.phone_number !== '') {
                this.phone = data.phone_number;
              }
              this.email = data.email
            }, this)
        }
      },

      validateLogin() {
        this.login_errors = [];
        const validation = /[$%^*={}|<>]/;

        if (!this.login_password) {
          this.login_errors['login_password'] = 'Заполните это поле'
        } else if (this.login_password.match(validation)) {
          this.login_errors['login_password'] = 'Недопустимые символы'
        }

        if (!this.login_username) {
          this.login_errors['login_username'] = 'Заполните это поле'
        } else if (this.login_username.match(validation)) {
          this.login_errors['login_username'] = 'Недопустимые символы'
        }

        return this.submitLogin()
      },

      submitLogin() {
        if (Object.keys(this.login_errors).length === 0) {
          let endpoint = "/api/login/";
          let method = "POST";
          this.$store.commit('changeLoading');
          apiService(endpoint, method, {'username': this.login_username, 'password': this.login_password})
            .then(data => {
              if (data.a_token) {
                this.$store.dispatch('setUsername', data.username);
                this.$store.dispatch('setAToken', data.a_token);
                this.$cookie.set('token', data.a_token, {expires: 14, domain: '127.0.0.1'})
              } else {
                this.login_errors = [];
                this.login_errors['login_password'] = 'Неверный e-mail или пароль'
              }
              this.$store.commit('changeLoading');
            }, this)
        }
      }
    },

    watch: {
      username(newVal, oldVal) {
        this.getClientInfo()
      },
      selected_delivery(newVal, oldVal) {
        if (newVal === 'nalozh') {
          this.selected_payment = 'nalozh_d'
        } else {
          if (this.selected_payment === 'nalozh_d') {
            this.selected_payment = 'cards'
          }
        }
      },
      selected_payment(newVal, oldVal) {
        if (newVal === 'nalozh_d') {
          this.selected_delivery = 'nalozh'
        } else {
          if (this.selected_delivery === 'nalozh') {
            this.selected_delivery = 'pochta'
          }
        }
      },
      country_value(newVal, oldVal) {
        if (newVal !== '1') {
          if (this.selected_delivery === 'nalozh') {
            this.selected_delivery = 'pochta'
          }
          if (this.selected_payment === 'nalozh_d') {
            this.selected_payment = 'cards'
          }
        }
      }
    }
    ,

    created() {
      this.getBasketProducts();
      this.getPopularCountries();
      this.getCountries();
      this.getRegions();
      this.getLocations();
      this.getClientInfo()
    }
    ,
    updated() {
      this.get_discount()
    }
  }
</script>

<style scoped>

  .disabled-choice {
    width: 100%;
  }

  .disabled-choice {
    width: 80%;
    text-align: center;
    display: block;
    position: relative;
  }

  .delivery-img img {
    width: 100%;
  }

  .delivery-img {
    width: 80%;
    text-align: center;
    display: block;
    position: relative;
  }

  .right-form .col-4  p{
    margin-left: -25px;
  }

  .delivery-img:before,
  .delivery-img:after {
    position: absolute;
    content: "";
    transition: all .25s;
  }

  .delivery-img:before {
    border-bottom: 2px solid black;
    border-left: 2px solid black;
    width: 10%;
    height: 10%;
    left: -10px;
    bottom: 4px;
  }

  .delivery-img:after {
    border-top: 2px solid black;
    border-right: 2px solid black;
    width: 10%;
    height: 10%;
    top: -10px;
    right: -10px;
  }

  .delivery-img:hover:before {
    width: 115.5%;
    height: 105%;
  }

  .delivery-img:hover:after {
    width: 115.5%;
    height: 105%;
  }

  input[name='delivery'] {
    display: none;
  }

  input[name='payment'] {
    display: none;
  }

  label {
    cursor: pointer;
  }

  .selected-delivery:before {
    border-bottom: 2px solid goldenrod;
    border-left: 2px solid goldenrod;
    width: 115.5%;
    height: 105%;
    left: -10px;
    bottom: 4px;
  }

  .selected-delivery:after {
    border-top: 2px solid goldenrod;
    border-right: 2px solid goldenrod;
    width: 115.5%;
    height: 105%;
    top: -10px;
    right: -10px;
  }

  .title-form {
    width: 100%;
  }

  .desktop-form {
    display: flex;
  }

  .mobile-form {
    display: none
  }

  .payment-link {
    padding: 0;
    line-height: inherit;
  }

  @media (max-width: 1499px) {
    .delivery-img:hover:before {
      width: 123.5%;
      height: 108%;
    }

    .delivery-img:hover:after {
      width: 123.5%;
      height: 108%;
    }

    .selected-delivery:before {
      width: 123.5%;
      height: 108%;
    }

    .selected-delivery:after {

      width: 123.5%;
      height: 108%;
    }

  }

  @media (max-width: 991px) {
    .delivery-img:hover:before {
      width: 134%;
      height: 111%;
    }

    .delivery-img:hover:after {
      width: 134%;
      height: 111%;
    }

    .selected-delivery:before {
      width: 134%;
      height: 111%;
    }

    .selected-delivery:after {
      width: 134%;
      height: 111%;
    }

  }

  @media (max-width: 767px) {
    .delivery-img:hover:before {
      width: 116%;
      height: 105%;
    }

    .delivery-img:hover:after {
      width: 116%;
      height: 105%;
    }

    .selected-delivery:before {
      width: 116%;
      height: 105%;

    }

    .selected-delivery:after {
      width: 116%;
      height: 105%;

    }
  }

  @media (max-width: 575px) {
    .delivery-img:hover:before {
      width: 133%;
      height: 107%;
    }

    .delivery-img:hover:after {
      width: 133%;
      height: 107%;
    }

    .selected-delivery:before {
      width: 133%;
      height: 107%;
    }

    .selected-delivery:after {
      width: 133%;
      height: 107%;
    }

    .desktop-form {
      display: none
    }

    .mobile-form {
      display: flex
    }

  }

  @media (max-width: 400px) {
    .delivery-img:hover:before {
      width: 140%;
      height: 111%;
    }

    .delivery-img:hover:after {
      width: 140%;
      height: 111%;
    }

    .selected-delivery:before {
      width: 140%;
      height: 111%;
    }

    .selected-delivery:after {
      width: 140%;
      height: 111%;
    }
  }

  @media (max-width: 360px) {
    .delivery-img:hover:before {
      width: 143%;
      height: 111%;
    }

    .delivery-img:hover:after {
      width: 143%;
      height: 111%;
    }

    .selected-delivery:before {
      width: 143%;
      height: 111%;
    }

    .selected-delivery:after {
      width: 143%;
      height: 111%;
    }

  }
</style>