<template>
  <div id="wrapper">
    <section>
      <div class="inner-bg">
        <div class="inner-head wow fadeInDown">
          <h3>{{article.title}}</h3>
        </div>
      </div>
    </section>
    <div class="blog-bg">
      <div class="container p-0">
        <div class="shop-in pl-0 pr-0">
          <!--breadcrumbs -->
          <div class="bread2 ml-3">
            <ul>
              <li>
                <router-link to="/">На главную</router-link>
              </li>
              <li>/</li>
              <li>
                <router-link to="/blog">Блог</router-link>
              </li>
              <li>/</li>
              <li>{{article.title}}</li>
            </ul>
          </div>
          <!--breadcrumbs -->
          <div class="clearfix"></div>
          <div class="col-md-12 p-0">
            <div class="blog-in pt-4">
              <div class="wow fadeIn">
                <h1>{{article.title}}</h1>
                <ul class="comm-date">
                  <li><i class="fa fa-calendar" aria-hidden="true"></i>&nbsp; &nbsp;{{article.date_created}}</li>
                </ul>
                <div v-for="block in article.blocks"
                     :block="block"
                     :key="block.id">

                  <div class="text-center" v-if="block.type === 'image'"><img :src="block.image" :alt="article.title" class="img-fluid my-3"></div>
                  <div class="blog-text text-justify" v-else-if="block.type === 'text'">
                    <p>{{block.text}}</p>
                  </div>
                  <div class="blog-text text-justify" v-else-if="block.type === 'image+text'">
                    <p><img class="paragraph-image" :src="block.image" :alt="article.title" title=""> {{block.text}}</p>
                  </div>
                </div>
                <div class="pull-right">
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="clearfix"></div>
        <div class="older-posts wow fadeInDown">
          <h1>Другие статьи</h1>
          <div class="carousel slide">
            <!-- Carousel items -->
            <div class="posts-arrow"><a @click="decrementArticles">‹</a> <a @click="incrementArticles">›</a></div>
            <div class="row">
              <div class="carousel-item active col-4" v-for="article in article_list"
                   :article="article"
                   :key="article.id">
                <div class="posts-title ">
                  <div>
                    <router-link :to="{ name: 'Article', params: {id: article.id}, slug: slug(article.title)}">
                      <img v-if="article.image" :src="article.image" :alt="article.title" title="" class="img-fluid">
                      <img v-else src="/static/images/no-image.jpg" :alt="article.title" title="" class="img-fluid">
                    </router-link>
                  </div>
                  <h4>
                    <router-link :to="{ name: 'Article', params: {id: article.id}, slug: slug(article.title)}" class="link-txt">{{article.title}}</router-link>
                  </h4>
                  <p>{{article.date_created}}</p>
                </div>
              </div>
            </div>
            <!--/carousel-inner-->
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
  import {apiService} from "@/common/api.service.js";
  import {slugify} from "@/common/slugify.js";
  import Footer from "@/components/Footer";

  export default {
    name: "Article",
    components: {
      Footer,
    },
    data() {
      return {
        article: {},
        article_list: [],
        pages: Number,
        isActive: 1,
      }
    },
    metaInfo() {
      return {
        title: this.title,
      };
    },
    computed: {
      title() {
        if (this.article) {
          return this.article.title
        } else {
          return ''
        }
      },
    },
    methods: {
      slug(text) {
        return slugify(text)
      },
      getArticle() {
        let id = this.$route.params.id;
        let endpoint = "/api/blog/article/" + id + '/';
        this.$store.commit('changeLoading');
        apiService(endpoint)
          .then(data => {
            this.article = data;
            this.$store.commit('changeLoading');
          })
      },
      getArticleList() {
        let endpoint = "/api/blog/articlelist/" + "?page=" + this.isActive;
        apiService(endpoint)
          .then(data => {
            this.article_list = data.results;
            this.pages = Math.ceil(data.count / 3);
          })
      },
      decrementArticles() {
        if (this.isActive === 1) {
          this.isActive = this.pages
        } else {
          this.isActive -= 1
        }
        this.getArticleList();
      },
      incrementArticles() {
        if (this.isActive === this.pages) {
          this.isActive = 1
        } else {
          this.isActive += 1
        }
        this.getArticleList();
      }
    },
    created() {
      this.getArticle();
      this.getArticleList();
    },
    beforeRouteUpdate(to, from, next) {
      next();
      this.getArticle();
      this.getArticleList();
    },
  }
</script>

<style scoped>
  .paragraph-image {
    width: 25%;
    float: left;
    margin: 0 15px 15px 0
  }
</style>