import Vue from 'vue'
import Vuex from 'vuex'
import {apiService} from "@/common/api.service.js";


Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    username: null,
    a_token: null,
    activeGroup: 0,
    basketQuantity: 0,
    loading: false,
    days_left: 0,
    hours_left: 0,
    mins_left: 0,
    sec_left: 0,
    verified_promocode: null,
    promocode_discount: null,
    search_value: '',
    product_reload: false,
    ignore_get_products: false,
    ignore_active_change: false,
  },
  mutations: {
    changeGroup(state, group) {
      state.activeGroup = group
    },
    changeLoading(state) {
      state.loading = !state.loading;
    },
    setVerifiedPromocode(state, code) {
      state.verified_promocode = code
    },
    setPromocodeDiscount(state, discount) {
      state.promocode_discount = discount
    },
    setSearchValue(state, value) {
      state.search_value = value
    },
    reloadProduct(state) {
      state.product_reload = !state.product_reload;
    },
    switchProductIgnore(state) {
      state.ignore_get_products = !state.ignore_get_products;
    },
    switchActiveIgnore(state) {
      state.ignore_active_change = !state.ignore_active_change;
    },
  },
  actions: {
    setUsername(state, username) {
      return this.state.username = username
    },
    setAToken(state, a_token) {
      return this.state.a_token = a_token
    },
    get_basket_quantity() {
      var basket = JSON.parse(localStorage.basket);
      if (basket) {
        return this.state.basketQuantity = Object.keys(basket).length
      }
    },
    get_time_countdown() {
      let endpoint = "/api/datetime/";
      apiService(endpoint)
        .then(data => {
          this.state.days_left = 6 - data.day;
          this.state.hours_left = 23 - data.hour;
          this.state.mins_left = 59 - data.minute;
          this.state.sec_left = 59 - data.second;
          setInterval(function () {
            this.state.sec_left -= 1;
            if (this.state.sec_left < 0) {
              this.state.sec_left = 59
              this.state.mins_left -= 1
            }
            if (this.state.mins_left < 0) {
              this.state.mins_left = 59
              this.state.hours_left -= 1
            }
            if (this.state.hours_left < 0) {
              this.state.hours_left = 23
              this.state.days_left -= 1
            }
          }.bind(this), 1000)
        })
    },
    userCookies(state, token) {
      this.state.a_token = token;
      let endpoint = "/api/getuser/";
      apiService(endpoint, undefined, undefined, token)
        .then(data => {
          this.state.username = data.username
        })
    },
  },
  modules: {},
})

