<template>
  <div class="product-card" v-if="!deleted">
    <div class="row justify-content-between m-0">
      <h4>{{ product.uid }}</h4>
      <h4>{{ product.category.group.name }} - {{ product.category.name }}</h4>
    </div>
    <h2 class="text-center">{{ product.name }}</h2>
    <h3 class="text-center">{{ product.short_name }}</h3>
    <h3 class="text-center">{{ product.variant_description }}</h3>
    <div class="row justify-content-between m-0 pt-3">
      <h4 v-if="product.shop">{{ product.shop.name }}</h4>
      <div v-else></div>
      <div>
        <h4 v-if="product.quantity <= 0" class="text-danger">Нет в наличии</h4>
        <h4 v-if="product.active" class="text-success">Активно</h4>
        <h4 v-else class="text-danger">Неактивно</h4>
      </div>
    </div>
    <div class="row justify-content-end m-0">
      <div class="product-menu">
        <a data-toggle="modal" :data-target="dataTargetMainInfo">Редактировать</a>
      </div>
    </div>
    <div class="product-menu row justify-content-between mx-0 mt-5">
      <router-link :to="{ name: 'Detail', params: {id: product.id, slug: slug}}">Смотреть на сайте</router-link>
      <div class="position-relative">
        <img class="card-image" :src="product.main_image">
      </div>
      <a data-toggle="modal" :data-target="dataTargetDeleteProduct">Удалить</a>
    </div>
    <div class="product-menu row justify-content-between mx-0 mt-3">
      <a @click="show_photo = !show_photo" :class="{'product-menu-active': show_photo}">Фотографии</a>
      <a @click="show_prices = !show_prices" :class="{'product-menu-active': show_prices}">Цены/Количество</a>
      <a @click="show_attributes = !show_attributes" :class="{'product-menu-active': show_attributes}">Характеристики</a>
      <a @click="show_collections = !show_collections" :class="{'product-menu-active': show_collections}">Коллекции/Варианты</a>
      <a @click="show_description = !show_description" :class="{'product-menu-active': show_description}">Описание</a>
    </div>
    <div v-if="show_photo">
      <div class="row justify-content-center mx-0 mt-5 py-5 images">
        <div v-for="image in product.images"
             :key="image.id"
             class="col text-center">
          <img class="image-carousel" :src="image.image" @click="big_image = image.image; big_image_id = image.id">
          <p v-if="image.main" class="text-success m-1">Основная</p>
        </div>
      </div>
      <div class="row justify-content-between mx-0 my-3 product-menu">
        <a data-toggle="modal" :data-target="dataTargetNewPhoto">Новое фото</a>
        <a @click="makeImageMain()">Сделать основной</a>
        <a data-toggle="modal" :data-target="dataTargetDeletePhoto">Удалить</a>
      </div>
      <div class="text-center mt-3">
        <img :src="big_image" class="big-image">
      </div>
    </div>
    <div v-if="show_prices" class="mt-5">
      <form
        @submit.prevent="changePrices">
        <div class="row m-0">
          <div class="col-sm-4">
            <div class="form-group">
              <div class="row align-items-center mx-0 my-1">
                <h3 class="m-0">Цена: </h3>
                <input type="number" class="input-price mx-1" required v-model="product.price">
                <h3 class="m-0">руб.</h3>
              </div>
              <div class="row align-items-center mx-0 my-1">
                <h3 class="m-0">Количество: </h3>
                <span class="input-group-btn mx-2">
                    <button type="button" class="btn btn-default btn-number" @click="product.quantity -= 1"
                            :disabled="product.quantity <= 0">
                    <span class="glyphicon glyphicon-minus"></span> </button>
                  </span>
                <input name="quant[1]" class="input-quantity mx-1" required v-model="product.quantity" type="number">
                <span class="input-group-btn mx-2">
                    <button type="button" class="btn btn-default btn-number" @click="product.quantity += 1">
                    <span class="glyphicon glyphicon-plus"></span> </button>
                  </span>
              </div>
              <button type="submit" class="filter2 mt-3">Изменить</button>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <div class="row align-items-center mx-0 my-1">
                <h3 class="m-0">Скидка: </h3>
                <input type="number" class="input-quantity mx-1" required v-model="product.discount_size">
                <h3 class="m-0">%</h3>
              </div>
              <div class="row align-items-center mx-0 my-1">
                <h3 class="m-0">Цена со скидкой: </h3>
                <input type="number" class="input-price mx-1" v-model="product.discounted_price" readonly>
                <h3 class="m-0">руб.</h3>
              </div>
              <h3 class="text-success" v-if="product.discounted_week_number !== 0">Товар недели </h3>
              <span class="text-muted" v-if="product.discounted_week_number"
                    v-for="week in product.discounted_week_number">{{ week }} </span>
              <div class="row align-items-center mx-0 my-1" v-if="product.discounted_week">
                <h3 class="m-0">Скидка без акции: </h3>
                <input type="number" class="input-quantity mx-1" v-model="product.cached_discount">
                <h3 class="m-0">%</h3>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div v-if="tags.length > 0" class="row m-0 justify-content-end align-items-center">
              <h3 class="mr-3">Тэги: </h3>
              <div v-for="tag in product.tag">
                <h3>{{ tag.name }}</h3>
              </div>
            </div>
            <div class="product-menu ">
              <a class="my-1 ml-auto" data-toggle="modal" :data-target="dataTargetWeekDiscount">Недели скидок</a>
              <a class="my-1  ml-auto" data-toggle="modal" :data-target="dataTargetTag">Тэги</a>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div v-if="show_attributes">
      <div class="attr-grid my-5">
        <div class="attr-item mt-3" v-for="attr in product.attributes">
          <img :src="attr.image.image">
          <h3 class="ml-3">{{ attr.name }} - </h3>
          <h3 class="ml-2">{{ attr.value }}</h3>
        </div>
      </div>
      <div class="row justify-content-between align-items-center m-0">
        <h3 class="m-0" v-if="product.delivery_weight">Вес для рассчета доставки: {{ product.delivery_weight }} г</h3>
        <h3 class="m-0" v-else>Вес для рассчета доставки: 0 г</h3>
        <div class="product-menu">
          <a data-toggle="modal" :data-target="dataTargetAttributes">Редактировать</a>
        </div>
      </div>
    </div>
    <div v-if="show_collections">
      <div class="row mx-0 mt-5">
        <div class="col-sm-6 border-right">
          <div v-if="product.collection">
            <h2 class="text-center">Коллекция {{ product.collection.name }}</h2>
            <div class="row justify-content-between mx-0 mt-3">
              <h3>Другие изделия в коллекции: </h3>
              <div>
                <h3 v-for="col_product in product.collection.products" class="collection-link"
                    v-if="col_product.uid !== product.uid && col_product.variant === false && col_product.short_name !== product.short_name">
                  {{ col_product.uid }} - {{ col_product.short_name }}
                </h3>
              </div>
            </div>
          </div>
          <div v-else>
            <h2 class="text-center">Не находится в коллекции в коллекции</h2>
          </div>
        </div>
        <div class="col-sm-6">
          <div v-if="product.variant">
            <h2 class="text-center">Вариант</h2>
            <h3 class="text-center">{{ product.variant_description }}</h3>
            <div class="row justify-content-between mx-0 mt-3">
              <h3>Основной вариант: </h3>
              <h3 class="collection-link">{{ product.variant_item.uid }} -
                {{ product.variant_item.variant_description }}</h3>
            </div>
            <div class="row justify-content-between mx-0 mt-3">
              <h3>Другие варианты: </h3>
              <div>
                <h3 v-for="variant in product.variant_item.variants" class="collection-link"
                    v-if="variant.uid !== product.uid">{{ variant.uid }} - {{ variant.variant_description }}</h3>
              </div>
            </div>
          </div>
          <div v-else-if="product.variants.length > 0">
            <h2 class="text-center">Основной вариант</h2>
            <h3 class="text-center">{{ product.variant_description }}</h3>
            <div class="row justify-content-between mx-0 mt-3">
              <h3>Другие варианты: </h3>
              <div>
                <h3 v-for="variant in product.variants" class="collection-link">{{ variant.uid }} -
                  {{ variant.variant_description }}</h3>
              </div>
            </div>
          </div>
          <div v-else>
            <h2 class="text-center">Отсутствуют варианты изделия</h2>
          </div>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col-sm-6 text-center">
          <div class="product-menu ">
            <a class="my-1 ml-auto" data-toggle="modal" :data-target="dataTargetCollections">Редактировать</a>
          </div>
        </div>
        <div class="col-sm-6 text-center">
          <div class="product-menu ">
            <a class="my-1 ml-auto" data-toggle="modal" :data-target="dataTargetVariants">Редактировать</a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="show_description">
      <form class="form-group mt-5"
            @submit.prevent="changeDescription()">
        <textarea class="description-area" required v-model="product.description"></textarea>
        <h5 class="mt-4">
          <p class="bold">Комментарий:</p>
        </h5>
        <textarea class="comment-area mt-1" v-model="product.comment"></textarea>
        <button type="submit" class="filter2 mt-3 ml-auto">Изменить</button>
      </form>
    </div>
    <div class="modal fade" :id="idMainInfo" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeMainInfo" data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Редактировать общую информацию об изделии {{ product.short_name }}</h4>
          </div>
          <div class="modal-body text-center">
            <form class="form-horizontal"
                  @submit.prevent="changeMainInfo()">
              <div class="form-group">
                <div class="col-sm-12">
                  <input type="text" class="form-control" v-model="uid" required placeholder="Артикул"/>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <input type="text" class="form-control" v-model="product.name" required placeholder="Название"/>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <input type="text" class="form-control" v-model="product.short_name" required placeholder="Короткое название"/>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <select v-model="product.category.id" class="js-countries">
                    <option v-for="category in categories" :value="category.id">{{ category.group.name }} -
                      {{ category.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <select v-model="shop" class="js-countries">
                    <option value="0">Выберите магазин</option>
                    <option v-for="shop in shops" :value="shop.id">{{ shop.name }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row mx-0 align-items-center">
                <div class="col-sm-3">
                  <span>Приоритет</span>
                </div>
                <div class="col-sm-2">
                  <input type="number" class="form-control" v-model="product.priority"/>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <input type="checkbox" class="form-control" v-model="product.active"/> <span>Активный</span>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <button type="submit" class="btn btn-default button-1">Сохранить</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" :id="idNewPhoto" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeNewPhoto" data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Добавить новую фотографию для {{ product.short_name }}</h4>
          </div>
          <div class="modal-body text-center">
            <form class="form-horizontal"
                  @submit.prevent="uploadImage()">
              <div class="form-group">
                <input @change="selectToUpload" ref="fileUpload" type="file" hidden>
                <div v-if="uploaded_url === null">
                  <img class="button-cross" src='../assets/images/button-cross.png' @click="$refs.fileUpload.click()"/>
                </div>
                <div v-else>
                  <img class="button-cross" :src='uploaded_url' @click="$refs.fileUpload.click()"/>
                </div>
              </div>
              <div class="form-group">
                <input type="checkbox" class="mt-3 mb-2" v-model="uploaded_main"> <span class="mt-3">Основное</span>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <button type="submit" class="btn btn-default button-1 ">Сохранить</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" :id="idDeletePhoto" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content" v-if="product.images.length > 1">
          <div class="modal-header">
            <button type="button" class="close" ref="closeDeletePhoto" data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Удалить фотографию?</h4>
          </div>
          <div class="modal-body text-center">
            <form class="form-horizontal"
                  @submit.prevent="deleteImage()">
              <div class="form-group">
                <div class="col-sm-12">
                  <button type="submit" class="btn btn-default button-1 ">Удалить</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="modal-content" v-else>
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Невозможно удалить последнюю фотографию</h4>
          </div>
          <div class="modal-body text-center">
            <div class="form-group">
              <div class="col-sm-12">
                <button type="submit" data-dismiss="modal" class="btn btn-default button-1 ">Закрыть</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" :id="idWeekDiscount" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeWeekDiscount" data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Недели скидок для {{ product.short_name }}</h4>
          </div>
          <div class="modal-body text-center">
            <form class="form-horizontal"
                  @submit.prevent="changeWeeklyDiscount()">
              <div class="row justify-content-between m-0">
                <div class="form-group">
                  <input type="checkbox" class="mt-3 mb-2" v-model="week_1"> <span class="mt-3">Текущая</span>
                </div>
                <div class="form-group">
                  <input type="checkbox" class="mt-3 mb-2" v-model="week_2"> <span class="mt-3">Следующая</span>
                </div>
                <div class="form-group">
                  <input type="checkbox" class="mt-3 mb-2" v-model="week_3"> <span class="mt-3">Через неделю</span>
                </div>
                <div class="form-group">
                  <input type="checkbox" class="mt-3 mb-2" v-model="week_4"> <span class="mt-3">Через 2 недели</span>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <button type="submit" class="btn btn-default button-1 ">Сохранить</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" :id="idTag" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeTag" data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Тэги для {{ product.short_name }}</h4>
          </div>
          <div class="modal-body text-center">
            <form class="form-horizontal"
                  @submit.prevent="changeTags()">
              <div v-for="tag in tags" class="form-group">
                <input type="checkbox" class="mt-3 mb-2" :value="tag.id" v-model="checked_tags"> <span
                class="mt-3">{{ tag.name }}</span>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <button type="submit" class="btn btn-default button-1 ">Сохранить</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" :id="idAttributes" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeAttributes" data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Характеристики {{ product.short_name }}</h4>
          </div>
          <div class="modal-body text-center" v-if="choose_attr_photo">
            <div class="row mx-3">
              <div v-for="image in attr_images">
                <img class="m-3 pointer" @click="selected_attr_image = image.id" :class="{selected: image.id === selected_attr_image}" :src="image.image">
              </div>
            </div>
            <div class="form-group">
              <div class="row m-0 justify-content-between">
                <a @click="choose_attr_photo = false; selected_attr_image=''" class="btn btn-default button-1 text-white">Назад</a>
                <a v-if="!new_attribute" @click="chooseAttrImage(selected_attr_image)" class="btn btn-default button-1 text-white">Сохранить</a>
                <a v-else @click="chooseNewAttrImage(selected_attr_image)" class="btn btn-default button-1 text-white">Сохранить</a>
              </div>
            </div>
          </div>
          <div class="modal-body text-center" v-else-if="!new_attribute">
            <form class="form-horizontal"
                  @submit.prevent="changeAttributes">
              <div v-for="attr in product.attributes" class="form-group row align-items-center">
                <input type="text" class="mr-2 mb-0 ml-3 col-3" required v-model="attr.name">
                <input type="text" class="mr-2 mb-0 col-4" required v-model="attr.value">
                <div @click="editAttributeImage(attr.id, attr.image.id)" class="col-3 mb-0 btn btn-default button-1 pl-2 pt-2">Выбрать иконку</div>
                <a class="close col-1" @click="deleteAttribute(attr.id)"><span aria-hidden="true">X</span></a>
              </div>
              <div class="text-left mb-5">
                <span>Вес для доставки: </span>
                <input type="text" class="mx-2" v-model="product.delivery_weight">
                <span>г</span>
              </div>
              <div class="form-group">
                <div class="row m-0 justify-content-between">
                  <a @click="new_attribute = true" class="btn btn-default button-1 text-white">Добавить</a>
                  <button type="submit" class="btn btn-default button-1 ">Сохранить</button>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-body text-center" v-else>
            <p v-if="selected_attr_image === ''" class="text-danger">Выберите иконку для характеристики</p>
            <form class="form-horizontal"
                  @submit.prevent="createAttribute">
              <div class="form-group row ">
                <input type="text" class="mr-2 ml-3 col-4" placeholder="Название" required v-model="new_attr_name">
                <input type="text" class="mr-2 col-4" placeholder="Значение" required v-model="new_attr_value">
                <div @click="editNewAttributeImage()" class="col-3 mb-0 btn btn-default button-1 pl-2 pt-2">Выбрать иконку</div>
              </div>
              <div class="form-group">
                <div class="row m-0 justify-content-between">
                  <a @click="new_attribute = false" class="btn btn-default button-1 text-white">Редактировать</a>
                  <button type="submit" class="btn btn-default button-1 ">Сохранить</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" :id="idCollections" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeCollections" data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Коллекции {{ product.short_name }}</h4>
          </div>
          <div class="modal-body text-center">
            <form class="form-horizontal"
                  @submit.prevent="changeCollections()">
              <div class="form-group row m-0">
                <h3>Выберите коллекцию: </h3>
                <select
                  class="js-countries mb-4"
                  v-model="product_collection">
                  <option value="" selected="selected">Не в коллекции</option>
                  <option v-for="collection in collections" :value="collection.id">{{ collection.name }}</option>
                </select>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <button type="submit" class="btn btn-default button-1 ">Сохранить</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" :id="idVariants" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeVariants" data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Вариант {{ product.short_name }}</h4>
          </div>
          <div class="modal-body text-center">
            <form class="form-horizontal"
                  @submit.prevent="changeVariant()">
              <div class="form-group">
                <h3>Вариант</h3>
                <input type="checkbox" v-model="variant_check">
                <select
                  v-if="variant_check"
                  class="js-countries mt-4"
                  v-model="product_variant">
                  <option value="" selected="selected" disabled>Выберите изделие</option>
                  <option v-for="product_item in product_list" :value="product_item.id">{{ product_item.uid }} -
                    {{ product_item.short_name }}
                  </option>
                </select>
                <div class="row mx-0 mt-4 align-items-baseline">
                  <h3 class="col-3">Описание</h3>
                  <input class="col-8" type="text" v-model="product.variant_description">
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-12">
                  <button type="submit" class="btn btn-default button-1 ">Сохранить</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" :id="idDeleteProduct" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" ref="closeDeleteProduct" data-dismiss="modal"><span
              aria-hidden="true">&times;</span><span class="sr-only">Закрыть</span></button>
            <h4 class="modal-title">Удалить изделие {{ product.short_name }}?</h4>
          </div>
          <div class="modal-body text-center">
            <form class="form-horizontal"
                  @submit.prevent="deleteProduct()">
              <div class="form-group">
                <div class="col-sm-12">
                  <button type="submit" class="btn btn-default button-1 ">Удалить</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {apiService} from "@/common/api.service.js";
  import {slugify} from "@/common/slugify.js";


  export default {
    name: "ManagerProductCard",
    props: {
      product: {
        type: Object,
        required: true
      },
      categories: {
        type: Array,
      },
      shops: {
        type: Array,
      },
      week_1_list: {
        type: Array,
      },
      week_2_list: {
        type: Array,
      },
      week_3_list: {
        type: Array,
      },
      week_4_list: {
        type: Array,
      },
      tags: {
        type: Array,
      },
      attr_images: {
        type: Array,
      },
      collections: {
        type: Array,
      },
      product_list: {
        type: Array,
      },
    },
    computed: {
      slug() {
        return slugify(this.product.name)
      },
      dataTargetMainInfo() {
        return '#mainInfo' + this.product.id
      },
      idMainInfo() {
        return 'mainInfo' + this.product.id
      },
      dataTargetNewPhoto() {
        return '#newPhoto' + this.product.id
      },
      idNewPhoto() {
        return 'newPhoto' + this.product.id
      },
      dataTargetDeletePhoto() {
        return '#deletePhoto' + this.product.id
      },
      idDeletePhoto() {
        return 'deletePhoto' + this.product.id
      },
      dataTargetWeekDiscount() {
        return '#weekDiscount' + this.product.id
      },
      idWeekDiscount() {
        return 'weekDiscount' + this.product.id
      },
      dataTargetTag() {
        return '#tag' + this.product.id
      },
      idTag() {
        return 'tag' + this.product.id
      },
      dataTargetAttributes() {
        return '#attr' + this.product.id
      },
      idAttributes() {
        return 'attr' + this.product.id
      },
      dataTargetCollections() {
        return '#collections' + this.product.id
      },
      idCollections() {
        return 'collections' + this.product.id
      },
      dataTargetVariants() {
        return '#variants' + this.product.id
      },
      idVariants() {
        return 'variants' + this.product.id
      },
      dataTargetDeleteProduct() {
        return '#deleteProduct' + this.product.id
      },
      idDeleteProduct() {
        return 'deleteProduct' + this.product.id
      },
    },
    data() {
      return {
        deleted: false,
        uid: this.product.uid,
        shop: (this.product.shop) ? this.product.shop.id : 0,
        big_image: this.product.main_image,
        big_image_id: undefined,
        uploaded_file: null,
        uploaded_url: null,
        uploaded_main: false,
        show_photo: false,
        week_1: this.week_1_list.includes(this.product.id),
        week_2: this.week_2_list.includes(this.product.id),
        week_3: this.week_3_list.includes(this.product.id),
        week_4: this.week_4_list.includes(this.product.id),
        checked_tags: [],
        show_prices: false,
        new_attribute: false,
        edited_attribute: '',
        choose_attr_photo: false,
        selected_attr_image: '',
        new_attr_name: undefined,
        new_attr_value: undefined,
        new_attr_image: undefined,
        show_attributes: false,
        product_collection: (this.product.collection) ? this.product.collection.id : '',
        product_variant: (this.product.variant) ? this.product.variant_item.id : '',
        variant_check: this.product.variant,
        show_collections: false,
        show_description: false,
      }
    },
    methods: {

      getBigImageID() {
        var image;
        for (image of this.product.images) {
          if (image.main) {
            this.big_image_id = image.id;
            return
          }
        }
      },

      getCheckedTags() {
        var tag;
        for (tag of this.product.tag) {
          this.checked_tags.push(tag.id)
        }
      },

      changeMainInfo() {
        let endpoint = "/api/manager/product/" + this.product.id + "/";
        let method = "PATCH";
        let data = {
          "uid": this.uid, "name": this.product.name, "short_name": this.product.short_name,
          "category": this.product.category.id, "active": this.product.active, "priority": this.product.priority
        };
        if (this.shop != '0') {
          data["shop"] = this.shop
        } else {
          data["nullify_shop"] = true
        }
        let auth = this.$store.state.a_token;
        this.product.uid = this.uid;
        this.product.category = this.categories.filter(category => {
          return category.id === this.product.category.id
        })[0];
        if (this.shop !== 0) {
          this.product.shop = this.shops.filter(shop => {
            return shop.id === this.shop
          })[0];
        }
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            if (data) {
              this.$refs.closeMainInfo.click()
            }
          })
      },

      makeImageMain() {
        let endpoint = "/api/manager/productimage/" + this.big_image_id + "/";
        let method = "PATCH";
        let data = {"main": true};
        let auth = this.$store.state.a_token;
        var image;
        for (image of this.product.images) {
          if (image.id === this.big_image_id) {
            image.main = true;
            this.product.main_image = image.image
          } else {
            image.main = false;
          }
        }
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, auth)
          .then(data => {
            this.$store.commit('changeLoading');
          })
      },

      selectToUpload(e) {
        this.uploaded_file = e.target.files[0];
        this.uploaded_url = URL.createObjectURL(this.uploaded_file);
      },

      uploadImage() {
        let endpoint = "/api/manager/productimage/";
        let data = new FormData;
        data.append('image', this.uploaded_file);
        data.append('main', this.uploaded_main);
        data.append('product', this.product.id);
        this.$store.commit('changeLoading');
        this.$http.post(endpoint, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Token ' + this.$store.state.a_token,
          },
        }).then(data => {
          this.$store.commit('changeLoading');
          this.$refs.closeNewPhoto.click();
          this.$emit('reloadProducts');
        }, this)
      },

      deleteImage() {
        let endpoint = "/api/manager/productimage/" + this.big_image_id + "/";
        let method = "DELETE";
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, method, undefined, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.$refs.closeDeletePhoto.click();
            this.$emit('reloadProducts');
            this.big_image = this.product.images[0].image;
            this.big_image_id = this.product.images[0].id;
          })
      },

      changePrices() {
        let endpoint = "/api/manager/product/" + this.product.id + "/";
        let method = "PATCH";
        let data = {
          "price": this.product.price, "quantity": this.product.quantity, "discount_size": this.product.discount_size,
          'cached_discount': this.product.cached_discount
        };
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, auth)
          .then(data => {
            this.$store.commit('changeLoading');
          });
        this.product.discounted_price = this.product.price - (this.product.price * (this.product.discount_size / 100));
      },

      changeWeeklyDiscount() {
        let endpoint = "api/manager/weeklydiscount/update_products/";
        let auth = this.$store.state.a_token;
        let method = "PATCH";
        let data = {
          "id": this.product.id,
          "week_1": this.week_1,
          "week_2": this.week_2,
          "week_3": this.week_3,
          "week_4": this.week_4,
        };
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.$refs.closeWeekDiscount.click();
            this.$emit('reloadProducts');
          })
      },

      changeTags() {
        let endpoint = "/api/manager/product/" + this.product.id + "/";
        let method = "PATCH";
        let data = {"tag": this.checked_tags};
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.$refs.closeTag.click();
            this.$emit('reloadProducts');
          })
      },

      changeAttributes() {
        var attr;
        for (attr of this.product.attributes) {
          let endpoint = "/api/manager/productattribute/" + attr.id + "/";
          let method = "PATCH";
          let auth = this.$store.state.a_token;
          let data = {"name": attr.name, "value": attr.value, "image": attr.image.id};
          this.$store.commit('changeLoading');
          apiService(endpoint, method, data, auth)
            .then(data => {
              this.$store.commit('changeLoading');
            });
          attr.image.image = this.attr_images.filter(image => {
            return image.id === attr.image.id
          })[0].image;
        }
        let endpoint = "/api/manager/product/" + this.product.id + "/";
        let data = {"delivery_weight": this.product.delivery_weight};
        let method = "PATCH";
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.$refs.closeAttributes.click()
          })
      },

      createAttribute() {
        let endpoint = "/api/manager/productattribute/";
        let method = "POST";
        let auth = this.$store.state.a_token;
        let data = {
          "name": this.new_attr_name,
          "value": this.new_attr_value,
          "image": this.new_attr_image,
          "product": this.product.id
        };
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.new_attribute = false;
            this.$emit('reloadProducts');
          })
      },

      deleteAttribute(id) {
        let endpoint = "/api/manager/productattribute/" + id + "/";
        let method = "DELETE";
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, method, undefined, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.$emit('reloadProducts');
          })
      },

      changeCollections() {
        let endpoint = "/api/manager/product/" + this.product.id + "/";
        let method = "PATCH";
        let data_collection = (this.product_collection) ? this.product_collection : null;
        let data = {'collection': data_collection};
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.$refs.closeCollections.click();
            this.$emit('reloadProducts');
          })
      },

      changeVariant() {
        let endpoint = "/api/manager/product/" + this.product.id + "/";
        let method = "PATCH";
        let data = {
          'variant': this.variant_check,
          'variant_item': this.product_variant,
          'variant_description': this.product.variant_description
        };
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.$refs.closeVariants.click();
            this.$emit('reloadProducts');
          })
      },

      changeDescription() {
        let endpoint = "/api/manager/product/" + this.product.id + "/";
        let method = "PATCH";
        let data = {"description": this.product.description, "comment": this.product.comment};
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, method, data, auth)
          .then(data => {
            this.$store.commit('changeLoading');
          });
      },

      deleteProduct() {
        let endpoint = "/api/manager/product/" + this.product.id + "/";
        let method = "DELETE";
        let auth = this.$store.state.a_token;
        this.$store.commit('changeLoading');
        apiService(endpoint, method, undefined, auth)
          .then(data => {
            this.$store.commit('changeLoading');
            this.$refs.closeDeleteProduct.click();
            this.$emit('reloadProducts');
          })
      },
      editAttributeImage(id, image) {
        this.edited_attribute = id;
        this.selected_attr_image = image;
        this.choose_attr_photo = true;
      },
      editNewAttributeImage(id, image) {
        this.selected_attr_image = '';
        this.choose_attr_photo = true;
      },
      chooseAttrImage() {
        this.product.attributes.filter(function (attr) {
            return attr.id === this.edited_attribute
          }, this)[0].image.id = this.selected_attr_image;
        this.choose_attr_photo = false;
        this.selected_attr_image = '';
      },
      chooseNewAttrImage() {
        this.new_attr_image = this.selected_attr_image
        this.choose_attr_photo = false;
      }
    },

    created() {
      this.getBigImageID();
      this.getCheckedTags();
    }
  }
</script>

<style scoped>
  .images {
    border: 1px solid black;
  }

  .image-carousel {
    width: 100%;
    max-width: 200px;
    padding: 0 2px;
    border-right: 1px solid black;
    border-left: 1px solid black;
    cursor: pointer;
  }

  .deleteLink {
    cursor: pointer;
  }

  .product-card {
    border: solid 1px black;
    margin: 10px 0;
    padding: 10px;
  }

  .product-menu a {
    text-align: center;
    background-color: white;
    font-weight: 600;
    color: #000;
    font-family: 'Open Sans', sans-serif;
    width: 240px;
    height: 60px;
    text-transform: uppercase;
    display: block;
    border: solid 2px #dfb859;
    line-height: 60px;
    margin: 0;
  }

  .product-menu a:hover {
    background: #222;
    border: solid 2px #222;
    color: #fff;
  }

  .product-menu-active {
    background: #222 !important;
    border: solid 2px #222 !important;
    color: #fff !important;
  }

  .big-image {
    width: 100%;
    max-width: 600px;
  }

  .input-price {
    width: 120px;
    font-size: 20px;
  }

  .input-quantity {
    width: 80px;
    font-size: 20px;
  }

  .attr-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .attr-item {
    display: flex;
    align-items: center;
  }

  .collection-link {
    cursor: pointer;
    text-decoration: underline;
    color: #575757;
  }

  .card-image {
    width: 150px;
    position: absolute;
    top: -150px;
    left: -75px;
  }

  .ml-50 {
    margin-left: 50%;
  }

  .description-area {
    font-size: 16px;
    height: 150px;
    width: 100%;
    max-width: 100%;
  }

  .comment-area {
    font-size: 12px;
    height: 70px;
    width: 100%;
    max-width: 100%;
  }

  .button-cross {
    width: 50%;
    text-align: center;
    cursor: pointer;
  }

  .selected {
    border: solid 3px #dfb859;
  }
</style>